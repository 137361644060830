<!-- eslint-disable vue/no-v-model-argument -->
<!-- eslint-disable vue/no-multiple-template-root -->
<template>
    <div class="item-list" ref="listItem">
        <div class="item-list-imgbox" v-if="item.pictureUrl">
            <div class="item-list-imgbox-center">
                <img class="item-list-img pic" v-lazy="item.pictureUrl" width="100%" height="100%" ref="imgRef" :class="'item-list-img' + item.pictureId" @load="loadHandler(item.pictureId)" />
                <img class="item-list-img tic" v-if="item1.titlePicture ? item1.titlePicture.pictureAddress : false" :src="item1.titlePicture.pictureAddress" :class="{ hide: isLook }" alt="" width="100%" height="100%">
                <img class="item-list-img tic" v-else :class="{ hide: isLook }" v-lazy="'https://a.imamemo.com/imageData/20230621/80f4075448124cc7afb09060574ee332.png'" alt="" width="100%" height="100%">
                <!-- <img class="item-list-img pic" -->
                <!-- src="https://pica.zhimg.com/v2-3b4fc7e3a1195a081d0259246c38debc_720w.jpg?source=172ae18b" /> -->
                <!-- <img class="item-list-img tic" -->
                <!-- src="https://img.iplaysoft.com/wp-content/uploads/2019/free-images/free_stock_photo.jpg!0x0.webp" /> -->
                <div class="edit-title" @click="addOrUpdateHandle">
                    <EditOutlined style="color: #fff;font-size: 20px;" />
                </div>
            </div>
        </div>
        <div class="item-dec" v-if="!item.pictureUrl">
            <span v-html="item.comment"></span>
        </div>
        <div class="item-info">
            <div class="info-left">
                <a-popover placement="bottomLeft">
                    <template #content>
                        <div class="searchTitle">{{ $t('searchTerm') }}</div>
                        <img class="searchImg" src="../../assets/guge.png" @click="goGuge(item.title)" height="21.5" width="26" />
                        <img class="searchImg" src="../../assets/weiji.png" @click="goWeiji(item.title)" height="26" width="26" />
                    </template>
                    <span class="item-info-name" v-show="item.isShow" @click="goDetail">{{ item.title }}</span>
                </a-popover>
                <span class="item-info-time">
                    {{ this.getWaitDurationTime(item.insertTime) }}
                </span>
                <a-button type="primary" class="item-info-follow" :class="{ active: item.isFollow }" @click="goFollow" v-show="item.isShow">{{
                    $t(`detail.${item.isFollow ?
                        'followed' : 'follow'}`) }}</a-button>
            </div>
            <div class="info-right">
                <div class="icon-box" style="margin-top: 10px">
                    <a-popover placement="bottom" v-model:visible="popVisible">
                        <template #content>
                            <div class="link">{{ shareUrl + '#/' + item.entryId + '/t' + '?' + 'pictureId=' +
                                item.pictureId }}</div>
                            <a-Button type="primary" danger class="embed" @click="onEmbed">{{ $t('embeddedCode')
                            }}</a-Button>
                            <a-Button type="primary" class="copy embed" @click="onCopy">{{ $t('copyLink') }}</a-Button>
                        </template>
                        <span class="icon-def-box">
                            <svg width="20" height="17" fill="currentColor" style="margin-top: -20px" @click="click">
                                <path d="M85.239,149.619a1.179,1.179,0,0,1-.172-.013,1.287,1.287,0,0,1-1.057-1.443c.439-4.221,1.882-7.359,4.291-9.331a11.025,11.025,0,0,1,6.512-2.365l.149-.009v-2.345a1.549,1.549,0,0,1,.4-1.022,1.45,1.45,0,0,1,1.059-.472,1.419,1.419,0,0,1,1.028.44l5.836,6.2a2.642,2.642,0,0,1,0,3.6l-5.838,6.2a1.428,1.428,0,0,1-.988.438h-.042a1.484,1.484,0,0,1-1.46-1.485V145.5h-.249c-2.25,0-6.339.462-8.453,3.557a1.226,1.226,0,0,1-1.021.56h0Zm9.474-5.558c.351,0,.628.01.794.016h.016l.13,0a.707.707,0,0,1,.692.721v3.379l5.957-6.323a1.163,1.163,0,0,0,0-1.587l-5.955-6.332v3.224a.707.707,0,0,1-.692.721c-3.954,0-8.921,1.623-10.13,9.356l-.084.535.355-.4C88.38,144.492,92.492,144.061,94.713,144.061Z" transform="translate(-83.999 -132.619)" />
                            </svg>
                        </span>
                    </a-popover>
                </div>
                <div class="icon-box" @click="favoriteHandle" :class="userModel.isFavorite ? 'ismy' : ''">
                    <span class="icon-bg-box shoucang" :style="{ clipPath: 'polygon(0% ' + calcPer(item.favoriteNum || 0) + '%, 100% ' + calcPer(item.favoriteNum || 0) + '%, 100% 100%, 0% 100%)' }">
                    </span>
                    <span class="icon-def-box">
                        <svg width="20" height="19" viewBox="0 0 20 19" fill="currentColor">
                            <path d="M67.93,96.69a1.5,1.5,0,0,0,1.53.08L74,94.374l4.538,2.4a1.5,1.5,0,0,0,1.531-.08,1.353,1.353,0,0,0,.591-1.351l-.884-5.179,3.815-3.735a1.332,1.332,0,0,0,.335-1.4,1.431,1.431,0,0,0-1.151-.926l-5.2-.759-2.263-4.6a1.474,1.474,0,0,0-2.615,0l-2.262,4.6-5.2.759a1.43,1.43,0,0,0-1.151.926,1.333,1.333,0,0,0,.336,1.4l3.813,3.735-.885,5.179A1.354,1.354,0,0,0,67.93,96.69ZM65.214,85.471l5.188-.756.913-.133.4-.814L74,79.127l2.282,4.641.4.814.913.133,5.188.756L79,89.171l-.635.622.148.867.884,5.169-4.572-2.411L74,92.983l-.826.436L68.6,95.831l.884-5.17.148-.867L69,89.171Z" transform="translate(-63.998 -77.942)" />
                        </svg>
                    </span>
                </div>

                <div class="icon-box" @click="openComment()">
                    <span class="icon-bg-box pinglun" :style="{ clipPath: 'polygon(0% ' + calcPer(item.commentNum || 0) + '%, 100% ' + calcPer(item.commentNum || 0) + '%, 100% 100%, 0% 100%)' }">
                    </span>
                    <span class="icon-def-box">
                        <svg width="20" height="19" viewBox="0 0 20 19" fill="currentColor">
                            <path d="M1.7,15c-0.3-0.4-0.4-0.7-0.5-0.8C0.4,12.9,0,11.5,0,10C-0.3,4.8,4,0.3,9.5,0S19.7,3.8,20,9s-4,9.7-9.5,10c-0.2,0-0.3,0-0.5,0c-1.8,0-3.6-0.5-5.1-1.2l-0.5-0.3L2,18c-0.4,0.1-0.8-0.1-0.9-0.5c0-0.1,0-0.3,0-0.4L1.7,15zM3.2,15l-0.4,1.4L4.4,16c0.2-0.1,0.4,0,0.6,0.1c0.3,0.1,0.5,0.3,0.8,0.4c1.4,0.8,3,1.1,4.6,1.1c4.7-0.2,8.3-4,8.1-8.4s-4.2-7.9-8.9-7.7C5.1,1.7,1.5,5.3,1.5,9.6c0,1.3,0.4,2.7,1.1,3.8c0.1,0.2,0.3,0.5,0.7,1C3.3,14.6,3.3,14.8,3.2,15L3.2,15z" />
                        </svg>
                    </span>
                </div>
                <div class="icon-box" @click="likeHandle" :class="userModel.isLike ? 'ismy' : ''">
                    <span class="icon-def-box">
                        <HeartOutlined style="font-size: 20px;" />
                    </span>
                </div>
                <div class="icon-box">
                    <a-popover placement="bottom" v-model:visible="report">
                        <template #content>
                            <div style="display: flex;flex-direction:column">
                                <a-Button style="color: #0870D1;border: 0" @click="blockUser">{{
                                    $t('detail.notShowAgainLeft') + item.insertUser +
                                    $t('detail.notShowAgainRight') }}</a-Button>
                                <a-Button style="color: #0870D1;margin-top: 5px;border: 0" @click="openReport">{{
                                    $t('detail.report') }}</a-Button>
                                <a-Button style="color: #0870D1;margin-top: 5px;  border: 0" v-show="isShow" @click="deleteImag">{{ this.$t('deletePicture.delete') }}</a-Button>
                            </div>
                        </template>
                        <div class="icon-def-box">
                            <MoreOutlined style="font-size:20px" />
                        </div>
                    </a-popover>
                </div>
            </div>
        </div>
        <div class="item-dec" v-if="item.pictureUrl">
            <span v-html="item?.comment?.replace(/<[^>]+>|&[^>]+;/g, ''.trim())">
            </span>
        </div>
    </div>
    <Reportdialog :reportDialog="reportDialog" ref="Reportdialog" :footer="null" centered cancelText @reportsubmit="reportsubmit($event)" @reportClose="reportClose" />
    <Embeddialog :showDialog="dialogVisible" ref="Embeddialog" :content="iframe" :footer="null" centered cancelText @submit="submit" @close="close" />
    <!-- 删除 -->
    <DeleteDialog :deleteDialog="deleteDialog" :list="item" ref="DeleteDialog" :footer="null" centered cancelText @deleteSubmit="deleteSubmit($event)" @deleteClose="deleteClose" />
</template>

<script>
import moment from "moment";
import { shareURL } from '@/utils/request';
import { calcPercentage } from '@/utils/common';
import storage from "storejs";
import { HeartOutlined, MoreOutlined, EditOutlined } from '@ant-design/icons-vue';
import Api from '@/Api';
import eventBus from '@/utils/eventBus.js';
// 举报选择框组件
import Reportdialog from "@/components/Reportdialog.vue";
// 嵌入代码框组件
import Embeddialog from "@/components/Embeddialog.vue";
//删除
import DeleteDialog from '@/components/DeleteDialog.vue';
import { toRefs } from 'vue';
// import $ from 'jquery'

export default {
    setup(props) {
        const { item: item1 } = toRefs(props)
        return { item1 }
    },
    emits: ['updateTitle', 'openCommentHandler', 'loadHandler'],
    props: {
        item: {
            type: Object,
            required: true
        },
        isLook: {
            type: Boolean,
            required: true
        },
        isMyInfo: Boolean,
        nowIndex: Number
    },
    components: { HeartOutlined, MoreOutlined, Reportdialog, Embeddialog, DeleteDialog, EditOutlined },
    data() {
        return {
            //转发气泡框
            popVisible: false,
            //删除框
            deleteDialog: false,
            shareUrl: shareURL,
            iframe: '',
            //嵌入代码框
            dialogVisible: false,
            userModel: {
                isLike: this.$props.item.isLike,
                isFavorite: this.$props.item.isFavorite
            },
            report: false,
            reportDialog: false,
            isShow: true,
            reportVisible: false,
            loaded: false,
            pictureItem: null
        }
    },
    mounted() {
        const user = storage.get('userInfo') || {};
        const itemUser = this.$props.item?.insertUser
        this.isShow = itemUser === user.userid;
    },
    methods: {
        // 关注条目
        async goFollow() {
            try {
                if (this.checkLogin()) {
                    return;
                }
                let userInfo = storage.get('userInfo') || '';
                if (!userInfo) return;
                const params = { userId: userInfo.userid, treeId: this.$props.item.entryId }
                !this.$props.item.isFollow ? await Api.postNote(params) : await Api.postCancelNote(params)
                this.$props.item.isFollow = !this.$props.item.isFollow
                this.$message.success(this.$t(`index.${!this.$props.item.isFollow ? 'cancelFollowSSuccess' : 'followSuccess'}`))
                if (this.$props.isMyInfo) {
                    eventBus.emit('updateLocationUser')
                }
            } catch (error) {
                // this.$message.error('error')
                console.log(error)
            }
        },
        //打开图片预览
        async addOrUpdateHandle() {
            if (this.checkLogin()) {
                return;
            }
            this.$emit('updateTitle', this.$props.item);
        },
        goGuge(key) {
            window.open('https://www.google.com/search?q=' + key, "_blank")
        },
        goWeiji(key) {
            window.open('https://' + this.$i18n.locale + '.wikipedia.org/wiki/' + key, "_blank")
        },
        // 具体到几时几刻
        getNewsTime(pubdate) {
            let timeZone = new Date().getTimezoneOffset() / 60
            // console.log('时区', new Date().getTimezoneOffset()/60)
            if (typeof timeZone !== "number") {
                return new Date();
            }
            if (pubdate, timeZone) {
                let pubdateTime = moment(pubdate);
                let newPubdate = new Date(pubdateTime - 3600000 * timeZone);
                // let newPubdateTime = moment(newPubdate);
                // let date = new Date(newPubdate)
                let y = newPubdate.getFullYear()
                let M = newPubdate.getMonth() < 9 ? '0' + (newPubdate.getMonth() + 1) : newPubdate.getMonth() + 1
                let d = newPubdate.getDate() < 9 ? '0' + newPubdate.getDate() : newPubdate.getDate()
                let h = newPubdate.getHours() < 9 ? '0' + newPubdate.getHours() : newPubdate.getHours()
                let m = newPubdate.getMinutes() < 9 ? '0' + newPubdate.getMinutes() : newPubdate.getMinutes()
                return y + '-' + M + '-' + d + ' ' + h + ':' + m
            }
        },
        // .. 前
        getWaitDurationTime(start, end = moment()) {
            if (start) {
                let startTime = moment(start).parseZone();
                let endTime = moment(end);
                if (startTime.isSame(end)) {
                    return ''
                }

                let waitTime = moment.duration(endTime - startTime, 'ms');
                let years = waitTime.get('years');
                let months = waitTime.get('months');
                let days = waitTime.get('days');
                let hours = waitTime.get('hours');
                let minutes = waitTime.get('minutes');

                if (!years && !months && !days && !hours) {
                    if (minutes >= 1) {
                        return minutes + this.$t('time.minutesAgo')
                    } else {
                        return this.$t('time.justNow')
                    }
                } else if (!years && !months && !days && hours) {
                    if (hours > 1) {
                        return hours + this.$t('time.hoursAgo')
                    } else {
                        return hours + this.$t('time.hourAgo')
                    }
                } else if (!years && !months && days) {
                    if (days > 1) {
                        return days + this.$t('time.daysAgo')
                    } else {
                        return days + this.$t('time.dayAgo')
                    }
                } else if (!years && months) {
                    if (months > 1) {
                        return months + this.$t('time.monthsAgo')
                    } else {
                        return months + this.$t('time.monthAgo')
                    }
                } else if (years) {
                    if (years > 1) {
                        return years + this.$t('time.yearsAgo')
                    } else {
                        return years + this.$t('time.yearAgo')
                    }
                } else {
                    return moment(startTime).format('YYYY.M.D HH:mm:ss')
                }
            }
            return ''
        },
        checkLogin() {
            const visible = !localStorage.getItem('token');
            eventBus.emit('updateLogin', visible);
            return visible;
        },
        //嵌入代码
        onEmbed() {
            this.popVisible = false
            this.iframe = `<iframe src=${this.$props.item.pictureUrl} width=${this.$props.item.pictureWidth} height=${this.$props.item.pictureHeight} frameborder="0" scrolling="auto" referrerpolicy="unsafe-url" allowfullscreen></iframe>`
            this.dialogVisible = true
        },
        //确定嵌入代码
        submit() {
            this.$copyText(this.iframe).then(() => {
                this.$message.success(this.$t('index.copySucceed'))
            }).catch(() => {
                this.$message.error(this.$t('index.copyFailed'))
            })
        },
        // 隐藏嵌入代码框
        close() {
            this.dialogVisible = false
        },
        // 复制成功时的回调函数
        onCopy() {
            console.log(this.shareUrl)
            this.popVisible = false
            this.$copyText(this.shareUrl + '#/' + this.$props.item.entryId + '/t?pictureId=' + this.$props.item.pictureId)
                .then(() => {
                    this.$message.success(this.$t('index.copySucceed'))
                }).catch(() => {
                    this.$message.error(this.$t('index.copyFailed'))
                })
        },
        // 是否收藏
        async favoriteHandle() {
            try {
                if (this.checkLogin()) {
                    return;
                }
                let userInfo = storage.get('userInfo') || '';
                if (!userInfo) return;
                let params = {
                    userId: userInfo.userid,
                    imageId: this.$props.item.pictureId
                }
                if (this.userModel.isFavorite === 0) {
                    await Api.postFavorite(params)
                    this.userModel.isFavorite = 1
                    this.$message.success(this.$t("detail.collectionSuccess"));
                } else {
                    await Api.postCancelFavorite(params);
                    this.userModel.isFavorite = 0
                    this.$message.success(this.$t("detail.cancelCollectionSuccess"));
                }
                if (this.$props.isMyInfo) {
                    eventBus.emit('updateLocationUser')
                }
            } catch (err) {
                if (err.response.status == 401 || err.response.status == 400) {
                    this.$message.error(this.$t("tokenErrMessage"));
                    eventBus.emit('updateLogin', true);
                } else {
                    console.log(err)
                    // this.$message.error(err);
                }

            }
        },
        calcPer(val) {
            if (!val) {
                return 100;
            }
            if (typeof val == "string") {
                val = parseInt(val);
            }
            return 100 - calcPercentage(val);
        },
        //打开评论
        openComment() {
            this.$emit('openCommentHandler', this.$props.item)
        },
        //喜欢
        async likeHandle() {
            try {
                if (this.checkLogin()) {
                    return;
                }
                let userInfo = storage.get('userInfo') || '';
                if (!userInfo) return;
                let params = {
                    pictureId: this.$props.item.pictureId
                }
                await Api.postpictureLike(params)
                this.userModel.isLike = this.userModel.isLike === 0 ? 1 : 0
                this.userModel.isLike === 0 ? this.$message.success(this.$t('detail.cancelLikeSuccess')) : this.$message.success(this.$t('detail.likeSuccess'));
            } catch (err) {
                if (err.response.status == 401 || err.response.status == 400) {
                    this.$message.error(this.$t("tokenErrMessage"));
                    eventBus.emit('updateLogin', true);
                } else {
                    console.log(err)
                }
            }

        },
        //不看这个图了
        async blockUser() {
            try {
                this.report = false
                this.reportVisible = false
                if (this.checkLogin()) {
                    return;
                }

                const user = storage.get('userInfo') || {};
                console.log(user)
                await Api.blockUser(this.$props.item.insertUser, user.userid)
                location.reload();
                this.$message.success(this.$t('detail.notShowAgainLeft') + user.username + this.$t('detail.notShowAgainRight'))
                console.log('这个有点有问题')
            } catch (err) {
                if (err.response.status == 401 || err.response.status == 400) {
                    this.$message.error(this.$t("tokenErrMessage"));
                    eventBus.emit('updateLogin', true);
                } else {
                    console.log(err)
                }
            }
        },
        openReport() {
            this.report = false;
            this.reportDialog = true;
        },
        reportClose() {
            console.log('关闭report的dialog')
            this.reportDialog = false
        },
        async reportsubmit(value) {
            try {
                if (this.checkLogin()) {
                    return;
                }
                if (!value) {
                    this.$message.error(this.$t('reportDialog.placeholder'))
                    return;
                }
                const user = storage.get('userInfo') || {};

                const res = await Api.reportImage(this.$props.item.entryId, this.$props.item.pictureId.toString(), value, user.userid)
                this.$message.success(this.$t('reportDialog.reportSuccessful'))
                location.reload();
                this.reportDialog = false
                console.log(res)

            } catch (err) {
                if (err.response.status == 401 || err.response.status == 400) {
                    this.$message.error(this.$t("tokenErrMessage"));
                    eventBus.emit('updateLogin', true);
                    this.reportDialog = false
                } else {
                    console.log('错误信息===>', err)
                }
            }
            console.log(value)
            console.log('report的dialog的提交了')
        },
        deleteImag() {
            this.report = false;
            if (this.checkLogin()) {
                return;
            }
            this.deleteVisible = false
            this.deleteDialog = true
        },
        //确认删除事件
        async deleteSubmit() {
            try {
                const user = storage.get('userInfo') || {};
                if (user === {}) return this.$message.error(this.$t("deletePicture.noPermission"));
                // 获取到登录用户的id
                var userId = user.userid
                // 获取到图片发布者的id
                var insertUser = this.$props.item.insertUser.toString();
                // pictrueId
                var pictureId = this.$props.item.pictureId.toString();
                // treeId
                var treeId = this.$props.item.entryId
                console.log(userId, insertUser)
                if (userId !== insertUser) {
                    this.deleteDialog = false;
                    return this.$message.error(this.$t("deletePicture.noPermission"));
                }
                const { error_code } = await Api.deleteImage(treeId, pictureId)
                if (error_code === 0) {
                    this.$message.success(this.$t("deletePicture.deleteSuccess"));
                    location.reload();
                }
                this.deleteDialog = false
            } catch (err) {
                console.log("Get Picture Page ==> ", err)
                if (err.response.status == 401 || err.response.status == 400) {
                    this.$message.error(this.$t("tokenErrMessage"));
                }
                this.deleteDialog = false
            }
        },
        deleteClose() {
            console.log('取消了')
            this.deleteDialog = false
        },
        loadHandler(id) {
            // console.log(id)
            this.$emit('loadHandler', id)
        },
        async goDetail() {
            try {
                await Api.saveHistory(this.$props.item.entryId, this.$props.item.entryTitle, this.$props.item.pictureId);
                let routeData = this.$router.resolve({
                    name: 'Detail',
                    params: { id: this.$props.item.entryId, position: 't' }
                });
                window.open(routeData.href, '_blank');
            } catch (e) {
                console.log(e)
            }

        }
    }
}
</script>

<style lang="scss" scoped>
.container-c-left {

    .item-list {
        width: 100%;
        padding: 20px;
        background: #fff;
        position: relative;
        margin-bottom: 20px;

        .item-icon-tabs {
            position: absolute;
            right: 20px;
            top: 20px;
            cursor: pointer;
            color: #999;

            &.ismy {
                color: #f69c4a;
            }

            .icon-bg-box {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                overflow: hidden;
                clip-path: polygon(0% 20%, 100% 20%, 100% 100%, 0% 100%);

                &.guanzhu {
                    background-image: url("../../assets/report.svg");
                    background-repeat: no-repeat;
                    background-position: -17px -17px;
                    background-size: 52px;
                }
            }

            .icon-def-box {
                position: relative;
                top: 0;
                left: 0;
                width: 20px;
                height: 20px;
                display: inline-block;
            }
        }

        .item-list-title {
            cursor: pointer;
            height: 31px;
            width: 100%;
            text-align: center;
            font-size: 20px;
            font-weight: bold;
        }

        .item-list-imgbox {
            width: 100%;
            padding-bottom: 66.25%;
            position: relative;

            .item-list-imgbox-center {
                cursor: pointer;
                background: #f3f3f3;
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                overflow: hidden;
                display: flex;
                align-items: center;
                justify-content: center;

                &:hover .edit-title {
                    opacity: 1;
                }

                .edit-title {
                    display: none;
                    transition: opacity .5s;
                    opacity: 0;
                    position: absolute;
                    bottom: 12px;
                    right: 12px;
                    background: rgba(32, 33, 36, 0.39);
                    width: 32px;
                    height: 32px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border-radius: 8px;
                    cursor: pointer;
                    z-index: 999;

                }
            }

            .item-list-img {
                min-width: 100%;
                min-height: 100%;
                position: absolute;
                top: 0;
                left: 0;
            }

            .tic {
                z-index: 1;
                // opacity: 0;
            }

            @keyframes hideAnimation {
                0% {
                    opacity: 1;
                }

                100% {
                    opacity: 0;
                    visibility: hidden;
                }
            }

            .hide {
                animation-name: hideAnimation;
                animation-duration: 0.5s;
                animation-delay: 3s;
                /* 设置延迟时间为3秒 */
                animation-fill-mode: forwards;
                animation-iteration-count: 1;
                /* 设置为1表示不重复播放 */
            }

            .pic {
                z-index: 0;
                // transition: .2s;
                // opacity: 0;
            }
        }

        .item-info {
            margin-top: 20px;
            line-height: 24px;
            display: flex;

            .info-left {
                flex: 1;

                .item-info-name {
                    font-size: 18px;
                    font-weight: bold;
                    color: #0870d1;
                    margin-right: 30px;
                    cursor: pointer;
                }

                .item-info-title {
                    margin-right: 25px;
                    font-size: 18px;
                    font-weight: 700;
                    color: #0870D1;
                }

                .item-info-time {
                    margin-right: 25px;
                    font-size: 16px;
                    font-weight: 400;
                    color: #999999;
                }

                .item-info-follow {
                    height: 30px;
                    border-radius: 16px;
                    font-size: 12px;
                    line-height: 12px;
                    border: 0;

                    &.active {
                        background-color: #eee;
                        color: #999;
                    }
                }
            }

            .info-right {
                position: relative;
                color: #999;

                .icon-box {
                    width: 20px;
                    height: 19px;
                    display: inline-block;
                    position: relative;
                    margin-left: 50px;
                    vertical-align: middle;
                    line-height: 1;
                    cursor: pointer;

                    .icon-bg-box {
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        overflow: hidden;
                        clip-path: polygon(0% 20%, 100% 20%, 100% 100%, 0% 100%);

                        &.shoucang {
                            background-image: url("../../assets/collection.svg");
                            background-repeat: no-repeat;
                            background-position: -10px -11px;
                        }

                        &.pinglun {
                            background-image: url("../../assets/comment.svg");
                            background-repeat: no-repeat;
                            background-position: -10px -13px;
                        }

                        &.dianzan {
                            background-image: url("../../assets/like.svg");
                            background-repeat: no-repeat;
                            background-position: -10px -10px;
                        }
                    }

                    .icon-def-box {
                        position: relative;
                        top: 0;
                        left: 0;
                        width: 20px;
                        height: 20px;
                        display: inline-block;
                    }

                    &.ismy {
                        color: #f69c4a;
                    }

                    & :deep(.ant-popover-inner-content) {
                        display: flex;
                        background-color: red;
                    }
                }

                .item-icon-tabs {
                    display: none;

                    .icon-bg-box {
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        overflow: hidden;
                        clip-path: polygon(0% 20%, 100% 20%, 100% 100%, 0% 100%);

                        &.guanzhu {
                            background-image: url("../../assets/report.svg");
                            background-repeat: no-repeat;
                            background-position: -17px -17px;
                            background-size: 52px;
                        }
                    }

                    .icon-def-box {
                        position: relative;
                        top: 0;
                        left: 0;
                        width: 20px;
                        height: 20px;
                        display: inline-block;
                    }
                }
            }
        }

        .item-dec {
            margin-top: 20px;
            font-size: 14px;
            font-weight: 400;
            line-height: 19px;
            color: #4D5156;
            // display: flex;

            &>span {
                display: block;
                width: 100%;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
            }
        }
    }
}

.embed {
    border-radius: 4px;
    margin-left: 16px;
    border-color: unset;
    font-size: 14px;
    color: #fff;
    cursor: pointer;
}

.copy {
    background: #2c8cf0;
}

.link {
    float: left;
    margin-top: 6px;
    font-size: 14px;
    color: #4d5156;
}
</style>

