/* eslint-disable no-prototype-builtins */
//获取url参数
import moment from "moment";

export function trimParams(params = {}) {
    let obj = {};
    for (let o in params) {
        if (params.hasOwnProperty(o) && params[o] !== '') {
            obj[o] = params[o]
        }
    }
    return obj
}

//将对象处理为URL的参数  urlParamEncode( {status:1, users:['lifanping', 'chuanzong']} )
export function urlParamsEncode(params, encode = false, needQuestionMark = true) {
    let paramStr = '';
    if (params) {
        let paramList = [];
        for (let key in params) {
            let value = params[key];
            if (value instanceof Array) {
                value = value.join()
            } else if (value == null) {
                value = ''
            }
            let itemStr = key + '=' + (encode ? encodeURIComponent(value) : value);
            paramList.push(itemStr)
        }
        paramStr = needQuestionMark ? '?' + paramList.join('&') : paramList.join('&');
    }
    return paramStr
}
export function urlParamsParse(str, arr) {
    let arrList = arr || [];
    let paramStr = str || window.location.search.slice(1);
    let params = {};
    if (paramStr.length <= 0) {
        return params
    }
    paramStr.replace(/([^=&]+)=([^&]*)/g, function (m, key, value) {
        let paramKey = decodeURIComponent(key);
        let paramValue = decodeURIComponent(value);
        arrList.includes(paramKey)

            ?
            params[paramKey] = paramValue != '' ? paramValue.split(/[;|,|；|，]/g) : []
            :
            params[paramKey] = paramValue

    });
    return params;
}
// 计算百分比
export function calcPercentage(molecule, denominator = 10000) {
    return (molecule / denominator) * 100;
}


export function getWaitDurationTime(start, end = moment()) {
    if (start) {
        let startTime = moment(start);
        let endTime = moment(end);
        if (startTime.isSame(end)) {
            return ''
        }

        let waitTime = moment.duration(endTime - startTime, 'ms');
        let years = waitTime.get('years');
        let months = waitTime.get('months');
        let days = waitTime.get('days');
        let hours = waitTime.get('hours');
        let minutes = waitTime.get('minutes');

        if (!years && !months && !days && !hours) {
            if (minutes >= 1) {
                return minutes + this.$t('time.minutesAgo')
            } else {
                return this.$t('time.justNow')
            }
        } else if (!years && !months && !days && hours) {
            if (hours > 1) {
                return hours + this.$t('time.hoursAgo')
            } else {
                return hours + this.$t('time.hourAgo')
            }
        } else if (!years && !months && days ) {
            if (days > 1) {
                return days + this.$t('time.daysAgo')
            } else {
                return days + this.$t('time.dayAgo')
            }
        } else if (!years && months ) {
            if (months > 1) {
                return months + this.$t('time.monthsAgo')
            } else {
                return months + this.$t('time.monthAgo')
            }
        } else if (years ) {
            if (years > 1) {
                return years + this.$t('time.yearsAgo')
            } else {
                return years + this.$t('time.yearAgo')
            }
        } else {
            return moment(startTime).format('YYYY.M.D HH:mm:ss')
        }

    }
    return ''
}

// 评论弹窗高度根据数据更新
export function autoTextarea (elem, extra, maxHeight) {
    extra = extra || 0;
    var isFirefox = !!document.getBoxObjectFor || 'mozInnerScreenX' in window,
        isOpera = !!window.opera && !!window.opera.toString().indexOf('Opera'),
        addEvent = function (type, callback) {
            elem.addEventListener ?
                elem.addEventListener(type, callback, false) :
                elem.attachEvent('on' + type, callback);
        },
        getStyle = elem.currentStyle ? function (name) {
            var val = elem.currentStyle[name];
            if (name === 'height' && val.search(/px/i) !== 1) {
                var rect = elem.getBoundingClientRect();
                return rect.bottom - rect.top -
                    parseFloat(getStyle('paddingTop')) -
                    parseFloat(getStyle('paddingBottom')) + 'px';
            }
            return val;
        } : function (name) {
            return getComputedStyle(elem, null)[name];
        },
        minHeight = parseFloat(getStyle('height'));
    elem.style.resize = 'none';
    var change = function () {
        var scrollTop, height,
            padding = 0,
            style = elem.style;
        if (elem._length === elem.value.length) return;
        elem._length = elem.value.length;
        if (!isFirefox && !isOpera) {
            padding = parseInt(getStyle('paddingTop')) + parseInt(getStyle('paddingBottom'));
        }
        scrollTop = document.body.scrollTop || document.documentElement.scrollTop;
        elem.style.height = minHeight + 'px';
        if (elem.scrollHeight > minHeight) {
            if (maxHeight && elem.scrollHeight > maxHeight) {
                height = maxHeight - padding;
                style.overflowY = 'auto';
            } else {
                height = elem.scrollHeight - padding;
                style.overflowY = 'hidden';
            }
            style.height = height + extra + 'px';
            scrollTop += parseInt(style.height) - elem.currHeight;
            document.body.scrollTop = scrollTop;
            document.documentElement.scrollTop = scrollTop;
            elem.currHeight = parseInt(style.height);
        }
    };
    addEvent('propertychange', change);
    addEvent('input', change);
    addEvent('focus', change);
    change();
}
// 评论弹窗高度根据浏览器变化更新
export function autoTextareaDivChange (elem, extra, maxHeight) {
    extra = extra || 0;
    var isFirefox = !!document.getBoxObjectFor || 'mozInnerScreenX' in window,
        isOpera = !!window.opera && !!window.opera.toString().indexOf('Opera'),
        addEvent = function (type, callback) {
            elem.addEventListener ?
                elem.addEventListener(type, callback, false) :
                elem.attachEvent('on' + type, callback);
        },
        getStyle = elem.currentStyle ? function (name) {
            var val = elem.currentStyle[name];
            if (name === 'height' && val.search(/px/i) !== 1) {
                var rect = elem.getBoundingClientRect();
                return rect.bottom - rect.top -
                    parseFloat(getStyle('paddingTop')) -
                    parseFloat(getStyle('paddingBottom')) + 'px';
            }
            return val;
        } : function (name) {
            return getComputedStyle(elem, null)[name];
        },
        minHeight = parseFloat(getStyle('height'));
    elem.style.resize = 'none';
    var change = function () {
        var scrollTop, height,
            padding = 0,
            style = elem.style;
        elem._length = elem.value.length;
        if (!isFirefox && !isOpera) {
            padding = parseInt(getStyle('paddingTop')) + parseInt(getStyle('paddingBottom'));
        }
        scrollTop = document.body.scrollTop || document.documentElement.scrollTop;
        elem.style.height = minHeight + 'px';
        if (elem.scrollHeight > minHeight) {
            if (maxHeight && elem.scrollHeight > maxHeight) {
                height = maxHeight - padding;
                style.overflowY = 'auto';
            } else {
                height = elem.scrollHeight - padding;
                style.overflowY = 'hidden';
            }
            style.height = height + extra + 'px';
            scrollTop += parseInt(style.height) - elem.currHeight;
            document.body.scrollTop = scrollTop;
            document.documentElement.scrollTop = scrollTop;
            elem.currHeight = parseInt(style.height);
        }
    };
    change();
}
