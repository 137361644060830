<!-- eslint-disable vue/no-multiple-template-root -->
<template>
    <Embeddialog :showDialog="dialogVisible" ref="Embeddialog" :content="iframe" :footer="null" centered cancelText @submit="submit" @close="close" />
    <div class="home-box" @scroll="scrollEvent">
        <Header :showSearch="false" :style="{
            position: 'relative',
        }" />

        <Header :tabs="headTags" :selectTab="selectTab" @changeTab="changeTab" :showSearch="true" :value="keywords" @keydownEnter="keydownEnter" @changeSearchEvent="changeSearchEvent" :style="headerStyle" />
        <!-- <div style="padding:20px;background-color: pink;color:aqua;font-size: 32px;position: fixed;top: 70px;right: 0;">{{
            ListIndex }}
        </div> -->

        <div class="container">
            <div class="container-first">
                <div class="logo-box">
                    <img class="img-logo" src="../assets/newLogo.png" alt="">
                </div>
                <div class="search-box" @keydown="keyDown" id="search" :style="{
                    borderBottomLeftRadius: showSearchList ? 0 : '25px',
                    borderBottomRightRadius: showSearchList ? 0 : '25px',
                }">
                    <!-- boxShadow: showSearchList? '0px 3px 6px rgba(0, 0, 0, 0.16)':'none',  -->
                    <img class="search-icon" :src="require('@/assets/icon-search.svg')" alt="">
                    <input class="search-input" :placeholder="$t('index.search')" @input="onChangeSearchValue($event)" @focus="inputFocusHandle" @blur="inputBlurHandle" v-model="keywords" />

                    <a-Button v-if="keywords" type="text" shape="circle" @click="keywords = ''">
                        <div style="font-size:x-large;margin-top: -10px;color: #bbbbbb">×</div>
                    </a-Button>
                    <div class="search-list" v-if="showSearchList">
                        <div class="search-list-item" v-for="item in searchList" :key="item" @click="changeSearchList(item)">
                            <HightLight :role_text="keywords" :text="item.title" />
                        </div>
                    </div>
                </div>
                <div class="search-buttom" @click="keydownEnter()">
                    <a-button class="search">{{ this.$t('index.button') }}</a-button>
                </div>
                <div class="tab-center">
                    <div v-for="item in headTagsTab" @click="changeTab(item)" :key="item" class="tab-item" :class="item.tagId == selectTab ? 'active' : ''">{{ item.tagName }}
                    </div>
                </div>
            </div>
            <div class="list-center" id="content" style="height: 152px;"></div>
            <List :datas="datas" :involveSeacrchList="involveSeacrchList" :nowIndex="ListIndex" />
            <div>
                <a-spin :spinning="loading" />
            </div>

        </div>
    </div>
    <Footer />
    <Login :visible="visible" :closeModal="closeModal" />
</template>
<script>
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
// import ListItem from '@/components/ListItem.vue'
// import ListItemSimple from '@/components/ListItemSimple.vue'
import HightLight from '@/components/HightLight.vue'
// import CommentDialog from "@/components/Commentdialog.vue"
import Embeddialog from "@/components/Embeddialog.vue";
import { shareURL } from '@/utils/request';

import Api from '@/Api'
import storage from 'storejs';
import Login from '@/views/Login.vue'

import List from '@/components/List/index.vue';
import eventBus from '@/utils/eventBus.js';
import { Constants } from "../constants";

let searchTimer = null
export default {
    name: 'Index',
    components: {
        Embeddialog,
        Header,
        Login,
        Footer,
        // ListItem,
        // ListItemSimple,
        HightLight,
        // CommentDialog
        List
    },
    data() {
        return {
            entryList: [],
            shows: true,
            upshows: true,
            upWindowHeight: 0,
            upScrollHeight: 0,
            shareUrl: '',
            iframe: '',
            dialogVisible: false,
            visibleSecond: false,
            replyInfo: {
                commentIndex: undefined,
                replyContent: undefined,
                commentId: undefined,
                replyUserId: undefined,
            },
            treeId: null,
            imageId: null,
            clientHeight: "1000px",
            isTop: true,
            visible: false,
            selectTab: 'recommend',

            headerStyle: {
                transform: `translateY(-52px)`
            },

            datas: [],
            page: 1,
            totalPage: 1,
            pageSize: 20,
            headTags: [
                { tagId: 'recommend', tagName: this.$t('index.recommend') },
                { tagId: 'hot_spots', tagName: this.$t('index.hot') },
                { tagId: 'follow', tagName: this.$t('index.follow') },
            ],
            filterTags: [],
            loading: false,
            hasMore: true,

            keywords: '', //搜索的绑定值
            showSearchList: false, //显示搜索列表
            searchList: [], //搜索列表
            activeSearch: '', //搜索列表选中

            currentIndex: 0,
            tagId: this.$route.params.id,

            ListIndex: -1, //当前查看到第几张图片,
            involveSeacrchList: []
        }
    },
    created() {
        eventBus.on('updateLogin', (visible) => {
            this.visible = visible;
        })
        eventBus.on('updateList', (params) => {
            console.log('更新主页===>', params)
            const url = window.URL.createObjectURL(params.file)
            // this.datas.find(item => item.pictureId === params.pictureId).titlePicture.pictureAddress = url;
            this.datas.forEach(item => {
                if (item.pictureId === params.pictureId) item.titlePicture = { ...item, pictureAddress: url }
            })

        })
    },
    mounted() {
        this.shareUrl = shareURL
        if (!localStorage.getItem('language')) {
            localStorage.setItem('language', 'ja')
        }
        this.$i18n.locale = localStorage.getItem('language');
        this.clientHeight = (document.documentElement.clientHeight + 100) + "px";
        this.getInitList();
        this.getTagByRecommend("header_recommend");

        console.log(String.fromCodePoint(128513));

        this.checkAuthority();
        this.checkToken();
    },
    watch: {
        ListIndex: {
            async handler() {
                try {
                    const { result: { data: involveSeacrchList } } = await Api.getOperation({ id: this.entryList[this.ListIndex].id })
                    // console.log('相关搜索列表===>', involveSeacrchList)
                    this.involveSeacrchList = involveSeacrchList;
                } catch (e) {
                    console.log(e)
                }
            },
        }
    },
    computed: {
        initTabs() {
            return [
                { tagId: 'recommend', tagName: this.$t('index.recommend') },
                { tagId: 'hot_spots', tagName: this.$t('index.hot') },
                { tagId: 'follow', tagName: this.$t('index.follow') }
            ]
        },
        headTagsTab() {
            // eslint-disable-next-line vue/no-side-effects-in-computed-properties
            this.headTags[0].tagName = this.$t('index.recommend')
            // eslint-disable-next-line vue/no-side-effects-in-computed-properties
            this.headTags[1].tagName = this.$t('index.hot')
            // eslint-disable-next-line vue/no-side-effects-in-computed-properties
            this.headTags[2].tagName = this.$t('index.follow')
            return this.headTags
        },
        datalist1() {
            let data = this.datas[this.currentIndex] || []
            return data.filter(o => o.lastPicture)
        },
        datalist2() {
            let data = this.datas[this.currentIndex] || []
            return data.filter(o => o.lastPicture && o.lastPicture.note && o.lastPicture.comment && !o.lastPicture.pictureUrl)
        }
    },
    methods: {

        submit() {
            this.$copyText(this.iframe).then(() => {
                this.$message.success(this.$t('index.copySucceed'))
            }).catch(() => {
                this.$message.error(this.$t('index.copyFailed'))
            })
        },
        onEmbed(pictureUrl, width, height) {
            this.iframe = `<iframe src=${pictureUrl} width=${width} height=${height} frameborder="0" scrolling="auto" referrerpolicy="unsafe-url" allowfullscreen></iframe>`
            this.dialogVisible = true
        },
        checkLogin() {
            this.visible = !localStorage.getItem('token');
            return this.visible;
        },
        addComment(type, content = undefined) {
            if (this.checkLogin()) {
                return;
            }
            if (!content) {
                return;
            }
            let userInfo = storage.get('userInfo') || '';
            if (userInfo) {
                content = content.replace(/\n/g, '<br>');
                let params = {
                    userId: userInfo.userid,
                    treeId: this.treeId,
                    imageId: this.imageId,
                    content: content,
                    type: type
                }
                this.replyInfo = {
                    commentIndex: undefined,
                    replyContent: undefined,
                    commentId: undefined,
                    replyUserId: undefined,
                };
                console.log("add comment params ==>", params);
                Api.addComment(params).then((res) => {

                    if (res.error_code == 0) {
                        this.$message.success(this.$t('detail.commentSuccess'));
                        this.visibleSecond = false
                    }
                }).catch(err => {
                    if (err.response.status == 401 || err.response.status == 400) {
                        this.$message.error(this.$t("tokenErrMessage"));
                        this.visible = true
                    }
                })
            }
        },
        scrollBotton() {
            document.querySelector("#content").scrollIntoView({
                behavior: "smooth"
            });
        },
        closeModal() {
            this.visible = false;
        },
        // 隐藏嵌入代码
        close() {
            this.dialogVisible = false
        },
        changeTab(val) {
            this.scrollBotton();
            if (val.tagId == this.initTabs[2].tagId && this.checkLogin()) {
                // this.visible = true;
                return;
            }

            this.selectTab = val.tagId;
            this.datas = [];
            this.page = 1;
            this.totalPage = 1;
            this.getList();
        },

        //Header
        keydownEnter() {
            this.toSearchPage()
        },
        changeSearchEvent(val) {
            this.keywords = val;
            this.setSearchList();
        },


        //搜索框更改
        onChangeSearchValue(e) {
            this.keywords = e.target.value;
            this.showSearchList = e.target.value ? true : false
            this.setSearchList();
        },
        inputFocusHandle() {
            document.getElementById("search").style.boxShadow = "0.5px 0.5px 1px 1px #66666635";
            this.showSearchList = this.keywords ? true : false
            this.setSearchList();
        },
        inputBlurHandle() {
            document.getElementById("search").style.boxShadow = "0px 0px 0px 0px ";
            setTimeout(() => {
                this.showSearchList = false
            }, 200)
        },
        //搜索框选中
        changeSearchList(val) {
            Api.modifyUserSearchHistory(val.id, val.title)
            if (!localStorage.getItem('token')) {
                //  如果已经登录,调用接口保存搜索历史数据
                //     Api.modifyUserSearchHistory(val.id, val.title)
                // } else {
                // 保存到本地
                let oldHistory = JSON.parse(localStorage.getItem('searchHistory')) || [];
                if (val) {
                    if (oldHistory.indexOf(val.title) > -1) {
                        oldHistory.splice(oldHistory.indexOf(val.title), 1)
                    }
                    oldHistory.unshift(val.title);
                    if (oldHistory.length > 10) {
                        oldHistory.splice(10)
                    }
                    localStorage.setItem("searchHistory", JSON.stringify(oldHistory));
                }
            }
            this.keywords = val.title;
            this.keywords = val.title;
            this.setSearchList();
            this.toSearchPage();
        },
        //绑定按键
        keyDown(e) {
            let e1 = e || event || window.event
            if (e1 && e1.keyCode == 13) {
                this.toSearchPage();
            }
            //键盘按键判断:左箭头-37;上箭头-38；右箭头-39;下箭头-40
            if (this.showSearchList) {
                if (!this.activeSearch) {
                    this.activeSearch = this.searchList.map((item) => item.title)[0];
                }
                let index = this.searchList.map((item) => item.title).indexOf(this.activeSearch);
                if (e1 && e1.keyCode == 38) {
                    index--
                    if (index < 0) {
                        index = 0
                    }

                    this.activeSearch = this.searchList.map((item) => item.title)[index];
                    this.keywords = this.activeSearch;
                } else if (e1 && e1.keyCode == 40) {
                    index++
                    if (index > this.searchList.length - 1) {
                        index = this.searchList.length - 1
                    }

                    this.activeSearch = this.searchList.map((item) => item.title)[index];
                    this.keywords = this.activeSearch;
                }

            }
        },

        //搜索页
        toSearchPage() {
            if (this.keywords != '') {
                this.$router.push({ name: 'search', query: { keywords: this.keywords } })
            }
        },
        scrollEvent(event) {
            // 获取视窗高度。
            let clientHeight = document.documentElement.clientHeight;
            // 获取滚动容器的滚动距离。
            let scrollTop = event.target.scrollTop
            // 根据视窗高度计算出一个值。
            let num = clientHeight - 125;

            // 声明一个空对象 headerStyle。
            let headerStyle = {};
            if (scrollTop > num) {
                let tNum = (scrollTop - num) - 52;
                if (tNum >= 0) {
                    tNum = 0
                }
                headerStyle = {
                    transform: `translateY(${tNum}px)`
                }

            } else {
                headerStyle = {
                    transform: `translateY(-52px)`
                }
            }
            this.headerStyle = headerStyle

            if (!this.loading) {
                if (event.target.scrollHeight < (event.target.scrollTop + event.target.clientHeight + 30)) {
                    this.loading = true;
                    this.isTop = false;
                    this.getList();
                }
            }

            const windowElement = event.target; // 获取滚动窗口元素
            const listItemElements = windowElement.querySelectorAll('.item-list');

            //记录滚动条数值
            // console.log(scrollTop)

            for (let i = 0; i < listItemElements.length; i++) {
                //遍历每一项元素
                const listItem = listItemElements[i];
                // 每一项元素的相对父元素的高度
                const offsetTop = listItem.offsetTop;
                //每一项元素的高度
                const offsetHeight = listItem.offsetHeight;
                // 滚动距离 比 元素距离顶部高度大，并且滚动距离小于 元素距离顶部高度加元素的高度
                if (scrollTop >= offsetTop && scrollTop < offsetTop + offsetHeight + 52) {
                    this.ListIndex = i + 1
                    let newDataEntryList = this.entryList.slice(i, i + 17)
                    // console.log(newDataEntryList)
                    localStorage.setItem("selectItemList", JSON.stringify(newDataEntryList))
                    break;
                } else if (scrollTop < offsetHeight && scrollTop < offsetTop + offsetHeight) {
                    this.ListIndex = 0
                }

            }
        },
        getInitList() {
            this.loading = true
            this.datas = [];
            this.page = 1;
            this.totalPage = 1;
            this.getList();
        },
        getList() {
            if (this.selectTab == this.initTabs[0].tagId) {
                // 获取推荐条目列表
                this.getRecommendList();
            } else if (this.selectTab == this.initTabs[1].tagId) {
                // 获取热门条目列表
                // this.getList();
                this.getRecommendList(this.tagId);
            } else if (this.selectTab == this.initTabs[2].tagId) {
                // 获取关注条目列表
                this.getEntryPage();
            } else {
                this.tagId = this.selectTab;
                this.getRecommendList(this.tagId);
            }
        },
        getEntryPage() {
            if (this.totalPage < this.page) {
                return;
            }
            Api.getEntryPage('my_follow', this.tagId, this.page, this.pageSize).then((res) => {
                if (res.error_code == 0) {
                    let data = res.result.data;
                    this.datas = this.datas.concat([data.list]);
                    this.totalPage = data.totalPageCount;
                    this.page++;
                }

            }).catch(err => {
                console.log("Get Entry Page ==> ", err)
            }).finally(() => {
                this.loading = false
            });
        },
        getRecommendList() {
            Api.getRecommend().then((res) => {
                let entryList = res.result.data.reduce((result, item) => {
                    if (item.lastPicture) {
                        result.push(item)
                    }
                    return result;
                }, []);
                let data = res.result.data.reduce((result, item) => {
                    if (!item.lastPicture) {
                        // result.push({ ...item.lastPicture, entryId: item.id, isFollow: item.isFollow, entryTitle: item.title, title: item.title, isShow: true, insertUser: item.insertUser })
                        // return
                    } else {
                        result.push({ ...item.lastPicture, entryId: item.id, isFollow: item.isFollow, entryTitle: item.title, title: item.title, isShow: true })
                    }
                    return result;
                }, []);

                this.entryList = this.entryList.concat(entryList)
                storage.set('selectItemList', entryList)
                this.datas = this.datas.concat(data)
                this.loading = false
            })
        },
        /*
            1、修改主页推荐列表不出现没有图文信息，可能的问题：创建图文可选择的条目有可能少于三条。
            2、创建条目超过一百张的时候会上传100张照片，超过一百张照片的部分没有上传。
            3、解决创建条目中第二次选择目标会卡住bug
            4、条目详情导航栏 ‘ 推荐 热点 故事 ’部分 多语言适配，故事部分未开发，现状为静，不 可点击
        */
        getTagByRecommend(recommendType) {
            Api.getTagByRecommend(recommendType).then((res) => {
                let data = res.result.data;
                switch (recommendType) {
                    case "left_recommend":
                        this.leftTags = data;
                        break;
                    case "header_recommend":
                        this.headTags = this.initTabs.concat(data);
                        break;
                    case "filter_recommend":
                        this.filterTags = data;
                        break;
                }

            })
        },
        setSearchList() {
            if (this.keywords && this.keywords.trim() != '') {
                clearTimeout(searchTimer)
                searchTimer = setTimeout(() => {
                    if (this.keywords) {
                        Api.getSearch({
                            keywords: this.keywords
                        }).then((res) => {
                            let data = res.result.data
                            if (data.length > 0) {
                                this.searchList = data
                            }
                        });
                    }
                }, 300)
            } else {
                this.searchList = []
            }

        },
        checkToken() {
            let token = this.$route.query.token;
            if (token) {
                storage.set('token', token);
                Api.getMe().then(res => {
                    localStorage.setItem("userInfo", JSON.stringify(res.result && res.result.data || null));
                    this.$message.success(res.reason || this.$t('login.success'));
                    // window.location.href = "./";
                    window.location.href = Constants.env[Constants.environment].root_uri;
                }).catch(error => {
                    let msg = error.response && error.response.data && error.response.data.reason || this.$t('login.fail');
                    this.$message.error(msg);
                })
            }
        },
        checkAuthority() {
            let code = this.$route.query.code;
            let state = this.$route.query.state;

            let params = {
                code: code,
                state: state
            };
            if (state == "wechat") {
                Api.wechatAuthority(params).then(res => {
                    localStorage.setItem("userInfo", JSON.stringify(res.result && res.result.data || null));
                    this.$message.success(res.reason || this.$t('login.success'));
                    window.location.href = "/";
                }).catch(error => {
                    let msg = error.response && error.response.data && error.response.data.reason || this.$t('login.fail');
                    this.$message.error(msg);
                })
            }
        },
    },
    beforeUnmount() {
        console.log('Index主页被销毁啦！')
        eventBus.off('updateLogin');
        eventBus.off('updateList');
    }
}

</script>
<style lang="scss">
body {
    background: #F8F8F8 !important;
}

.home-box {
    width: 100%;
    height: calc(100vh - 40px);
    overflow-y: auto;
    // background-color: #DEDEDE;

    .container {
        width: 1300px;
        margin: 0 auto;

        .container-first {
            display: flex;
            width: 100%;
            height: calc(100vh - 52px - 15px - 50px);
            align-items: center;
            justify-content: center;
            position: relative;
            flex-flow: column;

            .logo-box {
                padding: 0 21px;
                display: flex;
                text-align: center;
                z-index: 1;
                position: relative;
                bottom: 5%;
                width: 350px;

                .img-logo {
                    margin: auto;
                    width: 100%;
                    height: 100%;
                    // object-fit: cover;
                }
            }

            .search-box {
                /*width: 440px;*/
                width: 720px;
                height: 46px;
                background: #fff;
                border-radius: 25px;
                padding: 0 21px;
                display: flex;
                align-items: center;
                z-index: 1;
                position: relative;
                border: 1px solid #66666645;

                .search-icon {
                    width: 14px;
                    height: 14px;
                }

                .search-input {
                    flex: 1;
                    height: 14px;
                    margin-left: 21px;
                    font-size: 14px;
                    font-weight: 400;
                    border: none;
                    outline: none;
                    background: none;
                }

                .search-list {
                    width: 100%;
                    height: 380px;
                    background: #fff;
                    position: absolute;
                    top: 46px;
                    left: 0;
                    padding-bottom: 16px;
                    box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.2);
                    border-radius: 0 0 23px 23px;

                    .search-list-item {
                        font-size: 14px;
                        font-weight: bold;
                        color: #666666;
                        line-height: 1;
                        padding: 0 20px;
                        margin-top: 16px;
                        cursor: pointer;

                        &.active {
                            color: #0870D1;
                        }
                    }
                }
            }

            .search-buttom {
                margin-top: 2%;

                .search {
                    border: none;
                    border-radius: 8%;
                    align-items: center;
                    height: 30px;
                    cursor: pointer;
                    background-color: rgb(187 180 180 / 13%);
                    color: #747171;
                    font-size: 15px;
                    font-weight: 500;
                }
            }

            .tab-center {
                position: absolute;
                width: 100%;
                height: 60px;
                white-space: nowrap;
                /*display: flex;*/
                /*align-items: center;*/
                /*justify-content: center;*/
                bottom: 0;
                line-height: 60px;
                overflow: auto;
                text-align: center;

                .tab-item {
                    vertical-align: middle;
                    white-space: nowrap;
                    display: inline-block;
                    margin: 0 31px;
                    margin-top: 15px;
                    font-size: 20px;
                    font-weight: 400;
                    line-height: 26px;
                    color: #666666;
                    cursor: pointer;

                    &.active {
                        color: #0870D1;
                        font-weight: bold;
                        position: relative;

                        &::after {
                            content: '';
                            position: absolute;
                            left: 0;
                            bottom: -8px;
                            width: 100%;
                            height: 4px;
                            background: #0870D1;
                        }
                    }
                }
            }
        }

        .container-center {
            margin-top: 20px;

            .list-center {
                margin-top: 20px;
                display: flex;

                .list-left {
                    width: 840px;

                    .list-loading {
                        display: flex;
                        align-items: center;
                        margin-top: 20px;
                        height: 40px;
                        justify-content: center;

                        >span {
                            display: inline-block;
                            width: 16px;
                            height: 16px;
                            margin-right: 6px;
                            border-radius: 50%;
                            background: #EBEBEB;
                            -webkit-animation: load 1.04s ease infinite;

                            &:last-child {
                                margin-right: 0px;
                            }
                        }

                        @-webkit-keyframes load {
                            0% {
                                opacity: 1;
                            }

                            100% {
                                opacity: 0;
                            }
                        }

                        span:nth-child(1) {
                            -webkit-animation-delay: 0.13s;
                        }

                        span:nth-child(2) {
                            -webkit-animation-delay: 0.26s;
                        }

                        span:nth-child(3) {
                            -webkit-animation-delay: 0.39s;
                        }
                    }
                }

                .container-c-right {
                    margin-left: 20px;
                    width: 440px;
                    height: 653px;
                    background-color: #fff;
                    position: sticky;
                    top: 52px !important;

                    .container-right-box {
                        box-sizing: border-box;
                        padding: 40px 20px 60px 20px;
                        border-bottom: 1px solid #EEEEEE;


                        p {
                            margin-bottom: 40px;
                            font-size: 20px;
                            font-family: Microsoft YaHei;
                            font-weight: bold;
                            line-height: 26px;
                            color: #202124;
                            opacity: 1;
                        }

                        ul {
                            display: flex;
                            flex-direction: column;
                            margin: 0;

                            li {
                                margin-bottom: 20px;
                                font-size: 16px;
                                font-family: Microsoft YaHei;
                                font-weight: 400;
                                line-height: 21px;
                                color: #3C4043;
                                cursor: pointer;
                                padding-left: 5px;


                                span {
                                    font-size: 16px;
                                    font-family: Microsoft YaHei;
                                    font-weight: bold;
                                    line-height: 21px;
                                    margin-right: 9px;
                                }

                                &:first-child>span {
                                    color: #B34A4A;
                                }

                                &:nth-child(2)>span {
                                    color: #F04C4D;
                                }

                                &:last-child>span {
                                    color: #FFA218;
                                }

                                &:last-child {
                                    margin: 0;

                                }
                            }
                        }
                    }
                }
            }
        }
    }


}

@media (max-width: 840px) {
    .home-box .container {
        width: 100%;
        padding: 0 20px;
    }

    .home-box .container .container-center .list-center .list-left {
        width: 100%;
    }

    .home-box .container .container-center .list-center .container-c-right {
        display: none;
    }

    .home-box .container .container-center .list-center .list-left .item-list .item-icon-tabs {
        display: none;
    }

    .home-box .container .container-center .list-center .list-left .item-list .item-info {
        display: block;

        .info-right {
            >span {
                margin-left: 0;
                margin-right: 25px;
            }

            .item-icon-tabs {
                display: block;
                position: absolute;
                right: 0;
                top: 2px;
            }
        }
    }

    // .img-logo{
    //     width:80%
    // }
    .home-box .container .container-first .search-box {
        width: 360px
    }

    .home-box .container .container-first .search-buttom {
        margin-top: 6%
    }

}

// 隐藏滚动条
::-webkit-scrollbar {
    width: 0 !important;
}

::-webkit-scrollbar {
    width: 0 !important;
    height: 0;
}
</style>
