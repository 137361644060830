import { SlateElement } from '@wangeditor/editor'

/**
 * 生成“附件”元素的 HTML
 * @param elem 附件元素，即上文的 myResume
 * @param childrenHtml 子节点的 HTML 代码，void 元素可忽略
 * @returns “附件”元素的 HTML 字符串
 */

function neweleToHtml(elem, childrenHtml) {                             // JS 语法

    // console.log('elem 的数据为 ===>', elem);
    const { src = "", titleText = "" } = elem;
    // console.log('childrenHtml 的数据为 ===>',childrenHtml)

    // 生成 HTML 代码
    const html = `<div data-w-e-type="newele" data-src="${src}" data-titleText="${titleText}"></div>`

    return html
}

const elemToHtmlConf = {
    type: 'newele', // 新元素的 type ，重要！！！
    elemToHtml: neweleToHtml,
}

export default elemToHtmlConf;