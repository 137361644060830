<!-- eslint-disable vue/no-multiple-template-root -->
<template>
    <div ref="divRef" class="select-entry-box">
        <div class="select-entry-box-left" :class="{ active: isChoose }" @click="openMoreBoxHandler">
            <span> {{ isChoose ? $t('create.postToAfterSelect') : $t('create.postTo') }}</span>
            <span v-show="isChoose" v-text="chooseModel.entryName"></span>
            <img :src="require('@/assets/icon-right.svg')" :class="{ active: isMore }" alt="">
        </div>

        <div class="select-entry-box-right" v-if="!isChoose">
            <ul>
                <li v-for="item in hotHistories" :key="item.id" @click="chooseHandler({ entryId: item.id, entryName: item.title })">{{ item.title }}</li>
            </ul>
            <p>{{ $t('create.chooseTargetToLetMorePeopleKnow') }}</p>
        </div>
        <CloseOutlined @click="cancelHandler" v-if="isChoose" />
    </div>
    <transition name="bounce">
        <div v-click-outside="clickOutSideHandler" class="choose-box" v-if="isMore">
            <div class="search-item">
                <img class="search-icon" :src="require('@/assets/icon-search.svg')" alt="">
                <input v-model="searchValue" maxlength="15" class="search-input" :placeholder="$t('create.search')" @input="changeSearchVal($event)" />
                <p style="margin: 0;color: #999;"><span>{{ searchValue?.length }}</span>/<span>15</span></p>
            </div>
            <div class="search-bottom-box-choose" v-if="!isSearch">
                <div class="title">{{ $t('create.history') }}</div>
                <div class="item" v-for="item in searchHistories" :key="item.entryId" @click="chooseHandler(item)">
                    <div class="item-left">
                        <div class="item-title">{{ item.entryName }}</div>
                    </div>
                </div>
                <div class="more-list" @click="moreListHandler" v-show="hasMoreList">
                    {{ $t('create.historyAll') }}
                    <svg width="8" height="14" viewBox="0 0 8 14">
                        <g fill="currentColor" transform="translate(267.814 69.919) rotate(180)">
                            <path data-name="路径 524" class="cls-1" d="M260.542,63.619a.742.742,0,0,1-.515-.2.681.681,0,0,1,0-.99l6.545-6.3a.746.746,0,0,1,1.028,0,.681.681,0,0,1,0,.99l-6.545,6.3a.737.737,0,0,1-.513.2Zm0,0" transform="translate(0 0)" />
                            <path data-name="路径 525" class="cls-1" d="M267.084,474.124a.742.742,0,0,1-.515-.2l-6.541-6.3a.681.681,0,0,1,0-.99.746.746,0,0,1,1.028,0l6.543,6.3a.681.681,0,0,1,0,.99A.738.738,0,0,1,267.084,474.124Zm0,0" transform="translate(0 -404.205)" />
                        </g>
                    </svg>
                </div>

                <div class="title">{{ $t('create.relatedSearches') }}</div>
                <!--猜你想发-->
                <div class="item item-text" v-for="item in guessYourLikeList" :key="item.entryId" @click="chooseHandler(item)">
                    <div class="item-left">
                        <div class="item-title">{{ item.entryName }}</div>
                    </div>
                </div>
            </div>
            <div class="search-bottom-box-search" v-else>
                <div class="item" v-for="item in searchList" :key="item.entryId" @click="chooseHandler(item)">
                    <div class="item-left">
                        <div class="item-title">{{ item?.entryName }}</div>
                    </div>
                </div>
            </div>
            <div class="create-entries" v-if="createEntries">
                <button @click="addEntries">{{ $t('create.createEntry') }}</button>
            </div>
        </div>
    </transition>
</template>
<script>
import Api from '@/Api';
import { CloseOutlined } from "@ant-design/icons-vue";
import storage from "storejs";

let searchTimer = null;
export default {
    emits: ['updateEntryModel'],
    props: {
        width: {
            type: String,
            default: '730px'
        },
        height: {
            type: String,
            default: '74px'
        }
    },
    components: { CloseOutlined },
    data() {
        return {
            guessYourLikeList: [],
            isChoose: false,
            isMore: false,
            isSearch: false,
            hasMoreList: false,
            createEntries: false,
            searchValue: '',
            chooseModel: {
                entryId: '',
                entryName: ''
            },
            allSearchHistories: [],
            searchHistories: [],
            hotHistories: [],
            searchList: []
        }
    },
    async mounted() {
        const divRef = this.$refs.divRef;
        divRef.style.width = this.$props.width;
        divRef.style.height = this.$props.height;
        // console.log('mounted时候的props里的width && height ===>', this.$props.width)
        try {
            await this.findHotSearch()
            this.getSearchHistory();
        } catch (e) {
            console.log('serch - mounted -Error ===>', e)
        }
    },
    methods: {
        async findHotSearch() {
            try {
                const { result: { data }, error_code } = await Api.findHotSearch()
                if (error_code !== 0) throw new Error('看状态码')
                this.guessYourLikeList = data;
            } catch (e) {
                console.log('search - HotSearchApi - Error===>', e)
            }
        },
        cancelHandler() {
            console.log('取消选择');
            this.isChoose = false
            this.isMore = false;
            this.isSearch = false;
            this.chooseModel = {
                entryId: null,
                entryName: null
            }
            this.$emit('updateEntryModel', { entryId: null, entryName: null })
        },
        chooseHandler(item) {
            let histories = storage.get('searchHistories') || []
            console.log(histories)
            const i = histories.findIndex(item1 => item1.entryId == item.entryId)
            if (i > -1) histories.splice(i, 1)
            histories.unshift(item)
            storage.set('searchHistories', histories)
            this.getSearchHistory()
            this.chooseModel = item
            this.isChoose = true
            this.isMore = false
            this.isSearch = true;
            this.searchValue = item?.entryName;
            this.createEntries = false;
            this.getSearchList();
            this.$emit('updateEntryModel', this.chooseModel)
        },
        clickOutSideHandler(event) {
            if (document.querySelector('.select-entry-box-left').contains(event.target)) return;
            this.isMore = false;
            this.isSearch = false;
            this.searchValue = '';
        },
        async changeSearchVal(e) {
            try {
                this.isSearch = e.target.value ? true : false
                this.createEntries = false
                await this.getSearchList()
            } catch (err) {
                console.log('Select - changeSearchVal - Error ===>', err)
            }
        },
        openMoreBoxHandler() {
            this.isMore = !this.isMore
        },
        getSearchHistory() {
            // 获取首页的前三条条目 和搜索
            let histories = storage.get('searchHistories') || []
            if (histories.length > 3) this.hasMoreList = true
            this.allSearchHistories = histories;
            this.searchHistories = histories.slice(0, 3);
            let hotHistories = storage.get('selectItemList');
            this.hotHistories = hotHistories?.slice(0, 3);
        },
        moreListHandler() {
            const i = this.searchHistories.length + 3
            console.log(i)
            if (i >= this.allSearchHistories.length) {
                this.searchHistories = this.allSearchHistories
                this.hasMoreList = false
                return
            }
            this.searchHistories = this.allSearchHistories.slice(0, i)
        },
        async getSearchList() {
            try {
                if (this.searchValue.trim() !== '') {
                    clearTimeout(searchTimer)
                    searchTimer = setTimeout(async () => {
                        try {
                            const { error_code, result: { data } } = await Api.getSearch({ keywords: this.searchValue.trim() })
                            if (error_code !== 0) throw new Error("检查searchList的参数有没有错误。")
                            this.searchList = data.reduce((result, item) => {
                                result.push({
                                    entryId: item?.id,
                                    entryName: item?.title
                                })
                                return result;
                            }, [])
                            if (data.length <= 0) {
                                this.createEntries = true
                            }
                        } catch (err) {
                            console.log('timeout - Error ===》', err)
                        }
                    }, 200)
                } else {
                    this.searchList = []
                }
            } catch (err) {
                console.log('search - getSearchList - Error ===>', err)
            }

        },
        addEntries() {
            this.chooseModel = {
                entryId:undefined,
                entryName:this.searchValue
            }
            this.$emit('updateEntryModel', { ...this.chooseModel })
            this.isChoose = true;
            this.isMore = false;
            this.isSearch = false;
            this.createEntries = false;
        }
    }
}
</script>
<style lang="scss" scoped>
.select-entry-box {
    background-color: #fff;
    border-radius: 8px;
    box-sizing: border-box;
    padding: 11px 20px 12px 20px;
    display: flex;
    align-items: center;

    .select-entry-box-left {
        width: 15%;
        display: flex;
        align-items: center;
        border-right: 1px solid rgba(0, 0, 0, .1);
        cursor: pointer;
        //无法选中各浏览器适配
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;

        &>span:first-child {
            font-size: 18px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            line-height: 24px;
            color: #0870D1;
            // padding-right: 10px;
        }

        &>span:nth-child(2) {
            font-size: 18px;
            font-weight: 400;
            line-height: 24px;
            color: rgb(32, 33, 36);
        }

        img {
            margin-left: 10px;
            transition: transform .5s;

            &.active {
                transform: rotate(90deg);
            }
        }

        &.active {
            border: 0;
            flex-grow: 1;

            &>span:first-child {
                padding-right: 5px;
            }

        }
    }

    .select-entry-box-right {
        flex-grow: 1;
        margin-left: 10px;

        ul {
            display: flex;
            margin: 0;
            margin-bottom: 7px;
            height: 54.9%;
            -webkit-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;

            li {
                box-sizing: border-box;
                display: flex;
                justify-content: center;
                align-items: center;
                background-color: #fff;
                background-color: rgb(8, 112, 209);
                color: rgb(238, 238, 238);
                border-radius: 50px;
                padding: 0 16px 0 10px;
                margin-right: 10px;
                cursor: pointer;
            }
        }

        &>p {
            font-size: 12px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            line-height: 16px;
            color: #CCCCCC;
            margin: 0;
        }
    }
}

.choose-box {
    position: absolute;
    top: calc(100% + 20px);
    left: 0;
    width: 100%;
    background-color: #fff;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
    border-radius: 8px;
    box-sizing: border-box;
    padding: 20px;
    z-index: 999;

    .search-item {
        width: 100%;
        height: 58px;
        background: #f8f8f8;
        border-radius: 25px;
        padding: 0 21px;
        display: flex;
        align-items: center;
        z-index: 1;
        position: relative;

        .search-icon {
            width: 25px;
            height: 25px;
        }

        .search-input {
            flex: 1;
            height: 25px;
            margin-left: 21px;
            font-size: 18px;
            font-weight: 400;
            border: none;
            outline: none;
            background: none;
        }
    }

    .search-bottom-box-choose,
    .search-bottom-box-search {
        height: 400px;
        overflow: auto;

        .title {
            font-size: 18px;
            font-weight: bold;
            line-height: 24px;
            color: #333333;
            margin-top: 28px;
            padding-left: 15px;
            margin-bottom: 11px;
        }

        .item {
            padding: 0 15px;
            display: flex;
            align-items: center;
            border-bottom: 1px solid #eee;
            height: 54px;
            cursor: pointer;

            .item-left {
                flex: 1;

                .item-title {
                    font-size: 16px;
                    font-weight: 400;
                    line-height: 21px;
                    color: #333333;
                }

            }

            .item-right {
                font-size: 14px;
                font-weight: 400;
                line-height: 19px;
                color: #0870d1;
            }

            &.item-text {
                height: 64px;
            }
        }

        .more-list {
            text-align: center;
            height: 54px;
            font-size: 14px;
            font-weight: 500;
            line-height: 54px;
            color: #999999;
            cursor: pointer;

            svg {
                transform: rotate(90deg);
                vertical-align: middle;
                width: 6px;
                margin-left: 8px;
            }
        }


    }

    .create-entries {
        text-align: center;

        >button {
            width: 112px;
            height: 36px;
            line-height: 34px;
            background: #0870d1;
            border-radius: 50px;
            border: none;
            outline: none;
            color: #fff;
            font-size: 16px;
            cursor: pointer;
        }
    }


}

.bounce-enter-active {
    animation: bounce-in 0.3s;
}

.bounce-leave-active {
    animation: bounce-in 0.3s reverse;
}

@keyframes bounce-in {
    0% {
        transform: scale(0);
    }


    100% {
        transform: scale(1);
    }
}
</style>