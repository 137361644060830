<template>
    <div class="day" v-show="true">
        <a-form>
            <div class="title">
                <div class="title-inner">
                    <div class="fixed-left">
                        <div class="title-box">
                            <a-form-item class="input-lable">
                                <a-input class="input-ant" placeholder="标题" v-model:value="daysInfo.title.content"
                                    :maxlength="15" />
                            </a-form-item>

                            <div class="time-choose-box">
                                <a-date-picker class="picker-box" v-model:value="daysInfo.title.time" placeholder="选择日期"
                                    valueFormat="YYYY-MM-DD" style="cursor:pointer" :disabledDate="disabledDateDays">
                                    <div>
                                        <span class="time-fox">{{ daysInfo.title.time }}</span>
                                        <span class="time-fox iconfont icon-bianji"></span>
                                    </div>
                                </a-date-picker>
                                <!-- 什么情况下显示   index不为0 或 length ！== 1 -->
                                <CloseOutlined style="cursor:pointer"
                                    v-if="identify.index !== 0 || identify.length !== 1" />
                            </div>
                        </div>
                        <div class="line"></div>
                    </div>
                </div>
                <div class="top">
                    <div style="width:66px;position: relative;z-index: 1;}"><span
                            class="iconfont icon-zengjia add-icon-cust" :class="{ active: daysInfo.title.isCreated }"
                            @click="openInsertBoxHandler('title')"></span></div>
                    <ul class="choose-box" :class="{ active: daysInfo.title.isCreated }">
                        <li v-if="daysInfo.dayList.length === 0">
                            <span class="iconfont icon-caidan list-icon"></span>
                            <span class="text">插入一天</span>
                        </li>
                        <li>
                            <span style="font-size:17px" class="iconfont icon-didian list-icon"></span>
                            <span class="text">添加地点</span>
                        </li>
                        <li @click="addExperienceHandler(-1)">
                            <span style="font-size:16px" class="iconfont icon-wujiaoxing- list-icon"></span>
                            <span class="text">旅行经验</span>
                        </li>
                    </ul>
                </div>
                <div v-for="(item, index) in daysInfo.dayList" :key="index">
                    <div class="fixed-left">
                        <div v-if="item.type === 'place'" class="place">
                            <div class="place-nav">
                                <span class="iconfont icon-didian"></span>
                                <span v-show="!item?.place">添加游玩地点</span>
                                <span v-show="item?.place">{{ item?.place }}</span>
                            </div>
                            <div class="insert-img">
                                <Editor ref="EditorRef" />
                            </div>
                        </div>
                        <div v-else class="experience">
                            <a-form :model="item" :rules="experienceRules">
                                <div class="preface-title">
                                    <a-form-item class="item" name="experienceTitle">
                                        <a-input autocomplete="off" class="preface-title-input"
                                            v-model:value="item.experienceTitle" placeholder="经验标题" :maxlength="15" />
                                    </a-form-item>
                                    <div class="text-inner"><span>{{ item.experienceTitle?.length
                                    }}</span>/<span>15</span>
                                    </div>
                                </div>
                                <div class="preface-container">
                                    <a-form-item class="item" name="content">
                                        <a-textarea v-model:value="item.content" placeholder="添加随记，旅行心得" :autoSize="true"
                                            :maxlength="2000" />
                                    </a-form-item>
                                    <div class="text-inner"><span>{{ item.content?.length
                                    }}</span>/<span>2000</span>
                                    </div>
                                </div>
                            </a-form>
                        </div>
                    </div>
                    <!-- 增加按钮 -->
                    <div class="top" :style="{ marginBottom: daysInfo.dayList.length === (index + 1) ? '40px' : null }">
                        <div style="width:66px;position: relative;z-index: 1;"><span
                                class="iconfont icon-zengjia add-icon-cust" :class="{ active: item.isCreated }"
                                @click="openInsertBoxHandler(item.type, index)"></span></div>
                        <ul class="choose-box" :class="{ active: item.isCreated }">
                            <li v-if="daysInfo.dayList.length === (index + 1)" @click="addDayHandler">
                                <span class="iconfont icon-caidan list-icon"></span>
                                <span class="text">插入一天</span>
                            </li>
                            <li>
                                <span style="font-size:17px" class="iconfont icon-didian list-icon"></span>
                                <span class="text">添加地点</span>
                            </li>
                            <li @click="addExperienceHandler(index)">
                                <span style="font-size:16px" class="iconfont icon-wujiaoxing- list-icon"></span>
                                <span class="text">旅行经验</span>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </a-form>
        <!-- 插入一天 -->
        <a-modal @afterClose="cancelHandler('day')" class="a-dialog-story" :footer="false" v-model:visible="insertDay"
            title="插入一天">
            <a-form ref="dayInsertRef" :model="daysForm" :rules="insertDayRules">
                <a-form-item name="title">
                    <a-input style="height:40px;" :maxlength="15" v-model:value="daysForm.title" placeholder="标题">
                        <template #suffix>
                            <p class="input-suffix">
                                <span>{{ daysForm.title?.length }}</span>/<span>15</span>
                            </p>
                        </template>
                    </a-input>
                </a-form-item>
                <a-form-item name="dayDate">
                    <a-date-picker format="YYYY/MM/DD" valueFormat="YYYY-MM-DD" style="width:100%;height:40px;"
                        v-model:value="daysForm.dayDate" placeholder="日期" :disabledDate="disabledDate" />
                </a-form-item>
                <a-form-item class="button-group">
                    <a-button @click="cancelHandler('day')">取消</a-button>
                    <a-button type="primary" @click="addDayOkHandler">确定</a-button>
                </a-form-item>
            </a-form>
        </a-modal>
    </div>
</template>

<script>
import moment from 'moment';
import Editor from '@/components/Editor';
import { CloseOutlined } from '@ant-design/icons-vue';

export default {
    emits: ['openInsertBoxHandler', 'updateAddDayHandler'],
    components: { Editor, CloseOutlined },
    props: {
        identify: Object,
        item: Object
    },
    computed: {
        experienceRules() {
            return {
                experienceTitle: [
                    { required: true, message: '。。。。', trigger: 'blur' },
                ],
                content: [{ required: true, message: "。。。。", trigger: 'blur' }]
            }
        },
        insertDayRules() {
            return {
                title: [
                    { required: true, message: "不能为空！", trigger: "blur" }
                ],
                dayDate: [
                    { required: true, message: "不能选择空日期！", trigger: "change" }
                ]
            }
        }
    },
    data() {
        return {
            daysInfo: {
                title: {
                    content: "",
                    time: moment().format('YYYY-MM-DD'),
                    isCreated: false
                },
                dayList: [
                    {
                        type: 'place',
                        isCreated: false,
                        place: '',
                    },
                    // {
                    //     type: 'experience',
                    //     isCreated: false,
                    //     experienceTitle: "",
                    //     content: ""
                    // }
                ]
            },
            insertDay: false,
            daysForm: {
                title: "",
                dayDate: ""
            }
        }
    },
    mounted() {
        this.daysInfo.title.content = this.$props.item?.title ? this.$props.item.title : "";
        this.daysInfo.title.time = this.$props.item?.dayDate ? this.$props.item.dayDate : moment().format('YYYY-MM-DD');
    },
    methods: {
        openInsertBoxHandler(type, index) {
            if (type === "title") {
                if (this.daysInfo.title.isCreated) {
                    this.$emit('openInsertBoxHandler', 'clearn')
                } else {
                    this.$emit('openInsertBoxHandler', 'clearn')
                    this.daysInfo.title.isCreated = true
                }
            } else if (type === 'clearn') {
                this.daysInfo.title.isCreated = false
                this.daysInfo.dayList.forEach(item => item.isCreated = false)
            } else if (type === 'place' || type === 'experience') {
                if (this.daysInfo.dayList[index].isCreated) {
                    this.$emit('openInsertBoxHandler', 'clearn')
                } else {
                    console.log('index ===>', index)
                    this.$emit('openInsertBoxHandler', 'clearn')
                    this.daysInfo.dayList[index].isCreated = true
                }
            }
        },
        //textarea
        adjustTextareaHeight(index) {
            const textareaRef = 'place' + index
            const textarea = document.getElementById(textareaRef)
            textarea.style.height = 'auto'; // 先将高度设为 auto，以便正确计算内容高度
            textarea.style.height = textarea.scrollHeight + 'px'; // 根据内容设置新的高度
        },
        //选择图片
        choosePictureHandler(item) {
            console.log(item)
            console.log(Object.values(this.$refs.EditorRef))
        },
        addDayHandler() {
            this.insertDay = true
            console.log(moment("2023-07-26"))
        },
        cancelHandler(type) {
            if (type === "day") {
                this.$refs.dayInsertRef.resetFields();
                this.insertDay = false;
            }
        },
        async addDayOkHandler() {
            try {
                await this.$refs.dayInsertRef.validateFields()
                this.$emit('updateAddDayHandler', { ...this.daysForm, id: this.$props.item?.id, index: this.$props.identify?.index })
                this.$refs.dayInsertRef.resetFields();
                this.insertDay = false;
            } catch (e) {
                console.log('新增一天 Error ===>', e)
            }

        },
        addExperienceHandler(index) {
            const experience = {
                type: 'experience',
                isCreated: false,
                experienceTitle: "",
                content: ""
            }
            this.daysInfo.dayList.splice(index + 1, 0, experience)
            this.$emit('openInsertBoxHandler', 'clearn')
        },
        disabledDate(current) {
            // 头已经处理了
            if ((this.$props.identify.index + 1) === this.$props.identify.length) {
                return current && current < moment(this.daysInfo.title.time).endOf('day')
            } else {
                const end = this.$parent.$refs.everyDayRef[this.$props.identify.index + 1].daysInfo.title.time;
                return current && current < moment(this.daysInfo.title.time).endOf('day') || current && current > moment(end)
            }
        },
        disabledDateDays(current) {
            if (this.$props.identify.index === 0) {
                // 第一个小于第二个得值  ---  核心逻辑
                // 如果第二个不存在就是all
                if (this.$parent.$refs.everyDayRef[this.$props.identify.index + 1]) {
                    const end = this.$parent.$refs.everyDayRef[this.$props.identify.index + 1].daysInfo.title.time;
                    return current && current >= moment(end).startOf('day')
                    // return current && current > moment(this.daysInfo.title.time)
                } else {
                    return false
                }
            } else if ((this.$props.identify.index + 1) === this.$props.identify.length) {
                // 上一个到最后
                // 上一个存在 - 上一个到最后一个，否。。上一个一定存在，不然会被第一个截取
                const start = this.$parent.$refs.everyDayRef[this.$props.identify.index - 1].daysInfo.title.time;
                return current && current <= moment(start).startOf("day");
            } else {
                const start = this.$parent.$refs.everyDayRef[this.$props.identify.index - 1].daysInfo.title.time;
                const end = this.$parent.$refs.everyDayRef[this.$props.identify.index + 1].daysInfo.title.time;
                return current && current >= moment(end).startOf('day') || current <= moment(start).startOf('day')
            }
        }

    }
}
</script>

<style lang="scss" scoped>
.fixed-left {
    padding-left: 66px;
}


.top,
.bottom {
    height: 30px;
    position: relative;
    padding: 30px 0;
    box-sizing: content-box;
    display: flex;

    img {
        width: 30px;
        height: 30px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%)
    }

    .add-icon-cust {
        margin: 0 auto;
        font-size: 25px;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 30px;
        height: 30px;
        background: #fff;
        color: rgba(8, 112, 209, 0.60);
        border: 1px solid rgba(8, 112, 209, 0.60);
        border-radius: 30px;
        transition: .6s;
        cursor: pointer;

        &.active {
            transform: rotate(45deg);
            background: #0870D1;
            color: #fff;
        }
    }

    .choose-box {
        margin: 0 auto;
        width: 730px;
        display: flex;
        align-items: center;
        position: relative;

        li {
            position: absolute;
            left: -122px;
            display: flex;
            align-items: center;
            opacity: 0;
            cursor: pointer;

            .list-icon {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 30px;
                height: 30px;
                font-size: 13px;
                background: #0870D1;
                color: #fff;
                border-radius: 30px;
                margin-right: 12px;
            }

            .text {
                font-size: 20px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                line-height: 27px;
                color: #0870D1;
            }
        }

        li:first-child {
            -moz-transition: 0.3s 0.9s;
            -o-transition: 0.3s 0.9s;
            -webkit-transition: 0.3s;
            -webkit-transition-delay: 0.3s 0.9s;
            -webkit-transition: 0.3s 0.9s;
            transition: 0.3s 0.9s;
        }

        li:nth-child(2) {
            -moz-transition: 0.3s 0.6s;
            -o-transition: 0.3s 0.6s;
            -webkit-transition: 0.3s;
            -webkit-transition-delay: 0.3s 0.6s;
            -webkit-transition: 0.3s 0.6s;
            transition: 0.3s 0.6s;
        }

        li:last-child {
            -moz-transition: 0.3s 0.3s;
            -o-transition: 0.3s 0.3s;
            -webkit-transition: 0.3s;
            -webkit-transition-delay: 0.3s 0.3s;
            -webkit-transition: 0.3s 0.3s;
            transition: 0.3s 0.3s;
        }

        &.active {
            li:first-child {
                left: 0;
                opacity: 1;
            }

            li:nth-child(2) {
                left: 183px;
                opacity: 1;
            }

            li:last-child {
                left: 366px;
                opacity: 1;
            }
        }
    }
}

.day {
    .title {
        .title-box {
            width: 730px;
            margin: 0 auto;
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 10px;

            .input-lable {
                margin: 0;
                flex-grow: 1;

                .input-ant {
                    padding: 0;
                    font-size: 24px;
                    font-family: Microsoft YaHei;
                    font-weight: bold;
                    line-height: 27px;
                    color: #333;
                    border: 0;
                    background: transparent;

                    &:focus {
                        box-shadow: none;
                    }

                    &::placeholder {
                        color: #999999;
                    }


                }
            }


            .time-choose-box {
                flex-shrink: 0;
                font-size: 18px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                line-height: 27px;
                color: #999999;
                display: flex;
                align-items: center;

                .picker-box:hover .time-fox {
                    color: #0870d1;
                }


                span {
                    margin-left: 20px;
                    flex-shrink: 0;
                    font-size: 18px;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    line-height: 27px;
                    color: #999999;

                }
            }

        }

        .line {
            width: 730px;
            margin: 0 auto;
            height: 4px;
            background: #70757A;
            border-radius: 5px;
        }

    }

    .place {
        width: 730px;
        margin: 0 auto;
        border-radius: 5px;
        overflow: hidden;
        border: 1px solid #EEEEEE;
        box-sizing: border-box;

        .place-nav {
            padding: 0 20px;
            box-sizing: border-box;
            font-size: 16px;
            height: 62px;
            background-color: #EFF3F6;
            display: flex;
            align-items: center;
            color: #CCCCCC;

            span {
                cursor: pointer;
            }

            span:first-child {
                font-size: 20px;
                margin-right: 20px;
            }

            span:last-child {
                color: #4D5156;
            }
        }

        .insert-img {
            background-color: #fff;
            // height: 400px;
            box-sizing: border-box;
            padding: 20px;

            .text-area-box {
                width: 100%;
                height: 100%;
                border: 0;
                outline: none;
                resize: none;
            }
        }
    }

    .experience {
        width: 730px;
        margin: 0 auto;
        background-color: pink;
        border: 1px solid #EEEEEE;
        border-radius: 5px;
        overflow: hidden;
        box-sizing: border-box;

        .preface-title {
            height: 62px;
            background-color: #EFF3F6;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 0 20px;

            .item {
                margin: 0;
                flex-grow: 1;

                input {
                    border: 0;
                    background: transparent;
                    padding: 0;
                    font-size: 14px;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    line-height: 19px;

                    &::placeholder {
                        color: #CCCCCC;
                    }

                    &:focus {
                        box-shadow: none;
                    }
                }
            }

            .text-inner {
                flex-shrink: 0;
                margin-left: 5px;
                width: 31px;
                font-size: 14px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                line-height: 19px;
                color: #CCCCCC;
            }
        }

        .preface-container {
            min-height: calc(200px - 64px);
            background-color: #fff;
            padding: 20px;
            position: relative;

            .item {
                margin: 0;
                // height: 98px;

                textarea {
                    // height: 80px;
                    padding: 0;
                    border: 0;
                    resize: none;
                    margin-bottom: 5px;

                    &::placeholder {
                        font-size: 14px;
                        font-family: Microsoft YaHei;
                        font-weight: 400;
                        line-height: 19px;
                        color: #CCCCCC;
                    }

                    &:focus {
                        box-shadow: none;
                    }
                }
            }

            .text-inner {
                position: absolute;
                bottom: 10px;
                right: 20px;
                text-align: right;
                font-size: 14px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                line-height: 19px;
                color: #CCCCCC;
            }
        }
    }
}



.input-suffix {
    margin: 0;
    color: #EEEEEE;
    display: flex;


}
</style>