<template>
  <a-modal class="indexaa" @cancel="close" :visible="showDialog">
    <div class="allContent">
      <div class="title">{{ $t("embeddedCode") }}</div>
      <p></p>
      <div class="content">
        {{ content }}
      </div>
      <p></p>
      <a-Button class="copy" @click="submit">{{ $t("detail.oneClickCopy") }}</a-Button>
    </div>
  </a-modal>
</template>

<script>
export default {
  props: {
    showDialog: Boolean,
    content: String,
  },
  emits: ["submit", "close"],

  methods: {
    close: function () {
      this.$emit("close");
    },
    submit: function () {
      this.$emit("submit");
    },
  },

  watch: {},
};
</script>

<style lang="scss" scoped>
.allContent {
  margin-bottom: 40px;
}

.title {
  font-size: 16px;
  font-weight: 400;
  color: #202124;
}

.content {
  font-size: 14px;
  color: #4d5156;
}

.copy {
  background: #0870d1;
  border-radius: 4px;
  font-size: 14px;
  color: #ffffff;
  float: right;
}
</style>
