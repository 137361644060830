<template>
   <div
            class="list-loading"
            v-if="loading"
            :loading="loading"
            >
            <span></span>
            <span></span>
            <span></span>
    </div>
</template>

<script>
export default {
 name:"Loading",
 props:['loading'],
 data() {
     return {
         
     }
 },
}
</script>

<style lang="scss" scoped>
  .list-loading {
            display: flex;
            align-items: center;
            margin-top: 20px;
            height: 40px;
            justify-content: center;
            text-align: center;

        > span {
            display: inline-block;
            width: 16px;
            height: 16px;
            margin-right: 6px;
            border-radius: 50%;
            background: #ebebeb;
            -webkit-animation: load 1.04s ease infinite;

            &:last-child {
            margin-right: 0px;
            }
        }

        @-webkit-keyframes load {
            0% {
            opacity: 1;
            }
            100% {
            opacity: 0;
            }
        }

        span:nth-child(1) {
            -webkit-animation-delay: 0.13s;
        }

        span:nth-child(2) {
            -webkit-animation-delay: 0.26s;
        }

        span:nth-child(3) {
            -webkit-animation-delay: 0.39s;
        }
}
</style>