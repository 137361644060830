<template>
    <div ref="chooseRef" class="picture-add-box">
        <ul class="picture-nav">
            <li v-for="(item, index) in navList" :key="item.id" v-text="item.name" :class="{ active: item.id === nowMode }"
                @click="changeMode(item.id, index)"></li>
        </ul>
        <div class="inner-box">
            <Transition name="go-left">
                <div class="inner left" v-show="nowMode === 'local'">
                    <ul class="upload-list">
                        <li v-for="(item, index) in 20" :key="item"
                            :style="{ margin: (index + 1) % 4 === 0 ? 0 : null, border: uploadList[index]?.url ? 0 : '1px dashed #E8E8E9' }"
                            @click="choosePictureHandler(uploadList[index])">
                            <img :src="uploadList[index]?.url" v-show="uploadList[index]?.url" />
                        </li>
                    </ul>
                    <div class="get-img" @click="uploadPictureHandler">
                        <div class="upload">
                            <div>
                                <span class="iconfont icon-zengjia add-icon"></span>
                            </div>
                            <p>本地上传</p>
                        </div>
                        <p class="tip">单次最多可上传20张</p>
                    </div>
                </div>
            </Transition>
            <Transition name="go-right">
                <ul class="inner right" v-show="nowMode === 'look'">
                    <li v-for="(item, index) in pictureList" :key="item.id"
                        :style="{ margin: (index + 1) % 4 === 0 ? 0 : null }" @click="choosePictureHandler(item)">
                        <img v-lazy="item.url" />
                    </li>
                </ul>
            </Transition>

        </div>
        <input id="uploadImg" type="file" @change="handleFileUpload" multiple style="display: none;" />
    </div>
</template>

<script>
import axios from 'axios';
import eventBus from '@/utils/eventBus.js';
export default {
    emits: ['choosePictureHandler'],
    props: {
        width: {
            type: [Number, String],
            default: '372px'
        },
        height: {
            type: [Number, String],
            default: '514px'
        }
    },

    computed: {
        navList() {
            return [
                { id: "local", name: "本地图片" },
                { id: "look", name: "LOOK图片" }
            ]
        },
    },
    data() {
        return {
            nowMode: "local",
            pictureList: [
                { id: 1, url: "https://look-test.s3.ap-northeast-1.amazonaws.com/mockPicture/3gbizhiCom-1689408319.jpg", from: "network" },
                { id: 2, url: "https://look-test.s3.ap-northeast-1.amazonaws.com/mockPicture/3gbizhiCom-1689408344.jpg", from: "network" },
                { id: 3, url: "https://look-test.s3.ap-northeast-1.amazonaws.com/mockPicture/3gbizhiCom-1689408391.jpg", from: "network" },
                { id: 4, url: "https://look-test.s3.ap-northeast-1.amazonaws.com/mockPicture/3gbizhiCom-1689408413.jpg", from: "network" },
                { id: 5, url: "https://look-test.s3.ap-northeast-1.amazonaws.com/mockPicture/3gbizhiCom-1689408507.jpg", from: "network" },
                { id: 6, url: "https://look-test.s3.ap-northeast-1.amazonaws.com/mockPicture/3gbizhiCom-1689408520.jpg", from: "network" },
                { id: 8, url: "https://look-test.s3.ap-northeast-1.amazonaws.com/mockPicture/3gbizhiCom-1689408608.jpg", from: "network" },
                { id: 10, url: "https://look-test.s3.ap-northeast-1.amazonaws.com/mockPicture/3gbizhiCom-1689408627.jpg", from: "network" },
                { id: 11, url: "https://look-test.s3.ap-northeast-1.amazonaws.com/mockPicture/3gbizhiCom-1689408660.jpg", from: "network" },
                { id: 12, url: "https://look-test.s3.ap-northeast-1.amazonaws.com/mockPicture/3gbizhiCom-1689408691.jpg", from: "network" },
                { id: 13, url: "https://look-test.s3.ap-northeast-1.amazonaws.com/mockPicture/3gbizhiCom-1689408708.jpg", from: "network" },
                { id: 14, url: "https://look-test.s3.ap-northeast-1.amazonaws.com/mockPicture/3gbizhiCom-1689408724.jpg", from: "network" },
                { id: 17, url: "https://look-test.s3.ap-northeast-1.amazonaws.com/mockPicture/3gbizhiCom-1689408792.jpg", from: "network" },
                { id: 18, url: "https://look-test.s3.ap-northeast-1.amazonaws.com/mockPicture/3gbizhiCom-1689408853.jpg", from: "network" },
                { id: 19, url: "https://look-test.s3.ap-northeast-1.amazonaws.com/mockPicture/3gbizhiCom-1689408899.jpg", from: "network" },
                { id: 20, url: "https://look-test.s3.ap-northeast-1.amazonaws.com/mockPicture/3gbizhiCom-1689408907.jpg", from: "network" },
                { id: 21, url: "https://look-test.s3.ap-northeast-1.amazonaws.com/mockPicture/594faa8a065c39ad22146a9a73ff57b9.jpg", from: "network" },
                { id: 22, url: "https://look-test.s3.ap-northeast-1.amazonaws.com/mockPicture/img2.wallspic.com-kui_jia-dian_shi-chao_ji_ying_xiong-kong_jian-dong_zuo_mao_xian_de_you_xi-4320x2430.jpg", from: "network" },
                { id: 23, url: "https://look-test.s3.ap-northeast-1.amazonaws.com/mockPicture/img2.wallspic.com-zhan_zheng-yi_kuai-tao-ka_tong-zui-4920x2764.jpg", from: "network" },
                { id: 24, url: "https://look-test.s3.ap-northeast-1.amazonaws.com/mockPicture/img3.wallspic.com-hei_gua_fu-si_jia_liyue_han_xun-fu_chou_zhe_ao_chuang_ji_yuan-ka_tong-yi_shu-3840x2160.jpg", from: "network" },
            ],
            uploadList: []
        }
    },
    mounted() {
        this.domInit()
    },
    methods: {
        // 图形初始化
        domInit() {
            this.$refs.chooseRef.style.width = this.$props.width;
            this.$refs.chooseRef.style.height = this.$props.height;
        },
        // 'local图片' || 'look图片'
        changeMode(id) {
            this.nowMode = id
        },

        // 上传图片到图片池
        uploadPictureHandler(event) {
            const inputFile = document.getElementById('uploadImg');
            event.stopPropagation(); // 阻止事件冒泡
            console.log(inputFile)
            inputFile.click();
        },
        handleFileUpload(event) {
            const uploadList = Array.from(event.target.files);
            if ((this.uploadList?.length + uploadList?.length) > 20) {
                const i = 20 - this.uploadList?.length
                const newList = uploadList.slice(0, i)
                newList.forEach(item => {
                    const url = URL.createObjectURL(item);
                    const id = item?.lastModified;
                    const file = item;
                    this.uploadList.push({ url, id, file,from:"local" })
                })
                this.$message.error('最多上传20张图片哦！')
            } else {
                console.log('上传本地图片 ===>', uploadList)
                uploadList.forEach(item => {
                    // const url = URL.createObjectURL(item);
                    const reader = new FileReader();
                    let url = null;
                    const id = item?.lastModified;
                    const file = item;
                    reader.readAsDataURL(item);
                    reader.onload = (e) => {
                        url = e.target.result;
                        this.uploadList.push({ url, id, file,from:"local" })
                    }


                })
            }
        },
        //utils -- 工具方法
        async getImageFileFromURL(url) {
            try {
                return new Promise((resolve, reject) => {
                    axios.get(url, {
                        responseType: 'blob'
                    })
                        .then(response => {
                            const contentType = response.headers['content-type'];
                            const fileExtension = contentType.split('/')[1];
                            const fileName = `image.${fileExtension}`;
                            const blob = response.data;
                            const file = new File([blob], fileName, { type: contentType });
                            resolve(file)
                            // const blob = response.data;
                            // const file = new File([blob], 'image.jpg', { type: 'image/jpeg' });
                            // resolve(file);
                        })
                        .catch(error => {
                            reject(new Error('图片url转File文件 Error'));
                        })
                });
            } catch (e) {
                console.log(' 图片url转File文件 Error ===>', e)
            }
        },
        // 选择图片
        choosePictureHandler(item) {
            if (!item) return;     //判断空格子
            this.$emit('choosePictureHandler', item)
        },
        updateGallery(item) {
            console.log('pictureInfo ===>', item)
            this.pictureList.splice(this.pictureList.findIndex(item1 => item1.id === item.id), 1)
        }
    }
}

</script>

<style lang="scss" scoped>
.picture-add-box {
    background-color: #F1F7FA;
    padding: 20px 18px 0px 20px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    border-radius: 8px;


    .picture-nav {
        margin: 0;
        flex-shrink: 0;
        display: flex;
        align-items: center;
        margin-bottom: 30px;

        li {
            margin-right: 30px;
            padding-bottom: 4px;
            border-bottom: 2px solid transparent;
            font-size: 16px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            line-height: 21px;
            color: #3C4043;
            transition: .3s;
            cursor: pointer;

            &.active {
                border-color: #0870D1;
                color: #0870D1;
            }
        }
    }

    .inner-box {
        flex-grow: 1;
        width: 100%;
        overflow: hidden;
        display: flex;
        position: relative;

        .inner {
            margin: 0;
            flex-shrink: 0;
            width: 100%;
            height: 420px;
            transition: .3s;
            position: absolute;
            top: 0;
            left: 0;


        }

        .left {
            display: flex;
            flex-direction: column;

            .upload-list {
                flex-grow: 1;
                margin-bottom: 30px;
                overflow: auto;
                display: flex;
                flex-wrap: wrap;

                li {
                    flex-shrink: 0;
                    box-sizing: border-box;
                    width: 76px;
                    height: 76px;
                    background-color: #fff;
                    margin-bottom: 10px;
                    margin-right: 10px;
                    border-radius: 8px;
                    overflow: hidden;


                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }
                }
            }

            .get-img {
                flex-shrink: 0;
                height: 84px;
                background: rgba(255, 255, 255, 0.39);
                border: 2px dashed #E8E8E9;
                box-sizing: border-box;
                border-radius: 8px;
                cursor: pointer;


                p {
                    margin: 0;
                }

                .upload {
                    display: flex;
                    margin: 20px 0 9px 0;
                    height: 22px;
                    justify-content: center;

                    p {
                        font-size: 14px;
                        font-family: PingFang SC;
                        font-weight: 400;
                        line-height: 20px;
                        color: #3C4043;
                        margin-left: 10px;
                    }

                    .add-icon {
                        font-size: 18px;
                        border-radius: 18px;
                        background-color: #0870D1;
                        color: #fff;
                    }
                }

                .tip {
                    font-size: 10px;
                    font-family: PingFang SC;
                    font-weight: 400;
                    line-height: 14px;
                    color: #CCCCCC;
                    text-align: center;
                }


            }
        }

        .right {
            overflow: auto;
            display: flex;
            flex-wrap: wrap;

            li {
                flex-shrink: 0;
                margin-bottom: 10px;
                margin-right: 10px;



                img {
                    width: 76px;
                    height: 76px;
                    border-radius: 8px;
                    object-fit: cover;
                }

                &:last-child,
                &:nth-last-child(2),
                &:nth-last-child(3),
                &:nth-last-child(4) {
                    margin-bottom: 0;
                }
            }
        }





    }
}

.go-left-enter-active,
.go-left-leave-acitve {
    opacity: 1;
    transform: translateX(0);
    // left: 0;
}

.go-left-enter-from,
.go-left-leave-to {
    opacity: 0;
    transform: translateX(-100%);
}

.go-right-enter-active,
.go-right-leave-active {
    opacity: 1;
    transform: translateX(0)
}

.go-right-enter-from,
.go-right-leave-to {
    opacity: 0;
    transform: translateX(100%);
    // transform: translateX(0)
}
</style>


