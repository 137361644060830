<!-- eslint-disable vue/no-mutating-props -->
<!-- eslint-disable vue/no-v-model-argument -->
<template>
	<a-modal :bodyStyle="style" @cancel="cancelHandler(true)" centered :footer="null" width="1000px" :closable="false"
		:visible="imageEditVisible">
		<div class="edit-box">
			<div class="title">
				<p>{{ this.$t('create.setTitle') }}</p>
				<AntInput class="edit-input" v-model:inputValue="value" :maxlength="15"
					:placeholder="this.$t('create.createPlaceholder')"></AntInput>
				<div class="bubble-box">
					<div class="left-arrow"></div>
					<div class="bubble">
						<div class="bubble-left">
							<BulbTwoTone twoToneColor="#0870D1" />
							<p>{{ this.$t('create.tip') }}</p>
						</div>
						<div class="bubble-right">
							<CloseCircleFilled @click.once="tipCloseHandler" />
						</div>
					</div>
				</div>
			</div>
			<div class="container">
				<ul class="title-list" v-show="!isCreated">
					<li v-for="item in titleList" :key="item.titleId">
						<a-tooltip class="title-text">
							<template #title>{{ item.title }}</template>
							<span>{{ item.title }}</span>
						</a-tooltip>
						<HeartOutlined class="icon-like" @click="likeTitleHandler(item)"
							:style="{ color: item.doLike == 1 ? '#E84168' : '#999999' }" />
						<a-tooltip class="title-text1">
							<template #title>{{ item.likes }}</template>
							<span>{{ item.likes }}</span>
						</a-tooltip>

					</li>
				</ul>
				<div class="image-box" ref="fatherRef">
					<div
						:style="{ color: fontStyle.color, fontSize: fontStyle.fontSize + 'px', fontFamily: fontStyle.fontFamily, top: titlePosition.top, left: titlePosition.left, right: titlePosition.right, bottom: titlePosition.bottom, transform: titlePosition.transform }"
						@mousemove="mousemoveHandler" @mouseleave="mouseleaveHandler" @mousedown="mousedownHandler"
						@mouseup="mouseupHandler" ref="divRef" class="text-box">
						{{ value }}
						<div class="point top-left"></div>
						<div class="point top-center"></div>
						<div class="point top-right"></div>
						<div class="point center-left"></div>
						<div class="point center-right"></div>
						<div class="point bottom-left"></div>
						<div class="point bottom-center"></div>
						<div class="point bottom-right"></div>
					</div>
					<img @click="cancelHandler(false)" :style="isCreated ? 'width:640px;height:360px;' : ''" :src="atPresentSrc"
						alt="">
				</div>
				<div class="text-edit">
					<div class="edit-box1">
						<a-select v-model:value="fontStyle.fontFamily" class="custom-select"
							:style="{ width: '190px', fontWeight: 'bold', backgroundColor: '#ffffff', fontSize: '14px', fontFamily: fontStyle.fontFamily }"
							@change="fontChangeHandler">
							<a-select-option v-for="  item in fontList" :key="item.id" :value="item.value">{{
								item.name }}</a-select-option>

						</a-select>
						<a-input-number class="custom-select-input" v-model:value="fontStyle.fontSize" :min="12" :max="50" />
					</div>
					<div @click="openColorHandler" class="edit-box2">
						<div class="now-color" :style="{ backgroundColor: fontStyle.color }"></div>文字颜色
						<ColorBox id="colorBox" v-model:visible="isChooseColor" v-model:color="fontStyle.color">
						</ColorBox>
					</div>
					<ul class="place">
						<li :class="{ active: titlePosition.index === 0 }"
							@click="togglePositionHandler({ top: 0, left: '10px', right: null, bottom: null }, null, 0)">
							<span class="iconfont icon-zuoshang-"></span>
						</li>
						<li :class="{ active: titlePosition.index === 1 }"
							@click="togglePositionHandler({ top: 0, left: '50%' }, 'top-center', 1)">
							<span class="iconfont icon-zhixiangshang"></span>
						</li>
						<li :class="{ active: titlePosition.index === 2 }"
							@click="togglePositionHandler({ top: 0, right: '0' }, null, 2)">
							<span class="iconfont icon-youshang-"></span>
						</li>
						<li :class="{ active: titlePosition.index === 3 }"
							@click="togglePositionHandler({ top: '50%', left: 0 }, 'center-left', 3)">
							<span class="iconfont icon-zuojiantou"></span>
						</li>
						<li :class="{ active: titlePosition.index === 4 }"
							@click="togglePositionHandler({ top: '50%', left: '50%' }, 'center', 4)">
							<span class="iconfont icon-jutiweizhi1-xianxing"></span>
						</li>
						<li :class="{ active: titlePosition.index === 5 }"
							@click="togglePositionHandler({ top: '50%', right: 0 }, 'center-right', 5)">
							<span class="iconfont icon-zhixiangyou"></span>
						</li>
						<li :class="{ active: titlePosition.index === 6 }"
							@click="togglePositionHandler({ bottom: 0, left: 0 }, null, 6)"><span class="iconfont icon-zuoxia-"></span>
						</li>
						<li :class="{ active: titlePosition.index === 7 }"
							@click="togglePositionHandler({ bottom: 0, left: '50%' }, 'bottom-center', 7)">
							<span class="iconfont icon-xiangxia3"></span>
						</li>
						<li :class="{ active: titlePosition.index === 8 }"
							@click="togglePositionHandler({ bottom: 0, right: 0 }, null, 8)"><span class="iconfont icon-youxia-"></span>
						</li>
					</ul>
				</div>
			</div>
			<div v-show="isCreated" class="choose-box">
				<div class="left">
					<CaretLeftOutlined @click="lookOtherHandler(-75)" />
				</div>
				<ul ref="imageBoxRef" class="image-box">
					<li v-for="(item, i) in titleImageList" :class="{ active: imageIndex === i }" @click="chooseHandler(item, i)"
						:key="i">
						<img :src="item.file.url">
					</li>
				</ul>
				<div class="right">
					<CaretRightOutlined @click="lookOtherHandler(75)" />
				</div>
			</div>
			<div class="footer-box">
				<div>
					<a-button style="height: 36px;background-color: #F9F9F9;" block @click="cancelHandler(true)">{{
						this.$t('cancel')
					}}</a-button>
				</div>
				<!-- 创建走这个 -->
				<div v-if="isCreated">
					<a-button style="height:36px;background-color:#0870D1 ;" block type="primary" @click="okHandler">{{
						this.$t('done')
					}}</a-button>
				</div>
				<!-- 编辑走这个 -->
				<div v-else>
					<a-button style="height:36px;background-color:#0870D1 ;" block type="primary" @click="editHandler">{{
						this.$t('done')
					}}</a-button>
				</div>
			</div>
			<canvas style="display:none" ref="canvas"></canvas>
		</div>
	</a-modal>
</template>

<script>
import Api from '../../Api';
import AntInput from './AntInput.vue';
import ColorBox from './Color.vue';
import { BulbTwoTone, CloseCircleFilled, HeartOutlined, CaretLeftOutlined, CaretRightOutlined } from '@ant-design/icons-vue'
import storage from 'storejs';
import eventBus from '@/utils/eventBus.js';

import { Modal } from 'ant-design-vue';
import { ExclamationCircleOutlined } from '@ant-design/icons-vue';
import { createVNode } from 'vue'


export default {
	emits: ['saveTitleList', 'update:imageEditVisible', 'updateTitle'],
	props: {
		imageEditVisible: {
			type: Boolean,
			required: true
		},
		files: {
			type: Array,
			required: true
		},
		isCreated: {
			type: Boolean,
			required: true
		},
		imgNote: {
			type: String,
			required: true
		},
		titleList: Array,
		pictureId: Number,
		from: String
	},
	components: { AntInput, ColorBox, BulbTwoTone, CloseCircleFilled, HeartOutlined, CaretLeftOutlined, CaretRightOutlined },
	data() {
		return {
			style: { padding: 0 },
			value: '',
			fontStyle: {
				fontFamily: '微软雅黑',
				fontSize: 14,
				color: 'rgb(0,0,0)'
			},
			fontList: [
				{ id: 1, name: '黑体', value: 'SimHei' },
				{ id: 2, name: '仿宋', value: 'FangSong' },
				{ id: 3, name: '楷体', value: 'KaiTi' },
				{ id: 4, name: '标楷体', value: 'BiauKai' },
				{ id: 5, name: '隶书', value: 'lishu' },
				{ id: 6, name: '幼圆', value: 'youyuan' },
				{ id: 7, name: '宋体', value: 'SimSun' },
				{ id: 8, name: '微软雅黑', value: 'Microsoft YaHei' },
				{ id: 9, name: 'Arial', value: 'Arial' },
				{ id: 10, name: 'Tahoma', value: 'Tahoma' },
				{ id: 11, name: 'Verdana', value: 'Verdana' },
				{ id: 12, name: 'Times New Roman', value: 'Times New Roman' },
				{ id: 13, name: 'Courier New', value: 'Courier New' },
			],
			atPresentSrc: null,
			//在编辑颜色
			isChooseColor: false,
			mousePosition: {
				isMove: false,
				mouseOffsetY: null,
				mouseOffsetX: null,
			},
			nowIndex: -1,
			imageIndex: 0,
			titleImageList: [],
			titlePosition: {
				top: 0,
				left: 0,
				right: null,
				bottom: null,
				transform: null,
				index: -1
			}
		}
	},
	mounted() {
		console.log(1)
	},
	methods: {
		editInit() {
			console.log('init了')
			this.value = '';
			const list = this.$props.files.reduce((result, item) => {
				item = { ...item }
				result.push(item)
				return result
			}, [])
			this.fontStyle = {
				fontFamily: '微软雅黑',
				fontSize: 14,
				color: 'rgb(0,0,0)'
			}
			this.titlePosition = {
				top: 0,
				left: 0,
				right: null,
				bottom: null,
				transform: null,
				index: -1,
				topUse: 0,
				leftUse: 0
			}

			this.titleImageList = Array.from(list);
			console.log("files -----> ", this.$props.files)
			console.log("titleImageList -----> ", this.titleImageList)
			this.chooseHandler(this.titleImageList[0], 0)
		},
		init(text) {
			console.log(this.$props.imgNote)
			console.log('this.$props.files ===>', this.$props.files)
			if (text) this.value = text
			const list = this.$props.files.reduce((result, item) => {
				item = { ...item }
				result.push(item)
				return result
			}, [])
			this.titleImageList = Array.from(list);
			console.log("files -----> ", this.$props.files)
			console.log("titleImageList -----> ", this.titleImageList)
			this.chooseHandler(this.titleImageList[0], 0)
			console.log(this.$props.files)
		},
		openColorHandler() {
			//这两行代码的意义在于节流，避免内存拥挤
			if (this.isChooseColor) return
			else this.isChooseColor = true
			const doc = document.getElementById('colorBox')
			doc.style.display = 'flex';
			setTimeout(() => {
				doc.style.opacity = 1
			}, 500)
		},
		tipCloseHandler() {
			const tipEle = document.querySelector('.bubble-box')
			tipEle.style.opacity = 0
			setTimeout(() => {
				tipEle.style.display = 'none'
			}, 500)
		},
		cancelHandler(isClose) {
			console.log(isClose)
			if (isClose) this.$emit('update:imageEditVisible', false)
			const doc = document.querySelector('.text-box');
			const pointEle = document.querySelectorAll('.point');
			if (!doc.classList.value.includes('active')) return
			doc.classList.remove('active')
			pointEle.forEach(item => {
				item.classList.remove('active')
			})
		},
		fontChangeHandler() {
			console.log('字体切换了！')
		},
		chooseHandler(item, i) {
			const doc = document.querySelector('.text-box');
			const fat = document.querySelector('.image-box')
			console.log(doc, fat)
			let docRect;
			let fatRect;
			if (doc && fat) {
				docRect = doc.getBoundingClientRect();
				fatRect = fat.getBoundingClientRect();
			}
			let top = docRect && fatRect ? docRect.top - fatRect.top : 0;
			let left = docRect && fatRect ? docRect.left - fatRect.left : 0;
			//点击切换要保存信息，点击确定是最后一张
			this.titleImageList.forEach((item, i) => {
				if (this.imageIndex === i) {
					item.text = this.value
					item.fontStyle = this.fontStyle
					item.titlePosition = { ...this.titlePosition, topUse: top, leftUse: left }
				} else {
					if (item.text) return;
					if (item.fontStyle !== undefined) return;
					if (item.titlePosition !== undefined) return;
					item.text = this.$props.imgNote;
					item.fontStyle = {
						fontFamily: '微软雅黑',
						fontSize: 14,
						color: 'rgb(0,0,0)'
					}
					item.titlePosition = {
						top: 0,
						left: 0,
						right: null,
						bottom: null,
						transform: null,
						index: -1,
						topUse: 0,
						leftUse: 0
					}
				}
			});
			this.value = item.text ? item.text : this.$props.imgNote;
			//最后切换
			this.atPresentSrc = item.file.url
			this.imageIndex = i
			// 应该等于titleImageList[i]...
			this.fontStyle = item.fontStyle !== undefined ? item.fontStyle : {
				fontFamily: '微软雅黑',
				fontSize: 14,
				color: 'rgb(0,0,0)'
			}
			this.titlePosition = item.titlePosition !== undefined ? item.titlePosition : {
				top: 0,
				left: 0,
				right: null,
				bottom: null,
				transform: null,
				index: -1,
				topUse: 0,
				leftUse: 0
			}
		},
		lookOtherHandler(length) {
			this.$refs.imageBoxRef.scrollLeft += length;
		},
		togglePositionHandler(item, position, index) {

			console.log(item)
			if (!position) {
				this.titlePosition.position = null;
				this.titlePosition.transform = null;
			}
			if (position === 'top-center') {
				this.titlePosition.position = 'top-center';
				this.titlePosition.transform = 'translateX(-50%)';
			}
			if (position === 'center-left') {
				this.titlePosition.position = 'center-left';
				this.titlePosition.transform = 'translateY(-50%)'
			}
			if (position === 'center') {
				this.titlePosition.position = 'center';
				this.titlePosition.transform = 'translate(-50%,-50%)'
			}
			if (position === 'center-right') {
				this.titlePosition.position = 'center-right';
				this.titlePosition.transform = 'translateY(-50%)'
			}
			if (position === 'bottom-center') {
				this.titlePosition.position = 'bottom-center';
				this.titlePosition.transform = 'translateX(-50%)'
			}
			this.titlePosition.top = null
			this.titlePosition.left = null
			this.titlePosition.right = null
			this.titlePosition.bottom = null
			const itemArr = Object.keys(item)
			itemArr.forEach(item1 => {
				this.titlePosition[item1] = item[item1]
			})
			if (index >= 0) {
				this.titlePosition.index = index;
			} else {
				this.titlePosition.index = -1
			}
		},
		mousedownHandler(e) {
			// 拖动元素显示,记录鼠标距离元素距离
			const doc = document.querySelector('.text-box');
			const pointEle = document.querySelectorAll('.point');
			doc.classList.add('active')
			pointEle.forEach(item => {
				item.classList.add('active')
			})
			this.mousePosition.mouseOffsetX = e.offsetX
			this.mousePosition.mouseOffsetY = e.offsetY
			this.mousePosition.isMove = true;
			console.log('鼠标按下')
		},
		mousemoveHandler(e) {
			if (!this.mousePosition.isMove) return
			const divRef = this.$refs.divRef;
			const fatherRef = this.$refs.fatherRef;
			const fatherTop = fatherRef.getBoundingClientRect().top;
			const fatherLeft = fatherRef.getBoundingClientRect().left;
			const heightNumber = e.clientY - fatherTop - this.mousePosition.mouseOffsetY;
			const widthNumber = e.clientX - fatherLeft - this.mousePosition.mouseOffsetX;
			const maxHeight = fatherRef.offsetHeight - divRef.offsetHeight + 1;
			const maxWidth = fatherRef.offsetWidth - divRef.offsetWidth + 1;
			if (heightNumber < 0 || heightNumber > maxHeight || widthNumber > maxWidth || widthNumber < 0) {
				this.isMove = false
				return
			}
			this.togglePositionHandler({ top: heightNumber + 'px', left: widthNumber + 'px', right: null, bottom: null }, null, -1)
		},
		mouseleaveHandler() {
			//让拖动元素消失
			this.mousePosition.isMove = false;
			console.log('停止移动')
		},
		mouseupHandler() {
			this.mousePosition.isMove = false;
			console.log('停止移动')
		},
		okHandler() {
			this.chooseHandler(this.titleImageList[0], 0);
			this.titleImageList.forEach(item => {
				const file = item.file;
				item.titleFile = this.getFile(file.width, file.height, item.text, file.name, item.fontStyle, item.titlePosition)
			});
			this.$emit('saveTitleList', this.titleImageList)
			this.cancelHandler(true);
		},
		async editHandler() {
			try {
				Modal.confirm({
					icon: createVNode(ExclamationCircleOutlined),
					content: this.$t('detail.confirm'),
					okText: this.$t('done'),
					cancelText: this.$t('cancel'),
					centered: true,
					onOk: async () => {
						try {
							if (this.value === '') {
								Modal.confirm({
									icon: createVNode(ExclamationCircleOutlined),
									content: this.$t('detail.confirmNone'),
									okText: this.$t('done'),
									cancelText: this.$t('cancel'),
									centered: true,
									onOk: () => {
										this.cancelHandler(true);
										return
									}
								})
								return
							}
							const doc = document.querySelector('.text-box');
							const fat = document.querySelector('.image-box')
							console.log(doc, fat)
							let docRect;
							let fatRect;
							if (doc && fat) {
								docRect = doc.getBoundingClientRect();
								fatRect = fat.getBoundingClientRect();
							}
							let top = docRect && fatRect ? docRect.top - fatRect.top : 0;
							let left = docRect && fatRect ? docRect.left - fatRect.left : 0;
							this.titleImageList[0].titlePosition.topUse = top;
							this.titleImageList[0].titlePosition.leftUse = left;
							console.log('现在是新增图片标题哦！')
							const { file: { width, height, name }, fontStyle, titlePosition } = this.titleImageList[0]
							console.log(this.titleImageList)
							const file = this.getFile(width, height, this.value, name, fontStyle, titlePosition);
							const params = {
								title: this.value,
								pictureId: this.pictureId,
								file
							}
							if (this.$props.from !== 'createStroy') {
								await Api.addTitlePicture(params)
							}
							this.titleImageList = []
							this.cancelHandler(true);
							await eventBus.emit('updateList', params);
						} catch (e) {
							console.log(e)
						}
					}
				});




			} catch (e) {
				console.log(e)
			}
		},
		getFile(width, height, text, name, fontStyle, position) {
			const doc = document.querySelector('.text-box');
			const fat = document.querySelector('.image-box');
			// const docRect = doc.getBoundingClientRect();
			const fatRect = fat.getBoundingClientRect();
			const fatWidth = fatRect.width;
			// const fatHeight = fatRect.height;
			//需要来源
			const oImageWidth = width;
			const oImageHeight = height;
			//计算放大比例
			const o = oImageWidth / fatWidth;
			console.log(o)
			//获取top，left,font,fontColor
			const top = position.topUse + fontStyle.fontSize + 10;
			const left = position.leftUse + 10;
			// const top = ;
			const font = `bold ${fontStyle.fontSize * o}px ${fontStyle.fontFamily}`;
			const fontColor = fontStyle.color;
			//清除样式
			const pointEle = document.querySelectorAll('.point');
			doc.classList.remove('active')
			pointEle.forEach(item => {
				item.classList.remove('active')
			})

			const canvas = this.$refs.canvas;
			const context = canvas.getContext('2d');

			// 设置canvas的宽高
			canvas.width = oImageWidth;
			canvas.height = oImageHeight;

			// 设置文字样式
			context.font = font;
			context.fillStyle = fontColor;

			// 在canvas上绘制文字
			context.fillText(text.trim(), left * o, top * o);

			// 获取图片数据URL
			const dataURL = canvas.toDataURL('image/png');
			console.log(dataURL)
			function dataURLToBlob(dataURL) {
				const byteString = atob(dataURL.split(',')[1]);
				const mimeString = dataURL.split(',')[0].split(':')[1].split(';')[0];
				const ab = new ArrayBuffer(byteString.length);
				const ia = new Uint8Array(ab);

				for (let i = 0; i < byteString.length; i++) {
					ia[i] = byteString.charCodeAt(i);
				}

				return new Blob([ab], { type: mimeString });
			}

			const blob = dataURLToBlob(dataURL);

			var blobData = new Blob([blob], { type: 'image/png' });

			var file = new File([blobData], + new Date().getTime() + name.split('.')[0] + '-title.png', { type: 'image/png' });


			// // // 创建一个新的图片元素
			// const img = new Image();

			// // 设置图片的src为数据URL
			// img.src = dataURL;
			// img.style = `position:absolute;top:0;left:0;width:$100px;height:100px`
			// // 将图片添加到DOM中
			// document.querySelector('.image-box').appendChild(img);
			return file
		},
		async likeTitleHandler(item) {
			try {
				const userId = storage.get('userInfo').userid;
				const { error_code, result: { data } } = await Api.addTitleLike(item.titleId, this.$props.pictureId, userId)
				if (error_code !== 0) return;
				item.doLike = data?.doLike
				if (data?.doLike == 1) {
					this.$message.success(this.$t('detail.likeSuccess'))
				} else {
					this.$message.success(this.$t('detail.cancelLikeSuccess'))
				}
				this.$emit('updateTitle')
				// //不合理
				// eventBus.emit('updateList', '传的值');

			} catch (e) {
				console.log(e)
			}

		}
	}
}

</script>

<style lang="scss" scoped>
@import url('../../assets/style/antStyle.scss');

.edit-box {
	padding: 20px 20px 30px 20px;
	box-sizing: border-box;

	.title {
		height: 54px;
		display: flex;
		align-items: center;
		margin-bottom: 20px;

		p {
			margin: 0;
			flex-shrink: 0;
			font-size: 20px;
			font-family: Microsoft YaHei;
			font-weight: bold;
			line-height: 26px;
			color: #202124;
			margin-right: 20px;
		}

		.edit-input {
			width: 552px;
			height: 54px;
		}

		.bubble-box {
			margin-left: 18px;
			width: 26.4%;
			display: flex;
			align-items: center;
			transition: .3s;

			.left-arrow {
				width: 0;
				height: 0;
				border-top: 4px solid transparent;
				border-bottom: 4px solid transparent;
				border-right: 6px solid #CBDFFE;
			}

			.bubble {
				flex-grow: 1;
				max-width: 258px;
				height: 32px;
				background: linear-gradient(90deg, #CEE0FE 0%, #51A8F9 100%);
				border-radius: 8px;
				box-sizing: border-box;
				padding: 0 10px;
				display: flex;
				justify-content: space-between;
				align-items: center;

				.bubble-left {
					font-size: 19px;
					display: flex;
					align-items: center;
					overflow: hidden;
					/* 隐藏溢出部分 */
					text-overflow: ellipsis;
					/* 使用省略号表示溢出 */

					p {
						margin-left: 8px;
						font-size: 12px;
						font-family: Microsoft YaHei;
						font-weight: normal;
						line-height: 16px;
						color: #202124;
						white-space: nowrap;

					}
				}

				.bubble-right {
					font-size: 14px;
					color: #fff;
				}
			}
		}


	}

	.container {
		background-color: #F8F8F8;
		width: 100%;
		display: flex;
		height: 420px;
		box-sizing: border-box;
		padding: 46px 0;
		margin-bottom: 20px;

		.title-list {
			width: 210px;
			height: 328px;
			overflow: auto;
			// background-color: yellow;

			li {
				background: #FFFFFF;
				border-radius: 2px 2px 2px 2px;
				// border: 1px solid rgba(8, 112, 209, 0.2);
				margin: 0 auto;
				width: 190px;
				height: 40px;
				box-sizing: border-box;
				padding: 0 20px;
				display: flex;
				justify-content: space-between;
				align-items: center;
				margin-bottom: 8px;

				.title-text {
					font-size: 12px;
					font-family: SimHei-Regular, SimHei;
					font-weight: 400;
					color: #3C4043;
					line-height: 20px;
					flex-grow: 1;
					overflow: hidden;
					white-space: nowrap;
					overflow: hidden;
					text-overflow: ellipsis;
				}

				.title-text1 {
					font-size: 12px;
					font-family: SimHei-Regular, SimHei;
					font-weight: 400;
					color: #3C4043;
					line-height: 20px;
					overflow: hidden;
					white-space: nowrap;
					overflow: hidden;
					text-overflow: ellipsis;
					width: 20px;
					text-align: center;
					flex-shrink: 0;

				}

				.icon-like {
					font-size: '18px';
					flex-shrink: 0;
					margin-left: 10px;
				}
			}
		}

		.image-box {
			flex-shrink: 0;
			display: flex;
			justify-content: center;
			align-items: center;
			position: relative;
			margin: auto 20px;


			img {
				width: 437px;
				height: 246px;
				-webkit-user-drag: none;
				user-select: none;
				-webkit-user-select: none;
			}
		}

		.text-edit {
			flex-shrink: 0;
			width: 252px;

			.edit-box1 {
				display: flex;
				justify-content: space-between;
			}

			.edit-box2 {
				margin: 12px 0;
				height: 40px;
				display: flex;
				align-items: center;
				background-color: #fff;
				position: relative;
				cursor: pointer;

				.now-color {
					width: 15px;
					height: 15px;
					border-radius: 15px;
					background-color: #202124;
					margin-left: 20px;
					margin-right: 12px;
					border: 1px solid rgb(204, 204, 204);
				}
			}

			.place {
				width: 252px;
				height: 252px;
				margin: 0;
				background-color: #fff;
				display: flex;
				flex-wrap: wrap;

				li {
					flex-shrink: 0;
					width: 84px;
					height: 84px;
					display: flex;
					justify-content: center;
					align-items: center;
					box-sizing: border-box;

					cursor: pointer;

					&>span {
						font-size: 20px;
						color: #CCCCCC;

						&.active {
							color: #0870D1;
						}
					}
				}

				li.active {
					background-color: #EFF6FC;
				}

				li:first-child {
					border-right: 1px solid #EBEBEB;
					border-bottom: 1px solid #EBEBEB;
				}

				li:nth-child(2) {
					border-bottom: 1px solid #EBEBEB;
				}

				li:nth-child(3) {
					border-left: 1px solid #EBEBEB;
					border-bottom: 1px solid #EBEBEB;
				}

				li:nth-child(4) {
					border-right: 1px solid #EBEBEB;
					border-bottom: 1px solid #EBEBEB;
				}

				li:nth-child(5) {
					border-right: 1px solid #EBEBEB;
					border-bottom: 1px solid #EBEBEB;
				}

				li:nth-child(6) {
					border-bottom: 1px solid #EBEBEB;
				}

				li:nth-child(7) {
					border-right: 1px solid #EBEBEB;
				}

				li:nth-child(8) {
					border-right: 1px solid #EBEBEB;
				}
			}
		}
	}

	.choose-box {
		display: flex;
		align-items: center;

		.left,
		.right {
			flex-grow: 1;
			height: 83px;
			background: #F8F8F8;
			width: 20px;
			color: #000;
			display: flex;
			justify-content: center;
			align-items: center;
			cursor: pointer;
		}

		.image-box {
			width: 960px;
			display: flex;
			white-space: nowrap;
			overflow: auto;
			margin: 0 10px;

			li {
				flex-shrink: 0;
				border: 2px solid transparent;

				margin-right: 2px;

				img {
					width: 75px;
					height: 75px;
					margin: 2px;
					-webkit-touch-callout: none;
					-webkit-user-select: none;
					-khtml-user-select: none;
					-moz-user-select: none;
					-ms-user-select: none;
					user-select: none;
				}
			}

			li.active {
				border-color: #0870D1;
			}
		}

	}

	.footer-box {
		display: flex;
		justify-content: center;
		padding-top: 20px;

		&>div {
			display: flex;
			width: 112px;
			height: 36px;
			margin-right: 20px;
		}

		&>div:last-child {
			margin: 0;
		}
	}
}

.text-box {
	box-sizing: border-box;
	padding: 10px;
	position: absolute;
	cursor: move;
	border: 1px solid transparent;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-wrap: wrap;
	font-weight: bold;
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;

	&.active {
		border: 1px solid #333;
	}

	.point {
		display: none;
		position: absolute;
		// width: 8px;
		// height: 8px;
		// border-radius: 8px;
		// background-color: #fff;
		// border: 1px solid #333;

		&.active {
			display: inline-block;
		}
	}

	.top-left {
		margin-top: -4px;
		margin-left: -4px;
		top: 0;
		left: 0;
		cursor: nw-resize;
	}

	.top-center {
		top: 0;
		left: 50%;
		transform: translateX(-50%);
		margin-top: -4px;
		cursor: n-resize;
	}

	.top-right {
		margin-top: -4px;
		margin-right: -4px;
		top: 0;
		right: 0;
		cursor: ne-resize;
	}

	.center-left {
		top: 50%;
		left: 0;
		transform: translateY(-50%);
		margin-left: -4px;
		cursor: w-resize;
	}

	.center-right {
		top: 50%;
		right: 0;
		transform: translateY(-50%);
		margin-right: -4px;
		cursor: e-resize;
	}

	.bottom-left {
		bottom: 0;
		left: 0;
		margin-left: -4px;
		margin-bottom: -4px;
		cursor: sw-resize;

	}

	.bottom-center {
		bottom: 0;
		left: 50%;
		transform: translateX(-50%);
		margin-bottom: -4px;
		cursor: s-resize;
	}

	.bottom-right {
		bottom: 0;
		right: 0;
		margin-right: -4px;
		margin-bottom: -4px;
		cursor: se-resize;

	}
}
</style>
