<!-- eslint-disable vue/no-v-model-argument -->
<!-- eslint-disable vue/no-multiple-template-root -->
<template>
    <div class="comment-dialog">
        <div class="title">
            <span>{{ this.$t('entry.pictureDetails') }}</span>
            <CloseOutlined @click="closeHandler" class="close" />
        </div>
        <div class="comment">
            <div class="header">
                <span>{{ item?.note ? item.note : item?.title }}</span>
                <!-- <ul>
                    <li>
                        <div style="margin-top: 10px">
                            <a-popover placement="bottom" v-model:visible="popVisibletrue">
                                <template #content>
                                        <div class="link">{{ shareUrl + '/#/' + item.entryId + '/t' + '?' + 'pictureId=' +
                                        item.pictureId }}</div>
                                    <a-Button type="primary" danger class="embed" @click="onEmbed">{{ $t('embeddedCode')
                                    }}</a-Button>
                                    <a-Button class="copy" @click="onCopy">{{ $t('copyLink') }}</a-Button>
                                </template>
                                <span class="icon-def-box">
                                    <svg width="20" height="17" fill="currentColor" style="margin-top: -20px"
                                        @click="click">
                                        <path
                                            d="M85.239,149.619a1.179,1.179,0,0,1-.172-.013,1.287,1.287,0,0,1-1.057-1.443c.439-4.221,1.882-7.359,4.291-9.331a11.025,11.025,0,0,1,6.512-2.365l.149-.009v-2.345a1.549,1.549,0,0,1,.4-1.022,1.45,1.45,0,0,1,1.059-.472,1.419,1.419,0,0,1,1.028.44l5.836,6.2a2.642,2.642,0,0,1,0,3.6l-5.838,6.2a1.428,1.428,0,0,1-.988.438h-.042a1.484,1.484,0,0,1-1.46-1.485V145.5h-.249c-2.25,0-6.339.462-8.453,3.557a1.226,1.226,0,0,1-1.021.56h0Zm9.474-5.558c.351,0,.628.01.794.016h.016l.13,0a.707.707,0,0,1,.692.721v3.379l5.957-6.323a1.163,1.163,0,0,0,0-1.587l-5.955-6.332v3.224a.707.707,0,0,1-.692.721c-3.954,0-8.921,1.623-10.13,9.356l-.084.535.355-.4C88.38,144.492,92.492,144.061,94.713,144.061Z"
                                            transform="translate(-83.999 -132.619)" />
                                    </svg>
                                </span>
                            </a-popover>
                        </div>
                    </li>
                    <li>
                        <svg width="20" height="19" viewBox="0 0 20 19" fill="currentColor">
                            <path
                                d="M1.7,15c-0.3-0.4-0.4-0.7-0.5-0.8C0.4,12.9,0,11.5,0,10C-0.3,4.8,4,0.3,9.5,0S19.7,3.8,20,9s-4,9.7-9.5,10c-0.2,0-0.3,0-0.5,0c-1.8,0-3.6-0.5-5.1-1.2l-0.5-0.3L2,18c-0.4,0.1-0.8-0.1-0.9-0.5c0-0.1,0-0.3,0-0.4L1.7,15zM3.2,15l-0.4,1.4L4.4,16c0.2-0.1,0.4,0,0.6,0.1c0.3,0.1,0.5,0.3,0.8,0.4c1.4,0.8,3,1.1,4.6,1.1c4.7-0.2,8.3-4,8.1-8.4s-4.2-7.9-8.9-7.7C5.1,1.7,1.5,5.3,1.5,9.6c0,1.3,0.4,2.7,1.1,3.8c0.1,0.2,0.3,0.5,0.7,1C3.3,14.6,3.3,14.8,3.2,15L3.2,15z" />
                        </svg>
                    </li>
                    <li>
                        <HeartOutlined style="font-size: 20px;" />
                    </li>
                </ul> -->
            </div>
            <div class="content">
                <span>东京五日游</span>
                <span>2小时前</span>
            </div>
            <ul class="bottom">
                <li>
                    <img src="../assets/days.png" alt="">
                    <div>{{ this.$t('entry.days') }}<span>5</span>{{ this.$t('entry.day') }}</div>
                </li>
                <li>
                    <img src="../assets/NNT.png" alt="">
                    <div>{{ this.$t('entry.NNT') }}<span>5</span>{{ this.$t('entry.person') }}</div>
                </li>
                <li>
                    <img src="../assets/spend.png" alt="">
                    <div>{{ this.$t('entry.spend') }}<span>50000</span>{{ this.$t('entry.money') }}</div>
                </li>
            </ul>
        </div>
        <div class="category">
            <ul>
                <li @click="toggleHandler(2)"><span :class="{ active: commentParams.type === 2 }">{{
                    this.$t('detail.comment')
                }}</span><span>({{ commentCnt }})</span></li>
                <li @click="toggleHandler(1)"><span :class="{ active: commentParams.type === 1 }">{{
                    this.$t('detail.question') }}</span><span>({{ questionCnt }})</span></li>
                <li @click="toggleHandler(0)"><span :class="{ active: commentParams.type === 0 }">{{
                    this.$t('detail.suggest') }}</span><span>({{ suggestionCnt }})</span></li>
            </ul>
            <!-- isHead === 1说明是按热度排序，显示按时间 -->
            <div class="sort" @click="sortHandler(0)" v-if="commentParams.isHead === 1">
                <img src="../assets/time.png" alt="">
                <span>{{ this.$t('detail.time') }}</span>
            </div>
            <div class="sort" v-else @click="sortHandler(1)">
                <StockOutlined />
                <span>{{ this.$t('detail.hot') }}</span>
            </div>
        </div>
        <ul class="comment-box">
            <li v-for="item in commentModel.list" :key="item?.commentId">
                <img :src="item.userLogo ? item.userLogo : src" alt="Base64 Image">
                <div>
                    {{ item.content }}
                    <span class="user_self" v-show="item.userId === author">作者</span>
                    <span v-text="getWaitDurationTime(item.createDate)"></span>
                    <span style="cursor: pointer;" @click="replyHandler(item)">
                        <svg width="12" height="11" viewBox="0 0 20 19" fill="currentColor">
                            <path
                                d="M1.7,15c-0.3-0.4-0.4-0.7-0.5-0.8C0.4,12.9,0,11.5,0,10C-0.3,4.8,4,0.3,9.5,0S19.7,3.8,20,9s-4,9.7-9.5,10c-0.2,0-0.3,0-0.5,0c-1.8,0-3.6-0.5-5.1-1.2l-0.5-0.3L2,18c-0.4,0.1-0.8-0.1-0.9-0.5c0-0.1,0-0.3,0-0.4L1.7,15zM3.2,15l-0.4,1.4L4.4,16c0.2-0.1,0.4,0,0.6,0.1c0.3,0.1,0.5,0.3,0.8,0.4c1.4,0.8,3,1.1,4.6,1.1c4.7-0.2,8.3-4,8.1-8.4s-4.2-7.9-8.9-7.7C5.1,1.7,1.5,5.3,1.5,9.6c0,1.3,0.4,2.7,1.1,3.8c0.1,0.2,0.3,0.5,0.7,1C3.3,14.6,3.3,14.8,3.2,15L3.2,15z" />
                        </svg></span>
                    <div class="son-comment">
                        <div v-for="item1 in item.replyInfos.slice(0, (item.isMore + 3 > item.replyInfos.length ? item.replyInfos.length : item.isMore + 3))"
                            :key="item1?.replyId">
                            <img :src="item1.reply_userLogo ? item1.reply_userLogo : src" alt="Base64 Image">
                            <div>{{ item1?.reply_content }}
                                <span class="user_self" v-show="author === item1.reply_userId">作者</span>
                                <span v-text="getWaitDurationTime(item1.reply_createDate)"></span>
                            </div>
                            <HeartOutlined @click="likeCommentHandler(item1)" class="like heart"
                                :class="{ active: item1.reply_doLike == 1 }" />
                        </div>
                        <div v-show="item.replyInfos.length > 3 && item.isMore + 3 <= item.replyInfos.length"
                            @click="item.isMore += 3" class="has-more">更多</div>
                        <div v-show="item.replyInfos.length > 3 && item.isMore + 3 >= item.replyInfos.length"
                            @click="item.isMore = 0" class="has-more">
                            收起</div>
                    </div>
                </div>
                <HeartOutlined @click="likePicHandler(item)" class="like" :class="{ active: item.doLike == 1 }">
                </HeartOutlined>
            </li>
            <li ref="commentBoxRef"></li>
        </ul>

        <span class="icon-box" @click="toggleCommentHandler">
            <svg width="20" height="19" viewBox="0 0 20 19" fill="currentColor">
                <path
                    d="M1.7,15c-0.3-0.4-0.4-0.7-0.5-0.8C0.4,12.9,0,11.5,0,10C-0.3,4.8,4,0.3,9.5,0S19.7,3.8,20,9s-4,9.7-9.5,10c-0.2,0-0.3,0-0.5,0c-1.8,0-3.6-0.5-5.1-1.2l-0.5-0.3L2,18c-0.4,0.1-0.8-0.1-0.9-0.5c0-0.1,0-0.3,0-0.4L1.7,15zM3.2,15l-0.4,1.4L4.4,16c0.2-0.1,0.4,0,0.6,0.1c0.3,0.1,0.5,0.3,0.8,0.4c1.4,0.8,3,1.1,4.6,1.1c4.7-0.2,8.3-4,8.1-8.4s-4.2-7.9-8.9-7.7C5.1,1.7,1.5,5.3,1.5,9.6c0,1.3,0.4,2.7,1.1,3.8c0.1,0.2,0.3,0.5,0.7,1C3.3,14.6,3.3,14.8,3.2,15L3.2,15z" />
            </svg>
        </span>
        <div class="reply" v-show="commentBoxVisible">
            <textarea v-model="value" maxlength="200" />
            <ul v-if="!isSelf" class="button-set" :class="{ active: this.value.length > 0 }">
                <a-button type="primary" @click="commentHander(2)">{{ this.$t('detail.comment') }}</a-button>
                <a-button type="primary" @click="commentHander(1)">{{ this.$t('detail.question') }}</a-button>
                <a-button type="primary" @click="commentHander(0)">{{ this.$t('detail.suggest') }}</a-button>
            </ul>
            <ul v-else class="button-set" :class="{ active: this.value.length > 0 }">
                <a-button type="primary" @click="commentHander(this.type)">{{ this.$t('detail.comment') }}</a-button>
            </ul>
            <span class="numok">{{ value.length }}/200</span>
        </div>
    </div>
</template>
<script>
import { CloseOutlined, HeartOutlined, StockOutlined } from "@ant-design/icons-vue";
import Api from '@/Api/index.js'
import moment from "moment";
import { shareURL } from '@/utils/request.js'
import storage from "storejs";
import { useIntersectionObserver } from '@vueuse/core'
import eventBus from '@/utils/eventBus.js';

export default {
    components: { CloseOutlined, HeartOutlined, StockOutlined },
    data() {
        return {
            // eslint-disable-next-line no-undef
            src: require('../assets/test.png'),
            isSelf: false,
            type: null,
            list: [],
            commentModel: {
                list: [],
                pageIndex: 1,
                pageSize: 10,
                totalPageCount: 1,
                totalSize: 0
            },
            value: '',
            commentBoxVisible: false,
            popVisibletrue: true,
            shareUrl: shareURL,
            commentParams: {
                pictureId: null,
                type: 2,
                isHead: 1,
                page: 1,
                pageSize: 10,
            },
            item: null,
            userInfo: null,
            identification: 'picture',
            replyInfo: null,
            loading: false,
            stop: null,
            commentCnt: null,
            questionCnt: null,
            suggestionCnt: null,
            //作者
            author: null

        }
    },
    async mounted() {
        try {
            let userInfo = storage.get('userInfo');
            this.userInfo = userInfo;
        } catch (e) {
            console.log(e)
        }
    },
    methods: {
        async init(item) {
            try {
                if (this.item?.pictureId === item.pictureId) return
                this.item = item;
                this.commentParams.pictureId = item?.pictureId;
                this.observer()
                const { totalSize: commentCnt } = await this.getCommentList({ ...this.commentParams, type: 2 })
                const { totalSize: questionCnt } = await this.getCommentList({ ...this.commentParams, type: 1 })
                const { totalSize: suggestionCnt } = await this.getCommentList({ ...this.commentParams, type: 0 })
                this.author = item.insertUser;
                this.commentCnt = commentCnt;
                this.questionCnt = questionCnt;
                this.suggestionCnt = suggestionCnt;
            } catch (e) {
                console.log(e)
            }
        },
        async resetNumHandler() {
            try {
                await Api.searchPictures()
            } catch (e) {
                console.log(e)
            }
        },
        observer() {
            const commentBoxRef = this.$refs.commentBoxRef
            const { stop } = useIntersectionObserver(
                commentBoxRef,
                async ([{ isIntersecting }]) => {
                    try {
                        if (isIntersecting) {
                            commentBoxRef.innerText = '正在加载...';
                            const { list } = await this.getCommentList(this.commentParams)
                            console.log('list===>', list)
                            this.commentModel.list = this.commentModel.list.concat(list);
                            this.commentParams.page++
                            if (list.length < 10) {
                                commentBoxRef.innerText = '暂无更多评论...';
                                stop()
                                return;
                            } else {
                                commentBoxRef.innerText = '正在加载...';
                            }
                        }
                        return
                    } catch (e) {
                        console.log(e)
                    }
                }
            )
            this.stop = stop;
        },
        async getCommentList(commentParams) {
            try {
                const { result: { data }, error_code } = await Api.getCommentPage(commentParams)
                if (error_code !== 0) return
                const list = data.list.reduce((result, item) => {
                    item.isMore = 0
                    item.replyLenth = item.replyInfos.length
                    result.push(item)
                    return result;
                }, [])
                return { list, totalSize: data.totalSize }
            } catch (e) {
                console.log(e)
            }
        },
        async sortHandler(isHead) {
            try {

                this.commentParams.isHead = isHead;
                this.commentParams.page = 1;
                this.commentModel.list = []
                this.stop();
                this.observer()
            } catch (e) {
                console.log(e)
            }
        },
        async toggleHandler(type) {
            try {
                this.commentParams.type = type;
                this.commentParams.page = 1;
                this.commentModel.list = []
                this.stop();
                this.observer()
            } catch (e) {
                console.log(e)
            }
        },
        checkLogin() {
            const visible = !localStorage.getItem('token');
            eventBus.emit('updateLogin', visible);
            return visible;
        },
        async commentHander(type) {
            try {
                if (this.value.trim() === '') {
                    this.$message.error(this.$t('detail.nocontent'))
                    return;
                }
                if (this.checkLogin()) {
                    return;
                }
                if (this.identification === 'picture') {
                    console.log('回复图文')
                    await Api.addComment({
                        userId: this.userInfo.userid,
                        imageId: this.item.pictureId,
                        type: type,
                        content: this.value
                    })
                } else {
                    console.log('回复个人')
                    // userId	是	string	用户ID
                    // imageId	否	string	图文ID
                    // treeId	否	string	条目ID
                    // content	是	string	评论内容
                    // commentId	是	string	评论ID
                    // replyUserId	是	string	回复的评论用户ID
                    await Api.addReply({
                        userId: this.userInfo.userid,
                        content: this.value,
                        commentId: this.replyInfo.commentId,
                        replyUserId: this.replyInfo.userId
                    })
                }
                //回复完之后的状态
                this.stop()
                this.commentParams.type = type;
                this.commentParams.page = 1;
                this.commentModel.list = []
                const { totalSize } = await this.getCommentList(this.commentParams)
                this.observer();
                if (this.commentParams.type === 2) {
                    this.commentCnt = totalSize;
                } else if (this.commentParams.type === 1) {
                    this.questionCnt = totalSize;
                } else {
                    this.suggestionCnt = totalSize;
                }
                this.value = ''
                this.$message.success(this.$t('detail.commentSuccess'))
            } catch (e) {
                if (e.response.status == 401 || e.response.status == 400) {
                    this.$message.error(this.$t("tokenErrMessage"));
                    eventBus.emit('updateLogin', true);
                }
                console.log(e)
            }

        },
        toggleCommentHandler() {
            //此时是回复图文
            console.log('打开评论框，回复图文===》')
            if (this.commentBoxVisible) this.commentBoxVisible = false;
            else {
                console.log('这是打开的动作')
                this.commentBoxVisible = true;
                this.isSelf = false;
                this.identification = 'picture'
            }
        },
        replyHandler(item) {
            console.log('个人评论信息===>', item)
            this.replyInfo = item;
            this.type = parseInt(item.type)
            console.log('打开评论框，回复个人===》')
            if (this.commentBoxVisible) this.commentBoxVisible = false;
            else {
                console.log('这是打开的动作')
                this.commentBoxVisible = true;
                this.isSelf = true;
                this.identification = 'self'
            }
        },
        async likePicHandler(item) {
            try {
                console.log(item)
                if (this.checkLogin()) {
                    return;
                }
                await Api.commentLike({
                    imageId: this.commentParams.pictureId,
                    commentId: item.commentId,
                    userId: this.userInfo.userid
                })
                if (item.doLike == 0) {
                    item.doLike = 1;
                    this.$message.success(this.$t('detail.likeSuccess'))
                } else {
                    item.doLike = 0;
                    this.$message.success(this.$t('detail.cancelLikeSuccess'))
                }
            } catch (e) {
                if (e.response.status == 401 || e.response.status == 400) {
                    this.$message.error(this.$t("tokenErrMessage"));
                    eventBus.emit('updateLogin', true);
                }
                console.log(e)
            }
        },
        async likeCommentHandler(item) {
            try {
                console.log(item)
                if (this.checkLogin()) {
                    return;
                }
                await Api.replyLike({
                    imageId: this.commentParams.pictureId,
                    commentId: item.reply_commentId,
                    replyId: item.replyId,
                    userId: this.userInfo.userid
                })
                if (item.reply_doLike == 0) {
                    item.reply_doLike = 1;
                    this.$message.success(this.$t('detail.likeSuccess'))
                } else {
                    item.reply_doLike = 0;
                    this.$message.success(this.$t('detail.cancelLikeSuccess'))
                }
            } catch (e) {
                if (e.response.status == 401 || e.response.status == 400) {
                    this.$message.error(this.$t("tokenErrMessage"));
                    eventBus.emit('updateLogin', true);
                }
                console.log(e)
            }
        },
        //关闭评论框
        closeHandler() {
            this.$emit('update:commentVisible', false)
        },
        //时间转换 --？？前
        getWaitDurationTime(start, end = moment()) {
            if (start) {
                let startTime = moment(start).parseZone();
                let endTime = moment(end);
                if (startTime.isSame(end)) {
                    return ''
                }

                let waitTime = moment.duration(endTime - startTime, 'ms');
                let years = waitTime.get('years');
                let months = waitTime.get('months');
                let days = waitTime.get('days');
                let hours = waitTime.get('hours');
                let minutes = waitTime.get('minutes');

                if (!years && !months && !days && !hours) {
                    if (minutes >= 1) {
                        return minutes + this.$t('time.minutesAgo')
                    } else {
                        return this.$t('time.justNow')
                    }
                } else if (!years && !months && !days && hours) {
                    if (hours > 1) {
                        return hours + this.$t('time.hoursAgo')
                    } else {
                        return hours + this.$t('time.hourAgo')
                    }
                } else if (!years && !months && days) {
                    if (days > 1) {
                        return days + this.$t('time.daysAgo')
                    } else {
                        return days + this.$t('time.dayAgo')
                    }
                } else if (!years && months) {
                    if (months > 1) {
                        return months + this.$t('time.monthsAgo')
                    } else {
                        return months + this.$t('time.monthAgo')
                    }
                } else if (years) {
                    if (years > 1) {
                        return years + this.$t('time.yearsAgo')
                    } else {
                        return years + this.$t('time.yearAgo')
                    }
                } else {
                    return moment(startTime).format('YYYY.M.D HH:mm:ss')
                }
            }
            return ''
        },
        async submitHandler(item) {
            try {
                console.log(item)
                // await Api.
            } catch (e) {
                console.log(e)
            }
        }
    },
}

</script>
<style lang="scss" scoped>
.comment-dialog {
    width: 100%;
    height: 100%;
    background: #fff;
    box-shadow: -3px 0px 3px rgba(0, 0, 0, 0.08);
    // border-radius: 8px;
    display: flex;
    flex-direction: column;


    .title {
        flex-shrink: 0;
        height: 47px;
        border-bottom: 1px solid #EEEEEE;
        display: flex;
        justify-content: space-between;
        align-items: center;
        box-sizing: border-box;
        padding: 0 20px;

        span {
            font-size: 18px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            line-height: 24px;
            color: #202124;
        }

        .close {
            font-size: 14px;
            color: #999999;
        }
    }

    .comment {
        box-sizing: border-box;
        padding: 20px;
        border-bottom: 1px solid #EEEEEE;
        flex-shrink: 0;

        .header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 16px;


            span {
                font-size: 16px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                line-height: 21px;
                color: #3C4043;
            }

            ul {
                flex-shrink: 0;
                display: flex;
                width: 140px;
                justify-content: space-between;
                align-items: center;
                margin: 0;

                li {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    line-height: 23px;
                }

                li:last-child {
                    span {
                        display: flex;
                        align-items: center;
                    }
                }
            }
        }

        .content {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 18px;

            &>span:first-child {
                font-size: 18px;
                font-family: Microsoft YaHei;
                font-weight: bold;
                line-height: 24px;
                color: #202124;
            }

            &>span:last-child {
                font-size: 12px;
                font-family: Microsoft YaHei;
                font-weight: 300;
                line-height: 16px;
                color: #CCCCCC;
            }
        }

        .bottom {
            margin: 0;
            display: flex;
            justify-content: space-between;

            li {
                display: flex;

                img {
                    width: 22px;
                    height: 22px;
                    margin-right: 10px;
                    flex-shrink: 0;
                }

                span {
                    margin: 0 6px 0 10px;
                    color: #0870D1;
                }
            }
        }
    }

    .category {
        flex-shrink: 0;
        padding-top: 20px;
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        border-bottom: 1px solid #EEEEEE;

        ul {
            margin: 0;
            display: flex;
            margin-left: 38px;

            li {
                width: 66px;
                margin-right: 30px;
                display: flex;
                align-items: flex-end;
                cursor: pointer;

                span:first-child {
                    font-size: 18px;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    line-height: 24px;
                    color: #9E9E9E;
                    border-bottom: 3px solid transparent;
                    padding-bottom: 8px;
                }

                span:last-child {
                    font-size: 12px;
                    font-family: PingFang SC;
                    font-weight: 400;
                    line-height: 17px;
                    color: #707070;
                    border-bottom: 3px solid transparent;
                    padding-bottom: 8px;
                }

                span.active {
                    border-color: #0870D1;
                    font-weight: bold;
                    color: #202124;
                }
            }

            li:last-child {
                margin: 0;
            }
        }

        .sort {
            margin-right: 19px;
            font-size: 12px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            line-height: 16px;
            color: #0870D1;
            margin-bottom: 11px;
            cursor: pointer;

            span {
                margin-left: 6px;
            }
        }
    }

    .comment-box {
        margin: 0;
        flex-grow: 1;
        box-sizing: border-box;
        padding: 20px;
        overflow: auto;

        li {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 20px;

            .user_self {
                width: 40px;
                height: 20px;
                border: 1px solid #EEEEEE;
                border-radius: 50px;
                text-align: center;
                line-height: 20px;
            }

            img {
                flex-shrink: 0;
                width: 30px;
                height: 30px;
                align-self: flex-start;
                border-radius: 30px;
            }

            &>div {
                flex-shrink: 0;
                width: 334px;
                font-size: 16px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                line-height: 21px;
                color: #333333;
                margin-left: 10px;
                padding-top: 5px;


                &>span {
                    margin-left: 8px;
                    display: inline-block;
                    // width: 43px;
                    font-size: 12px;
                    font-family: Microsoft YaHei;
                    font-weight: 300;
                    line-height: 16px;
                    color: #CCCCCC;
                }

                &>.son-comment {
                    padding-top: 20px;
                    display: flex;
                    flex-direction: column;
                    // background-color: pink;

                    &>div {
                        flex-grow: 1;
                        // background-color: papayawhip;
                        display: flex;
                        margin-bottom: 10px;

                        & .heart {
                            display: none;
                            cursor: pointer;
                        }

                        &:hover .heart {
                            display: inline-block;
                        }

                        &.active {
                            color: red;
                        }

                        img {
                            flex-shrink: 0;
                            width: 18px;
                            height: 18px;
                            margin-right: 8px;
                            display: inline-block;
                        }

                        &>div {
                            width: 308px;
                            margin: 0;
                            font-size: 16px;
                            font-family: Microsoft YaHei;
                            font-weight: 400;
                            line-height: 21px;
                            color: #333333;

                            &>span {
                                display: inline-block;
                                // width: 43px;
                                margin-left: 8px;
                                font-size: 12px;
                                font-family: Microsoft YaHei;
                                font-weight: 300;
                                line-height: 16px;
                                color: #CCCCCC;
                            }

                            .user_self {
                                width: 40px;
                                height: 20px;
                                border: 1px solid #EEEEEE;
                                border-radius: 50px;
                                text-align: center;
                                line-height: 20px;
                            }
                        }

                    }

                }
            }

            .like {
                margin-left: 7px;
                align-self: flex-start;
                color: #CCCCCC;
                font-size: 16px;
            }

            .like.active {
                color: red;
            }
        }
    }

    .icon-box {
        position: absolute;
        bottom: 138px;
        right: 20px;
        width: 60px;
        height: 60px;
        background: rgb(255, 255, 255);
        box-shadow: 0px 3px 16px rgba(0, 0, 0, 0.08);
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
    }




}

.has-more {
    margin-top: 5px;
    width: 100%;
    font-size: 12px;
    font-weight: 400;
    line-height: 19px;
    color: #ccc;
    display: inline-block;
    text-align: center;
    cursor: pointer;
}

.reply {
    padding: 18px 20px;
    box-sizing: border-box;
    position: absolute;
    bottom: 20px;
    left: 20px;
    width: 400px;
    height: 108px;
    overflow: auto;
    background: #F9F9F9;
    border-radius: 8px;

    textarea {
        border: 0;
        background-color: transparent;
        width: 100%;
        overflow: auto;
        border: none;
        outline: none;
        resize: none;
        /* 禁止调整大小 */

    }

    .button-set {
        margin: 0;
        position: absolute;
        bottom: 10px;
        right: 20px;
        display: flex;

        button {
            background-color: #EEEEEE;
            border: 0;
            margin-right: 15px;
            width: 64px;
            height: 32px;
            color: #9E9E9E;
        }

        button:last-child {
            margin: 0;
        }

        &.active {
            button {
                background-color: #0870D1;
                color: #FFFFFF;
            }
        }
    }

    .numok {
        width: 39px;
        height: 19px;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        line-height: 20px;
        color: #EEEEEE;
    }
}
</style>
