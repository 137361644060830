import { createRouter, createWebHashHistory } from 'vue-router'
import Index from '../views/Index'
import Detail from '../views/Detail'
import SearchList from '../views/SearchList'
import MyInfo from '../views/MyInfo';
import Setting from '../views/Setting/Setting';
import CreateEntry from '../views/Create/CreateEntry'
import CreateStory from '../views/Create/CreateStory'
import CreateJourney from '../views/Create/CreateJourney'
import Privacy from '../views/Privacy';
import TermsOfService from '../views/TermsOfService';
import DeleteUser from '../views/DeleteUser.vue'
import CommercialTransactionsLaw from '../views/CommercialTransactionsLaw.vue'

//Demo
import EditorDemo from '../views/Demo/editor.vue'

const routes = [
    {
        path: '/',
        name: 'Index',
        component: Index,
    },
    {
        path: '/:id/:position',
        name: 'Detail',
        component: Detail,
    },
    {
        path: '/toDetail/:id/:pictureId',
        name: 'toDetail',
        component: Detail,
    },
    {
        path: '/search',
        name: 'search',
        component: SearchList,
    },
    {
        path: '/my',
        name: 'my',
        component: MyInfo,
    },
    {
        path: '/setting',
        name: 'setting',
        component: Setting,
    },
    {
        path: '/create_entry',
        name: 'CreateEntry',
        component: CreateEntry
    },
    {
        path:'/create_story',
        name:'CreateStory',
        component:CreateStory
    },
    {
        path:'/create_journey',
        name:'CreateJourney',
        component:CreateJourney
    },
    {
        path: '/privacy',
        name: 'privacy',
        component: Privacy,
    },
    {
        path: '/termsOfService',
        name: 'termsOfService',
        component: TermsOfService,
    },
    {
        path: '/commercialTransactionsLaw',
        name: 'CommercialTransactionsLaw',
        component: CommercialTransactionsLaw,
    },
    {
        path: '/removeUser',
        name: 'removeUser',
        component: DeleteUser,
    },

    //Demo
    {
        path:'/editor_demo',
        name:'EditorDemo',
        component:EditorDemo
    }
]

const router = createRouter({
    history: createWebHashHistory(),
    routes
})

router.beforeEach((to, from, next) => {
    document.title = 'Look'
    if (to.name === 'create' || to.name === 'my' || to.name === 'setting') {
        if (!localStorage.getItem('token')) {
            router.push({ name: 'Index' })
        } else {
            next();
        }
    } else {
        next()

    }
})

export default router
