<!-- eslint-disable vue/no-multiple-template-root -->
<!-- eslint-disable vue/no-v-model-argument -->
<template>
  <div>
    <a-spin size="large" :spinning="spinning">
      <div class="home-box-my" @scroll="scrollEvent">
        <Header />

        <div class="create-center">
          <ImageEditTitle ref="imageEditTieleRef" style="min-width: 1000px;position: relative;z-index: 5556;" v-model:image-edit-visible="imgEditVisible" :files="filesUrlArray" :is-created="true" @saveTitleList="saveTitleList" :imgNote="data.imgNote ? data.imgNote : ''">
          </ImageEditTitle>

          <div class="crete-center-box">
            <!-- 搜索框 -->
            <div style="position: relative;">
              <EntrySelect :width="'100%'" @updateEntryModel="updateEntryModel" />
            </div>

            <div class="create-box">
              <div class="info-box">
                <input class="info-title" type="text" :placeholder="$t('create.title')" maxlength="15" v-model="data.imgNote">
                <span>({{ data.imgNote ? data.imgNote.toString().length : 0 }}/15)</span>
              </div>
              <div class="info-text">
                <textarea :placeholder="$t('create.description')" maxlength="500" v-model="data.imgComment"></textarea>
                <span>({{ data.imgComment ? data.imgComment.toString().length : 0 }}/500)</span>
              </div>
              <div class="box-title">
                <span>{{ $t('create.addPictures') }}</span>
                <div class="edit-title-box" :class="{ active: data.files.length <= 0 }" @click="openImageEdit">
                  <edit-filled />
                  <span class="edit-title">{{ this.$t('create.titleStyle') }}</span>
                </div>
              </div>
              <div class="img-box">
                <div class="img-list aaaaaaaaa" v-for="(item, i) in data.files" :key="i">
                  <img class="view-img" :src="getFileUrl(item)" alt="">
                  <img class="del-img" :src="require('@/assets/icon-close.png')" alt="" @click="delFile(i)">
                </div>
                <label class="img-list img-list-add" v-show="data.files.length < 9">
                  <img class="add-img" :src="require('@/assets/add-img.svg')" alt="">
                  <input type="file" @change="getFile($event)" multiple accept="image/gif, image/jpeg, image/png, image/jpg">
                  <!-- accept="image/*" -->
                </label>
                <span class="info-img">({{ data.files ? data.files.length : 0 }}/9)</span>
              </div>
            </div>
            <div class="create-date">
              <div class="create-date-left">{{ $t('create.customShootingTime') }}</div>
              <div class="create-date-right">
                <a-date-picker ref="pickerRef" v-model="time" :disabledDate="dateHandler" format="YYYY-MM-DD HH:mm" :placeholder="$t('create.selectTime')" :hideDisabledOptions="true" @change="onChange" :disabledTime="disabledTime">
                  <span>{{ time ? time : $t('create.selectTime') }}</span>

                </a-date-picker>
              </div>
            </div>
          </div>
        </div>

        <div class="create-footer">
          <div class="create-footer-center" @click="uploadImg">
            <button>{{ $t('create.post') }}</button>
          </div>
        </div>
      </div>
      <Login :visible="visible" :closeModal="closeModal" />
    </a-spin>
  </div>
</template>
<script>
import Header from '@/components/Header.vue'
import moment from "moment";
import Api from "@/Api";
import Login from "@/views/Login";
import { message } from 'ant-design-vue';
import { EditFilled } from '@ant-design/icons-vue';
import ImageEditTitle from '@/components/ImageEdit/index.vue';
import EntrySelect from '@/components/EntrySelect'


let searchTimer = null

export default {
  name: 'Index',
  components: {
    Header,
    Login,
    EditFilled,
    ImageEditTitle,
    // 组件
    EntrySelect
  },
  data() {
    return {
      spinning: false,
      createEntries: false,
      entryList: [],
      //登录组件
      visible: false,
      //图片编辑
      imgEditVisible: false,
      showSelect: false,
      showSearchList: false,
      select: '',
      time: undefined,
      pic_types: ["gif", "png", "jpg", "bmp", "ico", "jpeg"],
      initData: {
        treeId: this.$route.query.entryId,
        treeName: this.$route.query.entryName,
        imgTime: undefined,
        imgNote: undefined,
        imgComment: undefined,
        tagId: undefined,
        files: []
      },
      data: {
        treeId: this.$route.query.entryId,
        treeName: this.$route.query.entryName,
        imgTime: undefined,
        imgNote: undefined,
        imgComment: undefined,
        tagId: undefined,
        files: [],
        titles: []
      },
      searchList: [],
      allSearchHistories: [],
      searchHistories: [],
      hotSearch: [],
      isAllHistory: false,
      filesList: [],
      hasTitle: false,
      value: ''
    }
  },
  created() {
    let date = new Date()
    let y = date.getFullYear()
    let M = date.getMonth() < 9 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1
    let d = date.getDate() < 9 ? '0' + date.getDate() : date.getDate()
    let h = date.getHours() < 9 ? '0' + date.getHours() : date.getHours()
    let m = date.getMinutes() < 9 ? '0' + date.getMinutes() : date.getMinutes()
    this.time = y + '-' + M + '-' + d + ' ' + h + ':' + m
  },
  mounted() {
    if (!localStorage.getItem('language')) {
      localStorage.setItem('language', 'ja')
    }
    this.$i18n.locale = localStorage.getItem('language');
    this.getSearchHistory();
    this.findHotSearch();
    this.range()
  },
  computed: {
    filesUrlArray: {
      get() {
        let array = [];
        array = (this.filesList || []).reduce((result, item) => {
          if (item.titleFile) {
            result.push({ ...item });
          } else {

            let URL = window.URL || window.webkitURL;
            let image = new Image();
            let imgURL = URL.createObjectURL(item.file || item);
            console.log((item.file || item).name)
            image.src = imgURL;
            image.onload = function () {
              const imageWidth = image.width;
              const imageHeight = image.height;
              let tmp = { ...item };
              tmp.file = { url: imgURL, width: imageWidth, height: imageHeight, name: (item.file || item).name };
              result.push(tmp)
              image = null
            }
          }

          return result
        }, [])

        return array;
      }
    }
  },
  methods: {
    addEntries() {
      if (this.checkLogin()) {
        return;
      }
      this.data.treeId = undefined
      this.data.treeName = this.value.trim()
      this.showSelect = false;
    },
    updateEntryModel(item) {
      console.log(item)
      this.data.treeId = item?.entryId;
      this.data.treeName = item.entryName;
    },
    dateHandler(date) {
      return date && date > moment().endOf('day');
    },
    range(start, end) {
      const result = [];
      for (let i = start; i < end; i++) {
        result.push(i);
      }
      return result;
    },
    disabledTime() {
      const h = moment().hours();
      const m = moment().minutes();
      return {
        disabledHours: () => this.range(h, 24),
        disabledMinutes: () => this.range(m, 60)
      }
    },
    close() {
      this.value = null
      this.showSearchList = false
      this.createEntries = false
      this.data.treeId = undefined
      this.data.treeName = undefined
    },
    selectdefaultEntry(entryTitle, entryId) {
      this.data.treeId = entryId;
      this.data.treeName = entryTitle;
    },
    selectEntry(item, isSaveHistory = true) {
      this.data.treeId = item.id;
      this.data.treeName = item.title;
      this.showSelect = false;
      if (isSaveHistory) {
        this.saveSearchHistory(this.data.treeId, this.data.treeName);
      }
    },
    selectHotSearch(val, isSaveHistory = true) {
      this.data.treeId = val.entryId;
      this.data.treeName = val.entryName;
      let el = document.getElementById("keyword")
      el.value = val.entryName;
      el.dispatchEvent(new Event('input'));
      this.showSelect = false;
      if (isSaveHistory) {
        this.saveSearchHistory(this.data.treeId, this.data.treeName);
      }
    },
    findHotSearch() {
      Api.findHotSearch().then((res) => {
        if (res.error_code == 0) {
          this.hotSearch = res.result.data;
        }
      });
    },

    getSearchHistory() {
      // 获取首页的前三条条目
      this.entryList = localStorage.getItem('selectItemList') && JSON.parse(localStorage.getItem('selectItemList')) || [];
      console.log('this.entryList', this.entryList.slice(0, 3))
      let histories = localStorage.getItem("searchHistories") && JSON.parse(localStorage.getItem("searchHistories")) || [];
      this.allSearchHistories = histories;
      this.searchHistories = histories.slice(0, 3);
      return histories;
    },

    /**
     * 保存搜索记录
     */
    saveSearchHistory(entryId, entryName) {
      let histories = localStorage.getItem("searchHistories") && JSON.parse(localStorage.getItem("searchHistories")) || [];
      let i = 0;
      for (; i < histories.length; i++) {
        if (histories[i].entryId == entryId) {
          break;
        }
      }
      if (i >= histories.length) {
        histories.unshift({ entryId: entryId, entryName: entryName });
        localStorage.setItem("searchHistories", JSON.stringify(histories));
      }
    },

    changeSearchVal(e) {
      console.log(e.target.value);
      this.showSearchList = e.target.value ? true : false
      this.createEntries = false
      this.setSearchList(e);
    },

    setSearchList(e) {
      if (this.value && this.value.trim() != '') {
        clearTimeout(searchTimer)
        searchTimer = setTimeout(() => {
          Api.getSearch({
            keywords: this.value
          }).then((res) => {
            let data = res.result.data;
            if (data.length > 0) {
              this.searchList = data
              let newList = data.map(element => { return element.title; });
              for (let index = 0; index < newList.length; index++) {
                const elementItem = newList[index];
                if (elementItem === e.target.value) {
                  return this.createEntries = false;
                } else {
                  return this.createEntries = true;
                }
              }
            } else {
              this.createEntries = true
            }
          })
        }, 200)

      } else {
        this.searchList = []
      }

    },

    getFileUrl(file) {
      let url;
      url = window.URL.createObjectURL(file);
      return url;
    },
    showSelecthandle() {
      this.showSelect = !this.showSelect
    },
    onChange(value, dateString) {
      console.log(value)
      console.log(dateString)
      if (value > moment()) {
        value = moment()
        dateString = moment().format("YYYY-MM-DD HH:mm")
        this.time = (moment().format("YYYY-MM-DD HH:mm")).trim()
        return
      }
      this.time = dateString
    },
    delFile(index) {
      this.filesList.splice(index, 1);
      this.data.files.splice(index, 1);
    },
    getFile(event) {
      var file = event.target.files;
      // console.log(file)
      const filesLength = file.length;  //21
      const dataFilesLength = this.data.files.length; //80
      const allFilesLength = filesLength + dataFilesLength;
      const useLength = 9 - dataFilesLength
      if (allFilesLength > 9) {
        file = Array.from(file)
        file = file.slice(0, useLength)
        this.$message.error(this.$t('editAvatar.maxTitle'))
      }
      for (var i = 0; i < file.length; i++) {
        //    上传类型判断
        var imgName = file[i].name;
        var idx = imgName.lastIndexOf(".");
        if (idx != -1) {
          var ext = imgName.substr(idx + 1).toUpperCase();
          ext = ext.toLowerCase();
          if (this.pic_types.indexOf(ext) > -1) {
            this.data.files.push(file[i]);
            if (!this.filesList) {
              this.filesList = []
            }
            this.filesList.push({
              file: file[i]
            })
          } else {
            message.error(this.$t('editAvatar.typeRule'));
          }
        } else {
          message.error(this.$t('editAvatar.typeRule'));
        }
      }
    },
    async uploadImg() {
      this.spinning = true
      try {
        if (this.checkLogin()) {
          return;
        }
        this.data.imgTime = (this.time ? moment(this.time) : moment()).utc().format("YYYYMMDDHHmm");
        
        if (!this.data.treeId  && !this.data.treeName) {
          this.$message.error(this.$t('create.prompt'));
          throw new Error;
        }
        console.log('imgTime ===>', this.data.imgTime)
        // 创建的
        let _that = this;
        if (this.data.treeId === undefined && this.data.treeName !== undefined) {
          console.log('创建条目')
          let params = {
            title: this.data.treeName
          }
          // 创建条目
          const data = await Api.createAddEntry(params)
          console.log(data)
          if (data.error_code == 0) {
            this.data.treeId = data.result.data.id
            console.log('创建成功')
          }
        }
        
        const files = this.filesList.reduce((result, item, i) => {
          if (item.titleFile) {
            result.push(this.data.files[i], item?.titleFile)
          } else {
            result.push(this.data.files[i])
          }
          return result
        }, [])
        
        const titles = this.filesList.reduce((result, item) => {
          if (item.text) result.push(item.text)
          return result
        }, [])
       
        const reqModel = {
          treeId: this.data.treeId,
          treeName: this.data.treeName,
          imgTime: this.data.imgTime,
          imgComment: this.data.imgComment?.replace(/\s+$/, ""),
          imgNote: this.data.imgNote?.trim(),
          files: files,
          titles: titles
        }
        
        if (!reqModel.imgNote) {
          this.$message.warning(this.$t('create.titlePlaceholder'))
          throw new Error('检查信息没有填好')
        }
        if (!(reqModel.files.length > 0 || this.data.imgComment)) {
          this.$message.warning(this.$t('create.checkPicture'))
          throw new Error('检查图文是否为空')
        }
        console.log(reqModel)
        const res = await Api.uploadImg(reqModel)
        if (res.error_code == 0) {
          const treeId = _that.data.treeId;
          this.data = {
            ...this.data,
            imgTime: undefined,
            imgNote: undefined,
            imgComment: undefined,
            tagId: undefined,
            files: []
          }
          this.filesList = [];
          this.spinning = false
          this.$confirm({
            // 询问是或否确定删除
            content: this.$t('create.success'),
            okText: this.$t('create.viewPost'),
            okType: 'danger',
            cancelText: this.$t('create.postNext'),
            onOk() {
              _that.$router.push({
                name: 'Detail',
                params: { id: treeId, position: 't' }
              })
            },
            oncancel() {
            }
          });
        }
        this.spinning = false
      } catch (e) {
        this.spinning = false
        if (e.response?.status == 401 || e.response?.status == 400) {
          this.$message.error(this.$t("tokenErrMessage"));
          this.checkLogin();
          this.visible = true
        } else {
          // this.$message.error(e);
        }
      }

    },

    checkLogin() {
      this.visible = !localStorage.getItem('token');
      return this.visible;
    },

    closeModal() {
      this.visible = false;
    },
    openImageEdit() {
      if (this.data.files.length > 0) {
        this.imgEditVisible = true
        this.$refs.imageEditTieleRef.chooseHandler(this.filesUrlArray[0], 0)
        const text = this.data.imgNote ? this.data.imgNote : ''
        const list = Array.from(this.filesUrlArray);
        list.forEach((item, index) => {
          if (item.name === this.filesList[index]) {
            // item已经存在，
            item = this.filesList[index];
          }
        })
        this.$refs.imageEditTieleRef.init(text, list)
      }
    },
    saveTitleList(list) {
      this.filesList = list
      this.hasTitle = true;
    }
  }
}

</script>
<style lang="scss">
body {
  background: #f8f8f8 !important;
}

.create-center {
  height: calc(100vh - 80px - 67px - 20px);
  margin-top: 100px;
  overflow-y: auto;

  .crete-center-box {
    width: 840px;
    margin: 0 auto;

    padding: 20px 0px;

    // .cretate-select-info {
    //   padding: 13px 20px;
    //   display: flex;
    //   align-items: center;
    //   background: #ffffff;
    //   border-radius: 8px;
    //   position: relative;
    //   height: 70px;

    //   .create-left-info {
    //     height: 24px;
    //     font-size: 18px;
    //     font-weight: 400;
    //     line-height: 24px;
    //     color: #0870d1;
    //     padding-right: 16px;
    //     cursor: pointer;

    //     >img {
    //       margin-left: 10px;
    //       width: 8px;
    //       vertical-align: middle;
    //       margin-top: -2px;
    //     }

    //     &.active {
    //       >img {
    //         transform: rotate(90deg);
    //       }
    //     }
    //   }

    //   .create-right-info {
    //     border-left: 1px solid #eeeeee;
    //     padding-left: 10px;
    //     border-radius: 8px;

    //     .slect-box-center {
    //       .select-item-box {
    //         overflow: hidden;

    //         .select-item {
    //           float: left;
    //           padding: 4px 10px;
    //           font-size: 14px;
    //           font-weight: 400;
    //           line-height: 1;
    //           // color: #999999;
    //           color: rgb(238, 238, 238);
    //           // background: #eff3f6;
    //           background-color: rgb(8, 112, 209);
    //           border-radius: 25px;
    //           margin-right: 10px;
    //           cursor: pointer;
    //         }
    //       }

    //       .select-text {
    //         font-size: 12px;
    //         font-weight: 400;
    //         line-height: 16px;
    //         color: #cccccc;
    //         margin-top: 7px;
    //       }
    //     }
    //   }

    //   .comment-cross {
    //     position: absolute;
    //     right: 15px;
    //     // z-index: 5555;
    //     cursor: pointer;
    //     -webkit-user-select: none;
    //     -moz-user-select: none;
    //     -ms-user-select: none;
    //     user-select: none;
    //   }

    //   .create-modal-box {
    //     position: absolute;
    //     top: 91px;
    //     left: 0;
    //     width: 100%;
    //     background: #ffffff;
    //     box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
    //     border-radius: 8px;
    //     padding: 20px;
    //     z-index: 3;

    //     .search-item {
    //       width: 100%;
    //       /*width: 840px;*/
    //       height: 58px;
    //       background: #f8f8f8;
    //       border-radius: 25px;
    //       padding: 0 21px;
    //       display: flex;
    //       align-items: center;
    //       z-index: 1;
    //       position: relative;

    //       .search-icon {
    //         width: 25px;
    //         height: 25px;
    //       }

    //       .search-input {
    //         flex: 1;
    //         height: 25px;
    //         margin-left: 21px;
    //         font-size: 18px;
    //         font-weight: 400;
    //         border: none;
    //         outline: none;
    //         background: none;
    //       }

    //       .search-list {
    //         width: 100%;
    //         height: 380px;
    //         background: #fff;
    //         position: absolute;
    //         top: 50px;
    //         left: 0;
    //         padding-bottom: 16px;

    //         .search-list-item {
    //           font-size: 14px;
    //           font-weight: bold;
    //           color: #666666;
    //           line-height: 1;
    //           padding: 0 20px;
    //           margin-top: 16px;
    //           cursor: pointer;

    //           &.active {
    //             color: #0870d1;
    //           }
    //         }
    //       }
    //     }

    //     .create-entries {
    //       text-align: center;

    //       >button {
    //         width: 112px;
    //         height: 36px;
    //         line-height: 34px;
    //         background: #0870d1;
    //         border-radius: 50px;
    //         border: none;
    //         outline: none;
    //         color: #fff;
    //         font-size: 16px;
    //         cursor: pointer;
    //       }
    //     }

    //     .title {
    //       font-size: 18px;
    //       font-weight: bold;
    //       line-height: 24px;
    //       color: #333333;
    //       margin-top: 28px;
    //       padding-left: 15px;
    //       margin-bottom: 11px;
    //     }

    //     .item {
    //       padding: 0 15px;
    //       display: flex;
    //       align-items: center;
    //       border-bottom: 1px solid #eee;
    //       height: 54px;
    //       cursor: pointer;

    //       .item-left {
    //         flex: 1;

    //         .item-title {
    //           font-size: 16px;
    //           font-weight: 400;
    //           line-height: 21px;
    //           color: #333333;
    //         }

    //         .item-text {
    //           font-size: 14px;
    //           font-weight: 400;
    //           line-height: 19px;
    //           color: #cccccc;
    //           margin-top: 5px;
    //         }
    //       }

    //       .item-right {
    //         font-size: 14px;
    //         font-weight: 400;
    //         line-height: 19px;
    //         color: #0870d1;
    //       }

    //       &.item-text {
    //         height: 64px;
    //       }
    //     }

    //     .more-list {
    //       text-align: center;
    //       height: 54px;
    //       font-size: 14px;
    //       font-weight: 500;
    //       line-height: 54px;
    //       color: #999999;
    //       cursor: pointer;

    //       svg {
    //         transform: rotate(90deg);
    //         vertical-align: middle;
    //         width: 6px;
    //         margin-left: 8px;
    //       }
    //     }
    //   }
    // }

    .create-box {
      background: #fff;
      margin-top: 20px;
      padding: 42px 20px;

      .info-box {
        padding-bottom: 20px;
        line-height: 1;
        border-bottom: 1px solid #eeeeee;
        display: flex;
        align-items: center;

        .info-title {
          flex: 1;
          font-size: 24px;
          font-weight: bold;
          line-height: 31px;
          color: #666666;
          border: none;
          outline: none;
        }

        .span {
          font-size: 14px;
          font-weight: 400;
          line-height: 19px;
          color: #cccccc;
        }
      }

      .info-text {
        margin-top: 10px;
        font-size: 16px;
        font-weight: 400;
        line-height: 21px;
        color: #666666;
        position: relative;
        padding-bottom: 43px;
        border-bottom: 1px solid #eeeeee;
        height: 163px;

        >textarea {
          width: 100%;
          height: 100%;
          padding: 0;
          outline: none;
          border: none;
          resize: none;
        }

        >span {
          position: absolute;
          bottom: 12px;
          right: 0;
          font-size: 14px;
          line-height: 1;
        }
      }

      .box-title {
        font-size: 16px;
        font-weight: 400;
        line-height: 21px;
        color: #666666;
        margin-top: 20px;
        margin-bottom: 40px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .edit-title-box {
          font-size: 14px;
          font-family: Microsoft YaHei-Regular, Microsoft YaHei;
          font-weight: 400;
          color: #0870D1;
          display: flex;
          justify-content: space-around;
          align-items: center;
          cursor: pointer;

          .edit-title {
            margin-left: 10px;
          }
        }

        .edit-title-box.active {
          color: #CCCCCC;
          cursor: not-allowed;
        }
      }

      .img-box {
        width: 100%;
        overflow: hidden;
        position: relative;

        .info-img {
          position: absolute;
          bottom: 12px;
          right: 0;
          font-size: 14px;
          line-height: 1;
        }

        .img-list {
          float: left;
          width: 108px;
          height: 108px;
          line-height: 108px;
          margin-right: 20px;
          margin-bottom: 20px;
          text-align: center;
          border: 1px solid #eee;
          position: relative;

          .view-img {
            max-width: 100%;
            max-height: 100%;
            vertical-align: middle;
            object-fit: cover;
          }

          .del-img {
            position: absolute;
            top: 6px;
            right: 6px;
            width: 16px;
            height: 16px;
            border-radius: 50%;
            background: rgba(0, 0, 0, 0.2);
            cursor: pointer;
            padding: 2px;
          }

          &.img-list-add {
            cursor: pointer;

            input {
              width: 2px;
              height: 2px;
              z-index: 0;
              position: absolute;
              visibility: hidden;
            }
          }
        }

        .aaaaaaaaa {
          display: flex;
        }
      }
    }

    .create-date {
      display: flex;
      background: #fff;
      margin-top: 20px;
      padding: 26px 20px;

      .create-date-left {
        flex: 1;
        font-size: 16px;
        font-weight: 400;
        line-height: 21px;
        color: #333333;
      }

      .create-date-right {
        text-align: right;

        span {
          cursor: pointer;
          font-size: 14px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          line-height: 19px;
          color: #0870D1;
          opacity: 1;
        }
      }
    }
  }
}

.create-footer {
  height: 67px;
  position: fixed;
  bottom: 0;
  width: 100%;
  background: #ffffff;
  box-shadow: -3px 0px 6px rgba(0, 0, 0, 0.1);

  .create-footer-center {
    line-height: 67px;
    width: 840px;
    margin: 0 auto;
    text-align: right;

    >button {
      width: 112px;
      height: 36px;
      line-height: 34px;
      background: #0870d1;
      border: none;
      outline: none;
      color: #fff;
      font-size: 16px;
      cursor: pointer;
    }
  }
}
</style>
