<template>
    <div class="insadlkaf">
        <div style="padding:20px;border:1px solid #000;">{{ valueHtml }}</div>
        <Toolbar style="border-bottom: 1px solid #ccc;display:none" :editor="editorRef" :defaultConfig="toolbarConfig"
            :mode="mode" />
        <Editor class="editor" style="min-height: 300px;overflow-y: hidden;border:0;" @input.native="inputHandler"
            v-model="valueHtml" :defaultConfig="editorConfig" :mode="mode" @customPaste="customPaste"
            @onCreated="handleCreated" @onFocus="focusHandler" />
    </div>
</template>


<script>
import '@wangeditor/editor/dist/css/style.css' // 引入 css
import { onBeforeUnmount, ref, shallowRef, onMounted } from 'vue'
import { Editor, Toolbar } from '@wangeditor/editor-for-vue'
// 引入 eventBus
import eventBus from '@/utils/eventBus.js';

export default {
    components: { Editor, Toolbar },
    setup() {

        // 编辑器实例，必须用 shallowRef
        const editorRef = shallowRef()

        // 内容 HTML
        const valueHtml = ref("")


        const toolbarConfig = {}
        const editorConfig = {
            placeholder: '请输入内容...',
            MENU_CONF: {},
            scroll: false
        }


        editorConfig.MENU_CONF['uploadImage'] = {
            // 上传图片的配置
            // 自定义上传
            async customUpload(uri, insertFn) {                   // JS 语法
                // file 即选中的文件
                // 自己实现上传，并得到图片 url alt href
                // console.log('file文件上传 ===>', file);
                // const url = URL.createObjectURL(file)
                const alt = 'this is a picture.';
                const href = ''
                // console.log('insertFn ===>', insertFn)
                // 最后插入图片

                insertFn(uri, alt, href)
            }
        }

        onMounted(() => {
            // setTimeout
        })
        // 组件销毁时，也及时销毁编辑器
        onBeforeUnmount(() => {
            const editor = editorRef.value
            if (editor == null) return
            editor.destroy()
        })

        const handleCreated = (editor) => {
            editorRef.value = editor // 记录 editor 实例，重要！
            console.log(editor.getConfig())
            editor.getConfig().scroll = false;
            editor.getConfig().hoverbarKeys['text'] = {}
            editor.getConfig().hoverbarKeys['image'] = {}
        }

        const inputHandler = () => {

        }

        // eslint-disable-next-line no-unused-vars
        const customPaste = (editor, event) => {                                       // JS 语法

            // event 是 ClipboardEvent 类型，可以拿到粘贴的数据
            // 可参考 https://developer.mozilla.org/zh-CN/docs/Web/API/ClipboardEvent

            // const html = event.clipboardData.getData('text/html') // 获取粘贴的 html
            // const rtf = event.clipboardData.getData('text/rtf') // 获取 rtf 数据（如从 word wsp 复制粘贴）

            // 同步
            // editor.insertText(text)

            // 阻止默认的粘贴行为
            // event.preventDefault()
            // return false

            // 继续执行默认的粘贴行为
            return true
        }


        const focusHandler = (e) => {
            console.log("id ===> ", e?.id)
            const EditorId = e?.id;
            // 更新id updateEditorId
            eventBus.emit("updateEditorId", EditorId);
        }


        return {
            editorRef,
            valueHtml,
            mode: 'default', // 或 'simple'
            toolbarConfig,
            editorConfig,
            handleCreated,
            inputHandler,
            customPaste,
            focusHandler
        };
    }
}
</script> 
