<template >
    <div>
        这里是行程创建
    </div>
</template>
<script>
export default {

}
</script>
<style lang="scss" scoped>
    
</style>