<!-- 举报框 -->
<template>
  <a-modal  @cancel="reportClose" :visible="reportDialog" width="20%">
      <div class="allContent">
          <div class="title">{{$t('reportDialog.reportingProblems')}}</div>
          <p></p>
          <a-radio-group v-model="value">
            <a-radio class="radio" value="1" @click="checked('1')">{{$t('reportDialog.repeat')}}</a-radio>
            <a-radio class="radio" value="2" @click="checked('2')">{{$t('reportDialog.antipathy')}}</a-radio>
            <a-radio class="radio" value="3" @click="checked('3')">{{$t('reportDialog.poorContentQuality')}}</a-radio>
          </a-radio-group>
        <p></p>
          <a-Button class="copy" @click="reportsubmit">{{$t('done')}}</a-Button>
      </div>
  </a-modal>
</template>

<script>

export default{
  data() {
    return {
     value: ''
    }
  },
  props:{
    reportDialog: Boolean
  },
  emits: ['reportsubmit', 'reportClose'],

  methods: {
    checked(v) {
      this.value = v
    },
    reportClose: function(){
       this.$emit('reportClose');
    },
    reportsubmit: function(){
      this.$emit('reportsubmit', this.value);
    },
  },

  watch:{

  }

}
</script>

<style lang="scss" scoped>
.allContent {
  margin-bottom: 40px;
}
.title {
   font-size: 16px;
   font-weight: 400;
   color: #202124;
}
.copy {
  background: #0870D1;
  border-radius: 4px;
  font-size: 14px;
  color: #FFFFFF;
  float: right;
}
.radio {
  display: block;
  height: 30px;
  lineHeight: 30px;
}
</style>
