import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Antd from 'ant-design-vue';
import 'ant-design-vue/dist/antd.css';
import '@/assets/style/antStyle.scss';
import '@/assets/style/baseStyle.scss';
import lazyPlugin from 'vue3-lazy'
import i18n from './language/i18n'
import VueClipboard from 'vue-clipboard2'
import '@/assets/font_4125579_gcq5nwmg5qv/iconfont.css'
// axios.defaults.headers.get['header-name'] = 'value'
import GoogleAuth from '@/config/google_oAuth.js'
const gauthOption = {
    clientId: '676418604540-bnt82iftbgdk1t52ptbhtlq3aordloqn.apps.googleusercontent.com',
    // clientId: '481909600784-35t6sc0mbrik8vg6g0nqrog0tk6le53v.apps.googleusercontent.com',
    scope: 'profile email',
    prompt: 'select_account'
}

import editorEle from './components/Editor/useIndex'

const app = createApp(App)

app.use(router)
app.use(store)
app.use(VueClipboard)


// 添加 Antd
app.use(Antd)

app.use(i18n)

app.use(GoogleAuth, gauthOption)

// 配置项
app.use(lazyPlugin, {
    preLoad: 1.3,
    // eslint-disable-next-line no-undef
    loading: require('@/assets/empty.png'),
    // eslint-disable-next-line no-undef
    error: require('@/assets/empty.png'),
    attempt: 1
})


let isRegistered = false;

// 注册函数
function register() {
    // 此处是你需要进行全局注册的代码
    console.log('执行了全局注册');
    app.use(editorEle);
    // 将全局变量标记为已注册状态
    isRegistered = true;
}

if (!isRegistered) {
    // 如果还没有进行过注册，则进行注册
    register();
}



//外部点击指令
app.directive('click-outside', {
    mounted(el, binding) {
        //el -- 绑定的元素
        // binding -- 指令信息绑定的对象
        // 具有以下属性：
        /*
        value: 指令的绑定值，可以是一个函数或表达式。在这个示例中，binding.value是一个函数，在外部点击事件时将被调用。
        arg: 指令的参数。如果指令使用了参数（例如：v-click-outside:arg），那么binding.arg将是该参数的值。
        modifiers: 一个包含修饰符的对象。修饰符是指令名称后面以.分隔的特殊后缀。
        例如：v-click-outside.prevent中的.prevent就是一个修饰符。在这个示例中，没有使用修饰符。
        instance: 指令所在的组件实例。
        */
        const handleClickOutside = (event) => {
            // 点击事件的目标元素不在指令绑定的元素内部。
            // 点击事件的目标元素不是指令绑定的元素本身。
            if (!el.contains(event.target) && el !== event.target) {
                binding.value(event);
            }
        };

        document.addEventListener('click', handleClickOutside);
        el._clickOutsideHandler = handleClickOutside;
    },
    beforeUnmount(el) {
        document.removeEventListener('click', el._clickOutsideHandler);
        delete el._clickOutsideHandler;
    }
})


app.mount('#app')
