

function parseAttachmentHtml(domElem, children, editor) {                                                     // JS 语法

    // 从 DOM element 中获取“附件”的信息
    const src = domElem.getAttribute('data-src') || ''
    const titleText = domElem.getAttribute('data-titleText') || ''

    console.log('src 为===>', src);
    console.log('titleText ===>', titleText);
    // 生成“附件”元素（按照此前约定的数据结构）
    const a = {
        type: "newele",
        src,
        titleText,
        children: [{ text: '' }]  // void node 必须有 children ，其中有一个空字符串，重要！！！
    }

    return a
}

const parseHtmlConf = {
    selector: 'div[data-w-e-type="newele"]', // CSS 选择器，匹配特定的 HTML 标签
    parseElemHtml: parseAttachmentHtml,
}
export default parseHtmlConf;