<template>
    <Header :tabs="tabs" :selectTab="selectTab" :showSearch="true"
            @targetSide="targetSide" @changeTab="changeTab"
            :value="keywords"
            @keydownEnter="keydownEnter"
            @changeSearchEvent="changeSearchEvent"
    />
    <div class="setting-center-div container">
        <div class="setting-center-top">
            <ul>
                <li>
                    <span class="label">{{$t('setting.profile')}}</span>
                    <div class="label-content">
                        <img class="img-user"
                             :src="user.avatar ? user.avatar : require('@/assets/def-ava.svg')"
                             alt="">
                    </div>
<!--                    <a-upload
                        name="file"
                        :multiple="true"
                        action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                        :headers="headers"
                        @change="handleChange"
                        :showUploadList="false"
                    >
                        <span class="action-btn">更改</span>
                    </a-upload>-->

                    <span class="action-btn" @click="editAvatar()">{{$t('setting.edit')}}</span>
                </li>
                <li>
                    <span class="label">{{$t('setting.username')}}</span>
                    <div class="label-content">{{ user.username }}</div>
                    <span class="action-btn" @click="editUser('name', user.username)">{{$t('setting.edit')}}</span>
                </li>
                <li>
                    <span class="label">{{$t('setting.phone')}}</span>
                    <div class="label-content">{{ user.phone }}</div>
                    <span class="action-btn" @click="editUser('phone', user.phone)">{{$t('setting.edit')}}</span>

                </li>
            </ul>
        </div>
        <div class="setting-center-bottom">
            <ul>
                <li>
                    <span class="label">{{$t('setting.userID')}}</span>
                    <div class="label-content">{{ user.userid }}</div>
                    <!--          <span class="action-btn" @click="editUser('userId', user.userid)">编辑</span>-->
                </li>
                <li>
                    <span class="label">{{$t('setting.gender')}}</span>
                    <div class="label-content">{{ showSex(user.sex) }}</div>
                    <span class="action-btn" @click="editUser('sex', user.sex)">{{$t('setting.edit')}}</span>
                </li>
                <li>
                    <span class="label">{{$t('setting.birthday')}}</span>
                    <div class="label-content">{{ user.birthday }}</div>
                    <span class="action-btn" @click="editUser('date', user.birthday)">{{$t('setting.edit')}}</span>
                </li>
                <li>
                    <span class="label">{{$t('setting.emailAddress')}}</span>
                    <div class="label-content">{{ user.email }}</div>
                    <span class="action-btn" @click="editUser('email', user.email)">{{$t('setting.edit')}}</span>
                </li>
                <li>
                    <span class="label">{{$t('setting.resetPassword')}}</span>
                    <div class="label-content">*********</div>
                    <span class="action-btn" @click="editUser('password')">{{$t('setting.edit')}}</span>
                </li>
            </ul>
        </div>
    </div>
    <Footer/>
    <div v-if="visible">
        <EditModal
            :visible="visible"
            :type="type"
            :value="value"
            :success="successHandle"
            :closeModal="closeModal"
        />
    </div>
<!--    <div v-if="true">-->
<!--    </div>-->
    <EditAvatar
        :visible="avatarVisible"
        :success="successHandle"
        @closeModal="avatarVisible=false"
        @getCurrentUser="getCurrentUser"
    />

</template>

<script>
import Header from "@/components/Header";
import Footer from "@/components/Footer";

import EditModal from "@/views/Setting/EditModal";
import EditAvatar from "@/views/Setting/EditAvatar";
import storage from "storejs";
import UserApi from "../../Api/UserApi";

let searchTimer = undefined;
export default {

    name: "Setting",
    components: {
        Header,
        Footer,
        EditModal,
        EditAvatar,
    },
    data() {
        return {
            showSide: false,
            tabs: [],
            keywords: undefined,
            selectTab: undefined,

            visible: false,
            avatarVisible: false,

            headers: {
                authorization: 'authorization-text',
            },

            type: '',
            user: {}
        }
    },

    mounted() {
        this.getCurrentUser();
    },
    methods: {

        //Header
        keydownEnter() {
            this.toSearchPage()
        },
        changeSearchEvent(val) {
            this.keywords = val;
            this.setSearchList();
        },
        //搜索页
        toSearchPage() {
            if (this.keywords != '') {
                this.$router.push({name: 'search', query: {keywords: this.keywords}})
            }
        },
        setSearchList() {
            if (this.keywords && this.keywords.trim() != '') {
                clearTimeout(searchTimer)
                searchTimer = setTimeout(() => {
                    Api.getSearch({
                        keywords: this.keywords
                    }).then((res) => {
                        let data = res.result.data.map((item) => item.title);
                        if (data.length > 0) {
                            this.searchList = data
                        }
                    })
                }, 300)
            } else {
                this.searchList = []
            }

        },

        getCurrentUser() {
            this.user = storage.get('userInfo') || {};
        },
        showSex(sex) {
            switch (sex) {
                case 0:
                    return this.$t('setting.male');
                case 1:
                    return this.$t('setting.female');
                default:
                    return this.$t('setting.custom');
            }
        },

        //编辑的类型
        editUser(type, value = undefined) {
            this.type = type;
            this.value = value;
            this.visible = true;
        },
        editAvatar() {
            this.avatarVisible = true;
        },
        //编辑成功
        successHandle(datas) {
            console.log('succ11 -->', datas);
            let request = undefined;
            switch (this.type) {
                case 'name':
                    request = UserApi.updateUserName(this.user.userid, datas.name)
                    break;
                case 'sex':
                    request = UserApi.updateSex(this.user.userid, datas.sex)
                    break;
                case 'phone':
                    request = UserApi.updatePhone(this.user.userid, datas.phone)
                    break;
                case 'email':
                    request = UserApi.updateUserEmail(this.user.userid, datas.email)
                    break;
                case 'password':
                    request = UserApi.updateUserPwd(this.user.userid, datas.password, datas.new_password)
                    break;
                case 'date':
                    request = UserApi.updateBirthday(this.user.userid, datas.date)
                    break;
            }
            request.then((res) => {
                if (res.error_code == 0) {
                    this.$message.success(res.result);
                    this.updateUser(datas);
                    this.closeModal();
                } else {
                    this.$message.fail(res.result);
                }
            });
        },
        updateUser(data) {
            switch (this.type) {
                case 'name':
                    this.user.username = data.name;
                    break;
                case 'sex':
                    this.user.sex = parseInt(data.sex);
                    break;
                case 'phone':
                    this.user.phone = data.phone;
                    break;
                case 'date':
                    this.user.birthday = data.date;
                    break;
                case 'email':
                    this.user.email = data.email;
                    break;
            }
            localStorage.setItem("userInfo", JSON.stringify(this.user));
        },
        closeModal() {
            this.visible = false;
        },
        handleChange(info) {
            if (info.file.status !== 'uploading') {
            }
            if (info.file.status === 'done') {
                this.$message.success(`${info.file.name} file uploaded successfully`);
            } else if (info.file.status === 'error') {
                this.$message.error(`${info.file.name} file upload failed.`);
            }
        },
    }
}
</script>

<style scoped lang="scss">

.setting-center-div {
    padding-top: 72px;
    padding-bottom: 50px;
    width: 840px;
    margin: auto;

    ul li {
        display: flex;
        padding: 0 20px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.05);

        &:last-child {
            border-bottom: none;
        }

        .label {
            color: #333333;
            font-size: 20px;
            width: 140px;
        }

        .label-content {
            margin-left: 15px;
            font-size: 16px;
            color: #333333;
            flex: 1;

            .img-user {
                width: 60px;
                height: 60px;
                border-radius: 50%;
            }
        }

        .action-btn {
            color: #333333;
            cursor: pointer;
        }
    }

    .setting-center-top {
        background: #ffffff;
        border-radius: 8px;

        line-height: 92px;
    }

    .setting-center-bottom {
        background: #ffffff;
        border-radius: 8px;
        margin-top: 10px;
        line-height: 92px;

        ul li {
            .label {
                color: #999999;

            }

            .label-content {
                color: #666666;
            }
        }
    }
}

</style>
