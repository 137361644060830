<template>
    <a-modal
            :title="setTitle"
            :visible="visible"
            :confirm-loading="confirmLoading"
            @ok="handleOk"
            @cancel="handleCancel"
            centered
            :okText="$t('done')"
            :cancelText="$t('cancel')"
    >
        <a-form ref="formRef" :model="formData" :rules="rules">
            <a-form-item ref="name" name="name" v-if="type == 'name'">
                <a-input v-model:value="formData.name" :placeholder="$t('editModal.usernamePlaceholder')"/>
            </a-form-item>
            <a-form-item ref="phone" name="phone" v-if="type == 'phone'">
                <a-input v-model:value="formData.phone" :placeholder="$t('editModal.phonePlaceholder')"/>
            </a-form-item>
            <a-form-item ref="userId" name="userId" v-if="type == 'userId'">
                <a-input v-model:value="formData.userId" :placeholder="$t('editModal.userIDPlaceholder')"/>
            </a-form-item>
            <a-form-item ref="sex" name="sex" v-if="type == 'sex'">
                <a-select v-model:value="formData.sex" :placeholder="$t('editModal.genderPlaceholder')">
                    <a-select-option value="0">{{$t('editModal.male')}}</a-select-option>
                    <a-select-option value="1">{{$t('editModal.female')}}</a-select-option>
                </a-select>
            </a-form-item>
            <a-form-item ref="date" name="date" v-if="type == 'date'">
                <a-date-picker v-model:value="formData.date" style="width: 100%"  :placeholder="$t('editModal.birthdayPlaceholder')"/>
            </a-form-item>
            <a-form-item ref="email" name="email" v-if="type == 'email'">
                <a-input v-model:value="formData.email" :placeholder="$t('editModal.emailAddressPlaceholder')"/>
            </a-form-item>


            <a-form-item ref="password" name="password" v-if="type == 'password'">
                <a-input v-model:value="formData.password" :placeholder="$t('editModal.currentPasswordPlaceholder')"/>
            </a-form-item>
            <a-form-item ref="new_password" name="new_password" v-if="type == 'password'">
                <a-input v-model:value="formData.new_password" :placeholder="$t('editModal.newPasswordPlaceholder')"/>
            </a-form-item>
            <a-form-item ref="confirm_password" name="confirm_password" v-if="type == 'password'">
                <a-input v-model:value="formData.confirm_password" :placeholder="$t('editModal.confirmPlaceholder')"/>
            </a-form-item>

        </a-form>
    </a-modal>
</template>

<script>
import moment from 'moment';
export default {
    name: "EditModal",
    props: {
        success: Function,
        closeModal: Function,
        visible:Boolean,
        type:String,
        value: String
    },
    data(){
        return {
            TITLE_DIC : {
                name:this.$t('editModal.nameTitle'),
                phone:this.$t('editModal.phoneTitle'),
                userId:this.$t('editModal.userIdTitle'),
                sex:this.$t('editModal.sexTitle'),
                date:this.$t('editModal.dateTitle'),
                email:this.$t('editModal.emailTitle'),
                password:this.$t('editModal.passwordTitle'),
            },
            confirmLoading:false,

            labelCol: { span: 4 },
            wrapperCol: { span: 14 },
            formLayout: 'horizontal',
            formData: {
                name: this.value,
                phone: this.value,
                userId: this.value,
                sex: this.value + "",
                date: this.value,
                email: this.value,
                password:"",   //当前密码
                new_password:"",  //新密码
                confirm_password:"",  //确认密码
            },
            title:'',
        }
    },
    computed: {
        setTitle() {
            return this.TITLE_DIC[this.type]
        },
        rules() {
            let validatePass = (rule, value, callback) => {
                if (value === '') {
                    callback(new Error(this.$t('editModal.passwordNullError')));
                } else {
                    if (this.formData.confirm_password !== '') {
                        this.$refs.formRef.validateFields(['confirm_password'])
                    }
                    callback();
                }
            };
            let validateConfirmPass = (rule, value, callback) => {
                let new_password = this.formData.new_password;
                if (value === '') {
                    callback(new Error(this.$t('editModal.newPasswordNullError')));
                } else if (value !== new_password) {
                    callback(new Error(this.$t('editModal.passwordDifferentError')));
                } else {
                    callback();
                }
            };
            return {
                name:[{ required: true, message: this.$t('editModal.nameRule')}],
                phone:[{ required: true, message: this.$t('editModal.phoneRule')}],
                userId:[{ required: true, message: this.$t('editModal.userIdRule')}],
                sex:[{ required: true, message: this.$t('editModal.sexRule')}],
                date:[{ required: true, message: this.$t('editModal.dateRule')}],
                email:[
                    {required: true, message: this.$t('editModal.emailRule')},
                    {type: 'email', message: this.$t('editModal.emailTypeRule')}
                ],
                password:[{ required: true, message:  this.$t('editModal.passwordRule')}],
                new_password:[{ validator: validatePass,trigger: 'change' }],
                confirm_password:[{ validator: validateConfirmPass,trigger: 'change'}],
            }
        }
    },
    mounted() {

    },
    methods:{
        handleOk(){
            this.$refs.formRef.validate().then(values => {
                if(values.date){
                    values.date = moment(values.date).format('YYYY-MM-DD')
                }

                this.success(values);
            });
        },
        handleCancel(){
            this.closeModal();
        },
    }
}
</script>

<style scoped>

</style>
