export default {
  basicInformation: '基本信息',
  expression: '表情',
  tokenErrMessage: '已经在另外一台电脑登录',
  searchTerm: '搜索词条',
  embeddedCode: '嵌入代码',
  copyLink: '复制链接',
  done: "确定",
  cancel: "取消",
  header: {
    search: '搜索',
    personal: '个人中心',
    account: '账户设置',
    switchAccount: '切换账户',
    exit: '退出登录'
  },
  footer: {
    about: '关于',
    terms: '条款',
    privacy: '隐私',
    language: '语言',
    commertialTransactionsLaw: "商事法",
  },
  mdialog: {
    replay: '回复',
    cancel: '取消',
    saySomething: '说点什么吧…',
  },
  editAvatar: {
    addPicture: '点击上传图片',
    preview: '头像预览',
    changeProfile: '修改头像',
    typeRule: "图片类型必须是.gif,jpeg,jpg,png,bmp中的一种",
    maxTitle:'最多上传100张图片'
  },
  editModal: {
    // input默认提示
    usernamePlaceholder: '昵称',
    phonePlaceholder: '手机号',
    userIDPlaceholder: '用户ID（用户ID只能修改一次）',
    genderPlaceholder: '性别',
    male: '男',
    female: '女',
    birthdayPlaceholder: '出生日期',
    emailAddressPlaceholder: '邮箱',
    currentPasswordPlaceholder: '当前密码',
    newPasswordPlaceholder: '新密码',
    confirmPlaceholder: '确认密码',
    // 弹窗标题
    nameTitle: "编辑昵称",
    phoneTitle: "手机",
    userIdTitle: "用户ID",
    sexTitle: "性别",
    dateTitle: "出生日期",
    emailTitle: "邮箱",
    passwordTitle: "修改密码",
    // 校验提示
    nameRule: "输入昵称",
    phoneRule: "输入手机号",
    userIdRule: "输入用户ID",
    sexRule: "输入姓别",
    dateRule: "输入日期",
    emailRule: "输入邮箱",
    emailTypeRule: "输入正确的邮箱格式",
    passwordRule: "输入密码",
    // Error提示语
    passwordNullError: "输入密码",
    newPasswordNullError: "确认新密码",
    passwordDifferentError: "两次输入的密码不一样",

  },
  setting: {
    profile: '头像',
    username: '昵称',
    phone: '手机',
    userID: '用户ID',
    gender: '性别',
    birthday: '出生日期',
    emailAddress: '邮箱',
    resetPassword: '修改密码',
    edit: '编辑',
    male: '男',
    female: '女',
    custom: '保密',
  },
  create: {
    postTo: '选择目标',
    postToAfterSelect: '发布于',
    chooseTargetToLetMorePeopleKnow: '选择目标，关注度会更高哦！',
    search: '搜索',
    history: '最近参与',
    historyAll: '展开全部记录',
    relatedSearches: '猜你想发',
    withInWordsBefore: '',
    withInWordsAfter: '字以内',
    addPictures: '添加图片',
    customShootingTime: '自定义拍摄时间',
    selectTime: '选择时间',
    post: '发布',
    title: '标题',
    titleStyle: '标题样式',
    setTitle: '设置标题',
    description: '分享镜头背后的故事',
    success: '上传成功',
    viewPost: '查看内容',
    postNext: '继续上传',
    createEntry: '创建条目',
    prompt: '选择目标处不能为空',
    titlePlaceholder:'请选择标题！',
    checkPicture:'不能发布空的图文！',
    createPlaceholder: '请输入标题内容',
    tip: '提炼标题关键字，有助于推荐'
  },
  detail: {
    follow: '关注',
    followed:'已关注',
    all: '全部',
    impression: '印象',
    queryResults: '查询结果',
    date: '时间',
    other: '其他',
    clear: '清除条件',
    selectDate: '选择月份',
    filter: '筛选条件',
    suggest: '建议',
    question: '提问',
    comment: '评论',
    hot: '按热度',
    time: '按时间',
    saySomething: '说点什么吧。',
    replay: '回复',
    totalReplyBefore: '共',
    totalReplyAfter: '条回复',
    replaySuccess: '回复成功',
    commentSuccess: '评论成功',
    collectionSuccess: '收藏成功',
    cancelCollectionSuccess: '取消收藏成功',
    likeSuccess: '点赞成功',
    cancelLikeSuccess: '取消点赞成功',
    // 时间
    justNow: '刚刚',
    title: '我来写标题',
    confirm:'写一个新的标题吗？',
    confirmNone:'当前未编辑标题，是否退出？',
    addTitle: '新增',
    report: '举报',
    notShowAgainLeft: '不再显示 用户(',
    notShowAgainRight: ')内容',
    oneClickCopy: '一键复制',
    entryDependent: '相关条目',
    searchDependent: '相关搜索',
    noSearchDependent:'暂无相关搜索...',
    nocontent:'不能评论为空！'
  },
  entry: {
    build: '始建于：',
    location: '地   点：',
    seatMark: '坐   标：',
    height: '高   度：',
    area: '占地面积：',
    numberOfPlies: '层    数：',
    numberOfElevators: '电梯数量：',
    seeMore:'查看更多 >',
    pictureDetails:'图片详情',
    days:'天数 /',
    NNT:'人数 /',
    spend:'人均 /',
    day:'天',
    person:'人',
    money:'元'
  },
  index: {
    entry:"条目",
    story: "故事",
    journey:"行程",
    search: '搜索',
    overview: '概要',
    // tab
    recommend: '推荐',
    hot: '热点',
    follow: '故事',
    collectionSuccess: '收藏成功',
    cancelCollectionSuccess: '取消收藏成功',
    likeSuccess: '点赞成功',
    cancelLikeSuccess: '取消点赞成功',
    followSuccess: '关注成功',
    cancelFollowSSuccess: '取消关注成功',
    copySucceed: '复制成功',
    copyFailed: '复制失败',
    button: 'Look 检索',
  },
  login: {
    loginFailed: '用户名或密码错误',
    login: '登录',
    verificationCodeLogin: '验证码',
    passwordLogin: '密码',
    // phone: '输入手机号或邮箱',
    phone: '输入邮箱',
    code: '验证码',
    getVerificationCode: '获取验证码',
    // 服务条款和隐私政策
    agree: '已阅读并同意',
    OurTermsOfService: '服务条款',
    and: '和',
    privacyPolicy: '隐私政策',
    privacyPolicyAfter: '',
    phoneAndEmail: '已有账号登录',
    password: '密码',
    // input 提示语
    phoneAndEmailRule: '输入账号',
    codeRule: '输入验证码',
    passwordRule: '输入密码',
    registerUsernameRule: "ID已存在,换一个ID",
    UsernameNullRule: "id不能为空",
    okRule: "id可以使用",
    // 登录后
    success: '成功',
    fail: '失败',
    // 注册
    seconPass: '确认密码',
    username: '用户名',
    register: '注册',
    // 校验提示
    registerName: '请输入6-12位由数字字母或下划线组成的id',
    passwordTip: '请输入8-20位由数字字母或特殊符号组成的密码'
  },
  searchList: {
    about: "为您找到相关结果共 ",
    results: " 条",
    relatedSearches: "猜你想搜",
    // tab
    all: "全部",
    picture: "图片",
    video: "视频",
    text: "文本",
    news: "新闻",
  },
  myInfo: {
    create: "创建",
    favorite: "收藏",
    follow: "关注",
    history: "历史",
  },
  time: {
    justNow: "刚刚",
    minutesAgo: "分钟前",
    hourAgo: "小时前",
    hoursAgo: "小时前",
    dayAgo: "天前",
    daysAgo: "天前",
    monthAgo: "月前",
    monthsAgo: "月前",
    yearAgo: "年前",
    yearsAgo: "年前",
  },
  reportDialog: {
    reportingProblems: '举报问题',
    repeat: '重复',
    antipathy: '反感',
    poorContentQuality: '内容质量差',
    placeholder:'请选择举报问题',
    reportSuccessful:'举报成功！'
  },
  titleDialog: {
    pictureTitle: '图片标题',

  },
  comment: {
    packUp: '收起评论',
    more: '更多'
  },
  deletePicture: {
    deletePicture: '删除图片',
    deleteConfirm: '确定删除',
    cancel: '取消',
    delete: '删除',
    deleteSuccess: '删除成功',
    noPermission: '暂无权限',
  }
}
