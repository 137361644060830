import request, { get, post, CONTENT_TYPE } from '../utils/request';
import qs from 'qs'

const Api = {
    //账号密码注册
    registerUser: (params) => {
        return post('/registerUser', params, CONTENT_TYPE.jsonHeader)
    },
    //账号密码登陆
    login: (params) => {
        // return post(`/login`,params)
        return request({
            url: '/login',
            method: 'post',
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
            data: qs.stringify(params)
        })
    },
    // 获取邮箱验证码
    sendEmail: (email) => {
        return post('api/v3/sendEmail', { email })
    },
    loginByEmail: (data) => {
        // return post(`/login`,params)
        return request({
            url: 'api/v3/login/email',
            method: 'post',
            headers: { 'Content-Type': 'application/json' },
            data
        })
    },
    // 验证码登陆
    loginByPhone: (params) => {
        // return post(`/login`,params)
        return request({
            url: '/v3/login/phone',
            method: 'post',
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
            data: qs.stringify(params)
        })
    },
    // 第三方注册用户
    registerUserByOther: (params) => {
        // return post(`/login`,params)
        return request({
            url: '/api/v3/login',
            method: 'post',
            headers: { 'Content-Type': 'application/json' },
            data: params
        })
    },
    // 获取本人信息
    getMe: () => {
        return get('/user/me');
    },
    // 第三方注册用户
    wechatAuthority: (params) => {
        return get('/auth/wechat', params);
    },


    //收藏接口
    postFavorite: (params) => {
        return post(`/modify/favorite`, params, CONTENT_TYPE.urlencodedHeader)
    },
    //取消收藏接口
    postCancelFavorite: (params) => {
        return post(`/modify/cancelFavorite`, params, CONTENT_TYPE.urlencodedHeader)
    },
    //关注接口
    postNote: (params) => {
        return post(`/modify/note`, params, CONTENT_TYPE.urlencodedHeader)
    },
    //取消关注接口
    postCancelNote: (params) => {
        return post(`/modify/cancelNote`, params, CONTENT_TYPE.urlencodedHeader)
    },
    //点赞/取消 点赞接口  //用  图文
    postpictureLike: (params) => {
        return post(`/modify/picture/like`, params, CONTENT_TYPE.urlencodedHeader)
    },
    //点赞/取消 评论点赞接口 //用
    commentLike: (params) => {
        return post(`/modify/comment/like`, params, CONTENT_TYPE.urlencodedHeader)
    },
    //点赞/取消回复点赞接口 // 用
    replyLike: (params) => {
        return post(`/modify/reply/like`, params, CONTENT_TYPE.urlencodedHeader)
    },
    //点赞/取消回复点赞接口 //未
    addReplyLike: (params) => {
        return post(`/modify/addReplyLike`, params, CONTENT_TYPE.urlencodedHeader)
    },
    //追加评论接口
    addComment: (params) => {
        return post(`/modify/addComment`, params, CONTENT_TYPE.urlencodedHeader)
    },
    //追加回复接口
    addReply: (params) => {
        return post(`/modify/addReply`, params, CONTENT_TYPE.urlencodedHeader)
    },
    //实时搜索条目接口
    getSearch: (params) => {
        return get('/search/v2/search', params)
    },
    //获取推荐条目
    getRecommend: (tagId) => {
        return get('/search/v2/recommend' + (tagId ? ('?tagId=' + tagId) : ''))
    },
    // 相关条目
    getRelatedArticles() {
        return get('/getRelatedArticles')
    },
    // @ApiOperation(value= "条目详情下获取相关搜索" , notes = "参数：{title ： 条目的标题}")
    getOperation(params) {
        // return get("/search/v2/search/entry",params);
        return get('/search/v2/getRelevantSearches', params);
    },
    // 分页获取条目列表
    searchEntries: (keyword, tagId, page = 1, pageSize = 10) => {
        let params = {
            page: page,
            pageSize: pageSize
        };
        if (keyword) {
            params.keyword = keyword;
        }
        if (tagId) {
            params.tagId = tagId;
        }
        return get('/search/v2/searchEntries', params)
    },

    // 分页获取图文列表
    searchPictures: (tagId, keyword, page = 1, pageSize = 10) => {
        let params = {
            page: page,
            pageSize: pageSize
        };
        if (keyword) {
            params.keyword = keyword;
        }
        if (tagId) {
            params.tagId = tagId;
        }
        return get('/search/v2/searchPictures', params)
    },
    // 分页获取图文 
    getPicturecontent: (myType, page = 1, pageSize = 10) => {
        let params = {
            page: page,
            pageSize: pageSize
        };
        if (myType) {
            params.myType = myType;
        }
        return get('/search/v2/picture/findPage', params)
    },
    // 分页获取图文标题列表
    getImageTitleInfos: (model) => {
        const params = {
            imageId: model.imageId,
            pageSize: model.pageSize,
            userId: model.userId,
            page: model.page,
            selectType: 'FIREST'
        }
        return post(`/search/getImageTitleInfos`, params, CONTENT_TYPE.urlencodedHeader)
    },
    // 追加标题点赞接口  标题点赞
    addTitleLike: (titleId, imageId, userId) => {
        const params = {
            titleId: titleId,
            imageId: imageId,
            userId: userId
        }
        return post(`/modify/title/like`, params, CONTENT_TYPE.urlencodedHeader)
    },
    // 添加图文标题
    addTitle: (title, imageId, userId) => {
        const params = {
            title: title,
            imageId: imageId,
            userId: userId
        }
        return post(`/modify/addTitle`, params, CONTENT_TYPE.urlencodedHeader)
    },
    //添加图文标题 （带图片版）
    addTitlePicture: (model) => {
        var formData = new FormData();
        if (model.title) {
            formData.append('title', model.title)
        }
        if (model.pictureId) {
            formData.append('pictureId', model.pictureId)
        }
        if (model.file) {
            formData.append('file', model.file)
        }
        return post('/addTitlePicture', formData, CONTENT_TYPE.formHeader)
    },
    // 分页获取我的关注
    getfollow: (myType, page = 1, pageSize = 10) => {
        let params = {
            page: page,
            pageSize: pageSize
        };
        if (myType) {
            params.myType = myType;
        }
        return get('/search/v2/findPage', params)
    },
    // 分页获取条目列表
    getEntryPage: (myType, tagId, page = 1, pageSize = 10) => {
        let params = {
            page: page,
            pageSize: pageSize
        };
        if (myType) {
            params.myType = myType;
        }
        if (tagId) {
            params.tagId = tagId;
        }
        return get('/search/v2/findPage', params)
    },
    // 根据 PictureId 获取图文接口
    getPicture: (pictureId) => {
        return get('/search/v2/picture/' + pictureId)
    },
    // 分页获取图文列表
    getPicturePage: (entryId, myType, tagId, date, page = 1, pageSize = 10) => {
        let params = {
            page: page,
            pageSize: pageSize
        };
        if (myType) {
            params.myType = myType;
        }
        if (entryId) {
            params.entryId = entryId;
        }
        if (tagId) {
            params.tagId = tagId;
        }
        if (date) {
            params.date = date;
        }
        return get('/search/v2/picture/findPage', params)
    },
    // 分页获取图文评论列表
    getCommentPage: ({ pictureId, type = undefined, isHead = 0, page = 1, pageSize = 10 }) => {
        let params = {
            page: page,
            pageSize: pageSize
        };
        if (pictureId) {
            params.pictureId = pictureId;
        }
        if (type != undefined) {
            params.type = type;
            // type:0，意见  1，问题  2，评论
        }
        //按热度
        if (isHead == 1) {
            params.sortName = "a.likes";
            // 点赞数
            params.sortOrder = "desc";
            // 倒序
        }
        return get('/search/v2/comment/findPage', params)
    },
    //初始获取条目下图文数据
    getAllImageInfos: (params) => {
        return post(`/search/getAllImageInfos`, params)   //新增
    },
    /**
     * 保存浏览记录
     * @param entryId
     * @param entryTitle
     * @param pictureId
     * @returns {Promise<AxiosResponse<any>>}
     */
    saveHistory: (entryId, entryTitle, pictureId) => {
        const params = {
            entryId: entryId,
            entryTitle: entryTitle,
            pictureId: pictureId
        }
        return post(`/modify/user/history`, params, CONTENT_TYPE.jsonHeader)
    },
    //获取更多条目下图文数据接口
    getMoreAllImageInfos: (params) => {
        return post(`/search/getMoreAllImageInfos`, params)
    },
    //？
    getTagByRecommend: (recommendType) => {
        return get('/search/v2/tag/recommend?recommendType=' + recommendType);
    },
    //获取用户个人关注、收藏等
    getLocationUser: () => {
        return get('/search/v2/user/relevant');
    },
    //猜你喜欢
    findHotSearch: () => {
        return get('/search/v2/hot/search');
    },
    sendSms: (phone) => {
        const params = {
            phoneNumber: phone.indexOf("+81") > -1 ? phone : ("+81" + phone)
        }
        return post('/sendSMS', params, CONTENT_TYPE.urlencodedHeader);
    },

    uploadImg: (data) => {
        var formData = new FormData();
        if (data.treeId) {
            formData.append('treeId', data.treeId);
        }
        if (data.treeName) {
            formData.append('treeName', data.treeName);
        }
        formData.append('imgTime', data.imgTime);
        if (data.imgNote) {
            formData.append('imgNote', data.imgNote);
        }
        if (data.imgComment) {
            formData.append('imgComment', data.imgComment);
        }
        if (data.files && data.files.length > 0) {
            for (const file of data.files) {
                formData.append('files', file);
            }
        }
        if (data.titles && data.titles.length > 0) {
            for (const text of data.titles) {
                formData.append('titles', text)
            }
        }
        return post('/v2/uploadimgmult', formData, CONTENT_TYPE.formHeader);
    },
    uploadImg2: (data) => {
        var formData = new FormData();
        if (data.treeId) {
            formData.append('treeId', data.treeId);
        }
        if (data.treeName) {
            formData.append('treeName', data.treeName);
        }
        formData.append('imgTime', data.imgTime);
        if (data.imgNote) {
            formData.append('imgNote', data.imgNote);
        }
        if (data.imgComment) {
            formData.append('imgComment', data.imgComment);
        }
        if (data.pictureInfos && data.pictureInfos.length > 0) {
            for (let i = 0; i < data.pictureInfos.length; i++) {
                const pictureInfo = data.pictureInfos[i];
                formData.append(`pictureInfos`, pictureInfo[0]);
                formData.append(`pictureInfos`, pictureInfo[1]);
                formData.append(`pictureInfos`, pictureInfo[2]);
            }
        }
        return post('/uploadMultiparFile', formData, CONTENT_TYPE.formHeader);
    },
    //目前不需要印象
    // 获取条目印象图文接口
    // getImpression: (params) => {
    //     return get('/search/v2/picture/impression', params)
    // },
    // 获取条目详情
    getEntryInfo: (params) => {
        return get('/search/v2/findEntryInfo', params)
    },
    // 保存浏览历史
    modifyUserSearchHistory: (entryId, keyword) => {
        const params = {
            entryId: entryId,
            keyword: keyword,
        }
        return post(`/modify/user/search/history`, params, CONTENT_TYPE.jsonHeader)
    },
    // 举报
    reportImage: (treeId, imageId, status, userId) => {
        const params = {
            treeId: treeId,
            imageId: imageId,
            status: status,
            userId: userId
        }
        return post(`/modify/reportImage`, params, CONTENT_TYPE.urlencodedHeader)
    },
    // 不在显示本用户内容接口
    blockUser: (blockUserId, userId) => {
        const params = {
            blockUserId: blockUserId,
            userId: userId
        }
        return post(`/modify/blockUser`, params, CONTENT_TYPE.urlencodedHeader)
    },
    //创建条目接口
    createAddEntry: (params) => {
        return post(`/modify/addEntry`, params, CONTENT_TYPE.urlencodedHeader)   //新增
    },
    // 检查用户名是否重复
    checkUsername: (userid) => {
        return get('/check/' + userid)
    },
    // 删除图文
    deleteImage: (treeId, imageId) => {
        const params = {
            treeId: treeId,
            imageId: imageId
        }
        return post('/modify/deleteImage', params, CONTENT_TYPE.urlencodedHeader)
    },
    // 删除用户
    deleteUser: (params) => {
        return post('/deleteUser/' + params)
    }
}

export default Api;
