<template>
    <div ref="boxRef" class="color-box">
        <div class="color" v-for="(item, i) in colorList" :key="i"
            :style="{ backgroundColor: item, border: `1px solid #ccc` }" @click.stop="colorChooseHandler(item)"></div>
    </div>
</template>
<script>
export default {
    props: {
        color: {
            type: String,
            required: true
        },
        visible: {
            type: Boolean,
            required: true
        }

    },
    data() {
        return {
            colorList: [
                "rgb(0, 0, 0)",
                "rgb(38, 38, 38)",
                "rgb(89, 89, 89)",
                "rgb(140, 140, 140)",
                "rgb(191, 191, 191)",
                "rgb(217, 217, 217)",
                "rgb(233, 233, 233)",
                "rgb(245, 245, 245)",
                "rgb(250, 250, 250)",
                "rgb(255, 255, 255)",
                "rgb(225, 60, 57)",
                "rgb(231, 95, 51)",
                "rgb(235, 144, 58)",
                "rgb(245, 219, 77)",
                "rgb(114, 192, 64)",
                "rgb(89, 191, 192)",
                "rgb(66, 144, 247)",
                "rgb(54, 88, 226)",
                "rgb(106, 57, 201)",
                "rgb(216, 68, 147)",
                "rgb(251, 233, 230)",
                "rgb(252, 237, 225)",
                "rgb(252, 239, 212)",
                "rgb(252, 251, 207)",
                "rgb(231, 246, 213)",
                "rgb(218, 244, 240)",
                "rgb(217, 237, 250)",
                "rgb(224, 232, 250)",
                "rgb(237, 225, 248)",
                "rgb(255, 77, 79)",
                "rgb(255, 122, 69)",
                "rgb(255, 169, 64)",
                "rgb(255, 236, 61)",
                "rgb(115, 209, 61)",
                "rgb(54, 207, 201)",
                "rgb(64, 169, 255)",
                "rgb(89, 126, 247)",
                "rgb(146, 84, 222)",
                "rgb(247, 89, 171)",
                "rgb(207, 19, 34)",
                "rgb(212, 56, 13)",
                "rgb(212, 107, 8)",
                "rgb(212, 177, 6)",
                "rgb(56, 158, 13)",
                "rgb(8, 151, 156)",
                "rgb(9, 109, 217)",
                "rgb(29, 57, 196)",
                "rgb(83, 29, 171)",
                "rgb(196, 29, 127)",
                "rgb(130, 0, 20)",
                "rgb(135, 20, 0)",
                "rgb(135, 56, 0)",
                "rgb(97, 71, 0)",
                "rgb(19, 82, 0)",
                "rgb(0, 71, 79)",
                "rgb(0, 58, 140)",
                "rgb(6, 17, 120)",
                "rgb(34, 7, 94)",
                "rgb(120, 6, 80)"
            ]
        }
    },
    methods: {
        colorChooseHandler(color) {
            //如果没在编辑 结束，否则变成没在编辑
            //这两行代码的意义在于节流，避免内存拥挤
            if (!this.$props.visible) return
            else this.$emit('update:visible', false)
            const boxRef = this.$refs.boxRef;
            this.$emit('update:color', color)
            boxRef.style.opacity = 0
            setTimeout(() => {
                boxRef.style.display = 'none'
            }, 500)
        }
    }
}
</script>
<style lang="scss" scoped>
.color-box {
    width: 252px !important;
    height: 142px !important;
    background: #fff;
    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.16);
    border-radius: 8px;
    position: absolute;
    top: 43px;
    left: 0;
    box-sizing: border-box;
    padding: 10px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    transition: 0.3s;
    display: none;
    opacity: 0;

    .color {
        flex-shrink: 0;
        width: 13px;
        height: 13px;
        border-radius: 13px;
        background-color: red;
        margin: 3px;
        cursor: pointer;
    }
}
</style>