<template>
    <div class="image-box">
        <div class="left">
            <div class="left-top">
                <a-carousel arrows class="image-car" :dots="false">
                    <template #prevArrow>
                        <div class="custom-slick-arrow" style="left: 60px">
                            <LeftOutlined />
                        </div>
                    </template>
                    <template #nextArrow>
                        <div class="custom-slick-arrow" style="right: 60px">
                            <RightOutlined />
                        </div>
                    </template>
                    <div v-for="item in list" :key="item.id" style="height:804px;margin: 0;">
                        <img :src="item.src" alt="">
                        <!-- <img :src="list[0].src" alt="" autoplay> -->
                    </div>
                </a-carousel>
                <div class="close-icon">
                    <CloseOutlined />
                </div>
                <div class="zoom-in-box">
                    <span>
                        <svg t="1688520257550" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="3311" width="200" height="200">
                            <path d="M919.264 905.984l-138.912-138.912C851.808 692.32 896 591.328 896 480c0-229.376-186.624-416-416-416S64 250.624 64 480s186.624 416 416 416c95.008 0 182.432-32.384 252.544-86.208l141.44 141.44a31.904 31.904 0 0 0 45.248 0 32 32 0 0 0 0.032-45.248zM128 480C128 285.92 285.92 128 480 128s352 157.92 352 352-157.92 352-352 352S128 674.08 128 480z" p-id="3312" fill="#ffffff"></path>
                            <path d="M625.792 448H336a32 32 0 0 0 0 64h289.792a32 32 0 1 0 0-64z" p-id="3313" fill="#ffffff"></path>
                        </svg>
                    </span>
                    <span>
                        <svg t="1688520241242" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="2193" width="200" height="200">
                            <path d="M919.264 905.984l-138.912-138.912C851.808 692.32 896 591.328 896 480c0-229.376-186.624-416-416-416S64 250.624 64 480s186.624 416 416 416c95.008 0 182.432-32.384 252.544-86.208l141.44 141.44a31.904 31.904 0 0 0 45.248 0 32 32 0 0 0 0.032-45.248zM128 480C128 285.92 285.92 128 480 128s352 157.92 352 352-157.92 352-352 352S128 674.08 128 480z" p-id="2194" fill="#ffffff"></path>
                            <path d="M625.792 448H512v-112a32 32 0 0 0-64 0V448h-112a32 32 0 0 0 0 64H448v112a32 32 0 1 0 64 0V512h113.792a32 32 0 1 0 0-64z" p-id="2195" fill="#ffffff"></path>
                        </svg>
                    </span>
                </div>
                <div class="">

                </div>
            </div>
            <div class="left-bottom">

            </div>

        </div>
        <div class="right">

        </div>
    </div>
</template>
<script>
import { LeftOutlined, RightOutlined, CloseOutlined } from '@ant-design/icons-vue';
export default {
    props: {
        imagePreviewVisible: {
            type: Boolean,
            required: true
        }
    },
    components: {
        // 组件库
        LeftOutlined,
        RightOutlined,
        CloseOutlined
    },
    data() {
        return {
            list: [
                { id: 'ajdklgshalkgjha', src: 'https://picx.zhimg.com/70/v2-76c9d2d1ce879d54cb3f7a6a0876b4fc_1440w.avis?source=172ae18b&biz_tag=Post' },
                { id: 'askgjhalkgd', src: 'https://pic2.zhimg.com/80/v2-bf60fe989b69f04413dc7b9f63467979_720w.webp' },
                { id: 'dsaghsdh', src: 'https://pic1.zhimg.com/80/v2-76c9d2d1ce879d54cb3f7a6a0876b4fc_720w.webp' },
                { id: 'hfaerfharhgbsg', src: 'https://pic3.zhimg.com/80/v2-e623423dba032fc6224a4092bf8de016_720w.webp' },
                { id: 'sdh4ERYHGFHQAEG', src: 'https://pic4.zhimg.com/80/v2-03e204371657a5d1dd0c1d83853d79f3_720w.webp' },

            ]
        }
    }
}
</script>
<style lang="scss" scoped>
/* For demo */
.ant-carousel :deep(.slick-slide) {
    text-align: center;
    background: #364d79;
    overflow: hidden;
    height: 804px;
}

.ant-carousel :deep(.slick-arrow.custom-slick-arrow) {
    margin: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 60px;
    height: 60px;
    border-radius: 60px;
    font-size: 25px;
    color: #fff;
    background-color: rgba(102, 102, 102, 0.3);
    z-index: 1;
    display: flex !important;
    justify-content: center;
    align-items: center;
}

.ant-carousel :deep(.custom-slick-arrow:before) {
    display: none;
}

.ant-carousel :deep(.custom-slick-arrow:hover) {
    background-color: rgba(102, 102, 102, 0.6);

}

.ant-carousel :deep(.slick-slide h3) {
    color: #fff;
}

.image-box {
    background-color: #000;
    padding: 22px 20px;
    display: flex;
    justify-content: space-between;


    .left {
        flex-shrink: 0;
        background-color: pink;

        .left-top {
            position: relative;
            background-color: yellow;

            .image-car {
                position: relative;
                background-color: #364d79;
                width: 1420px !important;
                height: 804px;

                img {
                    vertical-align: bottom;
                    width: 1420px !important;
                    height: 804px;
                }
            }

            .close-icon {
                position: absolute;
                top: 30px;
                left: 30px;
                z-index: 1;
                width: 48px;
                height: 48px;
                border-radius: 48px;
                background-color: rgba(102, 102, 102, 0.3);
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 14px;
                color: #fff;
            }

            .close-icon:hover {
                background-color: rgba(102, 102, 102, 0.6);
            }

            .zoom-in-box {
                position: absolute;
                bottom: 20px;
                left: 50%;
                transform: translateX(-50%);
                width: 123px;
                height: 40px;
                border-radius: 8px;
                // background-color: rgba(102, 102, 102, 0.6);
                background-color: rgba(216, 221, 229, 0.40);
                display: flex;
                justify-content: space-evenly;
                align-items: center;

                span {
                    display: flex;
                    align-items: center;
                    color: #fff;

                    svg {
                        width: 20px;
                        height: 20px;
                    }
                }

            }

        }


    }

    .right {
        flex-shrink: 0;
        width: 440px;
        background-color: #364d79;
    }
}
</style>