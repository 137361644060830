<!-- eslint-disable vue/no-v-model-argument -->
<!-- eslint-disable vue/no-multiple-template-root -->
<template>
  <TitleDialog ref="TitleDialog" width=480px :bodyStyle="{ overflowY: 'auto', background: '#f9f9f9', border: '10px' }" centered cancelText @loginVisible="visibleTitleList = false, visible = true" :visible="visibleTitleList" @cancel="visibleTitleList = false" @refreshTitle="refreshTitle" :footer="null" style="border-radius: 10px;">
  </TitleDialog>
  <!-- :tabs="headTags" -->
  <Header :title="entryName" :tabs="headTags" :showSearch="!showCommentHeader" :entryId="entryId" :entryName="entryName" style=" position: absolute; " :selectTab="navTab" :value="search_value" @keydownEnter="keydownEnter" @changeSearchEvent="changeSearchEvent" @changeTab="changeNavTab" />
  <div class="detail-box" @scroll="scrollEvent">
    <div class="container">
      <!--  -->
      <div class="title">
        <img src="../assets/icon-fire.png" />
        <!-- <p>{{ datas[0]?.note || datas[0]?.entryTitle }}</p> -->
        <p>{{ datas[0]?.titlePicture?.title ? datas[0]?.titlePicture.title : datas[0]?.entryTitle }}</p>
      </div>
      <Loading />
      <div class='basic-info' v-if="$route.params.position === 's'">
        <div class="basic-info-title">
          <div></div>
          <span>{{ this.$t('basicInformation') }}</span>
        </div>
        <!-- 基本信息-条目 -->
        <div class="basic-info-content">
          <ul class="basic-info-list">
            <li>
              <span>{{ this.$t('entry.build') }}</span><span>暂无数据</span>
            </li>
            <li>
              <span>{{ this.$t('entry.location') }}</span><span>暂无数据</span>
            </li>
            <li>
              <span>{{ this.$t('entry.seatMark') }}</span><span>暂无数据</span>
            </li>
            <li>
              <span>{{ this.$t('entry.height') }}</span><span>暂无数据</span>
            </li>
            <li>
              <span>{{ this.$t('entry.area') }}</span><span>暂无数据</span>
            </li>
            <li>
              <span>{{ this.$t('entry.numberOfPlies') }}</span><span>暂无数据</span>
            </li>
            <li>
              <span>{{ this.$t('entry.numberOfElevators') }}</span><span>暂无数据</span>
            </li>
          </ul>
          <div class="basic-info-img">
            <img :src="datas[0]?.pictureUrl" alt="">
            <div class="basic-info-image-box"></div>
          </div>
        </div>
        <a-popover placement="bottomLeft">
          <template #content>
            <div class="searchTitle">{{ $t('searchTerm') }}</div>
            <img class="searchImg" src="../assets/guge.png" @click="goGuge(entryName)" height="21.5" width="26" />
            <img class="searchImg" src="../assets/weiji.png" @click="goWeiji(entryName)" height="26" width="26" />
          </template>
          <!-- <span class="item-info-name" v-show="item.isShow" @click="goDetail">{{ item.title }}</span> -->
          <p class="see-more">{{ this.$t('entry.seeMore') }}</p>
        </a-popover>

      </div>
      <div class="tab-center">
        <div class="tab-item active">{{ this.$t('index.recommend') }}</div>
        <div class="tab-item">{{ this.$t('index.hot') }}</div>
        <div class="tab-item">{{ this.$t('index.follow') }}</div>
      </div>
      <!-- <ul class="category-box">
        <li class="active">111</li>
        <li>222</li>
        <li>333</li>
      </ul> -->
      <div class="story-box" v-show="false">
        <!-- 故事信息 -->
        <div class="story-box-top">
          <img src="../assets/story.png" alt="">
          <p>{{ this.$t('index.story') }}</p>
        </div>
        <div class="story-box-content">
          <ul>
            <li>
              <!-- <img src="" alt=""> -->
              <div style="background-color: aqua;"></div>
              <p>
                <span>1</span>
                第一条故事
              </p>
            </li>
            <!-- 静态 -->
            <li>
              <!-- <img src="" alt=""> -->
              <div style="background-color: aqua;"></div>
              <p>
                <span>2</span>
                第二条故事
              </p>
            </li>
            <li>
              <!-- <img src="" alt=""> -->
              <div style="background-color: aqua;"></div>
              <p>
                <span>3</span>
                第三条故事
              </p>
            </li>
          </ul>
          <img src="../assets/add-story.png" alt="">
        </div>
      </div>

      <List :involveSeacrchList="involveSeacrchList" :nowIndex="ListIndex" :datas="datas"></List>
    </div>
  </div>
  <Footer />
  <Login :visible="visible" :closeModal="closeModal" />
  <!-- <div
    style="position: fixed; width:200px;height:200px;top: 100px;left: 100px;background-color: antiquewhite;font-size: 32px;">
    {{ ListIndex }}</div> -->
</template>
<script>
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import TitleDialog from '@/components/TitleDialog.vue'
import Api from "../Api";
import Loading from '@/components/Loading.vue';
import List from '@/components/List/index.vue'
import Login from './Login.vue'
import eventBus from '@/utils/eventBus.js';


export default {
  name: 'Index',
  components: {
    Header,
    Footer,
    TitleDialog,
    Loading,
    List,
    Login
  },
  computed: {
    tabs() {
      return [
        { tagName: this.$t('detail.suggest'), tagId: 0 },
        { tagName: this.$t('detail.question'), tagId: 1 },
        { tagName: this.$t('detail.comment'), tagId: 2 }]
    }
  },
  data() {
    return {
      // 图片的userId
      insertUserId: '',
      // 删除按钮是否显示
      isShow: false,
      // 显示更多
      pullDown: true,
      // 箭头按钮参数
      upScrollTop: 0,
      shows: true,
      showsSecond: true,
      upWindowHeight: 0,
      upScrollHeight: 0,
      showIndex: 0,
      // // tab1
      firstShows: true,
      firstScrollHeight: 0,
      firstWindowHeight: 0,
      firstScrollTop: 0,
      listLength: 0,
      // tab3
      filterShows: true,
      filterScrollHeight: 0,
      filterWindowHeight: 0,
      filterScrollTop: 0,

      selectSort: false,
      reportVisible: false,
      deleteDialog: false,
      visibleSecond: false,
      imgList: [],
      imgTabNum: 1,
      visibleTitleList: false,
      commentTitle: '',
      detailInfoVisible: true,
      isShowDialog: false,
      visible: false,
      showCommentHeader: false,
      search_value: '',
      selectTab: 0,
      activeTab: 1,
      showFilter: false,
      filterStyle: {
        right: 0,
        top: 0,
      },

      loading: false,
      commentLoading: false,
      commentContext: '',
      replyInfo: {
        commentIndex: undefined,
        replyContent: undefined,
        commentId: undefined,
        replyUserId: undefined,
      },

      entryId: this.$route.params.id,
      pictureId: this.$route.query.pictureId,
      initPictureId: this.$route.params.pictureId,
      entryName: undefined,
      datas: [],
      page: 1,
      totalPage: 1,
      pageSize: 20,
      currPicture: {},

      isCommentMap: {},

      isHeat: 0,
      commentCondition: {
        type: 0,
        pictureId: undefined,
      },
      initPage: {
        page: 1,
        totalPage: 1,
        pageSize: 20,
        list: []
      },
      infoPage: [
        {
          page: 1,
          totalPage: 1,
          pageSize: 20,
          list: []
        },
        {
          page: 1,
          totalPage: 1,
          pageSize: 20,
          list: []
        },
        {
          page: 1,
          totalPage: 1,
          pageSize: 20,
          list: []
        },
      ],

      list: [],
      ListIndex: 0,

      rightFilterTabShow: false,

      filterTags: [],
      filterDate: '',
      filterSelect: '',
      filter: {
        filterDate: '',
        filterSelect: '',
        page: 1,
        totalPage: 1,
        pageSize: 20,
        list: []
      },
      impression: {
        page: 1,
        totalPage: 1,
        pageSize: 20,
        list: []
      },
      total: {
        suggestionCnt: 0,
        questionCnt: 0,
        commentCnt: 0
      },
      isReply: false,

      rightAllLoading: false,
      rightTabLoading: false,

      showEmoji: true,
      pictureLoading: false,
      isActive: false,
      navTab: 'recommend',
      openComments: false,
      involveSeacrchList: []
    }
  },
  created() {
    eventBus.on('updateLogin', (visible) => {
      this.visible = visible;
    })
    eventBus.on('updateList', async (params) => {
      try {
        console.log('我是列表，我重生了')
        console.log('更新列表接收到的值===》', params)
        const url = window.URL.createObjectURL(params.file);
        this.datas.forEach(item => {
          if (item.pictureId === params.pictureId) item.titlePicture = { ...item, pictureAddress: url }
        })
      } catch (e) {
        console.log(e)
      }

    })
  },
  async mounted() {
    this.getData()
  },
  // watch: {
  //   ListIndex: {
  //     async handler() {
  //       try {
  //         const { result: { data: involveSeacrchList } } = await Api.getOperation({ id: this.entryList[this.ListIndex].id })
  //         console.log('相关搜索列表===>', involveSeacrchList)
  //         this.involveSeacrchList = involveSeacrchList;
  //       } catch (e) {
  //         console.log(e)
  //       }
  //     },
  //   }
  // },
  methods: {
    async updateList() {
      try {
        await this.getData()
      } catch (e) {
        console.log(e)
      }
    },
    async getData() {
      try {
        if (!localStorage.getItem('language')) {
          localStorage.setItem('language', 'ja')
        }
        this.$i18n.locale = localStorage.getItem('language')
        if (this.initPictureId != null) {
          this.pictureId = this.initPictureId;
        }
        await this.init()
        console.log('init完了')
        if (this.$route.params.position === 's') {
          const a = await Api.getEntryInfo({ entryId: this.entryId })
          // console.log(a)
        }
        //相关搜索 
        const { result: { data: involveSeacrchList } } = await Api.getOperation({ id: this.entryId })
        console.log('相关搜索列表===>', involveSeacrchList)
        this.involveSeacrchList = involveSeacrchList;

      } catch (e) {
        console.log(e)
        // this.$message.error(e)
      }
    },
    async init() {
      try {
        // if (this.pictureId)
        console.log('init中')
        await this.getPicturePage();
        if (this.pictureId) await this.getPicture(this.pictureId)
      } catch (e) {
        // this.$message.error(e)
        console.log(e)
      }
    },
    //修改nav的tab
    changeNavTab(item) {
      console.log('nav上当前的tab ===>', item)
    },

    deleteClose() {
      this.deleteDialog = false
    },

    click() {
      this.popVisible = true
    },
    refreshTitle() {
      Api.getPicture(this.currPicture.pictureId).then((res) => {
        if (res.error_code == 0) {
          let data = res.result.data;
          if (data) {
            this.currPicture.note = data.note;
          }
        }
      }).catch(err => {
        console.log("Get Picture Page ==> ", err)
        if (err.response.status == 401 || err.response.status == 400) {
          this.$message.error(this.$t("tokenErrMessage"));
          this.visible = true
        }
      }).finally(() => {
        this.loading = false
      });
    },

    clickEmoji() {
      this.showEmoji = !this.showEmoji;
    },

    closeModal() {
      this.visible = false;
    },
    async getTagByRecommend() {
      try {
        const res = await Api.getTagByRecommend("filter_recommend")
        let data = res.result.data;
        this.filterTags = data;
      } catch (e) {
        console.log(e)
      }
    },
    keydownEnter() {
      if (this.search_value != '') {
        let routeData = this.$router.resolve({
          name: 'search',
          query: { keywords: this.search_value.toString() }
        });
        window.open(routeData.href, '_blank');
      }
    },
    changeSearchEvent(val) {
      this.search_value = val;
    },

    getFilterPicturePage() {
      if (this.filter.totalPage < this.filter.page) {
        this.filterLoading = false
        return;
      }
      Api.getPicturePage(this.entryId, undefined, this.filter.filterSelect, this.filter.filterDate, this.filter.page, this.filter.pageSize).then((res) => {
        if (res.error_code == 0) {
          let data = res.result.data;
          const isInit = !this.filter.list || this.filter.list.length < 1;
          this.filter.list = this.filter.list.concat(data.list);
          if (this.imgTabNum === this.activeTab) {
            this.currPictureIndex = 0
            this.imgList = this.filter.list.filter((obj, i) => {
              // eslint-disable-next-line no-undef
              if (obj.pictureUrl !== null && i < index) {
                this.currPictureIndex++;
              }
              return obj.pictureUrl !== null
            })
          }
          this.filter.totalPage = data.totalPageCount;
          this.filter.page++;
          if (isInit) {
            this.commentCondition.pictureId = this.currPicture.pictureId;
            this.total = {
              suggestionCnt: this.currPicture.suggestionCnt,
              questionCnt: this.currPicture.questionCnt,
              commentCnt: this.currPicture.commentCnt,
            }
            // this.getCommentPage();
          }
        }

      }).catch(err => {
        console.log("Get Picture Page ==> ", err)
      }).finally(() => {
        this.loading = false
      });
    },
    //获取单个图文
    async getPicture() {
      try {
        const { result: { data }, error_code } = await Api.getPicture(this.pictureId)
        if (error_code !== 0) return;
        if (!data) return
        this.entryName = data?.entryTitle;
        document.title = this.entryName;
        this.commentCondition.pictureId = data.pictureId
        this.datas = this.datas.concat(data);
      } catch (err) {
        console.log("Get Picture Page ==> ", err)
      }
    },
    //获取图文信息
    async getPicturePage() {
      try {
        if (this.totalPage < this.page) {
          this.loading = false
          return;
        }
        const { result: { data }, error_code } = await Api.getPicturePage(this.entryId, undefined, this.tagId, this.filterDate, this.page, this.pageSize)
        if (error_code == 0) {
          console.log('图文信息===>', data)
          this.loading = true
          // 保存信息
          if (this.datas.length <= 0) {
            this.entryName = data.list[0]?.entryTitle;
            document.title = this.entryName
          }
          this.totalPage = data.totalPageCount;
          data.list.forEach((item, i) => {
            item._id = i;
          })
          console.log(data.list)
          this.datas = this.datas.concat(data.list);
          const isInit = !this.datas || this.datas.length < 1;


          if (this.page == 1) {
            this.currPicture = JSON.parse(JSON.stringify(this.datas[0]))
            if (this.currPicture.isComment) {
              this.isCommentMap[this.currPicture.pictureId] = true;
            }
          }
          if (this.imgTabNum === this.activeTab) {
            this.currPictureIndex = 0
            this.imgList = this.datas.filter((obj) => {
              return obj.pictureUrl !== null
            })
          }
          this.page++;
          if (isInit) {
            this.commentCondition.pictureId = this.currPicture.pictureId;
            this.total = {
              suggestionCnt: this.currPicture.suggestionCnt,
              questionCnt: this.currPicture.questionCnt,
              commentCnt: this.currPicture.commentCnt,
            }
          }
        }
      } catch (e) {
        console.log("Get Picture Page ==> ", e)
      } finally {
        this.loading = false
      }

    },
    goGuge(key) {
      window.open('https://www.google.com/search?q=' + key, "_blank")
    },
    goWeiji(key) {
      window.open('https://' + this.$i18n.locale + '.wikipedia.org/wiki/' + key, "_blank")
    },
    // 右侧tab
    changeTab(val) {
      this.activeTab = val

    },
    async scrollEvent(event) {
      try {
        // 获取视窗高度。
        let scrollTop = event.target.scrollTop

        const listItemElements = document.querySelectorAll('.item-list');

        //记录滚动条数值
        // console.log(scrollTop)
        if (!this.loading) {
          if (event.target.scrollHeight < (event.target.scrollTop + event.target.clientHeight + 30)) {
            await this.getPicturePage()
            this.loading = true;
            console.log('嗷嗷嗷')
          }
        }

        for (let i = 0; i < listItemElements.length; i++) {
          //遍历每一项元素
          const listItem = listItemElements[i];
          // 每一项元素的相对父元素的高度
          const offsetTop = listItem.offsetTop;
          //每一项元素的高度
          const offsetHeight = listItem.offsetHeight;
          // 滚动距离 比 元素距离顶部高度大，并且滚动距离小于 元素距离顶部高度加元素的高度
          if (scrollTop >= offsetTop && scrollTop < offsetTop + offsetHeight) {
            this.ListIndex = i + 1;
            break;
          } else if (scrollTop < offsetHeight && scrollTop < offsetTop + offsetHeight) {
            this.ListIndex = 0
          }

        }
      } catch (e) {
        console.log(e)
      }
    },
    refreshComments() {
      for (let i = 0; i < 3; i++) {
        this.infoPage[i] = { ...this.initPage };
        // this.getCommentPage(i);
        this.isCommentMap[this.currPicture.pictureId] = true;
      }
    },
    checkLogin() {
      this.visible = !localStorage.getItem('token');
      return this.visible;
    },
  },
  // eslint-disable-next-line no-dupe-keys
  computed: {
    headTags() {
      return [
        { tagId: 'recommend', tagName: this.$t('index.recommend')},
        { tagId: 'hot_spots', tagName: this.$t('index.hot') },
        { tagId: 'follow', tagName: this.$t('index.follow') },
      ]
    }
  },
  beforeUnmount() {
    eventBus.off('updateLogin');
    eventBus.off('updateList');
  }
}

</script>
<style scoped lang="scss">
body {
  background: #f8f8f8;
}

textarea::-webkit-input-placeholder {
  /* WebKit browsers */
  color: #cccccc;
}

.searchTitle {
  margin-top: 3px;
  float: left;
  font-size: 14px;
  color: #0870D1;
}

.searchImg {
  margin-left: 10px;
  cursor: pointer;
}

.detail-box {
  width: 100%;
  height: calc(100vh - 40px);
  overflow-y: auto;

  .container {
    /*display: flex;*/
    width: 1300px;
    margin: 0 auto;
    margin-top: 72px;

    .title {
      height: 58px;
      background: rgba(8, 112, 209, 0.04);
      display: flex;
      align-items: center;
      margin-bottom: 20px;

      img {
        margin: 0 22px 0 20px;
      }

      p {
        font-size: 20px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        line-height: 26px;
        color: #202124;
        margin: 0;
      }
    }

    .basic-info {
      background-color: #fff;
      height: 562px;
      margin-bottom: 100px;
      box-sizing: border-box;
      padding-top: 40px;

      .basic-info-title {
        display: flex;
        margin-bottom: 50px;

        &>div {
          width: 5px;
          height: 22px;
          background-color: #0870D1;
        }

        &>span {
          margin-left: 16px;
          font-size: 20px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          line-height: 22px;
          color: #3C4043;
        }
      }

      .basic-info-content {
        display: flex;
        justify-content: space-between;
        margin-bottom: 40px;

        .basic-info-list {
          margin: 0;
          margin-left: 60px;
          width: 500px;

          li {
            margin-bottom: 26px;
            display: flex;

            span:first-child {
              width: 100px;
              font-size: 16px;
              font-family: Microsoft YaHei;
              font-weight: bold;
              line-height: 21px;
              color: #3C4043;
              text-align: right;
              white-space: nowrap;
              flex-shrink: 0;
            }

            span:last-child {
              margin-left: 28px;
              font-size: 16px;
              font-family: Microsoft YaHei;
              font-weight: 400;
              line-height: 21px;
              color: #4D5156;
              opacity: 1;
            }
          }

          li:last-child {
            margin: 0;
          }
        }

        .basic-info-img {
          width: 450px;
          margin-right: 73px;
          margin-bottom: 30px;
          position: relative;
          display: flex;
          justify-content: flex-end;
          align-items: flex-end;

          img {
            position: absolute;
            top: 0;
            left: 0;
            width: 420px;
            height: 236px;
            border-radius: 8px;
          }

          .basic-info-image-box {
            width: 347px;
            height: 195px;
            background-color: #EEF3F5;
            border-radius: 8px;
          }
        }
      }

      .see-more {
        display: inline-block;
        cursor: pointer;
        margin-left: 58px;
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        line-height: 21px;
        color: #0870D1;
        opacity: 1;
      }
    }

    .tab-center {
      margin-bottom: 148px;
      width: 100%;
      height: 60px;
      white-space: nowrap;
      line-height: 60px;
      overflow: auto;
      text-align: center;

      .tab-item {
        vertical-align: middle;
        white-space: nowrap;
        display: inline-block;
        margin: 0 31px;
        margin-top: 15px;
        font-size: 20px;
        font-weight: 400;
        line-height: 26px;
        color: #666666;
        cursor: pointer;

        &.active {
          color: #0870D1;
          font-weight: bold;
          position: relative;

          &::after {
            content: '';
            position: absolute;
            left: 0;
            bottom: -8px;
            width: 100%;
            height: 4px;
            background: #0870D1;
          }
        }
      }
    }

    .category-box {
      width: 240px;
      height: 32px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 0 auto;
      margin-bottom: 148px;

      li {
        font-size: 20px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        line-height: 26px;
        color: #4D5156;
        padding-bottom: 4px;
        border-bottom: 2px solid transparent;
        cursor: pointer;
      }

      li.active {
        color: #0870D1;
        border-color: #0870D1;
      }
    }

    .story-box {
      height: 140px;
      background: url('../assets/images/img-story-banner.png') no-repeat center;
      background-size: cover;
      box-sizing: border-box;
      padding: 10px 20px 18px 20px;
      margin-bottom: 20px;

      .story-box-top {
        display: flex;
        align-items: center;
        margin-bottom: 20px;

        img {
          width: 18px;
          height: 16px;
        }

        p {
          font-size: 20px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          line-height: 26px;
          color: #202124;
          margin: 0;
          margin-left: 6px;
        }
      }

      .story-box-content {
        display: flex;
        align-items: center;

        ul {
          display: flex;
          align-items: center;
          margin: 0;
          margin-right: 15px;

          li {
            width: 340px;
            height: 66px;
            background: rgba(255, 255, 255, 0.39);
            box-shadow: 0px 0px 6px rgba(198, 212, 226, 0.6);
            border-radius: 8px;
            display: flex;
            align-items: center;
            margin-right: 30px;

            &:last-child {
              margin: 0;
            }

            &>div {
              width: 66px;
              height: 66px;
            }

            p {
              font-size: 14px;
              font-family: Microsoft YaHei;
              font-weight: 400;
              line-height: 19px;
              color: #202124;
              margin: 0;
              margin-left: 11px;
              display: flex;
              align-items: center;

              span {
                font-size: 18px;
                font-family: Meiryo;
                font-weight: bold;
                line-height: 27px;
                margin-right: 10px;
              }

              &:last-child>span {
                color: #FFA218;
              }

              &:nth-child(2)>span {
                color: #F04C4D;
              }

              &:first-child>span {
                color: #B34A4A;
              }



            }
          }
        }

        &>img {
          width: 70px;
          height: 70px;
        }
      }
    }



  }

  .item-center {
    width: 840px;

    .analyse-box {
      width: 100%;
      height: 119px;
      padding: 28px 51px;
      background: #fff;
      margin-top: 20px;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .comment-box {
      padding: 20px;
      background: #fff;
      margin-top: 20px;

      .comment-text-div {
        padding: 18px 20px;
        height: auto;
        background: #f9f9f9;
        border-radius: 8px;
        overflow: hidden;
        position: relative;

        .comment-text {
          overflow-y: visible;
          width: 100%;
          height: auto;
          resize: none;
          border: none;
          font-size: 14px;
          line-height: 19px;
          background: none;
          outline: none;
        }

        .btn-emoticons {
          float: left;
          -webkit-user-select: none;
          -moz-user-select: none;
          -ms-user-select: none;
          user-select: none;

          .icon-emoticons {
            display: flex;
            align-items: center;

            span {
              margin-left: 6px;
              font-size: 15px;
            }
          }
        }

        .btn-box {
          float: right;
          /*position: absolute;*/
          bottom: 20px;
          right: 20px;

          >button {
            width: 72px;
            height: 32px;
            background: #eeeeee;
            border-radius: 4px;
            font-size: 14px;
            font-weight: 400;
            line-height: 34px;
            color: #cccccc;
            border: none;
            outline: none;
            margin-left: 15px;
            cursor: pointer;
          }
        }
      }

      .tab-box {
        width: 100%;
        margin-top: 20px;
        display: flex;
        position: relative;
        flex-direction: row;
        flex-wrap: wrap;

        .tab-item-box {
          min-width: 240px;
          flex: 1;
          height: 35px;

          .tab-item {
            color: #cccccc;
            font-size: 18px;
            font-weight: 400;
            line-height: 24px;
            margin-right: 30px;
            display: inline-block;
            cursor: pointer;

            &.active {
              color: #333333;
              font-weight: bold;
              border-bottom: 2px solid #0870d1;
              padding-bottom: 9px;
            }
          }
        }

        .filter-item {
          >span {
            margin-left: 40px;
            font-size: 12px;
            font-weight: 400;
            line-height: 16px;
            color: #666666;
            cursor: pointer;

            &.active {
              color: #0870d1;
            }

            >svg {
              margin-right: 2px;
              position: relative;
              top: 1px;
            }
          }
        }

        .line {
          position: absolute;
          height: 1px;
          left: -20px;
          right: -20px;
          bottom: 0;
          background: #eeeeee;
        }
      }

      .comment-item {
        margin-top: 20px;
        display: flex;
        position: relative;
        padding-bottom: 20px;
        flex-direction: column;

        .comment-item-info {
          display: flex;
          width: 100%;
          position: relative;

          .user-ava {
            width: 30px;
            height: 30px;
            margin-right: 16px;

            .img-user {
              width: 30px;
              height: 30px;
              border-radius: 50%;
            }
          }

          .info-text {
            padding-top: 4px;
            flex: 1;
            overflow: hidden;
            word-break: break-word;
            font-size: 16px;
            font-weight: 400;
            line-height: 24px;
            color: #333333;
            margin-right: 8%;

            // white-space:pre-wrap;
            .comment-content-span {
              word-wrap: break-word;
              word-break: break-all;
            }

            .text {
              font-size: 10px;
              color: #cccccc;
              margin-left: 10px;

              &.text-btn {
                cursor: pointer;
                color: #999999;
              }
            }
          }

          .info-dianzan {
            width: 20px;
            position: absolute;
            bottom: 0;
            right: 0px;
            cursor: pointer;
            top: 0;
            color: #999999;

            .icon-box {
              width: 20px;
              /*height: 20px;*/
              display: inline-block;
              position: relative;
              vertical-align: middle;
              line-height: 1;
              cursor: pointer;

              .icon-bg-box {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                overflow: hidden;
                clip-path: polygon(0% 20%, 100% 20%, 100% 100%, 0% 100%);

                &.dianzan {
                  background-image: url("../assets/like.svg");
                  background-repeat: no-repeat;
                  background-position: -10px -10px;
                }
              }

              .icon-def-box {
                position: relative;
                top: 0;
                left: 0;
                width: 20px;
                height: 20px;
                display: inline-block;
              }

              &.ismy {
                color: #f69c4a;
              }
            }

            .item-icon-tabs {
              position: absolute;
              right: 20px;
              top: 20px;
              cursor: pointer;
              color: #999;

              &.ismy {
                color: #f69c4a;
              }

              .icon-bg-box {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                overflow: hidden;
                clip-path: polygon(0% 20%, 100% 20%, 100% 100%, 0% 100%);

                &.guanzhu {
                  background-image: url("../assets/report.svg");
                  background-repeat: no-repeat;
                  background-position: -17px -17px;
                  background-size: 52px;
                }
              }

              .icon-def-box {
                position: relative;
                top: 0;
                left: 0;
                width: 20px;
                height: 20px;
                display: inline-block;
              }
            }
          }
        }

        .comment-item-reply {
          font-size: 14px;
          flex: 1;
          overflow: hidden;
          margin-left: 35px;
          margin-top: 10px;

          .reply-item-box {
            padding-left: 10px;

            .reply-item {
              display: flex;
              width: 100%;
              position: relative;
              margin-top: 10px;

              &:first-child {
                margin-top: 0;
              }

              .user-ava {
                width: 15px;
                height: 15px;
                margin-right: 10px;
                line-height: 1;

                .img-user {
                  width: 15px;
                  height: 15px;
                  border-radius: 50%;
                }
              }

              .info-text {
                flex: 1;
                overflow: hidden;
                word-break: break-word;
                font-size: 14px;
                font-weight: 400;
                line-height: 19px;
                color: #333333;
                margin-right: 8%;

                .comment-content-span {
                  white-space: pre-wrap;
                }

                .text {
                  font-size: 10px;
                  color: #cccccc;
                  margin-left: 10px;

                  &.text-btn {
                    cursor: pointer;
                    color: #999999;
                  }
                }
              }

              .info-dianzan {
                width: 20px;
                position: absolute;
                bottom: 0px;
                right: 0;
                cursor: pointer;
                display: none;
                color: #999999;


                .icon-box {
                  width: 20px;
                  /*height: 20px;*/
                  display: inline-block;
                  position: relative;
                  vertical-align: middle;
                  line-height: 1;
                  cursor: pointer;

                  .icon-bg-box {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    overflow: hidden;
                    clip-path: polygon(0% 20%, 100% 20%, 100% 100%, 0% 100%);

                    &.dianzan {
                      background-image: url("../assets/like.svg");
                      background-repeat: no-repeat;
                      background-position: -10px -10px;
                    }
                  }

                  .icon-def-box {
                    position: relative;
                    top: 0;
                    left: 0;
                    width: 20px;
                    height: 20px;
                    display: inline-block;
                  }

                  &.ismy {
                    color: #f69c4a;
                  }
                }
              }
            }

            .fold-btn {
              font-size: 12px;
              font-weight: 400;
              line-height: 19px;
              color: #cccccc;
              display: inline-block;
              margin-left: 25px;
              margin-top: 10px;
            }
          }
        }

        .line {
          position: absolute;
          height: 1px;
          background: #eeeeee;
          left: 46px;
          right: 0;
          bottom: 0;
        }
      }

      .list-loading {
        display: flex;
        align-items: center;
        margin-top: 20px;
        height: 40px;
        justify-content: center;

        >span {
          display: inline-block;
          width: 16px;
          height: 16px;
          margin-right: 6px;
          border-radius: 50%;
          background: #ebebeb;
          -webkit-animation: load 1.04s ease infinite;

          &:last-child {
            margin-right: 0px;
          }
        }

        @-webkit-keyframes load {
          0% {
            opacity: 1;
          }

          100% {
            opacity: 0;
          }
        }

        span:nth-child(1) {
          -webkit-animation-delay: 0.13s;
        }

        span:nth-child(2) {
          -webkit-animation-delay: 0.26s;
        }

        span:nth-child(3) {
          -webkit-animation-delay: 0.39s;
        }
      }
    }
  }
}


.link {
  float: left;
  margin-top: 6px;
  font-size: 14px;
  color: #4D5156;
}

.embed {
  background: #F48234;
  border-radius: 4px;
  margin-left: 16px;
  border-color: unset;
  font-size: 14px;
  color: #FFFFFF;
  cursor: pointer;
}

.copy {
  background: #2C8CF0;
  border-radius: 4px;
  margin-left: 16px;
  border-color: unset;
  font-size: 14px;
  color: #FFFFFF;
  cursor: pointer;
}


@media (max-width: 840px) {
  .detail-box .container {
    width: 100%;
    padding: 0 30px;
  }

  .detail-box .container .container-c {
    width: 100%;
    display: block;

    .container-c-left {
      width: 100%;
    }

    .container-c-right {
      margin-left: 0px;
      width: 100%;
      flex: 1;
      display: none;

    }
  }

  .detail-box .container .item-center {
    width: 100%;

    .comment-box {
      .comment-text-div {
        .btn-emoticons {
          .icon-emoticons {
            display: flex;
            align-items: center;

            span {
              margin-left: 6px;
              font-size: 14px;
            }
          }
        }

        .btn-box {
          >button {
            width: 70px;
            font-size: 13px;
          }
        }
      }
    }

  }

  .detail-box .container .container-c .container-c-left .item-list {
    height: 100%;

    .item-icon-tabs {
      display: none;
    }

    .item-info {
      display: block;

      .info-right {
        .icon-box {
          margin-left: 0;
          margin-right: 25px;
        }

        .item-icon-tabs {
          display: block;
          position: absolute;
          right: 0;
          top: 2px;
        }
      }
    }
  }

  .detail-box .container .item-center .comment-box {
    .tab-box {
      .tab-item-box {
        .tab-item {
          font-size: 16px;
          margin-right: 18px;
        }
      }

      .filter-item {
        >span {
          margin-left: 12px;
        }
      }
    }

    .comment-list-box {
      .comment-item {
        display: block;

        .comment-item-info {
          width: 100%;
        }

        .comment-item-reply {
          margin-top: 12px;

          .reply-item-box {
            padding-top: 12px;
            border-left: none;
            border-top: 1px solid #eeebeb;
          }
        }
      }
    }
  }
}
</style>
