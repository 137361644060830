<template>
    <a-modal
        :title="setTitle"
        :visible="visible"
        :confirm-loading="spinning"
        @ok="confirm"
        @cancel="handleCancel"
        centered
        :okText="$t('done')"
        :cancelText="$t('cancel')"
    >
      <div >
        <a-spin size="large" :spinning="spinning">
      <span>
        <div>
            <form id="uploads-form">
                <input
                        v-show="false"
                        ref="filElem"
                        type="file"
                        id="uploads"
                        accept="image/png, image/jpeg, image/gif, image/jpg"
                        @change="uploadImg($event,1)"
                        class="el-button hide"
                        style="margin-bottom:15px"
                />

            </form>
          <div class="upload-img" @click="clickUpload">{{$t('editAvatar.addPicture')}}</div>
        </div>
        <div>
          <div :span="14">
            <!-- 裁剪 -->
            <VueCropper
                style="width:100%;height:300px"
                ref="cropper"
                :img="attach.customaryUrl"
                :autoCrop="options.autoCrop"
                :fixedBox="options.fixedBox"
                :canMoveBox="options.canMoveBox"
                :autoCropWidth="options.autoCropWidth"
                :autoCropHeight="options.autoCropHeight"
                :centerBox="options.centerBox"
                @realTime="realTime"
            ></VueCropper>
          </div>
          <div :span="6.5">
            <h2 align="center" class=" mar-left-50">{{$t('editAvatar.preview')}}</h2>
            <div class="show-preview">
              <div :style="previews.div" class="preview">
                <img :src="previews.url" :style="previews.img" width="100%"/>
              </div>
            </div>
          </div>
        </div>
<!--        <div class="footerBtn" align="center">
          <el-button type="primary " size="medium" round @click="confirm('blob')">确认</el-button>
          <el-button type="info" size="medium" round @click="handleClose">取消</el-button>
        </div>-->
      </span>
        </a-spin>
      </div>
    </a-modal>
</template>

<script>
import 'vue-cropper/dist/index.css'
import { VueCropper }  from "vue-cropper";
import UserApi from "../../Api/UserApi";
import storage from "storejs";
export default {
    name: "Edit Avatar",
    components: {
        VueCropper
    },
    props: {
        success: Function,
        closeModal: Function,
        visible: Boolean,
        type: String,
        pic: String,
    },
    data() {
        return {
            spinning: false,
            options: {
                autoCrop: true, //默认生成截图框
                fixedBox: true, //固定截图框大小
                canMoveBox: false, //截图框不能拖动
                centerBox: false, //截图框被限制在图片里面
                autoCropWidth: 200,  //截图框宽度
                autoCropHeight: 200, //截图框高度
            },
            previews: {}, //实时预览图数据
            attach: {
                //后端附件表
                id: "",
                userId: "",
                customaryUrl: "", //原图片路径
                laterUrl: "", //裁剪后图片路径  /static/logo.png
                attachType: "photo" //附件类型
            },
        }
    },
    computed: {
        setTitle() {
            return this.$t('editAvatar.changeProfile')
        }
    },
    mounted() {
        this.user = storage.get('userInfo') || {};
    },
    methods: {
        handleOk() {
        },
        handleCancel() {
            this.$emit("closeModal");
            // this.closeModal();
        },
        //控制弹出层关闭
        handleClose() {
            this.dialogVisible = false;
        },
        //实时预览
        realTime(data) {
            this.previews = data;

        },
        //点击图片调取input
        clickUpload() {
            this.$refs.filElem.dispatchEvent(new MouseEvent('click'))
        },
        //选择本地图片
        uploadImg(e, num) {
            var file = e.target.files[0];
            if (!/\.(gif|jpg|jpeg|png|bmp|GIF|JPG|PNG)$/.test(e.target.value)) {
                this.$message.error(this.$t('editAvatar.typeRule'));
                return false;
            }
            //fileReader 接口，用于异步读取文件数据
            var reader = new FileReader();
            reader.readAsDataURL(file); //重要 以dataURL形式读取文件
            reader.onload = e => {
                // data = window.URL.createObjectURL(new Blob([e.target.result])) 转化为blob格式
                let data = e.target.result;
                this.attach.customaryUrl = data;
                // 转化为base64
                // reader.readAsDataURL(file)
                // 转化为blob
            };
        },
        //确认截图,上传
        confirm(type) {
            this.$refs.cropper.getCropData(res => {
                // console.log(res)// 这里截图后的url 是base64格式 让后台转下就可以
                this.spinning = true
                UserApi.updateUserAvatar(this.user.userid, res).then((obj) => {
                    if (obj.error_code == 0) {
                        this.$message.success(obj.result);
                        this.spinning = false
                        this.$emit("closeModal");
                        this.user.avatar = res
                        localStorage.setItem("userInfo",JSON.stringify(this.user || null));
                        // 两个
                        $('.img-user')[0].src = res
                        $('.img-user')[1].src = res
                        $('#uploads-form')[0].reset()
                        this.previews = {}
                        this.attach = {
                            id: "",
                            userId: "",
                            customaryUrl: "",
                            laterUrl: "",
                            attachType: "photo"
                        }
                    } else {
                        this.$message.fail(obj.result);
                        this.spinning = false
                    }
                });
            });
        }
    }
}
</script>

<style scoped>
/* 弹性布局 水平居中 */
.el-dialog {
    width: 700px !important;
    height: auto;
}

.show-preview {
    display: flex;
    justify-content: center;
}

.preview {
    border-radius: 50%;
    overflow: hidden;
    border: 1px solid #cccccc;
    background: #cccccc;
    width: 200px !important;
    height: 200px !important;
    /*margin-left: 50px;*/
    margin-top: 50px;
}

.upload-img {
    width: 100px;
    height: 30px;
    border: 1px solid #f08512;
    margin-bottom: 5px;
    line-height: 30px;
    text-align: center;
    cursor: pointer;
}

.footerBtn {
    display: flex;
    justify-content: center;
    margin-top: 15px;
}
</style>
