<template>
    <body>
	<article class="clearfix">
		<section>
				<Header
					:tabs="tabs"
					:selectTab="selectTab"
					:showSearch="true"
					@targetSide="targetSide"
					@changeTab="changeTab"
					:value="keywords"
					@keydownEnter="keydownEnter"
					@changeSearchEvent="changeSearchEvent"
				/>
			<div class="onerow">
			<div class="under_Layer">
					<h1 class="p_ttl mb_30">Look利用規約</h1>

					<div class="txt_m bold">第1条 はじめに</div>
					<p class="txt_s">1
						この利用規約（以下「本規約」といいます。）は、利用者が撮影した動画・画像、録音した音声および作成したテキスト等のコンテンツを投稿および閲覧するサービス「Look」（以下「本サービス」といいます。）について規定するものです。</p>
					<p class="txt_s">2 利用者は、本規約に必ずご同意の上ご利用いただくものとし、利用者は本サービスを利用（アカウント登録による利用に限らず本サービスへの投稿および閲覧操作を含みます。）した場合には、本規約に同意したものとみなされます。</p>
					<p class="txt_s">3
						利用者が未成年者であり、本サービスを利用した場合、利用者は親権者その他の法定代理人に対しても本規約に定められた遵守事項を確認し、同意を得た上で、自己および法定代理人の責任と負担において利用したものとします。なお、本サービスは、13歳未満の方はご利用できません。
					</p>
					<p class="txt_s">4
						利用者が芸能事務所または各種団体等（以下「事務所等」といいます。）に所属し、本サービスの利用において何等かの制限または負担が生じる場合、利用者は当該関係者に対しても本規約に定められた遵守事項を確認し、許可を得た上で、自己の責任と負担において本サービスを利用したものとします。
					</p>
					<p class="txt_s">5
						本サービスを提供するイマメモ株式会社（以下「当社」といいます。）は、本サービスを通じて、利用者が動画・画像・音声・テキスト・その他本サービスに掲載・発信した情報（以下「本コンテンツ」といいます。）の投稿・閲覧の機会を提供することに限られています。そのため、利用者間の投稿行為、契約行為、トラブル等に関しては、すべて当該利用者の自己責任とし、当社は、返金、保証、取引の遂行その他当該利用者間のやり取りには一切関与せず、また当社に故意または重大な過失がある場合を除き責任を負いません。
					</p>
					<p class="txt_s">6
						当社は、その裁量により、本規約を当社所定の通知方法により、いつでも任意の理由で変更することができるものとします。変更後の本規約は、当社が別途定めるときを除いて、本サービス上に表示した後に初めて利用者が本サービスを利用した時点または当社の定める合理的期間経過後より効力を生じるものとします。変更の内容を利用者に個別に通知することはいたしかねますので、本サービスをご利用の際には、随時、最新の本規約および適用のある個別利用規約をご参照ください。また、当社は本規約の改定・変更により利用者に生じたすべての損害について、当社に故意または重大な過失がある場合を除き責任を負いません。
					</p>
					<p class="txt_s">7 本サービスには、本規約およびその他の規約等において、利用条件が規定されています。その他の規約等は名称の如何に関わらず本規約の一部を構成するものとします。</p>
					<p class="txt_s">8 本規約に記載された事項に関わらず、本サービス内において掲示されたガイドラインについても、本規約の一部を構成するものとして、利用者は遵守しなければならないものとします。</p>
					<p class="txt_s mb_70">9 本規約の規定とその他の規約等の規定が矛盾する場合は、当社が別途定める場合を除いて、本規約の規定が優先して適用されるものとします。</p>

					<div class="txt_m bold">第2条 定義</div>
					<p class="txt_s">本規約の中で使用される以下の各用語は、次の通りとします。</p>
					<p class="txt_s_mbfix">(1) 「本規約等」とは、本規約、プライバシーポリシーその他本サービスを提供・利用する上で必要な一切の条件をいい、これらすべては本規約の内容の一部を構成するものとします。</p>
					<p class="txt_s_mbfix">(2) 「プライバシーポリシー」とは、当社が別に定めるプライバシーポリシー（名称の如何を問わないものとします。）をいいます。</p>
					<p class="txt_s_mbfix">(3) 「利用者」とは、本規約の内容に同意して、本サービスを利用するすべての個人（または法人）を指します。</p>
					<p class="txt_s_mbfix">(4) 「投稿者」とは、本サービス内でコンテンツを投稿する利用者を指します。</p>
					<p class="txt_s_mbfix">(5) 「閲覧者」とは、本サービス内で閲覧をする者を指します。</p>
					<p class="txt_s_mbfix">(6) 「投稿」とは、利用者が本サービスにて、本コンテンツをアップロードし、他の利用者が閲覧できる状態にすることをいいます。</p>
					<p class="txt_s_mbfix">(7) 「外部SNS」とは、Twitter、Instagram、LINE、Facebookその他当社が指定する、他の事業者提供のソーシャル・ネットワーキング・サービスを意味します。</p>
					<p class="txt_s_mbfix">(8) 「外部SNS事業者」とは、外部SNSのサービスを提供する事業者を意味します。</p>
					<p class="txt_s_mbfix">(9) 「外部SNS利用規約」とは、外部SNSの利用に関し利用者と外部SNS事業者との間で規定される規約（名称の如何を問いません。）を意味します。</p>
					<p class="txt_s_mbfix">(10) 「広告主」とは、本サービスにおける広告掲載サービスを利用する法人または団体を意味します。</p>
					<p class="txt_s_mbfix">(11) 「広告スタンプ」とは、当または広告主が適法に保有する商標、ロゴその他当社または広告主が作成する本サービスにおけるスタンプを意味します。</p>
					<p class="txt_s_mbfix mb_70">(12) 「いいね！」とは、本サービスにおける投稿等に対するアクションであり、第７条の通り利用できるものを意味します。</p>

					<div class="txt_m bold">第3条 アカウントの登録等</div>
					<p class="txt_s">1
						利用者は、本サービスを利用する場合は、本規約の内容に同意し、SMS認証その他当社所定の方法による本人確認手続をした上で、当社所定の方法によりアカウント登録を行うものとします。なお、登録内容は、登録日における最新の情報により行うものとし、登録した内容に変更があった場合には、登録内容を変更後の内容にするものとします。
					</p>
					<p class="txt_s">2
						前項における当社所定の本人確認手続として、SMS認証による場合、利用者は、自身の名義で保有する通信端末（電話が発着信できる端末に限ります。）、および自身の名義で取得をした電話番号を使用して本サービスを登録する必要があります。また、利用者は、本サービスを利用するために必要な自己の通信端末および電話番号の貸与、管理、使用についての一切の責任を持つものとし、第三者に対して、自己の通信端末および電話番号を用いて本サービスを利用することを許諾してはならないものとし、通信端末および電話番号が不正に使用されていると当社において判断した場合、事前の通知なしに、アカウントの停止および削除ができます。その際、利用者において、本サービスを利用できないことにより損害が発生しても、当社は一切の責任を負わないものとします。
					</p>
					<p class="txt_s">3 未成年者が本サービスを利用する場合には、必ず事前に親権者その他の法定代理人の同意を得てアカウント登録の手続きを行うものとします。</p>
					<p class="txt_s">4 利用者が事務所等に所属し、本サービスを利用する場合には、必ず関係者の許可を得てアカウント登録の手続きを行うものとします。</p>
					<p class="txt_s">5 利用者は、１つのアカウントを保有するものとし、本人以外は本サービスを利用できないものとします。複数のアカウントを保有することは出来ません。ただし、当社が別に認めたものを除きます。</p>
					<p class="txt_s">6
						利用者は、本条2項で使用した電話番号を変更する際は、当社が指定する方法により変更するものとし、当社からの指示がある場合には、これに従うものとします。利用者において、利用者の電話番号変更後にサービスの引継ぎおよびサービスの利用ができないことによる損害があっても、当社は一切の責任を負わないものとします。
					</p>
					<p class="txt_s">7 利用者が次の各号のいずれかに該当すると当社が判断する場合には、アカウントの登録拒否をする場合があります。</p>
					<p class="txt_s_mbfix">(1) 利用者が本規約のいずれかに違反していると当社が判断した場合</p>
					<p class="txt_s_mbfix">(2) 当社所定の方法によらずにアカウント登録を行った場合</p>
					<p class="txt_s_mbfix">(3) 過去に本規約に違反したことを理由にして何らかの処分を受けた者である場合</p>
					<p class="txt_s_mbfix">(4) 当社が提供する他のサービスにおいて何らかの処分を受けたことがある場合</p>
					<p class="txt_s_mbfix">(5)
						利用者またはその関係者が暴力団、暴力団員、右翼団体、反社会的勢力その他これらに準ずる者（以下「反社会的勢力等」といいます。）、または資金提供その他を通じて反社会的勢力の維持、運営もしくは経営に協力もしくは関与する等反社会的勢力等との何らかの交流もしくは関与を行っていると当社が判断した場合
					</p>
					<p class="txt_s_mbfix">(6) 当社および他の利用者に迷惑をかける行為</p>
					<p class="txt_s_mbfix mb_70">(7) その他当社が不適切と判断した場合</p>

					<div class="txt_m bold">第4条 アカウントの管理</div>
					<p class="txt_s">1 利用者は、自己のアカウント登録に必要な所定の情報（ID、メールアドレス、パスワードを含みますがそれらに限られません。）の不正利用の防止に努めるとともに、その管理について一切の責任を持つものとします。</p>
					<p class="txt_s">2 利用者は、いかなる場合においても、アカウントを第三者に譲渡または貸与することはできません。</p>
					<p class="txt_s">3
						登録された利用者のアカウント情報に事実と異なる虚偽の情報の記載がある場合や、第三者になりすましている可能性があると当社が判断した場合、当該アカウントを停止または抹消することがあります。この場合、当社は当社に故意または重大な過失がある場合を除き責任を負いません。
					</p>
					<p class="txt_s">4
						利用者は、本サービスにおける利用者のアカウントを任意の外部SNSログインにて管理することができます。利用者は、自身が外部SNSにおいて登録したメールアドレス、電話番号、パスワード等の情報を自己の責任において管理および保管するものとし、当該外部SNS情報の管理不十分、使用上の過誤、第三者の使用等による損害賠償等の責任につき、当社は故意または重大な過失がある場合を除き責任を負いません。
					</p>
					<p class="txt_s">5
						本サービスの利用については、利用者本人以外の第三者による利用および行為の場合も含め、すべてを当該利用者による利用および行為とみなし、当該利用者は、当該利用および行為により生じた一切の債務を負担するものとします。なお、利用者が事務所等に所属している場合において、その違反行為について事務所等において故意または過失が存在するときは、事務所等においてもその責めに帰す場合があるものとします。
					</p>
					<p class="txt_s mb_70">6
						利用者は、外部SNSのメールアドレス、電話番号、パスワード等の情報が第三者より不当に取得され、あるいは第三者に利用されるおそれがある場合は、直ちに当社にその旨連絡するとともに、当社からの指示がある場合には、これに従うものとします。</p>

					<div class="txt_m bold">第5条 SNS連携</div>
					<p class="txt_s">当社は、外部SNS事業者（本条においてはTwitter, Inc.、Instagram, LLC.、LINE株式会社、Facebook, Inc.をいいます。）と連携し、下記の取扱いをいたします。</p>
					<p class="txt_s_mbfix">(1) 取得する情報<br>
						外部SNSに登録されたID情報および当該外部SNSのプライバシー設定により利用者が連携先に開示を認めた情報</p>
					<p class="txt_s_mbfix">(2) 利用目的<br>
						・利用者のアカウント識別情報を連携し、当社サービスを通じてコンテンツを投稿するため<br>
						・個人を識別できないかたちに加工したうえ、マーケティングおよびイベント企画その他当社が行うあらゆるマーケティング活動全般のため</p>
					<p class="txt_s_mbfix mb_70">(3) ログイン連携の利用停止について<br>
						利用者自身で登録情報変更ページにログインすることで、連携の利用停止が可能です</p>

					<div class="txt_m bold">第6条 利用料金</div>
					<p class="txt_s mb_70">本サービスは、原則として無料で利用できます。ただし、投稿に関し発生する費用は投稿者が負担するものとします。</p>

					<div class="txt_m bold">第7条 「いいね！」の利用</div>
					<p class="txt_s">1 「いいね！」は、当社の定める基準に従い、当社が指定する商品等と交換することができます。</p>
					<p class="txt_s">2 利用者は、前項の交換をする場合、交換方法は当社の定める方法に従うものとします。</p>
					<p class="txt_s">3 第1項の交換をした時点で、当該商品等に対する当社所定の権利は利用者に帰属するものとし、当社は第1項の交換をした時点から当該権利を管理しないものとします。なお、当社が指定する商品等のうち、有効期限が定められているものについては、当該有効期限経過により、当該商品等に対する利用者の権利は消滅するものとします。</p>
					<p class="txt_s">4
						第1項の交換前に生じた当該商品等に関する権利の滅失、損傷、変質その他の損害は、利用者に責めに帰すべき事由がある場合を除き当社が負担し、第1項の交換後に生じた当該商品等に関する権利の滅失、損傷、変質その他の損害は、当社に責めに帰すべき事由がある場合を除き利用者が負担するものとします。
					</p>
					<p class="txt_s">5 利用者は、他の利用者に対し、獲得した「いいね！」を当社が指定する方法で譲渡することができます。</p>
					<p class="txt_s">6 前項の譲渡をする場合、当社が「いいね！」の譲渡を承認した時点で、他の利用者に対し「いいね！」の権利が移転するものとします。</p>
					<p class="txt_s">7 第1項の交換をした後、または第5項の譲渡をした後は、理由を問わず「いいね！」の数を戻すことはできません。</p>
					<p class="txt_s mb_70">8 「いいね！」は、本サービス内でのみ使用できるものとし、当社が認めた方法以外で使用または譲渡することはできません。</p>

					<div class="txt_m bold">第8条 知的財産権等</div>
					<p class="txt_s">1
						利用者は、自己の責任と負担において、本コンテンツに含まれる第三者の著作権、商標権、特許権、意匠権、実用新案権、営業秘密、名誉権、肖像権、プライバシー権、パブリシティ権、その他法令上または契約上の権利に関しては、本サービス上に投稿した時点より期限の定めなく利用することができるように必要な同意の取得または処理をしており、これを保証しているものとします。なお、当社は、当社所定の著作物について、利用者の本サービス内における利用の許諾を著作権管理等管理事業者等より事前に取得する場合があります。
					</p>
					<p class="txt_s">2
						本サービスに関する著作権その他の知的財産権およびノウハウ等の利益は、すべて当社に帰属するものとし、利用者および第三者は、当該知的財産権および利益の侵害により当社が損害（逸失利益、特別損害、間接的・付随的損害および合理的な弁護士費用相当額を含みます。）を被った場合、当社に対し当該損害を賠償する責任を負うものとします。
					</p>
					<p class="txt_s">3
						</p><p class="txt_s_mbfix">(1)
							本コンテンツの著作権その他一切の知的財産権は、本コンテンツの投稿者または投稿者に権利を許諾した第三者に帰属します。ただし、投稿者は、本コンテンツを投稿した場合、当社に対して、本サービスを提供するために本コンテンツを使用、配信、変更、運営、複製、公演、公開、翻訳、派生作品の作成をすることのできる非独占的で、無償で譲渡またはサブライセンス可能な全世界を対象としたライセンス（以下「本ライセンス」といいます。）を付与するものとします。
						</p>
					<p></p>
					<p class="txt_s_mbfix mb_30">(2)
						本ライセンスは、投稿者が本コンテンツやアカウントを削除することにより、いつでも終了させることができます。ただし、利用者が他の利用者にシェアしたコンテンツであっても、シェアされた利用者が削除していないコンテンツについては、本ライセンスが継続されます。</p>
					<p class="txt_s">4
						閲覧者は、本コンテンツの複製、公衆送信等を行うことはできません。当社は、本コンテンツを、本サービス提供のためまたは本サービスに関する宣伝広告、プロモーションもしくはマーケティング目的等に使用することがあり、投稿者または本コンテンツに関し知的財産権等を有する者は、当社が当該目的により本コンテンツ（知的財産権および肖像権その他これらに準ずる権利を含みます。）を使用することを予め許諾するものとします。
					</p>
					<p class="txt_s">5
						利用者は、本サービスにおいて自己が利用するため、自己の判断により本サービスに利用者プロフィールを登録することができるものとします。なお、当該利用者プロフィールに他者が権利を有する知的財産権等が含まれる場合には、利用者が当該権利者との権利許諾等に関する手続きを行うものとします。
					</p>
					<p class="txt_s">6 利用者は、本条の規定に違反することにより何らかの問題が発生した場合には、自己の費用と責任において当該問題を解決するものとし、当社に何らの迷惑または損害を与えないものとします。</p>
					<p class="txt_s">7
						本サービスでは、本コンテンツに利用者による、違法と当社が判断した音楽、音声その他これらに準ずる著作物の利用行為（歌唱または演奏の録音または録画、ＣＤやカラオケ等既存音源の利用を含むが、これに限られない。）が含まれる場合、投稿および閲覧をすることはできないものとします。
					</p>
					<p class="txt_s">8 当社は、広告スタンプについて、利用者に対し、譲渡および再許諾できず、非独占的な、本サービスの利用を唯一の目的とする利用権を付与します。</p>
					<p class="txt_s">9 利用者は、広告スタンプを利用する場合、本規約の定めに従うものとし、広告スタンプに関する知的財産権その他の権利は利用者に移転しないものとします。</p>
					<p class="txt_s mb_70">10 利用者は、本サービス内において、無償または「いいね！」との交換により、当社が提供するデジタルデータ化された漫画作品等を閲覧することができます（以下「ポップルマンガ」といいます。）。ポップルマンガは、利用者に対し、漫画作品等を当社指定の方法で閲覧する権利を与えるのみであり、利用者は、当該漫画作品等の所有権、複製権（スクリーンショットを行う権利を含む。）、頒布権、公衆送信権、知的財産権等の権利、その他一切の財産権を取得させるものではありません。</p>

					<div class="txt_m bold">第9条 利用環境の整備</div>
					<p class="txt_s">1 利用者は、本サービスを利用するために必要なあらゆる機器、ソフトウェア、通信手段を自己の責任と費用において、適切に整備するものとします。</p>
					<p class="txt_s">2 利用者は、自己の利用環境に応じて、コンピューターウィルス感染の防止、不正アクセスおよび情報漏洩の防止等のセキュリティ対策を講じるものとします。</p>
					<p class="txt_s mb_70">3 当社は、利用者の利用環境について一切関与せず、また当社に故意または重大な過失がある場合を除き責任を負いません。</p>

					<div class="txt_m bold">第10条 当社の不関与</div>
					<p class="txt_s">1
						本サービスにおける当社の責任は、本サービスの提供によって本コンテンツの投稿・閲覧のためのプラットフォームを提供することに限られ、これ以外については、本規約等で定める場合、当社に故意または重大な過失がある場合を除き責任を負いません。</p>
					<p class="txt_s">2
						本サービスの変更等に起因または関連して、利用者間でトラブル、紛争等が生じたときは、利用者間で直接解決するものとし、当社は帰責事由がある場合を除き一切これに関与する責任を負わず、違約金、損害賠償その他の一切の金銭の支払い責任を負いません。また、当該トラブル等によって当社に損害が発生した場合、利用者はこれを補償するものとします。
					</p>
					<p class="txt_s">3 投稿者に関するクレーム等の一切については、当該投稿者が受け付けるものとし、当社はこれらに対応する責任を負わないものとします。</p>
					<p class="txt_s ">4 利用者および広告主の間の広告スタンプに関するクレーム、紛争等の一切については、当社が広告スタンプの作成を共同で行った場合を除き、広告主が受け付けて解決するものとし、当社はこれらに対応する責任を負わないものとします。
					</p>
					<p class="txt_s mb_70">5 利用者が「いいね！」と商品等を交換した場合、当該商品等に関するクレーム、紛争等の一切について、当社はこれらに対応する責任を負わないものとします。</p>

					<div class="txt_m bold">第11条 本サービスの変更、中断、停止、終了</div>
					<p class="txt_s">1 当社は、本サービス用設備の保守、運営または工事上の都合および本サービス提供のための提携先事業者の都合により、本サービスの全部または一部の提供を中断または停止することがあります。</p>
					<p class="txt_s">2
						当社は、当社の都合により、本サービスの全部または一部について、その内容を変更、中断、停止し、または提供を終了することができます。当社が本サービスの全部の提供を終了する場合には、当社は利用者に事前に通知するものとします。</p>
					<p class="txt_s mb_70">3 当社は、本条に基づき当社が行った措置により利用者に生じた損害について、故意または重過失による場合を除き、一切の責任を負いません。</p>

					<div class="txt_m bold">第12条 アプリケーションの使用許諾等</div>
					<p class="txt_s">1
						当社は、本サービスを提供する場合、利用者に対し、本規約の定める条件に従い、本サービスの譲渡不能で非独占的な使用権を許諾しますが、本規約は、将来開発される可能性のある本サービスのアップグレード版等に対する使用権を許諾するものではありません。</p>
					<p class="txt_s">2 当社は、本サービスにおける一切の動作保証、使用目的への適合性の保証、使用結果に関わる的確性や信頼性の保証をせず、かつ、いかなる内容の担保責任も負いません。</p>
					<p class="txt_s">3
						利用者は、本サービス、広告スタンプおよび本サービスに関する情報を、当社の事前の許諾なしに、無断で複製、転載、再配布などをする行為をしてはならないものとします。また利用者は、広告スタンプ、本サービスに関する情報またはプログラム等につき、修正、変更、改変、リバース・エンジニアリング、逆コンパイル、逆アセンブルその他これらに準ずる行為をしてはならないものとします。
					</p>
					<p class="txt_s">4
						本サービスは、本サービスの使用時、本コンテンツの閲覧およびダウンロードの際には一定時間ごとにネットワークへ接続し、通信を行うことがあります。その際、別途通信料がかかります。当該通信料は当社ではなくサービスの利用者が負担するものとします。</p>
					<p class="txt_s">5 本サービスの表示および本サービスの動作に必要とされるプログラムデータおよび関連するドキュメントファイル等は、予告なく自動的に変更・更新される場合があります。</p>
					<p class="txt_s mb_70">6
						本サービスの実行中に圏外への移動などによってネットワーク接続が出来ない場合や、建物内、ビル陰などで通信ができない場合には、一時的に本サービスが利用できない場合があります。これにより生じたいかなる損害についても当社は当社に故意または重大な過失がある場合を除き責任を負いません。
					</p>

					<div class="txt_m bold">第13条 個人情報等の取扱い</div>
					<p class="txt_s">1 利用者は、その個人情報を含む利用情報について、当社が定めるプライバシーポリシーに基づき当社が取り扱うことに同意するものとします。</p>
					<p class="txt_s">2
						前項のプライバシーポリシーの規定に加え、当社は、本サービス上において投稿された投稿者の個人情報については、本サービス提供のためまたは本サービスに関する宣伝広告、プロモーションもしくはマーケティング目的等に使用することがあり、投稿者は予めこれに同意するものとします。
					</p>
					<p class="txt_s mb_70">3 当社は、利用者における報告等により利用者間における本規約の違反のおそれがあると判断した場合、利用停止や削除その他の必要な措置を行うことがあります。</p>

					<div class="txt_m bold">第14条 禁止事項</div>
					<p class="txt_s">1 利用者は、本サービスの利用にあたり、以下の各号のいずれかに該当する行為をしてはなりません。</p>
					<p class="txt_s_mbfix">(1) 当社もしくは第三者の著作権、商標権等の知的財産権を侵害する行為、または侵害するおそれのある行為</p>
					<p class="txt_s_mbfix">(2) 当社もしくは第三者が本サービスおよび本サービス以外で公開しているあらゆるコンテンツ（画像、動画、音声、テキスト等）を、当社もしくは第三者の許可なく投稿する行為</p>
					<p class="txt_s_mbfix">(3) 当社もしくは第三者の財産、プライバシーもしくは肖像権を侵害する行為、または侵害するおそれのある行為</p>
					<p class="txt_s_mbfix">(4) 当社もしくは第三者を不当に差別もしくは誹謗中傷し、当社もしくは第三者への不当な差別を助長し、またはその名誉もしくは信用を毀損する行為</p>
					<p class="txt_s_mbfix">(5) 自己以外の人物を名乗ったり、代表権や代理権がないのにも関わらずあるものと装ったり、または他の人物や組織と提携、協力関係にあると偽る行為、および誤認させる行為</p>
					<p class="txt_s_mbfix">(6) 本コンテンツ上の人物が他人であるにもかかわらず、自己であるものと偽る行為、装う行為、および誤認させる行為</p>
					<p class="txt_s_mbfix">(7) 宗教的勧誘、マルチへの勧誘、詐欺、規制薬物の濫用、児童売買春、預貯金口座および携帯電話の違法な売買等の犯罪に結びつく、または結び付くおそれのある行為</p>
					<p class="txt_s_mbfix">(8)
						わいせつ、ポルノ、児童ポルノもしくは児童虐待に相当すると当社が判断した情報を投稿または表示する行為、これらの情報を収録した媒体を販売する行為、またはその送信、表示、販売を想起させる広告を投稿もしくは表示する行為</p>
					<p class="txt_s_mbfix">(9) 性行為、わいせつな行為等を目的として利用する行為</p>
					<p class="txt_s_mbfix">(10) 異性・同性との出会い等を目的として利用する行為</p>
					<p class="txt_s_mbfix">(11) 違法な賭博・ギャンブルを行わせ、または違法な賭博・ギャンブルへの参加を勧誘する行為</p>
					<p class="txt_s_mbfix">(12) 違法行為（けん銃等の譲渡、爆発物の製造、児童ポルノの提供、公文書偽装、殺人、脅迫を含みますが、これらに限られません。）を請け負い、仲介し、または勧誘する行為</p>
					<p class="txt_s_mbfix">(13) 人を自殺に勧引または勧誘する行為、または他社に危害の及ぶおそれの高い自殺の手段等を紹介するなどの行為</p>
					<p class="txt_s_mbfix">(14) 人の殺害・殺害現場、死体、その他残虐な行為を撮影した情報、または社会通念上他人に嫌悪感を抱かせたり、他の利用者が不快と感じる可能性が高いと当社が判断する情報を投稿または表示する行為</p>
					<p class="txt_s_mbfix">(15) 無限連鎖講（ネズミ講）を開設し、またはこれを勧誘する行為</p>
					<p class="txt_s_mbfix">(16)
						アダルトサイト、ワンクリック詐欺サイト、ウィルス等の有害なコンピュータプログラム等を流布させることを目的とするサイト等、当社が不適切と判断するサイトに勧誘する情報を投稿または表示する行為（単にリンクを張る行為を含みます。）</p>
					<p class="txt_s_mbfix">(17) 営業、宣伝、広告その他営利を目的とする行為（ただし、当社が特に認めた場合を除きます。）</p>
					<p class="txt_s_mbfix">(18) 本サービスの利用目的の範囲を超えて投稿情報を表示する行為（ただし、当社が特に認めた場合を除きます。）</p>
					<p class="txt_s_mbfix">(19) 虚偽の情報を本サービスの登録に必要な情報に登録しまたは本サービスに投稿する行為</p>
					<p class="txt_s_mbfix">(20) 画像加工アプリ等により、ロゴ・キャラクター等第三者が権利を有するスタンプ、コンテンツその他加工が特定できるかたちで表示されたコンテンツを投稿する行為</p>
					<p class="txt_s_mbfix">(21) 当社の設備に蓄積された情報を不正に書き換え、または消去する行為</p>
					<p class="txt_s_mbfix">(22) ウィルス等の有害なコンピュータプログラム等を送信または掲載する行為</p>
					<p class="txt_s_mbfix">(23) 当社または第三者のサーバーに過度な負担をかける行為、もしくは本サービスの運営やネットワーク・システムに支障を与える行為、またはこれらのおそれのある行為</p>
					<p class="txt_s_mbfix">(24) 違法と判断される音楽、音声その他これらに準ずる著作物の投稿および閲覧行為</p>
					<p class="txt_s_mbfix">(25) 広告スタンプを本サービスにおける投稿以外の用途に利用する行為</p>
					<p class="txt_s_mbfix">(26) その行為が前各号のいずれかに該当することを知りつつ、その行為を助長する目的でリンクを貼る行為</p>
					<p class="txt_s_mbfix">(27) 法令、公序良俗に違反し、または当社もしくは第三者の権利・利益を侵害すると当社が判断する行為</p>
					<p class="txt_s_mbfix">(28) 当社および他の利用者に迷惑をかける行為</p>
					<p class="txt_s_mbfix">(29) 当社が別に認めたものを除き、複数のアカウントを作成、保有する行為</p>
					<p class="txt_s_mbfix">(30) 本サービスに対して一度投稿されたことがある動画・画像・音声・テキスト・その他の情報を、複数回投稿する行為</p>
					<p class="txt_s_mbfix">(31) 本サービスにおける当社所定のメッセージ機能における、ストーカー行為等の規制等に関する法律に違反する行為、同一または類似のメッセージを不特定多数の利用者に送信する行為（当社の認めたものを除きます。）、その他当社がスパムと判断する行為</p>
					<p class="txt_s_mbfix mb_30">(32) その他当社が不適切と判断する行為</p>
					<p class="txt_s">2 閲覧者は前項に該当する投稿を発見した場合、当社指定の方法に通報することができるものとします。ただし、通報された投稿を削除するかは当社の判断によって決定するものとします。</p>
					<p class="txt_s mb_70">3 当社は、投稿者の投稿が第1項に反する場合、当該投稿（広告スタンプを含む）を予告なく削除することができるものとします。</p>

					<div class="txt_m bold">第15条 免責</div>
					<p class="txt_s">1 当社は投稿者、閲覧者の通信や活動に関与しません。万一、当該利用者と他の利用者または第三者との間で紛争があった場合でも、当該紛争当事者間で解決するものとし、当社はその責任を負いません。</p>
					<p class="txt_s">2 本サービスの追加、変更、中断、終了により利用者に生じた損害について、当社は故意または重過失による場合を除き、いかなる責任も負いません。</p>
					<p class="txt_s">3 アクセス過多、その他予期せぬ要因で表示速度の低下や障害等によって生じた損害について、当社はいかなる責任も負いません。</p>
					<p class="txt_s">4
						当社は、当社が本サービスにより提供する一切の情報、データ等、または利用者が本サービス上に掲載する一切の情報、データ等を確実に保存することを保証するものではなく、当該情報、データ等が消失したとしても当社に故意または重大な過失がある場合を除き責任を負いません。
					</p>
					<p class="txt_s">5 当社は当サービスにおける一切の動作保証、使用目的への適合性の保証、使用結果にかかわる的確性や信頼性の補償をせず、かついかなる内容の瑕疵担保責任を負いません。</p>
					<p class="txt_s">6 当社は、次に掲げる場合には、必要な範囲にかぎり、本コンテンツの内容を閲覧、保存または第三者への開示をできるものとします。</p>
					<p class="txt_s_mbfix">(1) 本サービスの技術的不具合の原因を解明し、解消するために必要な場合</p>
					<p class="txt_s_mbfix">(2) 裁判所や警察などの公的機関から、法令に基づく正式な照合を受けた場合</p>
					<p class="txt_s_mbfix">(3) プロバイダ責任制限法その他の法令に基づく場合または弁護士法第23条の2等の法令に基づく申出に伴う場合（法令に定める要件充足性は当社が判断できるものとします。）</p>
					<p class="txt_s_mbfix">(4) 本規約に違反する行為またはそのおそれのある行為が行われ、投稿情報の内容を確認する必要が生じたと当社が判断した場合</p>
					<p class="txt_s_mbfix">(5) 人の生命、身体および財産等に差し迫った危険があり、緊急の必要性があると当社が判断した場合</p>
					<p class="txt_s_mbfix mb_30">(6) その他本サービスを適切に運営するために必要が生じた場合</p>
					<p class="txt_s">7
						当社は、本規約に違反する行為またはそのおそれのある行為が行われたと判断した場合には、当該行為を行った利用者に対して、事前の通知を行うことなく、投稿情報の削除やサービスの利用制限等の当社が必要と考える措置を講じる場合がありますが、それによって生じたいかなる損害についても、故意または重過失による場合を除き、一切責任を負いません。利用者は、当社が本項の規定に基づいて行った措置に対して、異議を申し立てしないものとします。
					</p>
					<p class="txt_s">8 利用者は、法律の範囲内で本サービスを利用することができます。本サービスの利用に関連して利用者が日本の法律または外国の法律に触れた場合、当社は当社に故意または重大な過失がある場合を除き責任を負いません。</p>
					<p class="txt_s">9
						本規約が消費者契約法第2条第3項の消費者契約に該当する場合には、本規約のうち、当社の損害賠償責任を免責する規定は適用されないものとします。ただし、当社に故意または重過失がある場合は除きます。</p>
					<p class="txt_s">10 本規約の定めに関わらず、いかなる場合においても当社は、本サービスに関し次に掲げる損害については当社に故意または重大な過失がある場合を除き責任を負いません。</p>
					<p class="txt_s_mbfix">(1) 当社の責に帰すべからざる事由から生じた損害</p>
					<p class="txt_s_mbfix">(2) 当社の予見の有無に関わらず、特別の事情から生じた損害</p>
					<p class="txt_s_mbfix mb_30">(3) サーバーメンテナンスなど本サービスの提供にあたって必要不可欠な行為によって生じた損害</p>
					<p class="txt_s">11 当社は、本コンテンツの権利およびその内容については一切保証をいたしません。本コンテンツの権利および内容については、利用者の自己責任により投稿および閲覧を行うものといたします。</p>
					<p class="txt_s">12
						投稿者はコンテンツの投稿内容および制作過程については自己責任を負います。このため、投稿者が第三者の提供するサービスまたはアプリを利用しコンテンツを制作し、投稿をする場合、当該第三者の許諾を得ずまたは認められない方法により本サービスに対して投稿したことにより、第三者との間においてトラブル、紛争が発生した場合、自己の責任と負担により解決するものとし、当社は帰責事由がある場合を除き、一切これに関与する責任を負わず、違約金、損害賠償その他の一切の金銭の支払い責任を負いません。また、当該トラブル等によって当社に損害が発生した場合、利用者はこれを補償するものとします。
					</p>
					<p class="txt_s">13 当社は、広告スタンプの不適切な表現等については、当社が広告スタンプの作成を共同で行った場合を除き、一切保証をせず、責任を負いません。</p>
					<p class="txt_s">14 当社は、利用者が「いいね！」を商品等と交換する際、有効期限切れ、URL・交換方法・パスワードの漏洩その他商品等との交換ができない事態が発生した場合、当社に故意または重大な過失がある場合を除き責任を負いません。</p>
					<p class="txt_s">15 当社は、利用者が「いいね！」との交換で取得した商品等について瑕疵があった場合であっても、当社に故意または重大な過失がある場合を除き責任を負いません。</p>
					<p class="txt_s mb_70">16 当社は、利用者が「いいね！」を譲渡する際、他の利用者とトラブルになった場合、当社に故意または重大な過失がある場合を除き責任を負いません。</p>

					<div class="txt_m bold">第16条 本サービスの利用停止措置等</div>
					<p class="txt_s">1 当社は、利用者が以下の各号のいずれかの事由に該当する場合、事前に通知または催告することなく、アカウント登録を抹消する、本サービスの利用を停止する等の措置を取ることができます。</p>
					<p class="txt_s_mbfix">(1) 本規約のいずれかの条項に違反した場合</p>
					<p class="txt_s_mbfix">(2) 支払停止もしくは支払不能となり、または破産手続開始、民事再生手続開始、会社更生手続開始、特別清算開始もしくはこれに類する手続の開始の申立てがあった場合</p>
					<p class="txt_s_mbfix">(3) 当社からの問い合わせその他の回答を求める連絡に対して30日間以上の応答がない場合</p>
					<p class="txt_s_mbfix mb_30">(4) その他、当社が本サービスの利用の継続を適当でないと判断した場合</p>
					<p class="txt_s">2 当社は、利用者に対して本人確認等の手続き等を要請したにもかかわらず行われなかった場合、アカウントの停止削除等の措置を取ることができます。</p>
					<p class="txt_s mb_70">3 当社は、本条に基づく当社の行為により利用者に生じた損害について、故意または重過失による場合を除き、一切の責任を負いません。</p>

					<div class="txt_m bold">第17条 本サービスの退会</div>
					<p class="txt_s">1 利用者は、本サービスを退会する場合は、当社所定の方法により退会手続を行うものとします。</p>
					<p class="txt_s">2 利用者が退会を希望する時点で保有していた、いいね等は退会により消滅します。同様に90日間1度も本サービスの利用が確認できなかった場合も、保有していたいいね等は消滅するものとします。</p>
					<p class="txt_s">3 利用者が退会した場合、当該利用者の投稿した本コンテンツは削除され新規での閲覧はできなくなります。</p>
					<p class="txt_s mb_70">4 当社は、本条に基づく当社の行為により利用者に生じた損害について、当社に故意または重大な過失がある場合を除き責任を負いません。</p>

					<div class="txt_m bold">第18条 連絡方法</div>
					<p class="txt_s">1 利用者は、当社へ問い合わせを行う場合は、当社所定の連絡方法（サービス内の問い合わせフォーム、メール、WEBページの問い合わせフォーム）により行うものとします。</p>
					<p class="txt_s mb_70">2 当社から利用者への連絡は、当社所定の連絡方法（アプリ内のプッシュ通知、メール、書面、電話、SMS等）により行うものとします。</p>

					<div class="txt_m bold">第19条 準拠法および管轄裁判所</div>
					<p class="txt_s">1 本規約は日本語を正文とし、その準拠法は、日本法とします。</p>
					<p class="txt_s mb_70">2
						当社は、本サービスまたは本規約に起因または関連して利用者との間で疑義または争いが生じた場合には、誠意をもって協議することとしますが、それでもなお解決しない場合の当該紛争については、訴額に応じ、東京地方裁判所または東京簡易裁判所を第一審の専属的合意管轄裁判所とします。
					</p>

					<div class="txt_m bold">第20条 その他</div>
					<p class="txt_s">1 利用者は、当社の書面による事前の承諾なく、本規約上の地位または本規約に基づく権利もしくは義務につき、第三者に対し、譲渡、移転、担保設定、その他の処分をすることはできません。</p>
					<p class="txt_s">2
						当社は、本サービスにかかる事業を他社に譲渡した場合には、当該事業譲渡に伴い本規約上の地位、本規約に基づく権利および義務ならびに利用者の登録事項その他の顧客情報を当該事業譲渡の譲受人に譲渡することができるものとし、利用者は、かかる譲渡につき本項において予め同意したものとします。なお、本項に定める事業譲渡には、通常の事業譲渡のみならず、会社分割その他事業が移転するあらゆる場合を含むものとします。
					</p>
					<p class="txt_s mb_70">3
						当社は、利用者が本規約に定める料金等を、支払期限を経過しても支払わない場合、本規約に基づき生じた利用者に対する権利については、第三者に譲渡することができるものとし、利用者は、かかる譲渡について予め同意するものとします。</p>

					<div class="txt_m bold">第20条 分離・存続</div>
					<p class="txt_s">1 本規約の一部が法令に基づいて無効と判断されても、当該規定は合理的な範囲で修正された内容にて当事者間の合意を形成するものとし、その他の規定は引き続き有効とします。</p>
					<p class="txt_s mb_70">2 本規約の一部がある利用者または第三者との関係で無効とされ、または取り消された場合でも、本規約はその他の利用者または第三者との関係では有効とします</p>

					<p class="txt_s mb_10">2020年7月7日 改定</p>
					<div class="col12">
						<div class="nav_txt center">
							｜ <a href="https://imamemo.co.jp/index.html">HOME</a>
						</div>
					</div>
					<div class="txt_ss mb_36">Copyright Imamemo inc. All Rights Reserved. </div>
				</div>           
			</div>
		</section>
	</article>
	<Footer/>
</body>
</template>
<script>
import Footer from '@/components/Footer.vue'
import Header from '@/components/Header.vue'
let searchTimer = undefined;

export default {
	components:{
		Footer,
		Header,
	},
	data() {
        return{
			showSide:false,
			keywords: '', //搜索的绑定值
            showSearchList: false, //显示搜索列表
            searchList: [], //搜索列表
            activeSearch: '', //搜索列表选中
			selectTab: undefined,
			// tabs: [],
		}
	},
	methods:{
		targetSide() {
            this.showSide = !this.showSide
        },
		//Header
         keydownEnter () {
      　　this.toSearchPage()
    　　},
		changeSearchEvent(val) {
            this.keywords = val;
            this.setSearchList();
        },
		 //搜索页
        toSearchPage() {
            if (this.keywords != '') {
                this.$router.push({name: 'search', query: {keywords: this.keywords}})
            }
        },
		 setSearchList() {
            if (this.keywords && this.keywords.trim() != '') {
                clearTimeout(searchTimer)
                searchTimer = setTimeout(() => {
                    if (this.keywords) {
                        Api.getSearch({
                            keywords: this.keywords
                        }).then((res) => {
                            let data = res.result.data
                            if (data.length > 0) {
                                this.searchList = data
                            }
							console.log(this.searchList);
                        });
                    }
                }, 300)
            } else {
                this.searchList = []
            }

        },
		changeTab (val) {
		this.selectTab = val
	　},
	}
}
</script>
<style lang="scss">
	.onerow{
		width: 1300px;
		margin: 0 auto;
        background-color: #FFFFFF;
        .under_Layer{
            padding: 77px 25px;
            line-height: 20px;
            font-size: 12px;
            font-family: 'Microsoft YaHei UI';
            color: #040000;
            font-weight: 300;
        }
	}
	h1{
		text-align: center;
        font-size: 24px;
        font-family:'Segoe UI';
        font-weight: 400 !important;
        // line-height: 0px;
        color: #707070 !important; 
        opacity: 1;
        padding: 5px;
	}
</style>