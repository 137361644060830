import { i18nAddResources } from '@wangeditor/editor'

export default function () {
    // 编辑器 多语言

    // 添加新语言，如日语 ja
    i18nAddResources('ja', {
        // 标题
        goback: "ギャラリーに戻す",
        editTitle:"タイトルの編集"
    })
    i18nAddResources('en', {
        // 标题
        goback: "Put back in the picture library",
        editTitle:"Edit Title"
    })
    i18nAddResources('zh', {
        // 标题
        goback: "放回图片库",
        editTitle:"编辑标题"
    })
}