<!-- eslint-disable vue/no-multiple-template-root -->
<template>
  <div ref="MusicChooseRef" class="music-select-box">
    <span
      @click="openMusicChooseBoxHandler"
      class="iconfont icon-yinleyule icon-music"
      v-if="!hasMusic"
    ></span>
    <div class="icon-music" v-else>
      <!-- 播放 》 -->
      <PlayCircleFilled @click="changePlayHandler" v-show="!musicInfo.isPlay" />
      <!-- 暂停 || -->
      <PauseCircleFilled @click="changePausedHandler" v-show="musicInfo.isPlay" />
    </div>
    <input
      v-show="!hasMusic"
      @click="openMusicChooseBoxHandler"
      class="choose-music-input"
      v-model="chooseMusicValue"
      type="text"
      placeholder="输入歌名/歌词"
    />
    <div class="music-info" v-show="hasMusic">
      <div class="music-player-box">
        <div class="player-top">
          <div class="player-info">
            <div class="song-name-box">
              <p
                class="song-name"
                id="songName"
                :class="{ active: musicInfo.nameRoll && musicInfo.isPlay }"
              >
                {{ musicInfo?.musicName }}
              </p>
            </div>
            <div class="song-author-box">
              <p
                class="song-author"
                id="songAuthor"
                :class="{ active: musicInfo.authorRoll && musicInfo.isPlay }"
              >
                {{ musicInfo?.author }}
              </p>
            </div>
          </div>
          <div class="player-time">
            <span>{{ musicInfo?.currentTime }}</span
            >/<span>{{ musicInfo?.duration }}</span>
          </div>
        </div>
        <div class="player-bottom">
          <a-progress
            :strokeWidth="4"
            :percent="percent"
            status="active"
            :show-info="false"
            strokeColor="rgba(112, 117, 122, 1)"
            trailColor="rgba(238, 238, 238, 1)"
            @mousedown="mouseDownHandler"
            @mouseup="stopMove"
            @mouseleave="stopMove"
            @mousemove="mouseMoveHandler"
          />
          <div class="point"></div>
        </div>
      </div>
      <div class="edit-music-editbox" @click="changeMusicHandler">
        <span class="iconfont icon-bianji icon-choose"></span>
        <span class="choose-music">修改音乐</span>
      </div>
    </div>
    <div
      class="choose-music-editbox"
      @click="openMusicChooseDrawerHandler"
      v-show="!hasMusic"
    >
      <span class="iconfont icon-bianji icon-choose"></span>
      <span class="choose-music">选择音乐</span>
    </div>
    <div class="delete-music" v-show="hasMusic" @click="deleteMusicHandler">
      <span class="iconfont icon-shanchu"></span>
    </div>
    <transition name="bounce">
      <div v-if="isChooseMusic" class="choose-music-box" v-click-outside="outsideHandler">
        <ul class="nav">
          <li
            v-for="item in navList"
            :key="item.id"
            @click="changeMusicCategory(item.role)"
            :class="{ active: musicCategory === item.role }"
          >
            {{ item.name }}
          </li>
        </ul>
        <ul class="music-list">
          <li
            v-for="(item, index) in musicList"
            :key="item.id"
            @mouseenter="mouseEnterHandler(item)"
            @mouseleave="mouseLeaveHandler(item)"
          >
            <div class="music-list-left">
              <div class="controls-btn">
                <PlayCircleFilled @click="playHandler(item)" v-show="!item.isPlay" />
                <PauseCircleFilled @click="pauseHandler(item)" v-show="item.isPlay" />
              </div>
              <span class="idx-text" v-text="indexTransitionHandler(index)"></span>
              <a-tooltip placement="topLeft">
                <template #title>{{ item?.name }}</template>
                <p class="song-name">{{ item?.name }}</p>
              </a-tooltip>
            </div>
            <div class="music-list-right">
              <a-tooltip>
                <template #title>{{ item?.author }}</template>
                <span>{{ item?.author }}</span>
              </a-tooltip>
              <span v-show="!item?.isUse">04:35</span>
              <span v-show="item.isUse">
                <a-button type="primary" @click="useHandler(item)">使用</a-button>
              </span>
            </div>
          </li>
        </ul>
      </div>
    </transition>
  </div>
  <a-drawer
    width="450px"
    class="drawer"
    placement="right"
    :bodyStyle="bodyStyle"
    :closable="false"
    :visible="visible"
    @close="onClose"
  >
    <template #title>
      <p
        style="
          margin: 0;
          font-size: 16px;
          font-family: Microsoft YaHei;
          font-weight: bold;
          line-height: 21px;
          color: #202124;
        "
      >
        音乐
      </p>
    </template>
    <div class="drawer-body">
      <a-input-group compact class="input-box">
        <!-- 歌名 0 ,歌手  1-->
        <a-select v-model:value="searchMusicModel.type" class="input-select">
          <a-select-option :value="0">歌词</a-select-option>
          <a-select-option :value="1">歌手</a-select-option>
        </a-select>
        <a-input
          style="width: 50%"
          v-model:value="searchMusicModel.searchValue"
          placeholder="搜索"
        />
      </a-input-group>
      <ul class="category">
        <li
          v-for="item in categoryList"
          :key="item.id"
          :class="{ active: item.id === searchMusicModel.categoryType }"
          @click="chooseCategoryHandler(item.id)"
        >
          {{ item.name }}
        </li>
      </ul>
      <ul class="song-list">
        <li
          v-for="item in musicList"
          :key="item.id"
          @mouseenter="mouseEnterHandler(item)"
          @mouseleave="mouseLeaveHandler(item)"
        >
          <div class="music-list-left">
            <div class="controls-btn">
              <PlayCircleFilled @click="playHandler(item)" v-show="!item.isPlay" />
              <PauseCircleFilled @click="pauseHandler(item)" v-show="item.isPlay" />
            </div>
            <a-tooltip placement="topLeft">
              <template #title>{{ item?.name }}</template>
              <p class="song-name">{{ item?.name }}</p>
            </a-tooltip>
          </div>
          <div class="music-list-right">
            <a-tooltip>
              <template #title>{{ item?.author }}</template>
              <span>{{ item?.author }}</span>
            </a-tooltip>
            <span v-show="!item?.isUse">04:35</span>
            <span v-show="item.isUse">
              <a-button type="primary" @click="useHandler(item)">使用</a-button>
            </span>
          </div>
        </li>
      </ul>
    </div>
  </a-drawer>
  <audio
    ref="audioRef"
    :src="audioSrc"
    loop
    @canplaythrough="canplaythrough"
    @timeupdate="timeupdate"
  />
</template>
<script>
import { PlayCircleFilled, PauseCircleFilled } from "@ant-design/icons-vue";
import moment from "moment";

export default {
  emits: ["useHandler", "updateData"],
  props: {
    width: {
      type: String,
      default: "730px",
    },
    height: {
      type: String,
      default: "74px",
    },
  },
  components: { PlayCircleFilled, PauseCircleFilled },
  mounted() {
    this.$refs.MusicChooseRef.style.width = this.$props.width;
    this.$refs.MusicChooseRef.style.height = this.$props.height;
    this.musicList.forEach((item) => {
      item.isUse = false;
      item.isPlay = false;
    });
  },
  computed: {
    navList() {
      return [
        { id: "gedan", role: 0, name: "单曲" },
        { id: "geshou", role: 1, name: "歌手" },
      ];
    },
    categoryList() {
      return [
        { id: 0, name: "热歌榜" },
        { id: 1, name: "说唱" },
        { id: 2, name: "摇滚" },
        { id: 3, name: "民族乐" },
        { id: 4, name: "轻音乐" },
      ];
    },
  },
  data() {
    return {
      musicCategory: 0,
      // 伪造
      musicList: [
        {
          id: "472361096",
          name: "Despacito (Remix)",
          author: "Luis Fonsi / Daddy Yankee / Justin Bieber",
          url:
            "https://look-test.s3.ap-northeast-1.amazonaws.com/MP3/Luis+Fonsi%2CDaddy+Yankee%2CJustin+Bieber+-+Despacito+(Remix).flac",
        },
        {
          id: "415793249",
          name: "Can't Stop The Feeling (SAXITY Remix)",
          author: "Saxity / Justin Timberlake / Angie Keilhauer",
          url:
            "https://look-test.s3.ap-northeast-1.amazonaws.com/MP3/Saxity%2CJustin+Timberlake%2CAngie+Keilhauer+-+Can't+Stop+The+Feeling+(SAXITY+Remix).mp3",
        },
        {
          id: "428375694",
          name: "Ain't My Fault",
          author: "Zara Larsson",
          url:
            "https://look-test.s3.ap-northeast-1.amazonaws.com/MP3/Zara+Larsson+-+Ain't+My+Fault.mp3",
        },
        {
          id: "440208643",
          name: "Psycho, Pt. 2",
          author: "Russ",
          url:
            "https://look-test.s3.ap-northeast-1.amazonaws.com/MP3/Russ+-+Psycho%2C+Pt.+2.flac",
        },
        {
          id: "460476510",
          name: "Save Me",
          author: "DEAMN",
          url:
            "https://look-test.s3.ap-northeast-1.amazonaws.com/MP3/DEAMN+-+Save+Me.flac",
        },
        {
          id: "1970396862",
          name: "忏悔录",
          author: "KKECHO / 那奇沃夫 / REDBOI",
          url:
            "https://look-test.s3.ap-northeast-1.amazonaws.com/MP3/KKECHO%2C%E9%82%A3%E5%A5%87%E6%B2%83%E5%A4%AB%2CREDBOI+-+%E5%BF%8F%E6%82%94%E5%BD%95.flac",
        },
        {
          id: "1878024978",
          name: "See You Again",
          author: "Peaceful Melody / soave lofi",
          url:
            "https://look-test.s3.ap-northeast-1.amazonaws.com/MP3/Peaceful+Melody%2Csoave+lofi+-+See+You+Again.flac",
        },
        {
          id: "1337352077",
          name: "如果，你等我",
          author: "张明旭",
          url:
            "https://look-test.s3.ap-northeast-1.amazonaws.com/MP3/%E5%BC%A0%E6%98%8E%E6%97%AD+-+%E5%A6%82%E6%9E%9C%EF%BC%8C%E4%BD%A0%E7%AD%89%E6%88%91.flac",
        },
        {
          id: "1336789769",
          name: "玫瑰 (Live)",
          author: "亚森",
          url:
            "https://look-test.s3.ap-northeast-1.amazonaws.com/MP3/%E4%BA%9A%E6%A3%AE+-+%E7%8E%AB%E7%91%B0+(Live).flac",
        },
        {
          id: "1902687013",
          name: "lovestory （小提琴版）",
          author: "JOHN",
          url:
            "https://look-test.s3.ap-northeast-1.amazonaws.com/MP3/JOHN+-+lovestory+%EF%BC%88%E5%B0%8F%E6%8F%90%E7%90%B4%E7%89%88%EF%BC%89.flac",
        },
        {
          id: "1818083944",
          name: "在这深夜里",
          author: "贺仙人",
          url:
            "https://look-test.s3.ap-northeast-1.amazonaws.com/MP3/%E8%B4%BA%E4%BB%99%E4%BA%BA+-+%E5%9C%A8%E8%BF%99%E6%B7%B1%E5%A4%9C%E9%87%8C.flac",
        },
        {
          id: "1294951288",
          name: "溯 (Reverse)",
          author: "CORSAK胡梦周/马吟吟",
          url:
            "https://look-test.s3.ap-northeast-1.amazonaws.com/MP3/CORSAK%E8%83%A1%E6%A2%A6%E5%91%A8%2C%E9%A9%AC%E5%90%9F%E5%90%9F+-+%E6%BA%AF+(Reverse).flac",
        },
        {
          id: "28713832",
          name: "기억이 머물던 곳",
          author: "July",
          url:
            "https://look-test.s3.ap-northeast-1.amazonaws.com/MP3/July+-+%EA%B8%B0%EC%96%B5%EC%9D%B4+%EB%A8%B8%EB%AC%BC%EB%8D%98+%EA%B3%B3.flac",
        },
        {
          id: "18495491",
          name: "Wild Side",
          author: "Roberto Cacciapaglia",
          url:
            "https://look-test.s3.ap-northeast-1.amazonaws.com/MP3/Roberto+Cacciapaglia+-+Wild+Side.flac",
        },
        {
          id: "1493077219",
          name: "Falling Down",
          author: "Wild Cards / James Delaney",
          url:
            "https://look-test.s3.ap-northeast-1.amazonaws.com/MP3/Wild+Cards%2CJames+Delaney+-+Falling+Down.flac",
        },
        {
          id: "3935191",
          name: "Sail",
          author: "AWOLNATION",
          url:
            "https://look-test.s3.ap-northeast-1.amazonaws.com/MP3/AWOLNATION+-+Sail.mp3",
        },
        {
          id: "1814085839",
          name: "迪迦!!!",
          author: "six nine",
          url:
            "https://look-test.s3.ap-northeast-1.amazonaws.com/MP3/six+nine+-+%E8%BF%AA%E8%BF%A6+(DJ%E6%97%8B%E5%BE%8B%E7%89%88).flac",
        },
        {
          id: "29095230",
          name: "ツナ覚醒",
          author: "佐橋俊彦",
          url:
            "https://look-test.s3.ap-northeast-1.amazonaws.com/MP3/%E4%BD%90%E6%A9%8B%E4%BF%8A%E5%BD%A6+-+%E3%83%84%E3%83%8A%E8%A6%9A%E9%86%92.flac",
        },
        {
          id: "436346833",
          name: "凄美地",
          author: "郭顶",
          url:
            "https://look-test.s3.ap-northeast-1.amazonaws.com/MP3/%E9%83%AD%E9%A1%B6+-+%E5%87%84%E7%BE%8E%E5%9C%B0.flac",
        },
        {
          id: "1472538276",
          name: "Numb",
          author: "Arc North / Aaron Richards / New Beat Order / Cour",
          url:
            "https://look-test.s3.ap-northeast-1.amazonaws.com/MP3/Arc+North%2CAaron+Richards%2CNew+Beat+Order+-+Numb.flac",
        },
        {
          id: "1439181436",
          name: "Relax",
          author: "Junona Boys",
          url:
            "https://look-test.s3.ap-northeast-1.amazonaws.com/MP3/Junona+Boys+-+Relax.flac",
        },
      ],
      isChooseMusic: false,
      chooseMusicValue: "",
      visible: false,
      searchMusicModel: {
        type: 0,
        searchValue: "",
        categoryType: 0,
      },
      bodyStyle: {
        display: "flex",
        padding: "12px 20px",
      },
      hasMusic: false,
      musicInfo: {
        musicName: "我的朋友我的朋友我的朋友我的朋友我的朋友",
        author: "毛不易",
        id: "",
        nameRoll: false,
        authorRoll: false,
        isPlay: false,
        currentTime: "00:00",
        duration: "00:00",
        durationNum: null,
      },
      isMove: false,
      percent: 0,
      audioSrc: "",
      musicInit: false,
    };
  },
  methods: {
    changeMusicCategory(category) {
      // catagory : 0 - 根据单曲分类  /  1 - 根据歌手分类
      this.musicCategory = category;
    },
    indexTransitionHandler(index) {
      if (index < 9) {
        return "0" + (index + 1);
      } else {
        return (index + 1).toString();
      }
    },
    outsideHandler(event) {
      if (
        document.querySelector(".music-select-f").contains(event.target) ||
        document.querySelector(".edit-music-editbox").contains(event.target)
      )
        return;
      this.isChooseMusic = false;
    },
    openMusicChooseBoxHandler() {
      this.isChooseMusic = true;
    },
    //抽屉
    openMusicChooseDrawerHandler() {
      this.visible = true;
    },
    onClose() {
      this.visible = false;
    },
    chooseCategoryHandler(categoryType) {
      this.searchMusicModel.categoryType = categoryType;
    },
    //播放器相关
    playHandler(item) {
      this.musicList.forEach((item1) => {
        item1.isPlay = false;
      });
      item.isPlay = true;
      this.musicInfo.isPlay = true;
      this.audioSrc = item?.url;
      if (!this.musicInit) this.musicInit = true;
      if (this.audioSrc !== item?.url) this.audioSrc = item?.url;
      this.$refs.audioRef.removeEventListener("canplaythrough", this.playAudio);
      this.$refs.audioRef.load();
      this.isPaly = true;
      this.$refs.audioRef.addEventListener("canplaythrough", this.playAudio);
    },
    playAudio() {
      if (this.$refs.audioRef.readyState >= this.$refs.audioRef.HAVE_CURRENT_DATA) {
        this.$refs.audioRef.play();
      }
      this.$emit("updateData", { isPlay: "true",isInit:true });
    },
    pauseHandler(item) {
      item.isPlay = false;
      this.$refs.audioRef.pause();
      this.$emit("updateData", { isPlay: "false" });
    },
    mouseEnterHandler(item) {
      if (item.isUse === true) return;
      item.isUse = true;
    },
    mouseLeaveHandler(item) {
      if (item.isUse === false) return;
      item.isUse = false;
    },
    useHandler(item) {
      this.playHandler(item);
      this.chooseMusicValue = item.name;
      this.$emit("useHandler", { ...item, chooseMusicValue: this.chooseMusicValue });
      this.isChooseMusic = false;
      this.visible = false;
      // 同步信息
      this.hasMusic = true;
      this.musicInfo.musicName = item?.name;
      this.musicInfo.author = item?.author;
      this.musicInfo.id = item?.id;
      this.$nextTick(() => {
        this.checkoutRoll(true);
      });
      this.itemInfo = item;
    },
    //可拖动播放器相关
    mouseDownHandler(e) {
      this.isMove = true;
      if (!this.isMove) return;
      const rect = e.target.getBoundingClientRect();
      console.log(event.clientX, rect.left);
      const mouseX = event.clientX - rect.left;
      const progress = (mouseX / rect.width) * 100;
      this.percent = progress;
      const a = (mouseX / rect.width).toFixed(2);
      const b = this.musicInfo?.durationNum.toFixed(2);
      const currentTime = parseFloat(a) * parseFloat(b).toFixed(2);
      this.$refs.audioRef.currentTime = currentTime;
    },
    stopMove() {
      this.isMove = false;
    },
    mouseMoveHandler(e) {
      if (!this.isMove) return;
      const rect = e.target.getBoundingClientRect();
      console.log(event.clientX, rect.left);
      const mouseX = event.clientX - rect.left;
      const progress = (mouseX / rect.width) * 100;
      this.percent = progress;
      const a = (mouseX / rect.width).toFixed(2);
      const b = this.musicInfo?.durationNum.toFixed(2);
      const currentTime = parseFloat(a) * parseFloat(b).toFixed(2);
      this.$refs.audioRef.currentTime = currentTime;
      console.log("拖动的播放时间为===》", currentTime);
    },

    //播放器功能
    checkoutRoll(isPlay) {
      const width = 64;
      const nameDom = document.getElementById("songName");
      const authorDom = document.getElementById("songAuthor");
      const nameDomWidth = nameDom.getBoundingClientRect()?.width;
      const authorDomWidth = authorDom.getBoundingClientRect()?.width;
      console.log(nameDom, authorDom);
      this.musicInfo.nameRoll = nameDomWidth > width;
      this.musicInfo.authorRoll = authorDomWidth > width;
      this.musicInfo.isPlay = isPlay;
    },
    changePlayHandler() {
      this.musicInfo.isPlay = !this.musicInfo.isPlay;
      if (this.$refs.audioRef.readyState >= this.$refs.audioRef.HAVE_CURRENT_DATA) {
        this.$refs.audioRef.play();
      }
      this.$emit("updateData", { isPlay: "true" });
    },
    changePausedHandler() {
      this.musicInfo.isPlay = !this.musicInfo.isPlay;
      this.$refs.audioRef.pause();
      this.$emit("updateData", { isPlay: "false" });
    },
    changeMusicHandler() {
      this.isChooseMusic = true;
      this.hasMusic = false;
      this.changePausedHandler();
    },
    deleteMusicHandler() {
      this.isChooseMusic = false;
      this.hasMusic = false;
      this.changePausedHandler();
    },
    //aduio事件
    canplaythrough(e) {
      const duration = e.target.duration;
      console.log("当前总时间 ===>", duration);
      const time = this.secondsToMinutes(duration);
      console.log("当前格式化后总时间 ===》", time);
      this.musicInfo.duration = time;
      this.musicInfo.durationNum = duration;
    },
    timeupdate(e) {
      const currentTime = e.target.currentTime;
      const duration = e.target.duration;
      const percentage = (currentTime / duration) * 100;
      this.percent = percentage;
      // console.log('当前播放时间 ===>', currentTime)
      const time = this.secondsToMinutes(parseInt(currentTime));
      // console.log('当前格式化后时间 ===》', time)
      this.musicInfo.currentTime = time;
    },
    // 时间转换功能
    secondsToMinutes(seconds) {
      const duration = moment.duration(seconds, "seconds");
      const formattedTime = moment.utc(duration.asMilliseconds()).format("mm:ss");
      return formattedTime;
    },
  },
};
</script>
<style lang="scss" scoped>
.music-select-box {
  background-color: red;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px 0px 6px rgba(50, 86, 120, 0.1);
  border-radius: 8px;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  padding: 0 40px 0 20px;
  position: relative;

  .icon-music {
    font-size: 34px;
    color: rgba(112, 117, 122, 1);
    margin-right: 21px;
  }

  .choose-music-input {
    height: 34px;
    width: 100%;
    border: 0;
    outline: none;
    padding-left: 17px;
    border-right: 1px solid rgba(238, 238, 238, 1);

    &::placeholder {
      color: rgba(153, 153, 153, 1);
      font-size: 16px;
    }
  }

  .music-info {
    flex-grow: 1;
    height: 100%;
    display: flex;
    justify-content: space-between;

    .music-player-box {
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;

      .player-top {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .player-info {
          display: flex;

          p {
            margin: 0;
            white-space: nowrap;
            text-align: center;
          }

          .song-name-box {
            width: 64px;
            overflow: hidden;
            display: flex;
            align-items: center;
            margin-right: 8px;

            .song-name {
              font-size: 16px;
              font-family: Microsoft YaHei;
              font-weight: 400;
              line-height: 21px;
              color: #202124;

              &.active {
                animation: huadong 10s infinite linear;
              }
            }
          }

          .song-author-box {
            width: 64px;
            overflow: hidden;
            display: flex;
            align-items: center;
            margin-right: 8px;

            .song-author {
              font-size: 12px;
              font-family: Microsoft YaHei;
              font-weight: 300;
              line-height: 16px;
              color: #cccccc;

              &.active {
                animation: huadong 10s infinite linear;
              }
            }
          }

          @keyframes huadong {
            0% {
              transform: translateX(0);
            }

            100% {
              transform: translateX(-100%);
            }
          }
        }

        .player-time {
          align-self: end;
          font-size: 12px;
          font-family: Microsoft YaHei;
          font-weight: 300;
          line-height: 16px;
          color: #3c4043;
        }
      }

      .player-bottom {
        :deep(.ant-progress-outer) {
          height: 4px;
        }
      }
    }

    .edit-music-editbox {
      flex-shrink: 0;
      width: 80px;
      display: flex;
      align-items: center;
      margin-left: 44px;
      margin-right: 20px;

      .icon-choose {
        font-size: 14px;
        color: rgba(8, 112, 209, 1);
        margin-right: 10px;
        cursor: pointer;
      }

      .choose-music {
        flex-shrink: 0;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        line-height: 19px;
        color: #0870d1;
        cursor: pointer;
      }
    }
  }

  .choose-music-editbox {
    flex-shrink: 0;
    width: 80px;
    display: flex;
    margin-left: 40px;

    .icon-choose {
      font-size: 14px;
      color: rgba(8, 112, 209, 1);
      margin-right: 10px;
      cursor: pointer;
    }

    .choose-music {
      flex-shrink: 0;
      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      line-height: 19px;
      color: #0870d1;
      cursor: pointer;
    }
  }

  .delete-music {
    height: 22px;
    padding-left: 20px;
    border-left: 1px solid rgba(238, 238, 238, 1);

    span {
      cursor: pointer;
    }
  }

  .choose-music-box {
    position: absolute;
    width: 100%;
    height: 236px;
    top: calc(100% + 5px);
    left: 0;
    z-index: 1;
    border-radius: 8px;
    background: rgba(255, 255, 255, 1);
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
    box-sizing: border-box;
    padding: 10px 20px;

    .nav {
      display: flex;
      margin: 0;
      border-bottom: 1px solid rgba(238, 238, 238, 1);

      li {
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        line-height: 21px;
        margin-right: 80px;
        padding-bottom: 10px;
        cursor: pointer;

        &.active {
          color: rgba(8, 112, 209, 1);
          font-weight: bold;
        }
      }
    }

    .music-list {
      margin: 0;
      height: 176px;
      overflow: auto;
      box-sizing: border-box;
      padding-top: 5px;

      li {
        height: 43px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        &:hover {
          background-color: rgba(248, 248, 248, 1);
        }

        .music-list-left {
          display: flex;
          align-items: center;

          .controls-btn {
            font-size: 16px;
            color: rgba(112, 117, 122, 1);
            margin-left: 12px;
            cursor: pointer;
          }

          .idx-text {
            margin: 0 10px;
            color: rgba(112, 117, 122, 1);
          }

          .song-name {
            width: 310px;
            margin: 0;
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            line-height: 19px;
            color: #3c4043;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            box-sizing: border-box;
            padding-right: 10px;
          }
        }

        .music-list-right {
          flex-shrink: 0;
          width: 316px;
          height: 100%;
          display: flex;
          align-items: center;

          span {
            flex: 1;
            flex-shrink: 0;
            text-align: center;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            font-size: 12px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            line-height: 16px;
            color: #cccccc;

            :deep(.ant-btn-primary) {
              padding: 0;
              width: 50px;
              height: 24px;
              background: rgba(8, 112, 209, 1);
              border-radius: 8px;
              font-size: 12px;
              font-family: Microsoft YaHei;
              font-weight: 400;
              color: #ffffff;
            }
          }
        }
      }
    }
  }
}

.drawer {
  margin: 0;

  .drawer-body {
    width: 100%;

    .input-box {
      display: flex;

      .input-select {
        background-color: rgba(248, 248, 248, 1);
      }

      :deep(.ant-select-selector) {
        background-color: rgba(248, 248, 248, 1);
      }

      input {
        flex-grow: 1;
        background-color: rgba(248, 248, 248, 1);
      }
    }

    ul {
      margin: 0;
    }

    .category {
      width: 100%;
      display: flex;
      padding: 40px 30px 10px;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid rgba(238, 238, 238, 1);

      li {
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        line-height: 19px;
        color: #9e9e9e;
        cursor: pointer;

        &.active {
          font-family: Microsoft YaHei;
          font-weight: 400;
          line-height: 19px;
          color: #202124;
          font-weight: bold;
        }
      }
    }

    .song-list {
      width: 100%;
      height: 720px;
      box-sizing: border-box;
      overflow: hidden;
      margin: 18px 0;
      overflow: auto;

      li {
        display: flex;
        height: 54px;
        padding: 20px;
        box-sizing: border-box;

        &:hover {
          background-color: rgba(248, 248, 248, 1);
        }

        .music-list-left {
          display: flex;
          align-items: center;

          .controls-btn {
            font-size: 24px;
            cursor: pointer;
          }

          p {
            margin: 0;
          }

          .song-name {
            width: 128px;
            margin: 0;
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            line-height: 19px;
            color: #3c4043;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            box-sizing: border-box;
            margin: 0 16px;
          }
        }

        .music-list-right {
          flex-shrink: 0;
          width: 208px;
          height: 100%;
          display: flex;
          align-items: center;

          span {
            flex: 1;
            flex-shrink: 0;
            text-align: center;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            font-size: 12px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            line-height: 16px;
            color: #cccccc;

            :deep(.ant-btn-primary) {
              padding: 0;
              width: 50px;
              height: 24px;
              background: rgba(8, 112, 209, 1);
              border-radius: 8px;
              font-size: 12px;
              font-family: Microsoft YaHei;
              font-weight: 400;
              color: #ffffff;
            }
          }
        }
      }
    }
  }
}

.bounce-enter-active {
  animation: bounce-in 0.3s;
}

.bounce-leave-active {
  animation: bounce-in 0.3s reverse;
}

@keyframes bounce-in {
  0% {
    transform: scale(0);
  }

  100% {
    transform: scale(1);
  }
}
</style>
