<!-- eslint-disable vue/no-multiple-template-root -->
<template>
  <Header :tabs="tabs" :selectTab="selectTab" :showSearch="showSearch" @changeTab="changeTab" :value="keywords"
    @keydownEnter="keydownEnter" @changeSearchEvent="changeSearchEvent" />
  <div class="bg">
    <div class="container">
      <div class="mine-center-div">
        <div class="mine-center-left">
          <img class="img-user" :src="info.userLogo ? info.userLogo : require('@/assets/avatar.svg')" alt="">
          <div class="user-name-id">
            <a-tooltip placement="topLeft" :title="info.userName">
              <div class="user-name">
                {{ info.userName || "默认账户昵称" }}
              </div>
            </a-tooltip>
            <a-tooltip placement="bottomLeft" :title="info.userId">
              <div class="user-id">{{ info.userId || "默认用户ID" }}</div>
            </a-tooltip>
          </div>
        </div>

        <div class="mine-center-right">
          <ul>
            <li>
              <div>
                <button :style="'color:' + (clickText === 'my_create' ? '#1673CE' : '#333333')" class="nums"
                  @click="create('my_create')">{{ info.create }}</button>
              </div>
              <div>
                <button class="item-text" :style="'color:' + (clickText === 'my_create' ? '#1673CE' : '#666666')"
                  @click="create('my_create')">{{ $t('myInfo.create') }}</button>
              </div>
            </li>
            <li>
              <div>
                <button :style="'color:' + (clickText === 'my_favorite' ? '#1673CE' : '#333333')" class="nums"
                  @click="create('my_favorite')">{{ info.favorite }}</button>
              </div>
              <div>
                <button @click="create('my_favorite')" class="item-text"
                  :style="'color:' + (clickText === 'my_favorite' ? '#1673CE' : '#666666')">{{ $t('myInfo.favorite')
                  }}</button>
              </div>
            </li>
            <li>
              <div>
                <button :style="'color:' + (clickText === 'my_follow' ? '#1673CE' : '#333333')" class="nums"
                  @click="create('my_follow')">{{ info.follow }}</button>
              </div>
              <div>
                <button @click="create('my_follow')" class="item-text"
                  :style="'color:' + (clickText === 'my_follow' ? '#1673CE' : '#666666')">{{ $t('myInfo.follow')
                  }}</button>
              </div>
            </li>
            <li>
              <div>
                <button :style="'color:' + (clickText === 'my_history' ? '#1673CE' : '#333333')" class="nums"
                  @click="create('my_history')">{{ info.history }}</button>
              </div>
              <div>
                <button @click="create('my_history')" class="item-text"
                  :style="'color:' + (clickText === 'my_history' ? '#1673CE' : '#666666')">{{ $t('myInfo.history')
                  }}</button>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="home-box" @scroll="scrollEvent">
      <div class="container-center" style="margin-top: 10px">
        <div class="list-center" :style="{ minHeight: clientHeight }">
          <div class="list-left">
            <!-- <p v-for="(item,index) in 999" :key="index">{{ index+1 }}</p> -->
            <List :involveSeacrchList="[]" :datas="datas" :nowIndex="currentIndex" :isMyInfo="true" />
            <div class="list-loading" v-if="loading">
              <span></span>
              <span></span>
              <span></span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <Footer />
  <Login :visible="visible" :closeModal="closeModal" />
</template>

<script>

import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue';
import List from '@/components/List/index.vue'
import Api from "../Api";
import storage from "storejs";
// import $ from 'jquery';
import Login from '@/views/Login.vue'
let searchTimer = undefined;
import eventBus from '@/utils/eventBus.js';


export default {
  name: "MyInfo",
  components: {
    Header,
    Footer,
    Login,
    List
  },
  data() {
    return {
      treeId: null,
      imageId: null,
      clientHeight: "1000px",
      visible: false,
      page: 1,
      totalPage: 1,
      pageSize: 20,
      //栏目点击
      clickText: '',
      loading: false,
      datas: [],
      showSearch: true,
      tabs: [],
      selectTab: undefined,
      keywords: undefined,
      info: {
        userId: undefined,
        userLogo: undefined,
        userName: undefined,
        create: 0,
        favorite: 0,
        history: 0,
        follow: 0
      },
      currentIndex: null
    };
  },
  created() {
    eventBus.on('updateLocationUser', async () => {
      await this.getLocationUser();
      const { result: { data: { totalSize } } } = await Api.getfollow('my_follow', this.page, this.pageSize)
      this.info.follow = totalSize;
    })
    eventBus.on('updateList', (params) => {
      console.log('更新主页===>', params)
      const url = window.URL.createObjectURL(params.file)
      this.datas.forEach(item => {
        if (item.pictureId === params.pictureId) item.titlePicture = { ...item, pictureAddress: url }
      })
    })
  },
  mounted() {
    this.clientHeight = (document.documentElement.clientHeight + 100) + "px";
    this.page = 1;
    this.totalPage = 1;
    this.clickText = 'my_create'
    this.loading = true
    this.getLocationUser();
    this.getPicturecontent(this.clickText)
    Api.getfollow('my_follow', this.page, this.pageSize)
      .then(({ result: { data } }) => {
        console.log('my_follow ==》', data.totalSize)
        this.info.follow =data.totalSize;
      })
  },
  methods: {
    addComment(type, content = undefined) {
      if (this.checkLogin()) {
        return;
      }
      if (!content) {
        return;
      }
      let userInfo = storage.get('userInfo') || '';
      if (userInfo) {
        let params = {
          userId: userInfo.userid,
          treeId: this.treeId,
          imageId: this.imageId,
          content: content,
          type: type
        }
        this.replyInfo = {
          commentIndex: undefined,
          replyContent: undefined,
          commentId: undefined,
          replyUserId: undefined,
        };
        console.log("add comment params ==>", params);
        Api.addComment(params).then((res) => {

          if (res.error_code == 0) {
            this.$message.success(this.$t('detail.commentSuccess'));
            this.visibleSecond = false
          }
        })
      }
    },
    create(str) {
      this.datas = []
      this.page = 1
      this.totalPage = 1
      this.loading = true
      this.clickText = str
      this.getList(str);
    },
    getList(str) {
      if (str === 'my_follow') {
        this.getfollow()
      } else {
        this.getPicturecontent(str)
      }
    },
    // >>>>项目二开，下一次是三开了吧...我到此一游...哈哈哈...<<<<
    //获取图文列表
    async getPicturecontent(str) {
      try {
        if (this.totalPage < this.page) {
          return;
        }
        const { result: { data }, error_code } = await Api.getPicturecontent(str, this.page, this.pageSize);
        if (error_code !== 0) return;
        console.log(data)
        const list = data.list.reduce((result, item) => {
          result.push({ ...item, title: item.entryTitle, isShow: true })
          return result
        }, [])
        this.datas = this.datas.concat(list)
        this.totalPage = data.totalPageCount;
        this.page++;
        if (str == 'my_create') {
          this.info.create = data.totalSize || 0;
        }
      }
      catch (err) {
        console.log("Get Entry Page ==> ", err)
      } finally {
        this.loading = false
      }
    },
    async getfollow() {
      try {
        if (this.totalPage < this.page) {
          return;
        }
        const { error_code, result: { data } } = await Api.getfollow('my_follow', this.page, this.pageSize)
        if (error_code !== 0) return;
        const list = data.list.reduce((result, item) => {
          if (!item.lastPicture) {
            result.push({ ...item.lastPicture, entryId: item.id, isFollow: item.isFollow, title: item.title, isShow: true, insertUser: item.insertUser })
          } else {
            result.push({ ...item.lastPicture, entryId: item.id, isFollow: item.isFollow, title: item.title, isShow: true })
          }
          return result
        }, [])
        this.datas = this.datas.concat(list)
        this.totalPage = data.totalPageCount;
        this.page++;
      } catch (err) {
        console.log("Get Entry Page ==> ", err)
      } finally {
        this.loading = false
      }
    },

    checkLogin() {
      this.visible = !localStorage.getItem('token');
      return this.visible;
    },

    closeModal() {
      this.visible = false;
    },
    changeTab(val) {
      this.selectTab = val
    },

    //Header
    keydownEnter() {
      this.toSearchPage()
    },
    changeSearchEvent(val) {
      this.keywords = val;
      this.setSearchList();
    },
    //搜索页
    toSearchPage() {
      if (this.keywords != '') {
        this.$router.push({ name: 'search', query: { keywords: this.keywords } })
      }
    },
    //搜索事件
    setSearchList() {
      if (this.keywords && this.keywords.trim() != '') {
        clearTimeout(searchTimer)
        searchTimer = setTimeout(() => {
          Api.getSearch({
            keywords: this.keywords
          }).then((res) => {
            let data = res.result.data.map((item) => item.title);
            if (data.length > 0) {
              this.searchList = data
            }
          })
        }, 300)
      } else {
        this.searchList = []
      }

    },
    //获取关注收藏列表
    getLocationUser() {
      let userInfo = storage.get('userInfo') || '';
      if (userInfo) {
        this.info = {
          ...this.info,
          userId: userInfo.userid,
          userLogo: userInfo.avatar,
          userName: userInfo.username,
        }
        Api.getLocationUser().then((res) => {

          if (res.error_code == 0) {
            let data = res.result.data;
            this.info = {
              ...this.info,
              // create: data.create || 0,
              favorite: data.favorite || 0,
              history: data.history || 0,
              // follow: data.follow || 0,
            }
          }
        });
      }
    },
    //滚动记录
    scrollEvent(event) {
      // 获取滚动容器的滚动距离。
      let scrollTop = event.target.scrollTop
      // 根据视窗高度计算出一个值。

      if (!this.loading) {
        if (event.target.scrollHeight < (event.target.scrollTop + event.target.clientHeight + 30)) {
          this.loading = true;
          this.isTop = false;
          // this.getList();
          console.log('加载更多')
          this.getList(this.clickText)
        }
      }

      const windowElement = event.target; // 获取滚动窗口元素
      const listItemElements = windowElement.querySelectorAll('.item-list');

      //记录滚动条数值
      // console.log(scrollTop)

      for (let i = 0; i < listItemElements.length; i++) {
        //遍历每一项元素
        const listItem = listItemElements[i];
        // 每一项元素的相对父元素的高度
        const offsetTop = listItem.offsetTop;
        //每一项元素的高度
        const offsetHeight = listItem.offsetHeight;
        // 滚动距离 比 元素距离顶部高度大，并且滚动距离小于 元素距离顶部高度加元素的高度
        if (scrollTop >= offsetTop && scrollTop < offsetTop + offsetHeight + 52) {
          this.currentIndex = i + 1
          break;
        } else if (scrollTop < offsetHeight && scrollTop < offsetTop + offsetHeight) {
          this.currentIndex = 0
        }

      }
    }
  },
  beforeUnmount() {
    console.log('我被销毁了')
    eventBus.off('updateLocationUser');
    eventBus.off('updateList');
  }
}
</script>

<style scoped lang="scss">
.container {
  width: 940px;
  padding-top: 72px;
}

.bg {
  background: #f2f4f7;
  min-height: calc(100vh);
}

.mine-center-div {
  background: #ffffff;
  display: flex;
  padding: 15px;

  .mine-center-left {
    display: flex;
    align-items: center;
    margin-right: 20px;
    width: 350px;
    margin-left: 20px;

    .img-user {
      margin-right: 26px;
      width: 75px;
      height: 75px;
    }

    .user-name-id {
      display: flex;
      flex-direction: column;

      /*align-items: center;*/
      /*max-width: 215px;*/
      //   margin-left: 26px;
      .user-name {
        width: fit-content;
        max-width: 215px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        font-size: 20px;
        font-weight: bold;
        line-height: 26px;
        color: #333333;
      }

      .user-id {
        width: fit-content;
        max-width: 215px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        margin-top: 13px;
        font-size: 16px;
        font-weight: 400;
        line-height: 21px;
        color: #666666;
      }
    }
  }

  .mine-center-right {
    margin-left: -32px;
    flex: 1;

    ul {
      height: 100%;
      display: flex;
      align-items: center;

      li {
        width: 152px;
        text-align: center;
        position: relative;

        &::after {
          display: inline-block;
          content: "";
          width: 2px;
          height: 26px;
          background: #eeeeee;
          position: absolute;
          right: 0;
          top: 0;
          bottom: 0;
          margin: auto;
        }

        &:last-child {
          &::after {
            display: none;
          }
        }
      }

      .nums {
        cursor: pointer;
        background-color: unset;
        border: none;
        font-size: 22px;
        font-weight: bold;
        line-height: 29px;
        color: #333333;
      }

      .item-text {
        cursor: pointer;
        background-color: unset;
        border: none;
        margin-top: 10px;
        font-size: 16px;
        font-weight: 400;
        line-height: 21px;
        color: #666666;
      }
    }
  }
}


@media (max-width: 840px) {
  .container {
    width: 100%;
    margin: 0 auto;
    padding-left: 30px;
    padding-right: 30px;
  }

  .mine-center-div {
    display: block;

    .mine-center-left {
      width: 100%;
      display: flex;
      flex-direction: column;
      text-align: center;
      margin-left: 0px;

      .user-name-id {
        align-items: center !important;
        max-width: 100% !important;

        .user-name {
          max-width: 100% !important;
        }

        .user-id {
          max-width: 100% !important;
        }
      }

      .img-user {
        margin-right: 0;
      }
    }

    .mine-center-right {
      margin-left: 0;
      margin-top: 30px;

      ul {
        display: block;

        li {
          width: 100%;
          display: block;
          height: 48px;
          padding-top: 12px;

          border-bottom: 1px solid #eeeeee;

          &::after {
            display: none;
          }

          .nums {
            float: right;
            color: #cccccc;
          }

          .item-text {
            float: left;
            margin-top: 0;
            line-height: 29px;
          }
        }
      }
    }
  }
}

.list-loading {
  display: flex;
  align-items: center;
  margin-top: 20px;
  height: 40px;
  justify-content: center;

  >span {
    display: inline-block;
    width: 16px;
    height: 16px;
    margin-right: 6px;
    border-radius: 50%;
    background: #ebebeb;
    -webkit-animation: load 1.04s ease infinite;

    &:last-child {
      margin-right: 0px;
    }
  }

  @-webkit-keyframes load {
    0% {
      opacity: 1;
    }

    100% {
      opacity: 0;
    }
  }

  span:nth-child(1) {
    -webkit-animation-delay: 0.13s;
  }

  span:nth-child(2) {
    -webkit-animation-delay: 0.26s;
  }

  span:nth-child(3) {
    -webkit-animation-delay: 0.39s;
  }
}

.list-left {
  width: 840px;
  margin: 0 auto;

  .list-loading {
    display: flex;
    align-items: center;
    margin-top: 20px;
    height: 40px;
    justify-content: center;

    >span {
      display: inline-block;
      width: 16px;
      height: 16px;
      margin-right: 6px;
      border-radius: 50%;
      background: #ebebeb;
      -webkit-animation: load 1.04s ease infinite;

      &:last-child {
        margin-right: 0px;
      }
    }

    @-webkit-keyframes load {
      0% {
        opacity: 1;
      }

      100% {
        opacity: 0;
      }
    }

    span:nth-child(1) {
      -webkit-animation-delay: 0.13s;
    }

    span:nth-child(2) {
      -webkit-animation-delay: 0.26s;
    }

    span:nth-child(3) {
      -webkit-animation-delay: 0.39s;
    }
  }
}

.list-center {
  margin-top: 20px;
  display: flex;

  .list-left {
    width: 840px;

    .list-loading {
      display: flex;
      align-items: center;
      margin-top: 20px;
      height: 40px;
      justify-content: center;

      >span {
        display: inline-block;
        width: 16px;
        height: 16px;
        margin-right: 6px;
        border-radius: 50%;
        background: #ebebeb;
        -webkit-animation: load 1.04s ease infinite;

        &:last-child {
          margin-right: 0px;
        }
      }

      @-webkit-keyframes load {
        0% {
          opacity: 1;
        }

        100% {
          opacity: 0;
        }
      }

      span:nth-child(1) {
        -webkit-animation-delay: 0.13s;
      }

      span:nth-child(2) {
        -webkit-animation-delay: 0.26s;
      }

      span:nth-child(3) {
        -webkit-animation-delay: 0.39s;
      }
    }
  }
}

.container-center {
  margin-top: 20px;

  .list-center {
    margin-top: 20px;
    display: flex;

    .list-left {
      width: 840px;

      .list-loading {
        display: flex;
        align-items: center;
        margin-top: 20px;
        height: 40px;
        justify-content: center;

        >span {
          display: inline-block;
          width: 16px;
          height: 16px;
          margin-right: 6px;
          border-radius: 50%;
          background: #ebebeb;
          -webkit-animation: load 1.04s ease infinite;

          &:last-child {
            margin-right: 0px;
          }
        }

        @-webkit-keyframes load {
          0% {
            opacity: 1;
          }

          100% {
            opacity: 0;
          }
        }

        span:nth-child(1) {
          -webkit-animation-delay: 0.13s;
        }

        span:nth-child(2) {
          -webkit-animation-delay: 0.26s;
        }

        span:nth-child(3) {
          -webkit-animation-delay: 0.39s;
        }
      }
    }
  }
}

@media (max-width: 840px) {
  .home-box .container {
    width: 100%;
    padding: 0 20px;
  }

  .home-box .container .container-center .list-center .list-left {
    width: 100%;
  }

  .home-box .container .container-center .list-center {
    display: none;
  }

  .home-box .container .container-center .list-center .list-left .item-list .item-icon-tabs {
    display: none;
  }

  .home-box .container .container-center .list-center .list-left .item-list .item-info {
    display: block;

    .info-right {
      >span {
        margin-left: 0;
        margin-right: 25px;
      }

      .item-icon-tabs {
        display: block;
        position: absolute;
        right: 0;
        top: 2px;
      }
    }
  }
}
</style>
