import request, { post, CONTENT_TYPE } from '../utils/request';
import qs from 'qs'
const UserApi = {
    // 修改个人头像
    updateUserAvatar: (userId, avatar) => {
        let params = {
            userid: userId,
            avatar: avatar
        }
        return post(`/user/updateUserAvatar`, params, CONTENT_TYPE.jsonHeader)
    },
    // 修改个人昵称
    updateUserName: (userid, username) => {
        let params = {
            userid: userid,
            username: username
        }
        return post(`/user/updateUsername`, params, CONTENT_TYPE.urlencodedHeader)
    },
    // 修改个人性别
    updateSex: (userid, sex) => {
        let params = {
            userid: userid,
            sex: sex
        }
        return post(`/user/updateSex`, params, CONTENT_TYPE.urlencodedHeader)
    },
    // 修改个人生日
    updateBirthday: (userid, birthday) => {
        let params = {
            userid: userid,
            birthday: birthday
        }
        return post(`/user/updateBirthday`, params, CONTENT_TYPE.urlencodedHeader)
    },
    // 修改个人手机号
    updatePhone: (userid, phone) => {
        let params = {
            userid: userid,
            phone: phone,
            zone: "+81"
        }
        return post(`/user/v2/updatePhone`, params, CONTENT_TYPE.urlencodedHeader)
    },
    // 修改个人邮箱
    updateUserEmail: (userid, email) => {
        let params = {
            userid: userid,
            email: email,
        }
        return post(`/user/updateUserEmail`, params, CONTENT_TYPE.urlencodedHeader)
    },
    // 修改密码
    updateUserPwd: (userid, oldPassword, newPassword) => {
        let params = {
            userid: userid,
            oldPassword: oldPassword,
            newPassword: newPassword,
        }
        return post(`/user/updateUserPwd`, params, CONTENT_TYPE.urlencodedHeader)
    },
    //账号密码登陆
    login: (params) => {
        // return post(`/login`,params)
        return request({
            url: '/login',
            method: 'post',
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
            data: qs.stringify(params)
        })
    },
}

export default UserApi;
