import { Boot } from '@wangeditor/editor'

import withAttachment from './elementPlugin.js'
import renderElemConf from './elementRender.js'
import elemToHtmlConf from './elemToHtml.js'
import parseHtmlConf from './parseElemHtml.js'
import i18n from './i18n.js'


export default function () {
    Boot.registerPlugin(withAttachment);
    Boot.registerRenderElem(renderElemConf);
    Boot.registerElemToHtml(elemToHtmlConf);
    Boot.registerParseElemHtml(parseHtmlConf);
    i18n()
}




/*
 开发手册：
    wangEditor-github:  https://github.com/wangeditor-team/wangEditor
    1、dom所需节点结构如${structure}所示
    2、图片插入模块 type === newele
    3、src为图片src
    4、titleText为图片默认标题
*/
const structure = {
    type: "newele",
    src: "xxx.jpg",
    titleText: "默认标题",
    children: [{ text: '' }]  // void node 必须有 children ，其中有一个空字符串，重要！！！
}
