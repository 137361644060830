import { h } from 'snabbdom';
import { t } from '@wangeditor/editor'
import eventBus from '@/utils/eventBus';

/**
 * 渲染“附件”元素到编辑器
 * @param elem 附件元素，即上文的 myResume
 * @param children 元素子节点，void 元素可忽略
 * @param editor 编辑器实例
 * @returns vnode 节点（通过 snabbdom.js 的 h 函数生成）
 */
function renderAttachment(elem, children, editor) {                                                // JS 语法

    const { src = '', titleSrc = "", isPreview = "" } = elem;
    const titleSpan = h(
        'span',
        {
            fontSize: "14px",
            fontFamily: "Microsoft YaHei",
            fontWeight: 400,
            lineHeight: "19px",
            color: "#0870D1"

        },
        t('editTitle')
    )
    const titleIconSapn = h(
        'span',
        {
            className: "iconfont icon-bianji",
            style: { marginRight: "10px" }
        }
    )
    const topEditBox = h(
        'div',
        {
            style: {
                cursor: "pointer"
            },
            on: { click() { eventBus.emit('editTitle', elem) }, /* 其他... */ },

        },
        [titleIconSapn, titleSpan]
    )
    const topDiv = h(
        'div',
        {
            style: {
                width: '100%',
                height: '44px',
                backgroundColor: "#F9F9F9",
                fontSize: "14px",
                fontFamily: "Microsoft YaHei",
                fontWeight: "400",
                lineHeight: "19px",
                color: "#0870D1",
                paddingLeft: "30px",
                boxSizing: "border-box",
                display: isPreview ? "none" : "flex",
                alignItems: "center",
            }
        },
        [topEditBox]
    )
    const imgNode = h(
        'img',
        {
            props: { src: src },
            style: {
                width: '100%',
                verticalAlign: "bottom",
            }
        }
    )
    const titleImgNode = h(
        'img',
        {
            props: { src: titleSrc },
            className: "title-img-src",
            style: {
                width: "100%",
                verticalAlign: "bottom",
                position: "absolute",
                top: "0px",
                left: "0px",
            }
        }
    )
    const pRef = h(
        'p',
        {
            style: {
                width: "100%",
                margin: "0",
                position: "relative"
            }
        },
        // 子节点
        [imgNode, titleImgNode]
    )
    const goBackIcon = h(
        'span',
        {
            className: 'iconfont icon-tuichu',
            style: {
                marginRight: "6px",
                width: "14px"
            }
        }
    )
    const goBackSpan = h(
        "span",
        {
            style: {
                fontSize: " 12px",
                fontFamily: "Microsoft YaHei",
                fontWeight: 400,
                lineHeight: "16px",
                flexGrow: 1
            }
        },
        t('goback')
    )
    const closeIcon = h(
        'div',
        {
            style: {
                width: "auto",
                height: "32px",
                backgroundColor: "rgba(0, 0, 0, 0.25)",
                position: "absolute",
                top: "44px",
                right: "0",
                alignItems: "center",
                fontSize: "14px",
                padding: "0 10px",
                boxSizing: "border-box",
                color: "#fff",
                cursor: "pointer",
                display: isPreview ? "none" : "flex"
            },
            on: { click() { eventBus.emit('updatePicture', elem) }, /* 其他... */ },
        },
        [goBackIcon, goBackSpan]
    )

    const attachVnode = h(
        // HTML tag
        'div',
        // HTML 属性、样式、事件
        {
            props: { contentEditable: false },
            style: {
                color: "red",
                backgroundColor: 'black',
                width: "100%",
                height: "100%",
                position: "relative",
                marginBottom: "20px"
            },
        },
        // 子节点
        [topDiv, pRef, closeIcon]
        // [h1, p]
    )
    // const htmlString = toHTML(attachVnode);
    // console.log(htmlString)
    return attachVnode
}

const renderElemConf = {
    type: 'newele', // 新元素 type ，重要！！！
    renderElem: renderAttachment,
}


export default renderElemConf

