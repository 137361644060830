<!-- eslint-disable vue/no-v-model-argument -->
<!-- eslint-disable vue/no-mutating-props -->
<template>
      <div class="ant-input-box">
            <a-input class="custom-input" :value="inputValue" @input="inputHandler" showCount :placeholder="placeholder"
                  :maxlength="maxlength" />
            <p class="count"><span>{{ inputValue.length }}</span>/<span>{{ maxlength }}</span></p>
      </div>
</template>

<script>
export default {
      props: {
            inputValue: {
                  type: String,
                  required: true
            },
            maxlength: {
                  type: Number,
                  required: true
            },
            placeholder:{
                  type:String,
                  required:true
            }
      },
      data() {
            return {

            }
      },
      methods: {
            inputHandler(e) {
                  const value = e.target.value;
                  this.$emit('update:inputValue', value)
            }
      }
}
</script>

<style lang="scss" scoped>
.ant-input-box {
      display: flex;
      position: relative;

      .custom-input {
            background: rgba(255, 255, 255, 0.39);
            border: 1px solid #DFE1E5;
            opacity: 1;
            border-radius: 60px;
            font-size: 18px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            line-height: 24px;
            color: #3C4043;
            opacity: 1;
            padding-left: 20px;
            padding-right: 50px;
      }

      .count {
            margin: 0;
            position: absolute;
            top: 50%;
            right: 20px;
            transform: translateY(-50%);
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            line-height: 20px;
            color: #EEEEEE;
            opacity: 1;
      }
}
</style>