<!-- eslint-disable vue/no-multiple-template-root -->
<template>
  <Header style="opacity: 1" />
  <div class="content">
    <div class="banner">
      <div class="upload-banner" @click="uploadBannerHander">
        <img src="@/assets/upload-story.png" alt="" srcset="" />
        <span>上传头图</span>
      </div>
      <!-- multiple 因为背景只有一张，所以不可多个上传 -->
      <input type="file" id="fileInput" @change="handleFileUpload" style="display: none" />
      <img :src="Editmodel.imgSrc" />
      <transition name="bounce">
        <div class="continue-edit" v-show="continueEdit">
          <div class="continue-edit-left">
            <img :src="require('@/assets/continue-edit.png')" alt="" />
            <span>你还有上次未提交的故事，是否继续编辑？</span>
          </div>
          <div class="continue-edit-right">
            <span @click="continueEditHandler">继续编辑</span>
            <span @click="deleteHandler">删除</span>
          </div>
        </div>
      </transition>
      <div class="music-box">
        <img @click="changePlayHandler" src="../../../assets/4082057.png" :class="{ active: isPaly }" alt="" />
      </div>
    </div>
    <div class="container one">
      <div class="left-nav">
        <img :src="require('@/assets/foreword-icon.png')" alt="" />
        <div class="line"></div>
      </div>
      <div class="add-info">
        <div class="input-title">
          <AntInput class="input-ant" v-model:inputValue="Editmodel.titleValue" :maxlength="15" :placeholder="'请编辑标题'" />
        </div>
        <div class="entry-select-f">
          <EntrySelect @updateEntryModel="updateEntryModel" />
        </div>
        <div class="music-select-f">
          <MusicSelect ref="musicSelectRef" @useHandler="useHandler" @updateData="updateData" />
        </div>
      </div>
      <div class="right-box"></div>
    </div>
    <!-- 正文开始 -->
    <div class="container two">
      <div class="left-container-line"></div>
      <div class="container-info">
        <!-- 前言 -->
        <div class="preface-box">
          <div class="fixed-left">
            <div class="preface">
              <p>前言</p>
              <div class="preface-line"></div>

              <!-- 表单 -->
              <div class="preface-content">
                <a-form :model="prefaceModel" :rules="prefaceRules">
                  <div class="preface-title">
                    <a-form-item class="item" name="preface">
                      <a-input autocomplete="off" name="oneyoansfioahsj" class="preface-title-input"
                        v-model:value="prefaceModel.preface" placeholder="经验标题" :maxlength="15" />
                    </a-form-item>
                    <div class="text-inner"><span>{{ prefaceModel.preface?.length }}</span>/<span>15</span>
                    </div>
                  </div>
                  <div class="preface-container">
                    <a-form-item class="item" name="content">
                      <a-textarea v-model:value="prefaceModel.content" placeholder="添加随记，旅行心得" :autoSize="true"
                        :maxlength="2000" />
                    </a-form-item>
                    <div class="text-inner"><span>{{ prefaceModel.content?.length }}</span>/<span>2000</span>
                    </div>
                  </div>
                </a-form>
              </div>
            </div>
          </div>
          <div class="top">
            <div style="width:66px;position: relative;z-index: 1;}"><span class="iconfont icon-zengjia add-icon-cust"
                :class="{ active: prefaceModel.createEdit }" @click="openInsertBoxHandler('preface')"></span></div>
            <ul class="choose-box" :class="{ active: prefaceModel.createEdit }">
              <li @click="addDayHandler">
                <span class="iconfont icon-caidan list-icon"></span>
                <span class="text">插入一天</span>
              </li>
              <!-- <li>
                <span style="font-size:17px" class="iconfont icon-didian list-icon"></span>
                <span class="text">添加地点</span>
              </li>
              <li>
                <span style="font-size:16px" class="iconfont icon-wujiaoxing- list-icon"></span>
                <span class="text">旅行经验</span>
              </li> -->
            </ul>
          </div>
        </div>
        <Days ref="everyDayRef" @openInsertBoxHandler="openInsertBoxHandler" v-for="(item, index) in dayListInfo"
          :key="item" :identify="{ index: index, length: dayListInfo?.length }" :item="item"
          @updateAddDayHandler="updateAddDayHandler" />
      </div>

      <div class="right-box">
        <PictureChoose ref="pictureChooseRef" class="piture-choose" @choose-picture-handler="choosePictureHandler" />
      </div>
    </div>
    <!-- 插入一天 -->
    <a-modal class="a-dialog-story" :footer="false" v-model:visible="insertDay" title="插入一天">
      <a-form ref="dayInsertRef" :model="daysForm" :rules="insertDayRules">
        <a-form-item name="title">
          <a-input style="height:40px;" :maxlength="15" v-model:value="daysForm.title" placeholder="标题">
            <template #suffix>
              <p class="input-suffix">
                <span>{{ daysForm.title?.length }}</span>/<span>15</span>
              </p>
            </template>
          </a-input>
        </a-form-item>
        <a-form-item name="dayDate">
          <a-date-picker format="YYYY/MM/DD" valueFormat="YYYY-MM-DD" style="width:100%;height:40px;"
            v-model:value="daysForm.dayDate" placeholder="日期" :disabledDate="disabledDate" />
        </a-form-item>
        <a-form-item class="button-group">
          <a-button @click="cancelHandler('day')">取消</a-button>
          <a-button type="primary" @click="addDayOkHandler">确定</a-button>
        </a-form-item>
      </a-form>
    </a-modal>
  </div>
  <div class="share">
    <div class="share-content">
      <div class="share-title">
        <span>添加基本信息，你的故事将被更多人看到</span>
        <img :src="require('@/assets/share-nav.png')" alt="" />
      </div>
      <div class="share-container">
        <ul class="input-group">
          <li>
            <span>目 的</span>
            <div>
              <a-input width="100%" v-model:value="shareModel.purpose" placeholder="目   的" />
            </div>
            <ul class="purpose">
              <li @click="chooseSharePurposeHandler('休闲观光')">休闲观光</li>
              <li @click="chooseSharePurposeHandler('放松身心')">放松身心</li>
              <li @click="chooseSharePurposeHandler('增长见识')">增长见识</li>
              <li @click="chooseSharePurposeHandler('出境购物')">出境购物</li>
              <li @click="chooseSharePurposeHandler('陪伴家人')">陪伴家人</li>
              <li @click="chooseSharePurposeHandler('出差旅游')">出差旅游</li>
              <li @click="chooseSharePurposeHandler('纪念会议')">纪念会议</li>
              <li @click="chooseSharePurposeHandler('拜亲访友')">拜亲访友</li>
            </ul>
          </li>
          <li>
            <span>人 物</span>
            <div>
              <a-input width="100%" v-model:value="shareModel.character" placeholder="人   物" />
            </div>
            <ul class="crowd">
              <li @click="chooseShareCrowdHandler('独自一人')">独自一人</li>
              <li @click="chooseShareCrowdHandler('三五好友')">三五好友</li>
              <li @click="chooseShareCrowdHandler('亲子')">亲子</li>
              <li @click="chooseShareCrowdHandler('家庭')">家庭</li>
              <li @click="chooseShareCrowdHandler('情侣')">情侣</li>
              <li @click="chooseShareCrowdHandler('闺蜜')">闺蜜</li>
              <li @click="chooseShareCrowdHandler('学生')">学生</li>
            </ul>
          </li>
          <li>
            <span>天 数</span>
            <div>{{ shareModel.days }}</div>
            <span class="day">天</span>
            <span class="people">人 均</span>
            <div class="people-input">
              <a-input-number id="inputNumber" v-model:value="shareModel.perCapita" :min="1" />
            </div>
            <span class="money">元</span>
          </li>
        </ul>
      </div>
    </div>
  </div>
  <div class="footer">
    <div class="footer-button-ground">
      <a-button>预览</a-button>
      <a-button type="primary">发布</a-button>
    </div>
  </div>
  <imageEdit ref="imageEditRef" v-model:imageEditVisible="imageEditVisible" :files="filesList" :isCreated="false"
    :imgNote="imgNote" :from="'createStroy'" />
</template>
<script>
import Header from "@/components/Header.vue";
import EntrySelect from "@/components/EntrySelect";
import MusicSelect from "@/components/MusicSelect";
import AntInput from "@/components/ImageEdit/AntInput.vue";
import Days from './days.vue'
import PictureChoose from "./pictureChoose.vue";
import imageEdit from '@/components/ImageEdit';


import moment from 'moment';
// event Bus
import eventBus from '@/utils/eventBus.js';
// ant - component
import { ExclamationCircleOutlined } from '@ant-design/icons-vue';
import { createVNode } from 'vue';
import { Modal } from 'ant-design-vue';


import { SlateTransforms } from '@wangeditor/editor'
import { SlateEditor, SlateElement } from '@wangeditor/editor'


export default {
  // emit:["updateAddDayHandler"],
  components: {
    //组件
    Header,
    AntInput,
    EntrySelect,
    MusicSelect,
    Days,
    PictureChoose,
    imageEdit
  },
  data() {
    return {
      Editmodel: {
        treeId: undefined,
        treeName: "",
        titleValue: "",
        music: {
          id: null,
          name: "",
        },
        imgSrc: "https://static-cse.canva.cn/blob/239388/e1604019539295.jpg"
      },

      //继续编辑？？
      continueEdit: false,
      shareModel: {
        purpose: "",
        character: "",
        days: 1,
        perCapita: null,
      },
      isPaly: false,
      musicInit: false,
      // 前言表单数据
      prefaceModel: {
        preface: "",
        content: "",
        createEdit: false
      },
      EditorId: "",
      insertDay: false,
      daysForm: {
        title: "",
        dayDate: ""
      },
      dayListInfo: [],
      // 图片编辑器相关
      imageEditVisible: false,
      filesList: [],
      imgNote: "",
      path: null

    };
  },
  created() {
    eventBus.on('updateEditorId', (id) => {
      if (!id) return;
      this.EditorId = id
    })
    eventBus.on('updatePicture', (item) => {
      console.log('更新创建内容 ===>', item)
      this.deletePicture(item)
    })
    eventBus.on('editTitle', (item) => {
      this.editPicture(item)
    })
    eventBus.on('updateList', (item) => {
      console.log(item)
      this.updatePictrueTitle(item)
    })
    const uuid = this.generateUUID()
    this.dayListInfo.push({ id: uuid });

  },
  mounted() {
    // 转utc时间
    moment().utc().format('YYYY-MM-DD HH:mm')
  },
  methods: {
    // 上传相关函数
    handleFileUpload(file) {
      console.log('上传的背景图片file ===>', file.target.files)
      const url = URL.createObjectURL(file.target.files[0])
      console.log('上传的背景图片本地地址 ===>', url);
      this.Editmodel.imgSrc = url;
    },
    uploadBannerHander(event) {
      event.stopPropagation(); // 阻止事件冒泡
      document.getElementById("fileInput").click();
    },
    //Entry信息获取
    updateEntryModel(item) {
      console.log("获取到了Entry信息 ===>", item);
    },
    //继续编辑 相关函数
    continueEditHandler() {
      this.continueEdit = false;
    },
    deleteHandler() {
      this.continueEdit = false;
    },

    //分享相关部分函数
    chooseShareCrowdHandler(value) {
      this.shareModel.character = value;
    },
    chooseSharePurposeHandler(value) {
      this.shareModel.purpose = value;
    },

    //音乐播放相关函数
    useHandler(item) {
      this.Editmodel.music.id = item?.id;
      this.Editmodel.music.name = item?.name;
    },
    updateData(data) {
      if (data?.isPlay) {
        this.isPaly = data?.isPlay === "true" ? true : false;
      }
      if (data?.isInit && !this.musicInit) {
        this.musicInit = true
      }
    },
    changePlayHandler() {
      console.log(1)
      if (!this.musicInit) return;
      this.isPaly ? this.$refs.musicSelectRef.changePausedHandler() : this.$refs.musicSelectRef.changePlayHandler()
    },

    //前言 
    openInsertBoxHandler(type) {
      if (type === 'preface') {
        this.prefaceModel.createEdit = !this.prefaceModel.createEdit
        let everyDays = Object.values(this.$refs.everyDayRef);
        everyDays.forEach(child => {
          child.openInsertBoxHandler('clearn')
        });
      } else if (type === 'clearn') {
        this.prefaceModel.createEdit = false
        let everyDays = Object.values(this.$refs.everyDayRef);
        everyDays.forEach(child => {
          child.openInsertBoxHandler('clearn')
        });
      }
    },
    // 选择图片 及 相关 
    choosePictureHandler(item) {
      // 先找到所有的 editor 根据id 找到选中的
      let EditorRefArr = []
      Object.values(this.$refs.everyDayRef).forEach(item1 => {
        const arr = item1.$refs.EditorRef.reduce((result, item) => {
          result.push({
            id: item.editorRef?.id,
            editor: item.editorRef
          })
          return result;
        }, [])
        EditorRefArr = EditorRefArr.concat(arr)
      })
      console.log(EditorRefArr)
      // 先找到所有的 editor 根据id 找到选中的 ！ 找到了 ！
      const canInsertEditor = EditorRefArr.find(item => item.id === this.EditorId)

      if (!canInsertEditor) {
        this.$message.error('请选择目标');
        return;
      } else {
        const pictrueDomNode = {
          type: "newele",
          src: item?.url,
          titleText: "",
          from: item?.from,
          titleSrc: "",
          isPreview: false,
          children: [{ text: '' }]  // void node 必须有 children ，其中有一个空字符串，重要！！！
        }
        canInsertEditor.editor.insertNode(pictrueDomNode)
        // 插入图片后 图片库减少
        this.$refs.pictureChooseRef.updateGallery(item)
      }
    },
    // eslint-disable-next-line no-unused-vars
    deletePicture(item) {

      Modal.confirm({
        title: () => this.putPictureBackConfirmTitle,
        icon: () => createVNode(ExclamationCircleOutlined),
        content: () =>
          createVNode(
            'div',
            {
              style: 'color:red;',
            },
            this.putPictureBackConfirmContainer,
          ),
        okText: this.$t('done'),
        cancelText: this.$t('cancel'),
        onOk: () => {
          let EditorRefArr = []
          Object.values(this.$refs.everyDayRef).forEach(item1 => {
            const arr = item1.$refs.EditorRef.reduce((result, item) => {
              result.push({
                id: item.editorRef?.id,
                editor: item.editorRef
              })
              return result;
            }, [])
            EditorRefArr = EditorRefArr.concat(arr)
          })
          console.log(EditorRefArr)
          const canInsertEditor = EditorRefArr.find(item => item.id === this.EditorId)
          if (!canInsertEditor) {
            this.$message.error('请选择目标');
            return;
          } else {
            canInsertEditor.editor.deleteBackward()
            const uuid = this.generateUUID();
            const model = {
              id: uuid,
              url: item.src,
              from: item.from
            }
            if (item.from === 'network') {
              this.$refs.pictureChooseRef.pictureList.push(model)
            } else {
              this.$refs.pictureChooseRef.uploadList.push(model)
            }
          }
        },
        onCancel() {
          console.log('Cancel');
        },
        class: 'test',
      });
    },
    async editPicture(item) {
      try {
        console.log(' NodeInfo ===>item', item)
        // 记录选中的节点 path
        let EditorRefArr = []
        Object.values(this.$refs.everyDayRef).forEach(item1 => {
          const arr = item1.$refs.EditorRef.reduce((result, item) => {
            result.push({
              id: item.editorRef?.id,
              editor: item.editorRef
            })
            return result;
          }, [])
          EditorRefArr = EditorRefArr.concat(arr)
        })
        console.log(EditorRefArr)
        // 先找到所有的 editor 根据id 找到选中的 ！ 找到了 ！
        const canInsertEditor = EditorRefArr.find(item => item.id === this.EditorId)

        if (!canInsertEditor) {
          this.$message.error('请选择目标');
          return;
        } else {
          const nodeEntries = SlateEditor.nodes(canInsertEditor.editor, {
            match: (node) => {          // JS syntax
              if (SlateElement.isElement(node)) {
                if (node.type === 'newele') {
                  return true // 匹配 newele
                }
              }
              return false
            },
            universal: true,
          })

          if (nodeEntries == null) {
            console.log('当前未选中的 paragraph')
          } else {
            for (let nodeEntry of nodeEntries) {
              const [node, path] = nodeEntry
              console.log('选中了 paragraph 节点', node)
              console.log('节点 path 是', path)
              this.path = path
            }
          }
        }



        this.filesList = []
        this.imageEditVisible = true;
        const url = item.src;
        const imageDimensions = await this.getImageDimensions(url)
        console.log('imageDimensions ===>', imageDimensions)
        this.filesList.push({ file: imageDimensions });
        this.$refs.imageEditRef.editInit()
      } catch (e) {
        console.log('editInit Error ===>', e)
      }
    },
    updatePictrueTitle(item) {
      // 先找到所有的 editor 根据id 找到选中的
      let EditorRefArr = []
      Object.values(this.$refs.everyDayRef).forEach(item1 => {
        const arr = item1.$refs.EditorRef.reduce((result, item) => {
          result.push({
            id: item.editorRef?.id,
            editor: item.editorRef
          })
          return result;
        }, [])
        EditorRefArr = EditorRefArr.concat(arr)
      })
      console.log(EditorRefArr)
      // 先找到所有的 editor 根据id 找到选中的 ！ 找到了 ！
      const canInsertEditor = EditorRefArr.find(item => item.id === this.EditorId)

      if (!canInsertEditor) {
        this.$message.error('请选择目标');
        return;
      } else {
        const url = URL.createObjectURL(item.file)
        console.log('此时获得的url为 ===>', url)
        SlateTransforms.setNodes(canInsertEditor.editor,
          {
            titleText: item.title,
            titleSrc: url
          },
          {
            at: this.path
          })
      }
    },
    // 工具函数
    cancelHandler(type) {
      if (type === "day") {
        this.insertDay = false;
      }
    },
    generateUUID() {
      return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        const r = (Math.random() * 16) | 0;
        const v = c === 'x' ? r : (r & 0x3) | 0x8;
        return v.toString(16);
      });
    },
    // 获取图片尺寸 以及name
    getImageDimensions(url) {
      return new Promise((resolve, reject) => {
        const img = new Image();
        const urlParts = url.split('/');
        const fileNameWithExtension = urlParts[urlParts.length - 1]
        const fileName = fileNameWithExtension.split('.')[0]
        img.onload = function () {
          resolve({ width: img.width, height: img.height, name: fileName, url });
        };
        img.onerror = function () {
          reject(new Error('Failed to load the image'));
        };
        img.src = url;
      });
    },
    // 添加天
    addDayHandler() {
      this.insertDay = true;
    },
    async addDayOkHandler() {
      try {
        this.openInsertBoxHandler('preface')
        await this.$refs.dayInsertRef.validateFields()
        const uuid = this.generateUUID()
        this.dayListInfo.unshift({ id: uuid, ...this.daysForm });

        this.$refs.dayInsertRef.resetFields();
        this.insertDay = false;
      } catch (e) {
        console.log(e)
      }
    },
    updateAddDayHandler(model) {
      console.log(model)
      // eslint-disable-next-line no-unused-vars
      const { id, title, dayDate, index } = model;
      const uuid = this.generateUUID();
      const insertModel = {
        id: uuid,
        title,
        dayDate
      }
      this.dayListInfo.splice(index + 1, 0, insertModel)
    },
    disabledDate(current) {
      return current && current > moment().startOf('day');
    }
  },
  computed: {
    prefaceRules() {
      return {
        preface: [
          { required: true, message: '经验标题不能为空！', trigger: 'blur' }
        ],
        content: [
          { required: true, message: "请添加随记，不能填写为空。", trigger: "blur" }
        ]
      }
    },
    insertDayRules() {
      return {
        title: [
          { required: true, message: "不能为空！", trigger: "blur" }
        ],
        dayDate: [
          { required: true, message: "不能选择空日期！", trigger: "change" }
        ]
      }
    },

    putPictureBackConfirmTitle() {
      console.log('===>')
      return "确定要放回图片库吗？"
    },
    putPictureBackConfirmContainer() {
      return "操作不会删除图片，将返回到原图片库"
    }
  },
  beforeUnmount() {
    eventBus.off("updateEditorId");
    eventBus.off('updatePicture');
    eventBus.off('editTitle')
    eventBus.off('updateList')
  }
};
</script>
<style lang="scss" scoped>
.content {
  padding-top: 52px;
  position: relative;

  .banner {
    position: absolute;
    top: 52px;
    left: 0;
    width: 100%;
    height: 600px;
    box-sizing: border-box;
    padding-top: 102px;

    .upload-banner {
      box-sizing: border-box;
      width: 158px;
      height: 66px;
      margin: 0 auto;
      border: 1px dashed #eeeeee;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-right: 30px;
      padding-left: 20px;
      cursor: pointer;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      position: relative;
      z-index: 1;

      img {
        width: 36px;
        height: 36px;
      }

      span {
        font-size: 14px;
        font-weight: 400;
        line-height: 22px;
        color: #3c4043;
      }
    }

    &>img {
      position: absolute;
      z-index: -1;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
    }

    .continue-edit {
      position: absolute;
      top: 20px;
      left: 0;
      right: 0;
      margin: auto;
      width: 1000px;
      height: 42px;
      z-index: 999;
      box-sizing: border-box;
      padding: 0 20px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      background-color: #f9f9f9;

      .continue-edit-left {
        display: flex;
        align-items: center;

        img {
          flex-shrink: 0;
          width: 18px;
          height: 18px;
          margin-right: 20px;
        }

        span {
          flex-shrink: 0;
          color: #5f6368;
        }
      }

      .continue-edit-right {
        display: flex;
        align-items: center;

        span {
          flex-shrink: 0;
          font-size: 14px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          line-height: 20px;
          cursor: pointer;
        }

        span:first-child {
          color: #0870d1;
          margin-right: 30px;
        }

        span:last-child {
          color: #70757a;
        }
      }
    }

    .music-box {
      position: absolute;
      top: 20px;
      right: 42px;
      z-index: 1;
      width: 68px;
      height: 68px;
      border-radius: 68px;
      background: #ffffff;
      box-shadow: 0px 0px 6px 1px #c5d2e5;
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        width: 100%;
        height: 100%;
        border-radius: 100%;
        animation: rotate-6bbfdbe3 7s linear infinite;
        animation-play-state: paused;

        &.active {
          animation-play-state: running;
        }

        @keyframes rotate {
          0% {
            transform: rotate(0deg);
          }

          100% {
            transform: rotate(360deg);
          }
        }
      }
    }
  }

  .container {
    position: relative;
    width: 1213px;
    display: flex;
    justify-content: space-between;
    box-sizing: border-box;

    .left-nav {
      width: 66px;
      position: relative;

      .line {
        width: 4px;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        margin: 0 auto;
        z-index: -1;
        background: #eeeeee;
      }
    }

    .right-box {
      width: 372px;
    }
  }

  .one {
    padding-top: 316px;
    height: 600px;

    .add-info {
      .input-title {
        width: 730px;
        height: 74px;
        display: flex;
        margin-bottom: 20px;

        .input-ant {
          width: 100%;
          height: 100%;
        }

        .input-ant :deep(input) {
          border-radius: 8px;
          background-color: #fff;
        }
      }

      .entry-select-f {
        position: relative;
        margin-bottom: 20px;
      }

      .music-select-f {
        //   position: relative;
      }
    }
  }

  .two {
    // height: 999px;

    .left-container-line {
      width: 66px;
      height: calc(100% - 70px);
      position: absolute;
      z-index: -1;
      top: 0;
      left: 0;

      &::after {
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        display: inline-block;
        content: "";
        width: 4px;
        height: 100%;
        background-color: #eeeeee;
      }
    }

    .container-info {
      flex-grow: 1;

      .preface {
        width: 730px;
        margin: 0 auto;

        p {
          font-size: 24px;
          font-family: Microsoft YaHei;
          font-weight: bold;
          line-height: 27px;
          color: #4D5156;
          padding: 20px 0 12px 0;
          margin: 0;
        }

        .preface-line {
          height: 4px;
          background-color: #70757A;
          margin-bottom: 40px;
        }

        .preface-content {
          // height: 200px;
          // background: #FFFFFF;
          border: 1px solid #EEEEEE;
          border-radius: 5px;
          overflow: hidden;
          box-sizing: border-box;

          .preface-title {
            height: 62px;
            background-color: #EFF3F6;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 0 20px;

            .item {
              margin: 0;
              flex-grow: 1;

              input {
                border: 0;
                background: transparent;
                padding: 0;
                font-size: 14px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                line-height: 19px;

                &::placeholder {
                  color: #CCCCCC;
                }

                &:focus {
                  box-shadow: none;
                }
              }
            }

            .text-inner {
              flex-shrink: 0;
              margin-left: 5px;
              width: 31px;
              font-size: 14px;
              font-family: Microsoft YaHei;
              font-weight: 400;
              line-height: 19px;
              color: #CCCCCC;
            }

          }

          .preface-container {
            min-height: calc(200px - 64px);
            background-color: #fff;
            padding: 20px;
            position: relative;

            .item {
              margin: 0;
              // height: 98px;

              textarea {
                // height: 80px;
                padding: 0;
                border: 0;
                resize: none;
                margin-bottom: 5px;

                &::placeholder {
                  font-size: 14px;
                  font-family: Microsoft YaHei;
                  font-weight: 400;
                  line-height: 19px;
                  color: #CCCCCC;
                }

                &:focus {
                  box-shadow: none;
                }
              }
            }

            .text-inner {
              position: absolute;
              bottom: 10px;
              right: 20px;
              text-align: right;
              font-size: 14px;
              font-family: Microsoft YaHei;
              font-weight: 400;
              line-height: 19px;
              color: #CCCCCC;
            }
          }
        }
      }
    }

    .right-box {
      padding-top: 20px;

      .piture-choose {
        position: sticky;
        top: 72px;
      }
    }

  }
}

.share {
  background-color: rgba(157, 198, 255, 0.1);
  padding-top: 40px;
  height: 302px;

  .share-content {
    width: 1300px;
    margin: 0 auto;

    .share-title {
      position: relative;
      margin-bottom: 50px;

      img {
        width: 100%;
        height: 100%;
      }

      span {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: 0 auto;
        text-align: center;
        font-size: 12px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        line-height: 27px;
        color: #202124;
      }
    }

    .share-container {
      display: flex;

      .input-group {
        li {
          display: flex;
          align-items: center;
          margin-bottom: 20px;

          &:last-child {
            width: 358px;
            margin: 0;

            span {
              flex-shrink: 0;
            }

            .day {
              margin: 0 30px 0 9px;
            }

            .people {
              margin-right: 12px;
            }

            .money {
              margin-left: 10px;
            }

            div {
              width: 83px;
              height: 34px;
              line-height: 34px;
              padding-left: 9px;
              background-color: rgba(242, 242, 242, 1);
            }

            .people-input {
              margin: 0;
              padding: 0;
              background-color: transparent;

              div {
                background-color: #fff;
              }
            }
          }

          &>div {
            flex-shrink: 0;
            width: 260px;
            position: relative;
            margin-left: 54px;

            p {
              position: absolute;
            }
          }

          span {
            flex-shrink: 0;

            word-spacing: 20px;
          }

          .purpose,
          .crowd {
            display: flex;
            align-items: center;
            margin-left: 40px;

            li {
              cursor: pointer;
              flex-shrink: 0;
              margin: 0;
              margin-right: 40px;
              font-size: 14px;
              font-family: Microsoft YaHei;
              font-weight: 400;
              line-height: 22px;
              color: #70757a;

              &:nth-child(3) {
                margin-right: 80px;
              }
            }
          }

          :deep(.ant-input-number-handler-wrap) {
            display: none;
          }
        }
      }
    }
  }
}

.footer {
  height: 67px;
  width: 100%;
  background: rgba(255, 255, 255, 0.39);
  box-shadow: -3px 0px 6px rgba(0, 0, 0, 0.1);

  .footer-button-ground {
    height: 100%;
    width: 70%;
    margin: 0 auto;
    display: flex;
    justify-content: flex-end;
    align-items: center;

    button {
      width: 112px;
      height: 36px;
      font-size: 16px;
      font-family: PingFang SC;
      font-weight: 400;
      line-height: 22px;
      color: #ffffff;
    }

    button:first-child {
      color: rgba(32, 33, 36, 1);
    }

    button:last-child {
      margin-left: 20px;
      background: rgba(8, 112, 209, 1);
    }
  }
}

.bounce-enter-active {
  animation: bounce-in 0.3s;
}

.bounce-leave-active {
  animation: bounce-in 0.3s reverse;
}

@keyframes bounce-in {
  0% {
    transform: scale(0);
  }

  50% {
    transform: scale(1.25);
  }

  100% {
    transform: scale(1);
  }
}

.top,
.bottom {
  height: 30px;
  position: relative;
  padding: 30px 0;
  box-sizing: content-box;
  display: flex;

  img {
    width: 30px;
    height: 30px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%)
  }

  .add-icon-cust {
    margin: 0 auto;
    font-size: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;
    background: #fff;
    color: rgba(8, 112, 209, 0.60);
    border: 1px solid rgba(8, 112, 209, 0.60);
    border-radius: 30px;
    transition: .6s;
    cursor: pointer;

    &.active {
      transform: rotate(45deg);
      background: #0870D1;
      color: #fff;
    }
  }

  .choose-box {
    margin: 0 auto;
    width: 730px;
    display: flex;
    align-items: center;
    position: relative;

    li {
      position: absolute;
      left: -122px;
      display: flex;
      align-items: center;
      opacity: 0;
      cursor: pointer;

      .list-icon {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 30px;
        height: 30px;
        font-size: 13px;
        background: #0870D1;
        color: #fff;
        border-radius: 30px;
        margin-right: 12px;
      }

      .text {
        font-size: 20px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        line-height: 27px;
        color: #0870D1;
      }
    }

    li:last-child {
      -moz-transition: 0.3s 0.3s;
      -o-transition: 0.3s 0.3s;
      -webkit-transition: 0.3s;
      -webkit-transition-delay: 0.3s 0.3s;
      -webkit-transition: 0.3s 0.3s;
      transition: 0.3s 0.3s;
    }


    li:nth-child(2) {
      -moz-transition: 0.3s 0.6s;
      -o-transition: 0.3s 0.6s;
      -webkit-transition: 0.3s;
      -webkit-transition-delay: 0.3s 0.6s;
      -webkit-transition: 0.3s 0.6s;
      transition: 0.3s 0.6s;
    }

    li:first-child {
      -moz-transition: 0.3s 0.9s;
      -o-transition: 0.3s 0.9s;
      -webkit-transition: 0.3s;
      -webkit-transition-delay: 0.3s 0.9s;
      -webkit-transition: 0.3s 0.9s;
      transition: 0.3s 0.9s;
    }


    &.active {

      li:last-child {
        left: 366px;
        opacity: 1;
      }

      li:nth-child(2) {
        left: 183px;
        opacity: 1;
      }

      li:first-child {
        left: 0;
        opacity: 1;
      }

    }
  }
}

.fixed-left {
  padding-left: 66px;
}

.input-suffix {
  margin: 0;
  color: #EEEEEE;
  display: flex;


}
</style>
