export default {
  basicInformation: 'Basic Information',
  expression: 'Emoticon',
  tokenErrMessage: 'Already logged in on another computer',
  searchTerm: 'Search term',
  embeddedCode: 'Embedded code',
  copyLink: 'Copy link',
  done: "Done",
  cancel: "Cancel",
  header: {
    search: 'Search',
    personal: 'Personal',
    account: 'Account',
    switchAccount: 'Switch account',
    exit: 'Sign out'
  },
  footer: {
    about: 'About',
    terms: 'Terms',
    privacy: 'Privacy',
    language: 'Language',
    commertialTransactionsLaw:'Commercial Transactions Law',

  },
  mdialog: {
    replay: 'Replay',
    cancel: 'Cancel',
    saySomething: 'Say something…',
  },
  editAvatar: {
    addPicture: 'Add picture',
    preview: 'Preview',
    changeProfile: 'Modify avatar',
    typeRule: "The picture type must be one of. GIF, JPEG, JPG, PNG, BMP.",
    maxTitle:'Upload up to 100 images'
  },
  editModal: {
    // input默认提示
    usernamePlaceholder: 'Input username',
    phonePlaceholder: 'Input phone number',
    userIDPlaceholder: 'Input ID (UserID can be changed only once)',
    genderPlaceholder: 'Gender',
    male: 'Male',
    female: 'Female',
    birthdayPlaceholder: 'Select birthday',
    emailAddressPlaceholder: 'Input email',
    currentPasswordPlaceholder: 'Current password',
    newPasswordPlaceholder: 'New password',
    confirmPlaceholder: 'Confirm password',
    // 弹窗标题
    nameTitle: "Edit username",
    phoneTitle: "Edit phone",
    userIdTitle: "Edit userID",
    sexTitle: "Gender",
    dateTitle: "Edit birthday",
    emailTitle: "Edit email",
    passwordTitle: "Change password",
    // 校验提示
    nameRule: "Enter a username",
    phoneRule: "Enter your phone number",
    userIdRule: "Enter user ID",
    sexRule: "Enter gender",
    dateRule: "Enter a date",
    emailRule: "Enter email address",
    emailTypeRule: "Enter the correct email format",
    passwordRule: "Input a password",
    // Error提示语
    passwordNullError: "Input a password",
    newPasswordNullError: "Input a new password",
    passwordDifferentError: "The passwords entered twice are different",

  },
  setting: {
    profile: 'Profile',
    username: 'Username',
    phone: 'Phone',
    userID: 'UserID',
    gender: 'Gender',
    birthday: 'Birthday',
    emailAddress: 'Email address',
    resetPassword: 'Reset password',
    edit: 'Edit',
    male: 'Male',
    female: 'Female',
    custom: 'Custom',
  },
  create: {
    postTo: 'Post to',
    postToAfterSelect: 'Post to',
    chooseTargetToLetMorePeopleKnow: 'Choose target to let more people know！',
    search: 'Search',
    history: 'History',
    historyAll: 'All history',
    relatedSearches: 'Related searches',
    withInWordsBefore: 'Within ',
    withInWordsAfter: ' words',
    addPictures: 'Add pictures',
    customShootingTime: 'Custom shooting time',
    selectTime: 'Select time',
    post: 'Post',
    title: 'Title',
    setTitle: 'Set Title',
    titleStyle: 'Heading Style',
    description: 'Description',
    success: 'Success',
    viewPost: 'View post',
    postNext: 'Post next',
    createEntry: 'Create Entry',
    prompt: 'The selection target cannot be empty',
    titlePlaceholder:'Please select a title!',
    checkPicture:'Cannot post empty graphics!',
    createPlaceholder: 'Please enter the title content',
    tip: 'Refine title keywords to help recommend'

  },
  detail: {
    follow: 'Follow',
    followed: 'Followed',
    all: 'All',
    impression: 'Impression',
    queryResults: 'Query results',
    date: 'Date',
    other: 'Other',
    clear: 'Clear',
    selectDate: 'Select month',
    filter: 'Filter',
    suggest: 'Suggest',
    question: 'Question',
    comment: 'Comment',
    hot: 'Hot',
    time: 'Time',
    saySomething: 'Say something.',
    replay: 'Replay',
    totalReplyBefore: 'Total ',
    totalReplyAfter: ' replies',
    replaySuccess: 'Replay success',
    commentSuccess: 'Comment success',
    collectionSuccess: 'Collection success',
    cancelCollectionSuccess: 'Cancel collection success',
    likeSuccess: 'Like success',
    cancelLikeSuccess: 'Cancel like success',
    // 时间
    justNow: 'Just now',
    title: 'Add title',
    confirm:'Write a new title?',
    confirmNone:'No title is currently edited, do you want to exit?',
    addTitle: 'Add',
    report: 'Report',
    notShowAgainLeft: 'Hide this user(',
    notShowAgainRight: ') content',
    oneClickCopy: 'One click Copy',
    entryDependent: 'Related Entries',
    searchDependent: 'Correlation Search',
    noSearchDependent: 'No related searches yet...',
    nocontent: "Can't leave a comment blank!"
  },
  entry: {
    build: 'build times:',
    location: 'Ground point:',
    seatMark: 'Seat mark:',
    height: 'High degree:',
    area: 'floor space:',
    numberOfPlies: 'Layers:',
    numberOfElevators: 'Elevators:',
    seeMore: 'See More >',
    pictureDetails: 'Picture details',
    days: 'Days /',
    NNT: 'Headcount /',
    spend: 'Spend /',
    day: 'day',
    person: 'Number',
    money: 'RMB'

  },
  index: {
    entry:"Entry",
    story: "Story",
    journey:"Journey",
    search: 'Search',
    overview: 'Overview',
    // tab
    recommend: 'Recommend',
    hot: 'Hot',
    follow: 'Story',
    collectionSuccess: 'Collection success',
    cancelCollectionSuccess: 'Cancel collection success',
    likeSuccess: 'Like success',
    cancelLikeSuccess: 'Cancel like success',
    followSuccess: 'Follow success',
    cancelFollowSSuccess: 'Cancel follow success',
    copySucceed: 'Copy succeed',
    copyFailed: 'Copy failed',
    button: 'Look Search',
  },
  login: {
    loginFailed: 'Wrong username or password',
    login: 'Login',
    verificationCodeLogin: 'Verification code',
    passwordLogin: 'Password',
    // phone: 'Phone number or email',
    phone: 'Enter email',
    code: 'Code',
    getVerificationCode: 'Get verification code',
    // 服务条款和隐私政策
    agree: 'By signing up you agree to our ',
    OurTermsOfService: 'Terms of Service',
    and: ' and ',
    privacyPolicy: 'Privacy Policy',
    privacyPolicyAfter: '',
    phoneAndEmail: 'Account',
    password: 'Password',
    // input 提示语
    phoneAndEmailRule: 'Enter account',
    codeRule: 'Enter code',
    passwordRule: 'Enter password',
    UsernameNullRule: "ID not null",
    registerUsernameRule: "ID already exists, please change one",
    okRule: "id can be used",
    // 登录后
    success: 'Success',
    fail: 'Fail',
    // 注册
    seconPass: 'Confirm Password',
    username: 'username',
    register: 'Register',
    // 校验提示
    registerName: 'Please enter an id composed of 6-12 digits, letters and underscores',
    passwordTip: 'Please enter a password consisting of 8-20 digits and underscores'
  },
  searchList: {
    about: "About ",
    results: " results",
    relatedSearches: "Related searches",
    // tab
    all: "All",
    picture: "Picture",
    video: "Video",
    text: "Text",
    news: "News",
  },
  myInfo: {
    create: "Create",
    favorite: "Favorite",
    follow: "Follow",
    history: "History",
  },
  time: {
    justNow: "Just now",
    minutesAgo: " minutes ago",
    hourAgo: " hour ago",
    hoursAgo: " hours ago",
    dayAgo: " day ago",
    daysAgo: " days ago",
    monthAgo: " month ago",
    monthsAgo: " months ago",
    yearAgo: " year ago",
    yearsAgo: "years ago",
  },
  reportDialog: {
    reportingProblems: 'Reporting problems',
    repeat: 'Repeat',
    antipathy: 'Antipathy',
    poorContentQuality: 'Poor content quality',
    placeholder: 'Please select Report issue.',
    reportSuccessful: 'Report success!'
  },
  titleDialog: {
    pictureTitle: 'Picture title',

  },
  comment: {
    packUp: 'pack up',
    more: 'more'
  },
  deletePicture: {
    deletePicture: 'Delete Picture',
    deleteConfirm: 'Confirm Delete',
    cancel: 'cancel',
    delete: 'delete',
    deleteSuccess: 'Successfully Deleted',
    noPermission: 'No Permission',
  }
}
