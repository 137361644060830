<template>

	<body>
		<article class="clearfix">
			<section>
				<Header :tabs="tabs" :selectTab="selectTab" :showSearch="true" @targetSide="targetSide"
					@changeTab="changeTab" :value="keywords" @keydownEnter="keydownEnter"
					@changeSearchEvent="changeSearchEvent" />
				<div class="container">
					<h1>販売業者情報</h1>
					<h2>販売業者</h2>
					<p>イマメモ株式会社</p>

					<h2>代表責任者</h2>
					<p>代表取締役 劉志鎖</p>

					<h2>所在地</h2>
					<p>東京都大田区大森北3-1-2 KKSビル4F</p>

					<h2>電話番号</h2>
					<p>03-6404-6200</p>

					<h2>電話受付時間</h2>
					<p>9：30～18：30（土日祝を除く）</p>
					<p>※受付時間外の場合は、メールにてお問い合わせください。</p>

					<h2>メールアドレス</h2>
					<p><a href="mailto:commercial@imamemo.co.jp">commercial@imamemo.co.jp</a></p>

					<h2>サイトURL</h2>
					<p><a href="https://www.imamemo.com/" target="_blank">https://www.imamemo.com/</a></p>

					<h2>商品の販売価格</h2>
					<p>各商品ページをご参照ください。</p>

					<h2>商品以外に必要な費用</h2>
					<p>消費税／手数料（コンビニ決済）</p>

					<h2>支払方法及び支払時期</h2>
					<p>1. クレジットカードでのお支払い</p>
					<p>ご利用頂けるカードは国内発行かつご本人様名義のVISA、MasterCard、JCB、AMEXです。商品注文時にお支払いが確定します or 支払時期は各カード会社の引き落とし日です。</p>

					<p>2. キャリア決済でのお支払い</p>
					<p>ご利用いただける携帯電話通信事業者が提供する決済サービスは、当社が指定するものに限られます。支払時期はお客様がご契約されている携帯電話通信事業者とのご契約内容をご確認ください。</p>

					<p>3. コンビニ決済</p>

					<h2>購入申込後のキャンセルと中途解約</h2>
					<p>売買契約が成立前で、かつ当社が指定する期間中に限り、当社所定の方法で、購入申込の取消が可能です。当該期間の経過後は、途中での解約はできません。ただし、当社の責めに帰すべき事由による場合は除きます。
					</p>
				</div>
			</section>
		</article>
		<Footer></Footer>
	</body>
</template>
<script>
import Footer from '@/components/Footer.vue'
import Header from '@/components/Header.vue'
let searchTimer = undefined;

export default {
	components: {
		Footer,
		Header,
	},
	data() {
		return {
			showSide: false,
			keywords: '', //搜索的绑定值
			showSearchList: false, //显示搜索列表
			searchList: [], //搜索列表
			activeSearch: '', //搜索列表选中
			selectTab: undefined,
			// tabs: [],
		}
	},
	methods: {
		targetSide() {
			this.showSide = !this.showSide
		},
		//Header
		keydownEnter() {
			this.toSearchPage()
		},
		changeSearchEvent(val) {
			this.keywords = val;
			this.setSearchList();
		},
		//搜索页
		toSearchPage() {
			if (this.keywords != '') {
				this.$router.push({ name: 'search', query: { keywords: this.keywords } })
			}
		},
		setSearchList() {
			if (this.keywords && this.keywords.trim() != '') {
				clearTimeout(searchTimer)
				searchTimer = setTimeout(() => {
					if (this.keywords) {
						Api.getSearch({
							keywords: this.keywords
						}).then((res) => {
							let data = res.result.data
							if (data.length > 0) {
								this.searchList = data
							}
							console.log(this.searchList);
						});
					}
				}, 300)
			} else {
				this.searchList = []
			}

		},
		changeTab(val) {
			this.selectTab = val
		},
	}
}
</script>
<style lang="scss">
.onerow {
	width: 1300px;
	margin: 0 auto;
	background-color: #FFFFFF;

	.under_Layer {
		padding: 77px 25px;
		line-height: 20px;
		font-size: 12px;
		font-family: 'Microsoft YaHei UI';
		color: #040000;
		font-weight: 300;
	}
}

.container {
	width: 80%;
	margin: 50px auto;
	background-color: #fff;
	padding: 20px;
	border-radius: 8px;
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

h1 {
	text-align: center;
	color: #333;
}

h2 {
	color: #555;
	margin-top: 20px;
}

p {
	margin: 10px 0;
}
</style>