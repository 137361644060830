import { DomEditor } from '@wangeditor/editor'
function withAttachment(editor) {
  const { isInline, isVoid } = editor
  const newEditor = editor
  newEditor.isInline = elem => {
    const type = DomEditor.getNodeType(elem)
    if (type === 'newele') return true // 针对 type: attachment ，设置为 inline
    return isInline(elem)
  }
  newEditor.isVoid = elem => {
    const type = DomEditor.getNodeType(elem)
    if (type === 'newele') return true // 针对 type: attachment ，设置为 void
    return isVoid(elem)
  }
  return newEditor;
}
export default withAttachment;