<!-- eslint-disable vue/no-multiple-template-root -->
<!-- eslint-disable vue/no-v-model-argument -->
<template>
    <div class="container-c">
        <div class="container-c-left">
            <!-- 在这里循环 -->
            <ListItem v-for="(item, index) in datas" :key="index" :item="item" :isLook="index === nowIndex"
                @loadHandler="loadHandler" @updateTitle="updateTitle" @openCommentHandler="openCommentHandler"
                :is-my-info="isMyInfo" :nowIndex="nowIndex">
            </ListItem>
        </div>
        <div class="container-c-right"
            :style="{ backgroundColor: isMyInfo ? 'transparent' : '', top: isMyInfo ? 0 : '52px' }">
            <!-- 相关条目 - 相关搜索-->
            <div class="container-right-box" v-show="!isMyInfo">
                <p>{{ this.$t('detail.entryDependent') }}</p>
                <ul>
                    <li @click="goDetail('622')"><span>1</span>西の河原公園</li>
                    <li @click="goDetail('1146')"><span>2</span>広島城</li>
                    <li @click="goDetail('1213')"><span>3</span>磐梯吾妻スカイライン</li>
                </ul>
            </div>
            <div class="container-right-box" style="border: 0;" v-show="!isMyInfo">
                <p>{{ this.$t('detail.searchDependent') }}</p>
                <ul v-if="involveSeacrchList.length > 0">
                    <li v-for="(item, index) in involveSeacrchList.slice(0, 3)" :key="item.id" @click="goDetail(item.id)">
                        <span>{{ index +
                            1 }}</span>{{ item.title }}
                    </li>
                </ul>
                <ul v-else>
                    <li>{{ this.$t('detail.noSearchDependent') }}</li>
                </ul>
            </div>

        </div>
    </div>
    <transition name="fade">
        <CommentBox ref="commentBoxRef" v-model:commentVisible="commentVisible" v-if="commentVisible"
            class="fade-enter-active comment-box" />
    </transition>
    <ImageEdit ref="imageEditTieleRef" v-model:imageEditVisible="imageEditVisible" :files="item" :isCreated="false"
        @updateTitle="updateTitle" :imgNote="imgNote" :pictureId="pictureId" :titleList="titleList" />
    <transition name="fade">
        <LargeImagePreview class="fade-enter-active large-image-preview " v-model:imagePreviewVisible="imagePreviewVisible"
            v-if="imagePreviewVisible" />
    </transition>
</template>
<script>
import ListItem from './ListItem';
import ImageEdit from '../ImageEdit/index.vue';
// 评论框
import CommentBox from '@/components/CommentBox.vue'
import storage from 'storejs';
import Api from '@/Api/index.js';
import $ from 'jquery';
// 引入大图预览组件
import LargeImagePreview from '@/components/LargeImagePreview/index.vue'


export default {
    components: { ListItem, ImageEdit, CommentBox, LargeImagePreview },
    props: {
        involveSeacrchList: {
            type: Array,
            required: true
        },
        datas: {
            type: Array,
            required: true
        },
        nowIndex: {
            type: Number
        },
        isMyInfo: {
            type: Boolean
        }
    },
    data() {
        return {
            imageEditVisible: false,
            item: [],
            imgNote: '',
            pictureId: null,
            commentVisible: false,
            titleListModel: {
                imageId: null,
                userId: storage.get('userInfo')?.userid,
                page: 1,
                pageSize: 10
            },
            titleList: [],
            //大图是否展示
            imagePreviewVisible: false
        }
    },
    created() {
    },
    async mounted() {
        try {
            //伪造数据 -- 相关条目 ==》后期需要改
            await Api.getRelatedArticles()
        } catch (e) {
            console.log('ListItem =>index List ===>Error', e)
        }

    },
    watch: {
        nowIndex: {
            handler() {
                this.commentVisible = false
            }
        }
    },
    methods: {
        // ImageUpdateTitle(){
        //     console.log(this.pictureId)
        // },
        //获取Title列表
        async updateTitle(item) {
            try {
                console.log('打开图片编辑器的item===》', item)
                if (item?.pictureUrl) {
                    // 如果有url,说明是初始化。
                    const temp = {
                        file: {
                            height: item?.pictureHeight,
                            name: item?.pictureName,
                            url: item?.pictureUrl,
                            width: item?.pictureWidth
                        }
                    }
                    this.item = [{ ...temp }];
                    this.titleListModel.imageId = item?.pictureId
                    this.imgNote = ''
                    this.pictureId = item?.pictureId ? item.pictureId : null
                }
                const { result: { data }, error_code } = await Api.getImageTitleInfos(this.titleListModel)
                if (error_code !== 0) return;
                this.titleList = data.list
                this.imageEditVisible = true
                this.$refs.imageEditTieleRef.editInit('')
            } catch (e) {
                console.log(e)
            }

        },
        goDetail(id) {
            let routeData = this.$router.resolve({
                name: 'Detail',
                params: { id, position: 't' }
            });
            window.open(routeData.href, '_blank');
        },
        openCommentHandler(item) {
            this.commentVisible = true
            this.$nextTick(() => {
                this.$refs.commentBoxRef.init(item)
            })
        },
        loadHandler(id) {
            $('.item-list-img' + id).hide();
            $('.item-list-img' + id).fadeIn(500);
            // console.log( $('.item-list-img' + id))
        }
    }
}
</script>

<style lang="scss" scoped>
.container-c {
    width: 1300px;
    display: flex;

    .container-c-left {
        width: 840px;
        height: 100%;
        overflow: hidden;

    }

    .container-c-right {
        margin-left: 20px;
        width: 440px;
        height: 653px;
        background-color: #fff;
        position: sticky;
        top: 52px;

        .container-right-box {
            box-sizing: border-box;
            padding: 40px 20px 60px 20px;
            border-bottom: 1px solid #EEEEEE;

            p {
                margin-bottom: 40px;
                font-size: 20px;
                font-family: Microsoft YaHei;
                font-weight: bold;
                line-height: 26px;
                color: #202124;
                opacity: 1;
            }

            ul {
                display: flex;
                flex-direction: column;
                margin: 0;

                li {
                    margin-bottom: 20px;
                    font-size: 16px;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    line-height: 21px;
                    color: #3C4043;
                    cursor: pointer;
                    padding-left: 5px;


                    span {
                        font-size: 16px;
                        font-family: Microsoft YaHei;
                        font-weight: bold;
                        line-height: 21px;
                        margin-right: 9px;
                    }

                    &:first-child>span {
                        color: #B34A4A;
                    }

                    &:nth-child(2)>span {
                        color: #F04C4D;
                    }

                    &:last-child>span {
                        color: #FFA218;
                    }

                    &:last-child {
                        margin: 0;

                    }
                }
            }
        }

        // .comment-dialog-outside {
        //     position: absolute;
        //     top: 0;
        //     left: 0;
        // }
    }
}

@media (max-width: 840px) {
    .detail-box .container {
        width: 100%;
        padding: 0 30px;
    }

    .story-box {
        width: 100%;
    }

    .detail-box .container .container-c {
        width: 100%;
        display: block;

        .container-c-left {
            width: 100%;
        }

        .container-c-right {
            margin-left: 0px;
            width: 100%;
            flex: 1;
            display: none;
        }
    }
}

.comment-box {
    width: 440px;
    height: calc(100vh - 52px);
    position: fixed;
    top: 52px;
    right: 0;
    z-index: 999;
}
</style>
<style>
.fade-enter-active,
.fade-leave-active {
    transition: opacity .5s;
}

.fade-enter-from,
.fade-leave-to {
    opacity: 0;
}

.large-image-preview {
    position: fixed;
    z-index: 999;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: pink;
}</style>