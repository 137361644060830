<template>
    <div  v-html="hightrightText"></div>
</template>
<script>
    export default {
        name: 'HightLight',
        props: ['role_text','text'],
        data() {
            return {

            }
        },
        computed: {
            hightrightText() {
                let text = this.text;
                if (!text) {
                    return '';
                }
                let textString = text;
                if (this.text && this.text.length > 0) {
                    let re = new RegExp(this.role_text,'g')
                    textString = text.replace(re, function(x) {
                        return '<span style="color: #0870D1">' + x + '</span>';
                    });
                }
                return textString;
            }
        },
        methods: {

        }
    }

</script>
<style scoped lang="scss">
</style>
